import { RadioGroup } from "@mui/material";
import { RadioButton } from "@sherloq/design-system";
import React from "react";
import {
  RadioLabel,
  Label,
  RadioButtonContainer,
  Container,
} from "./components";

interface Props {
  value: any;
  onChange?: any;
}

export const QueriedInFilter: React.FC<Omit<Props, "children">> = ({
  value,
  onChange,
}) => {
  return (
    <Container>
      <Label variant="small">Queried in</Label>
      <RadioGroup
        row
        value={value}
        onChange={onChange}
        aria-labelledby="demo-radio-buttons-group-label"
        name="radio-buttons-group"
      >
        <RadioButtonContainer
          value=""
          control={<RadioButton />}
          label={<RadioLabel>All</RadioLabel>}
        />
        <RadioButtonContainer
          value="1"
          control={<RadioButton />}
          label={<RadioLabel>Last day</RadioLabel>}
        />
        <RadioButtonContainer
          value="7"
          control={<RadioButton />}
          label={<RadioLabel>Last week</RadioLabel>}
        />
        <RadioButtonContainer
          value="30"
          control={<RadioButton />}
          label={<RadioLabel>Last month</RadioLabel>}
        />
      </RadioGroup>
    </Container>
  );
};
