import { FormControl, FormControlLabel } from "@mui/material";
import { Typography, TypographyProps } from "@sherloq/design-system";
import React from "react";
import styled from "styled-components";
import { LabelTypography } from "../Typography";

export const Label = styled(LabelTypography)`
  margin-bottom: 8px;
`;

export const Container = styled(FormControl)`
  margin-bottom: 24px !important;
`;

export const RadioButtonContainer = styled(FormControlLabel)`
  display: flex;
  height: 32px;
  background: white;
  border: 1px solid #d9d9d9;
  border-radius: 3px;
  justify-content: center;
  align-items: center;
  padding-right: 8px;
  box-sizing: border-box;
  margin-right: 8px !important;
  margin-left: 0 !important;
`;

export const RadioLabel = ({ children, ...props }: TypographyProps) =>
  React.createElement(Typography, { variant: "small", ...props }, children);
