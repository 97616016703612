import { AppRoutes } from "../../application";
import { useNavigate } from "react-router-dom";
import { IQuery, Query } from "../../core";
import { AnalyticsClient } from "../../infrastructure";

export const useQueryComponent = (data: IQuery) => {
  const navigate = useNavigate();
  const query = new Query(data).data;

  const onClickQuery = () => {
    navigate(`/${AppRoutes.QUERY_PROFILE}/${query.queryId}`);
    AnalyticsClient.trackAction("main_query_results_query_click", {
      id: query.queryId,
    });
  };
  return { ...query, onClickQuery };
};
