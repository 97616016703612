import { DefualtQueriedInFilterOptions, TableService } from "../../application";
import { useQuery } from "react-query";
import { makeUser } from "../../core";
import { useAssetFilters } from "../useAssetFilters";
import { useGlobalAuthState } from "../user";
import { useEffect, useMemo } from "react";
import { AnalyticsClient } from "../../infrastructure";

export const useTableProfileQueryUsageSideView = () => {
  const {
    addRule,
    filter,
    onChangeLastQueriedBy,
    onChangeQueriedIn,
    onUpdateRules,
    removeRule,
    resetFilters,
    getDropdownRuleValues,
    getFilterRuleConditions: getFilterAssetRuleConditions,
    getRuleDropdownState: getFilterAssetRuleDropdownState,
  } = useAssetFilters("main_table_profile");
  const { users, user } = useGlobalAuthState();
  const {
    data: queries,
    isLoading,
    isFetching,
    isError,
    refetch,
  } = useQuery({
    queryKey: ["tables-queries-usage", filter],
    queryFn: () => TableService.getTableProfileQueriesUsage(filter),
    enabled: false,
  });

  const getFilterRuleConditions = getFilterAssetRuleConditions("table");
  const getRuleDropdownState = getFilterAssetRuleDropdownState("table");

  const onResetFilters = () => {
    resetFilters();
    refetch();
    AnalyticsClient.trackAction(
      "main_table_profile_query_usage_reset_filters_button_click"
    );
  };

  const onApplyFilters = () => {
    refetch();
    AnalyticsClient.trackAction(
      "main_table_profile_query_usage_apply_button_click",
      { filter }
    );
  };

  const ruleColumns = useMemo(() => {
    if (!user) return [];
    const SherloqUserInstance = makeUser(user);
    return SherloqUserInstance.tableRulesColumns;
  }, [user]);

  useEffect(() => {
    refetch();
  }, []);

  return {
    queries,
    addRule,
    filter,
    onChangeLastQueriedBy,
    onChangeQueriedIn,
    onUpdateRules,
    removeRule,
    onResetFilters,
    QueriedInFilterOptions: DefualtQueriedInFilterOptions,
    lastQueriedByOptions: users,
    getFilterRuleConditions,
    filterRuleColumns: ruleColumns as any,
    onApplyFilters,
    isLoading: isLoading || isFetching,
    isError,
    getDropdownRuleValues,
    getRuleDropdownState,
  };
};
