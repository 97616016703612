import { RadioButtonContainer as RootRadioButton } from "../../../../shared-components";
import { RadioButton as SherloqRadioButton } from "@sherloq/design-system";
import styled from "styled-components";

export const RadioButton = styled(SherloqRadioButton)`
  width: 16px;
  height: 16px;
  box-sizing: border-box;
  margin-left: 2px;
  margin-right: 4px;
  padding: 0;
`;

export const RadioButtonContainer = styled(RootRadioButton)`
  border: none;
`;

export const MostCommonFieldsGraphContainer = styled.div`
  width: 100%;
  height: 390px;
`;
