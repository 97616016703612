/* eslint-disable no-unsafe-optional-chaining */
import {
  ITableProfileMostJoinedTableAndFields,
  ITableProfileMostUsedAndLastUsedQuery,
  ITableProfileRowType,
  ITableProfileTableStatics,
  ITableProfileTopUser,
  TableProfileTopField,
  transformAssetDate,
  transformAssetUsername,
} from "../../core";

export interface TransformedTableProfile {
  tableName: string;
  tableDescription: string;
  tableProfileRow: ITableProfileRowType;
  topFields: TableProfileTopField[];
  mostUsedQuery: ITableProfileMostUsedAndLastUsedQuery;
  lastQueryRun: ITableProfileMostUsedAndLastUsedQuery;
  topUsers: ITableProfileTopUser[];
  mostJoinedTablesAndFields: ITableProfileMostJoinedTableAndFields[];
  mostCommonFieldsByOperator: any[];
  tableStatistics: ITableProfileTableStatics;
  readme: string;
}

const createDescription = (data: any[]) => {
  if (Array.isArray(data) && data.length > 0) {
    return data[0].input;
  } else return "";
};

const parsePercentage = (value: number) => {
  return Number((value * 100).toFixed(1));
};

export const transformTableProfileResults = (data: any) => {
  //
  const {
    TableInfo,
    TopFields,
    TopUsers,
    MostJoinedTable,
    MostCommonFieldByOperator: mostCommonFieldsByOperator,
    ReadMe,
    GivenOwner,
  } = data;
  const tableDescription = createDescription(data?.TableDescription);
  const tableReadMe = createDescription(ReadMe);
  const tableOwner = createDescription(GivenOwner);
  const {
    tableName,
    source,
    dbName,
    lastReferenceDatetime,
    topQueryNumReferences,
    topQueryString,
    topQueryUsername,
    topQueryLastDate,
    topQueryId,
    lastQueryThatReferenced,
    lastUsernameThatReferenced,
    lastSherloqExecutionId,
    numOfFields,
    numOfActiveFields,
    numOfReferences,
    percentageOfActiveFields,
  } = TableInfo?.[0];

  const lastQueried = transformAssetDate(lastReferenceDatetime);
  const topFields = TopFields.map(
    ({
      fieldName,
      fieldDescription,
      referencePercentageOutOfTableReferences: value,
    }: any): TableProfileTopField => ({
      fieldName,
      fieldDescription,
      value: Number(value.toFixed(1)),
    })
  );

  const topUsers = TopUsers?.map(
    ({
      username,
      numofreferences,
      lastquerydate,
      querystring,
      queryid,
    }): ITableProfileTopUser => ({
      lastquerydate: transformAssetDate(lastquerydate),
      numofreferences,
      username: transformAssetUsername(username),
      querystring: querystring,
      queryId: queryid,
    })
  );
  const mostJoinedTablesAndFields = MostJoinedTable?.map(
    ({
      firsttablename: tableName,
      secondtablename: fieldName,
      num_references: numOfReferences,
      fields,
    }): ITableProfileMostJoinedTableAndFields => ({
      numOfReferences,
      fieldName,
      tableName,
      fields,
    })
  );

  const tableStatistics: ITableProfileTableStatics = {
    numOfActiveFields,
    numOfFields,
    numOfReferences,
    percentageOfActiveFields: parsePercentage(percentageOfActiveFields),
  };

  const transformedTableProfileData: TransformedTableProfile = {
    tableName,
    tableDescription,
    tableProfileRow: {
      createdBy: "-",
      dbName,
      lastUpdated: "-",
      source,
      tableOwner: tableOwner || "-",
      lastQueried,
    },
    mostUsedQuery: {
      id: topQueryId,
      lastQueriedBy: transformAssetUsername(topQueryUsername),
      lastQueriedOn: transformAssetDate(topQueryLastDate),
      numberOfRuns: topQueryNumReferences,
      query: topQueryString,
    },
    lastQueryRun: {
      id: lastSherloqExecutionId,
      lastQueriedBy: transformAssetUsername(lastUsernameThatReferenced),
      lastQueriedOn: lastQueried,
      numberOfRuns: 1,
      query: lastQueryThatReferenced,
    },
    topFields,
    topUsers,
    mostJoinedTablesAndFields,
    mostCommonFieldsByOperator,
    tableStatistics,
    readme: tableReadMe,
  };

  return transformedTableProfileData;
};
