/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  TableService,
  TransformedTableProfile,
  transformTableProfileResults,
} from "../../application";
import { useMutation, useQueries } from "react-query";
import { useParams } from "react-router-dom";
import {
  useSetTableProfileGlobalState,
  useTableProfileGlobalState,
} from "../context";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  AnalyticsClient,
  notificationSnackbar,
  TableProfileGlobalState,
} from "../../infrastructure";
import { nativeCopyToClipboard } from "../../infrastructure";
import { useGlobalAuthState } from "../user";
import { useDOMDetectComponentScroll } from "../useDOMDetectComponentScroll";

export const useTableProfile = () => {
  const { id } = useParams();
  const { user } = useGlobalAuthState();
  const {
    openEditTableProfileModal,
    openQueriesUsageSideView,
    openTopFieldsSideView,
    openTableStatisticsSideView,
    openMostJoinedFieldsSideView,
  } = useTableProfileGlobalState();
  const updateTableProfileGlobalState = useSetTableProfileGlobalState();
  const [readMe, setReadme] = useState("");
  const [tableDescription, setTableDescription] = useState("");
  const [tableOwner, setTableOwner] = useState("");
  const { componentRef, isSatisfied: isSticky } = useDOMDetectComponentScroll({
    threshold: 41,
  });

  const [
    {
      data,
      isLoading: loadingTableProfile,
      isError: failedToLoadTableProfile,
      refetch: fetchTableProfile,
    },
    { data: tableFieldsData, refetch: fetchTableProfileFields },
    { data: tableIsInUserList, refetch: fetchIsTableInUserListState },
  ] = useQueries([
    {
      queryKey: ["table-profile", id],
      queryFn: () => TableService.getTableProfile(id ?? ""),
      enabled: false,
    },
    {
      queryKey: ["table-profile-fields", id],
      queryFn: () => TableService.getTableFields(id ?? ""),
      enabled: false,
    },
    {
      queryKey: ["table-in-user-list", id],
      queryFn: () =>
        TableService.isTableInUserList(id ?? "", user.SherloqUserId),
      enabled: false,
    },
  ]);

  const { mutateAsync: removeTableFromUserListAsync } = useMutation({
    mutationKey: ["remove-table-from-user-list", id],
    mutationFn: () =>
      TableService.removeTableFromUserList(id!, user?.SherloqUserId),
    onSuccess: () => {
      fetchIsTableInUserListState();
      notificationSnackbar.success("Table removed from My List");
      AnalyticsClient.trackAction(
        "main_table_profile_remove_from_my_list_success"
      );
    },
    onError: () => {
      notificationSnackbar.error("Failed to remove table from My List");
      AnalyticsClient.trackAction(
        "main_table_profile_remove_from_my_list_failed"
      );
    },
  });

  const { mutateAsync: addTableToUserList } = useMutation({
    mutationKey: ["add-table-to-user-list", id],
    mutationFn: () => TableService.addTableToUserList(id!, user?.SherloqUserId),
    onSuccess: () => {
      fetchIsTableInUserListState();
      notificationSnackbar.success("Table added to My List");
      AnalyticsClient.trackAction("main_table_profile_add_to_my_list_success");
    },
    onError: () => {
      notificationSnackbar.error("Failed to add table to My List");
      AnalyticsClient.trackAction("main_table_profile_add_to_my_list_failed");
    },
  });

  const { mutateAsync: updateTableReadMe } = useMutation({
    mutationKey: ["update-table-readme", id],
    mutationFn: (readMe: string) => {
      return new Promise((res, rej) => {
        TableService.changeTableReadMe(id!, user?.SherloqUserId, readMe)
          .then(() => res(readMe))
          .catch(() => rej(readMe));
      });
    },
    onSuccess: (value: any) => {
      notificationSnackbar.success(`Successfully updated Table comments`);
      setReadme(value);
    },
    onError: () => {
      notificationSnackbar.error("Failed to update Table comments");
    },
  });

  const tableProfile = useMemo(() => {
    if (!data) return {} as TransformedTableProfile;
    const transformedTableData = transformTableProfileResults(data);
    setReadme(transformedTableData.readme);
    setTableDescription(data?.TableInfo[0]?.tableDescription ? data?.TableInfo[0]?.tableDescription : transformedTableData.tableDescription);
    setTableOwner(transformedTableData.tableProfileRow.tableOwner);
    return transformedTableData;
  }, [data]);

  const displayedTableName = useMemo(() => {
    if ("tableProfileRow" in tableProfile) {
      return `${tableProfile.tableProfileRow.dbName}.${tableProfile.tableName}`;
    }
    return "";
  }, [tableProfile]);

  const mostCommonFieldsByFieldData = useMemo(() => {
    if (tableFieldsData) return tableFieldsData;
    else return [];
  }, [tableFieldsData]);

  const minTopFields = useMemo(() => {
    if (Object.keys(tableProfile).length > 0) {
      const minItems = tableProfile?.topFields?.slice(0, 6);
      return minItems;
    }
    return [];
  }, [tableProfile]);

  const minTopUsers = useMemo(() => {
    if (Object.keys(tableProfile).length > 0) {
      const minItems = tableProfile?.topUsers?.slice(0, 6);
      return minItems;
    }
    return [];
  }, [tableProfile]);

  const minMostJoinedTablesAndFields = useMemo(() => {
    if (
      tableProfile.mostJoinedTablesAndFields &&
      tableProfile.mostJoinedTablesAndFields.length > 5
    ) {
      const listCopy = [...tableProfile.mostJoinedTablesAndFields];
      listCopy.length = 5;
      return listCopy;
    }
    return tableProfile.mostJoinedTablesAndFields;
  }, [tableProfile]);

  const createEditSideViewsStateFactoryFn = useCallback(
    (componentState: keyof TableProfileGlobalState) => {
      return function (state: boolean) {
        updateTableProfileGlobalState((prev) => ({
          ...prev,
          [componentState]: state,
        }));
      };
    },
    [updateTableProfileGlobalState]
  );

  const setOpenEditTableProfileModal = (state: boolean) => {
    AnalyticsClient.trackAction("main_table_profile_edit_info_button_click", {
      state: state ? "open" : "close",
    });
    createEditSideViewsStateFactoryFn("openEditTableProfileModal")(state);
  };

  const setOpenQueriesUsageSideView = (state: boolean) => {
    AnalyticsClient.trackAction(
      "main_table_profile_view_queries_usage_button_click",
      { state: state ? "open" : "close" }
    );
    createEditSideViewsStateFactoryFn("openQueriesUsageSideView")(state);
  };
  const setOpenTopFieldsSideView = (state: boolean) => {
    AnalyticsClient.trackAction(
      "main_table_profile_view_top_fields_button_click",
      { state: state ? "open" : "close" }
    );
    createEditSideViewsStateFactoryFn("openTopFieldsSideView")(state);
  };

  const setOpenTopusersSideView = (state: boolean) => {
    AnalyticsClient.trackAction(
      "main_table_profile_view_top_users_button_click",
      { state: state ? "open" : "close" }
    );
    createEditSideViewsStateFactoryFn("openTopusersSideView")(state);
  };

  const setOpenTableStatisticsSideView = (state: boolean) => {
    AnalyticsClient.trackAction(
      "main_table_profile_view_table_stats_button_click",
      { state: state ? "open" : "close" }
    );
    createEditSideViewsStateFactoryFn("openTableStatisticsSideView")(state);
  };

  const setOpenMostJoinedFieldsBreakdownSideView = (state: boolean) => {
    AnalyticsClient.trackAction(
      "main_table_profile_view_most_joined_fields_breakdown_button_click",
      { state: state ? "open" : "close" }
    );
    createEditSideViewsStateFactoryFn("openMostJoinedFieldsSideView")(state);
  };

  const onUpdateTableInfo = (updatedOwner: string, updatedDesc: string) => {
    // these checks make sure that we set the updated value of the fields only when we changed them from the modal
    if (updatedOwner) {
      setTableOwner(updatedOwner);
    }
    if (updatedDesc) {
      setTableDescription(updatedDesc);
    }
  };

  const onShareTable = () => {
    nativeCopyToClipboard(location.href);
    notificationSnackbar.success("Link to table profile copied to clipboard");
    AnalyticsClient.trackAction("main_table_profile_share_table_button_click");
  };

  useEffect(() => {
    fetchTableProfile();
    fetchTableProfileFields();
    fetchIsTableInUserListState();
  }, []);

  return {
    openEditTableProfileModal,
    openQueriesUsageSideView,
    openTopFieldsSideView,
    openMostJoinedFieldsSideView,
    setOpenEditTableProfileModal,
    setOpenQueriesUsageSideView,
    setOpenTopFieldsSideView,
    setOpenTopusersSideView,
    setOpenMostJoinedFieldsBreakdownSideView,
    tableProfile,
    minTopFields,
    minTopUsers,
    mostCommonFieldsByFieldData,
    id,
    onUpdateTableInfo,
    tableDescription,
    openTableStatisticsSideView,
    setOpenTableStatisticsSideView,
    loadingTableProfile,
    failedToLoadTableProfile,
    onShareTable,
    tableIsInUserList: Boolean(tableIsInUserList),
    removeTableFromUserListAsync: removeTableFromUserListAsync as any,
    addTableToUserList: addTableToUserList as any,
    updateTableProfileReadMe: updateTableReadMe,
    readMe,
    tableOwner,
    minMostJoinedTablesAndFields,
    isSticky,
    componentRef,
    displayedTableName,
  };
};
