import { ITableProfileMostUsedAndLastUsedQuery } from "../../core";
import {
  nativeCopyToClipboard,
  notificationSnackbar,
} from "../../infrastructure";

interface Props {
  data: ITableProfileMostUsedAndLastUsedQuery;
}
export const useTableProfileMostUsedQuery = ({ data }: Props) => {
  const onClickCopy = () => {
    nativeCopyToClipboard(data.query);
    notificationSnackbar.success("Copied Most Used Query string to Clipboard ");
  };
  return {
    ...data,
    onClickCopy,
  };
};
