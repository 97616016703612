import React from "react";
import { Typography } from "../../..";
import { AvatarContainer } from "./components";

interface UserAvatarProps {
  initials: string;
}

export const UserAvatar: React.FC<UserAvatarProps> = ({ initials }) => {
  return (
    <AvatarContainer>
      <Typography color="#fff" variant="small">
        {initials}
      </Typography>
    </AvatarContainer>
  );
};
