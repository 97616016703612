import { Typography } from "../../../../../../shared-components";
import { Container } from "./components";

interface IQueryProfileUsedTablesAndFields {
  table: string;
  fields: string;
}

interface Props {
  data: IQueryProfileUsedTablesAndFields;
}

export const UsedTablesAndFieldsRow = ({ data }: Props) => {
  const { fields, table } = data;

  return (
    <Container>
      <Typography variant="p-light" color="#252525">
        {table}
      </Typography>
      <Typography variant="small" color="#707070">
        {fields}
      </Typography>
    </Container>
  );
};
