import { IQueryProfileRow } from "@/v2/application";
import {
  AssetProfileRowDataTitle,
  AssetProfileRowDataValue,
  AssetSource,
  Column,
} from "../../../../shared-components";
import { Container } from "./components";

interface Props {
  data: IQueryProfileRow;
}

export const QueryProfileRow = ({ data }: Props) => {
  const { createdBy, lastRunAt, lastRunBy, source } = data ?? {};
  return (
    <Container>
      <Column>
        <AssetProfileRowDataTitle>Created by</AssetProfileRowDataTitle>
        <AssetProfileRowDataValue>{createdBy}</AssetProfileRowDataValue>
      </Column>
      <Column>
        <AssetProfileRowDataTitle>Last run at</AssetProfileRowDataTitle>
        <AssetProfileRowDataValue>{lastRunAt}</AssetProfileRowDataValue>
      </Column>
      <Column>
        <AssetProfileRowDataTitle>Last run by</AssetProfileRowDataTitle>
        <AssetProfileRowDataValue>{lastRunBy}</AssetProfileRowDataValue>
      </Column>
      <Column>
        <AssetProfileRowDataTitle>Source</AssetProfileRowDataTitle>
        <AssetSource source={source} />
      </Column>
    </Container>
  );
};
