import SelectDropdownButton from '../../../../components/SelectDropdown/SelectDropdown'
import React, { useEffect, useState } from 'react'
import theme from '../../../../styles/theme'
import globalImages from '../../../../assets/images/global';
import { AppTypography } from '../../../../styles/global'
import { TypographySize } from '../../../../types/styles.d'
import * as Styled from './Workgroup.styled'
import Button from '../../../../components/Button/button-component'
import { TableService } from "../../../../v2/application";
import { RootState, useReduxSelector } from '../../../../redux'
import { Tooltip } from '@material-ui/core';
import { setMaxString } from '../../../../utils/helpers/strings';

function Workgroups({ getWorkgroupFilteredString, reset }) {
    const state = useReduxSelector((state: RootState) => state)
    const globalUser = state.auth.details;
    const [filterDropDownText, setFilterDropDownText] = useState('Workgroups')
    const [workgroups, setWorkgroups] = useState<any>([])

    const getWorkgroups = async () => {
        const workgroupData = await TableService.getWorkgroups()
        const workgroupStructure = workgroupData.map((workG: string) => {
            return {
                checked: false,
                value: workG
            }
        })
        setWorkgroups(workgroupStructure)
    }
    const handleCheckboxChange = (index: number) => {
        if (!workgroups) return
        if (workgroups.length <= 0) return
        const newStruct = [...workgroups].map((struct, i) => {
            if (index === i) {
                return {
                    checked: !struct.checked,
                    value: struct.value
                }
            }
            return struct
        })
        setWorkgroups(newStruct)
    }

    const handleApplyClick = () => {
        const selectedWorkgroups = workgroups
            .filter(({ checked }) => checked == true)
            .map(({ value }) => value)
            .join(',')
        getWorkgroupFilteredString(selectedWorkgroups)
        setFilterDropDownText('Filtered')
    }

    const handleReset = () => {
        reset()
        setFilterDropDownText('Workgroups')
    }
    useEffect(() => {
        setFilterDropDownText('Workgroups')
        getWorkgroups()
    }, [globalUser.CompanyId])
    return (
        <Styled.WorkgroupsContainerWrapper>
            <Styled.WorkgroupsDropDown>
                {workgroups.length > 0 && <SelectDropdownButton
                    radius={5}
                    border={`1px solid ${theme.appColors.ash}`}
                    postIcon={globalImages.chevronBottomBlack}
                    postIconSize={'13'}
                    positionRight={'6'}
                    text={filterDropDownText}
                    wrapper={
                        <Styled.FilterDropdownContainer>
                            {
                                workgroups.map((workG: { checked: boolean, value: string }, index: number) => (
                                    <div key={index}>
                                        <label htmlFor={workG.value}>
                                            <Styled.CheckboxContainer>
                                                <Tooltip title={workG.value} placement='top-start'>
                                                    <Styled.CheckboxAndText>
                                                        <Styled.InputCheckbox
                                                            checked={workG.checked}
                                                            onChange={() => handleCheckboxChange(index)}
                                                            type="checkbox"
                                                            id={workG.value}
                                                            name={workG.value}
                                                            value={workG.value} />
                                                        <AppTypography
                                                            size={TypographySize.sm}>
                                                            {setMaxString(workG?.value, 15)}
                                                        </AppTypography>
                                                    </Styled.CheckboxAndText>
                                                </Tooltip>
                                            </Styled.CheckboxContainer>
                                        </label>
                                    </div>
                                ))
                            }
                            <Styled.DropDownFooter>
                                <Styled.ButtonContainer>
                                    <Button
                                        border={`1px solid ${theme.appColors.tertiary}`}
                                        background={theme.appColors.tertiary}
                                        color={theme.appColors.white}
                                        text={'Apply'}
                                        radius={5}
                                        textSize='xs'
                                        size={{ width: 'fit-content', height: '4px' }}
                                        type={'button'}
                                        onClick={handleApplyClick} />
                                    <Button
                                        id={'SelectDropdownCancel'}
                                        border={`1px solid ${theme.appColors.tertiary}`}
                                        color={theme.appColors.tertiary}
                                        radius={5}
                                        size={{ width: 'fit-content', height: '4px' }}
                                        text={'Cancel'}
                                        textSize='xs'
                                        type={'button'} />
                                </Styled.ButtonContainer>
                            </Styled.DropDownFooter>
                        </Styled.FilterDropdownContainer>
                    }
                />}
            </Styled.WorkgroupsDropDown>
            <Styled.ResetButtonContainer>
                <Styled.SmallIcon onClick={handleReset} src={globalImages.resetIcon} />
            </Styled.ResetButtonContainer>
        </Styled.WorkgroupsContainerWrapper>
    )
}

export default Workgroups