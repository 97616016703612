import { useWeeklyCostReport } from "./useWeeklyCostsReport";

export function useRuntime() {
  const report = useWeeklyCostReport({
    type: "runtime",
    averageYIndex: (data) => data.WeeklyCost.AverageCost / 1000,
    sumYIndex: (data) => data.WeeklyCost.Sum / 1000 / 60 / 60,
  });

  return { ...report };
}
