import {
  QueryClient,
  QueryClientProvider as RQClientProvider,
} from "react-query";

const client = new QueryClient();

export const QueryClientProvider = ({ children }: any) => {
  return <RQClientProvider client={client}>{children}</RQClientProvider>;
};
