import React from "react";
import MostExpensiveQuery from "../Tables/MostExpensiveQuery";
import WeeklyCost from "../Tables/WeeklyCost";
import * as Styled from "./Runtime.styled";
import { useGlobalAuthState, useRuntime } from "../../../../v2/hooks";

function Runtime() {
  const { details } = useGlobalAuthState();
  const {
    mostExpensiveQueries,
    loadingMostExpensiveQueries,
    weeklyCost,
    loadingWeeklyCost,
    getWeeklyCostPerQueryRun,
    getTop5MostExpensiveThisWeek,
  } = useRuntime();
  return (
    <Styled.CostContainer>
      <Styled.CostGraphContainer>
        <WeeklyCost
          type='Runtime'
          sumTitle='Runtime in Hours'
          avgTitle='Runtime in Seconds'
          callAPI={getWeeklyCostPerQueryRun} 
          loading={loadingWeeklyCost} 
          data={weeklyCost}
        />
      </Styled.CostGraphContainer>
      {details.CompanyId != 4 ? (
        <Styled.MostExpensiveContainer>
          <MostExpensiveQuery
            title="5 Longest Queries This Week"
            assetType="runtime"
            callAPI={getTop5MostExpensiveThisWeek}
            loading={loadingMostExpensiveQueries}
            mostExpensiveQueryData={mostExpensiveQueries}
          />
        </Styled.MostExpensiveContainer>
      ) : (
        <></>
      )}
    </Styled.CostContainer>
  );
}

export default Runtime;
