import React from "react";
import PaginationComponent from "../Pagination";
import {
  HeaderRow,
  LegendColorCircle,
  LegendContainer,
  LegendItemContainer,
  LoadingContainer,
} from "./styled";
import CardComponent, { CardProps } from "../card/CardComponent";
import { useTableRuntimeAnalytics } from "./useRuntime";
import { AppTypography } from "../../styles/global";
import { TypographyBold, TypographySize } from "../../types/styles.d";
import Loading from "../Loading/Loading";
import { RuntimeGraph, RuntimeGraphProps } from "./graph";

type GraphLegendType = {
  [key in "label" | "color"]: string;
};
export interface RuntimeProps extends Omit<CardProps, "children"> {
  refetchData?: (...args: any[]) => void;
  loading?: boolean;
  data?: any;
  header?: string;
  legend?: GraphLegendType[];
  legendMetric?: string;
  pageSize?: number;
  graphProps?: Omit<RuntimeGraphProps, "data">;
}

const Runtime: React.FC<RuntimeProps> = ({
  refetchData,
  loading,
  data,
  pageSize,
  legend,
  legendMetric,
  header,
  ...props
}) => {
  const { paginationConfig, onSkipToNextPage, onSkipToPreviousPage } =
    useTableRuntimeAnalytics({ refetch: refetchData, PageSize: pageSize });

  return (
    <CardComponent {...props}>
      <>
        <HeaderRow>
          <AppTypography
            wrap="true"
            bold={TypographyBold.lg}
            size={TypographySize.md}
            textColor="#7209B7"
          >
            {header ?? ""}
          </AppTypography>
          <LegendContainer>
            {legend &&
              legend?.map(({ color, label }, idx) => {
                return (
                  <LegendItemContainer key={idx}>
                    <LegendColorCircle color={color} />
                    <AppTypography size={TypographySize.sm}>
                      {label} {legendMetric ?? ""}
                    </AppTypography>
                  </LegendItemContainer>
                );
              })}
          </LegendContainer>
        </HeaderRow>
        <div style={{ position: "relative" }}>
          <div style={{ height: 300 }}>
            {loading && (
              <LoadingContainer>
                <Loading size={20} height={5} />
              </LoadingContainer>
            )}
            {!loading && data && (
              <RuntimeGraph data={data ?? []} {...props.graphProps} />
            )}
          </div>
          <PaginationComponent
            style={{
              marginTop: 0,
              position: "absolute",
              bottom: 0,
            }}
            currentPage={paginationConfig.start}
            totalPages={paginationConfig.end}
            onSkipToNextPage={onSkipToNextPage}
            onSkipToPreviousPage={onSkipToPreviousPage}
            showNext
            showPrevious={paginationConfig.showPrevious}
          />
        </div>
      </>
    </CardComponent>
  );
};

export default Runtime;
