import { RenderIf } from "../../../lib";
import { useQueryResultsSubpage } from "../../../../hooks";
import { DefaultQuery, HomeNestedNavOutlet } from "../components";
import { ListFallBack } from "../../../shared-components";
import InfiniteScroll from "react-infinite-scroll-component";

export const HomeQueryResults = () => {
  const { data, isLoading, isError, fetchMoreData, hasMore } =
    useQueryResultsSubpage();
  return (
    <>
      {RenderIf(
        data.length > 0,
        <InfiniteScroll
          dataLength={data ? data?.length : 10}
          next={fetchMoreData}
          hasMore={hasMore}
          loader={""}
          endMessage={<p>All data has been loaded.</p>}
          scrollableTarget={"homepage"}
        >
          <HomeNestedNavOutlet
            data={data as any}
            renderData={(query: any, key) => (
              <DefaultQuery data={query} key={key} />
            )}
          />
        </InfiniteScroll>
      )}
      {RenderIf(
        isLoading || isError || data?.length === 0,
        <ListFallBack
          {...{
            isError,
            isLoading,
            noData: data?.length === 0,
          }}
        />
      )}
    </>
  );
};
