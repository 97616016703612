import { ActionMeta } from "react-select";
import * as React from "react";
import {
  filterBarPlaceHolders,
  getValueOptions,
} from "../../utils/consts/FilterBar";
import { ChangeEventHandler } from "react";
import {
  Dropdown,
  FilterBarContainer,
  HeaderText,
  ValueTextBox,
} from "./FilterBar.styled";
import { Option } from "../../types/select.d";

interface FilterBarProps {
  headerText: string;
  columnValue: Option | null;
  onColumnChange: (newValue: any, actionMeta: ActionMeta<any>) => void;
  columnOptions: Array<Option>;
  conditionValue: Option | null;
  onConditionChange: (newValue: any, actionMeta: ActionMeta<any>) => void;
  onValueDropdownChange: (newValue: any, actionMeta: ActionMeta<any>) => void;
  conditionOptions: Array<Option>;
  value: string;
  onValueChange: ChangeEventHandler<HTMLInputElement>;
  onSubmit: () => void;
  currentSelectedValues: any;
}

export const customStyles = {
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  indicatorSeparator: (styles: any) => {
    return { ...styles, display: "none" };
  },

  indicatorsContainer: (styles: any) => {
    return { ...styles, innerWidth: "80px", outerWidth: "100px" };
  },
};

const FilterBar: React.FC<FilterBarProps> = ({
  headerText,
  columnValue,
  onColumnChange,
  columnOptions,
  conditionValue,
  onConditionChange,
  conditionOptions,
  value,
  onValueChange,
  onValueDropdownChange,
  currentSelectedValues,
  onSubmit,
}) => {
  const [valueOptions, setValueOptions] = React.useState<any>([]);
  const [businessUnit, setBusinessUnit] = React.useState(undefined);
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key !== "Enter") return;
    if (onSubmit) return onSubmit();
    return;
  };
  React.useEffect(() => {
    (async () => {
      setBusinessUnit(undefined);
      currentSelectedValues.forEach((item) => {
        if (item?.value?.value && item?.column?.type == "businessUnit") {
          setBusinessUnit(item?.value?.value);
        }
      });
      let data: any[] = [];
      if (["querySource", "source"].includes(columnValue?.type ?? "")) {
        data = [
          { value: "bigquery", label: "BigQuery" },
          { value: "athena", label: "Athena" },
        ];
      } else {
        data = await getValueOptions(columnValue?.type, businessUnit);
      }
      setValueOptions(data);
    })();
  }, [columnValue?.type, JSON.stringify(currentSelectedValues)]);

  return (
    <FilterBarContainer>
      <HeaderText>{headerText}</HeaderText>
      <Dropdown
        menuPortalTarget={document.body}
        menuPosition={"fixed"}
        styles={customStyles}
        value={columnValue}
        onChange={onColumnChange}
        placeholder={filterBarPlaceHolders.COLUMN_PLACEHOLDER}
        options={columnOptions as Option[]}
      />

      <Dropdown
        value={conditionValue}
        menuPortalTarget={document.body}
        menuPosition={"fixed"}
        styles={customStyles}
        onChange={onConditionChange}
        placeholder={filterBarPlaceHolders.CONDITION_PLACEHOLDER}
        options={conditionOptions}
      />

      {columnValue?.type == "department" ||
      columnValue?.type == "jobTitle" ||
      columnValue?.type == "querySource" ||
      columnValue?.type == "source" ||
      columnValue?.type == "businessUnit" ? (
        <Dropdown
          menuPortalTarget={document.body}
          menuPosition={"fixed"}
          styles={customStyles}
          value={
            typeof value === "string" ? { label: value, value: value } : value
          }
          onChange={onValueDropdownChange}
          placeholder={filterBarPlaceHolders.VALUE_PLACEHOLDER}
          options={valueOptions}
        />
      ) : (
        <ValueTextBox
          value={value}
          onChange={onValueChange}
          placeholder={filterBarPlaceHolders.VALUE_PLACEHOLDER}
          onKeyDown={handleKeyDown}
        />
      )}
    </FilterBarContainer>
  );
};

export default FilterBar;
