import React from "react";
import {
  QueryProfileGlobalState,
  QueryProfileGlobalStateContextType,
} from "./interface";

export const initialQueryProfileGlobalState: QueryProfileGlobalState = {
  openEditProfileModal: false,
  openQueryStatistics: false,
};

export const QueryProfileGlobalStateContext =
  React.createContext<QueryProfileGlobalStateContextType>([
    initialQueryProfileGlobalState,
    () => null,
  ]);
