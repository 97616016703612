import { Typography } from "../../../../../../shared-components";
import { TopUserRowDescContainer } from "../../..";
import { FieldTableRow } from "../../../topFieldsRow";
import { ITableProfileMostJoinedTableAndFields } from "@/v2/core";

interface Props {
  data: ITableProfileMostJoinedTableAndFields;
}

export const MostJoinedTablesAndFieldsRow = ({ data }: Props) => {
  const { fieldName, numOfReferences } = data;
  return (
    <FieldTableRow value={numOfReferences}>
      <TopUserRowDescContainer>
        <Typography variant="p-light" color="#252525">
          {fieldName}
        </Typography>
      </TopUserRowDescContainer>
    </FieldTableRow>
  );
};
