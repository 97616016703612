import { AssetSourcePlatformsType } from "../../../core";
import { Icons } from "@sherloq/design-system";
import {
  TableDataValue,
  TableSourceContainer,
} from "../TableDataContentRow/components";
import { RenderAssetSource } from "../../lib";
import React from "react";

const renderTableProfileSource = (
  iconSrc: string | null,
  source: AssetSourcePlatformsType
) => {
  if (!iconSrc) return null;
  return (
    <TableSourceContainer>
      <Icons src={iconSrc as any} />
      <TableDataValue>{source}</TableDataValue>
    </TableSourceContainer>
  );
};

export const AssetSource: React.FC<{ source: AssetSourcePlatformsType }> = ({
  source,
}) => <RenderAssetSource {...{ render: renderTableProfileSource, source }} />;
