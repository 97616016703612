import ic16InfoPreview from "./ic_16InfoPreview.svg";
import ic24CancelPreview from "./ic_24CancelPreview.svg";
import ic16ArrowLeft from "./ic_16ArrowBackRounded.svg";
import ic24Comment from "./ic_24Comment.svg";
import ic24DoubleLeftArrow from "./ic_24DoubleLeftArrow.svg";
import ic_24searchPreview from "./ic_24searchPreview.svg";

export const StaticIcons = {
  ic16InfoPreview,
  ic24CancelPreview,
  ic16ArrowLeft,
  ic24Comment,
  ic24DoubleLeftArrow,
  ic_24searchPreview,
};
