import styled from "styled-components";

export const HeaderRow = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const LegendContainer = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-columns: min-content min-content;
  grid-auto-flow: column;
  column-gap: 10px;
  row-gap: 9px;
`;

export const LegendItemContainer = styled.div`
  display: flex;
  column-gap: 7px;
  align-items: center;
`;

export const LegendColorCircle = styled.div<{ color: string }>`
  height: 10px;
  width: 10px;
  background-color: ${({ color }) => color};
  border-radius: 5px;
`;

export const LoadingContainer = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
`;
