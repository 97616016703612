import { IQueryProfileUsedQueriesAndFieldsData } from "@/v2/application";
import { AssetProfileSectionHeaderWithInfo } from "../../../../shared-components";
import { Body, Container, Header, UsedTablesAndFieldsRow } from "./components";

interface Props {
  data: IQueryProfileUsedQueriesAndFieldsData[];
}

export const UsedTablesAndFields = ({ data }: Props) => {
  return (
    <Container>
      <Header>
        <AssetProfileSectionHeaderWithInfo tooltipText="Used Tables and fields - Used tables and fields in this query">
          Used Tables & Fields
        </AssetProfileSectionHeaderWithInfo>
      </Header>
      <Body>
        {data?.map((obj, idx) => (
          <UsedTablesAndFieldsRow data={obj} key={idx} />
        ))}
      </Body>
    </Container>
  );
};
