import { RootState, useReduxSelector } from "./";

export const useLoggedInUser = () => {
  const state = useReduxSelector((state: RootState) => state);
  return state.auth.user;
};

export const useLoggedInUserDetails = () => {
  const state = useReduxSelector((state: RootState) => state);
  return state.auth.details;
};

export const useSavedExtensionQueries = () => {
  const state = useReduxSelector((state: RootState) => state);
  return state.app.extensionQueries;
};
