import { Asset } from "../asset";
import { IQuery } from "./interfaces";

export class Query extends Asset<IQuery> {
  constructor(_query: IQuery) {
    super(_query);
  }

  private get associatedTables() {
    return this._asset.listOfTablesAccessed?.join(", ");
  }

  get data() {
    return {
      ...this._asset,
      lastReferencedDateTime: this._asset.lastReferenceDatetime,
      lastReferencedUsername:this._asset.lastUsernameThatReferenced,
      associatedTables: this.associatedTables,
    };
  }
}
