import styled from "styled-components";

export const JoinedWithContainer = styled.div`
  margin-top: 24px;
  margin-bottom: 16px;
  width: 100%;
`;

export const JoinedWithDetailsContainer = styled.div`
  margin-top: 16px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
