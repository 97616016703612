import { BarSvgProps, ResponsiveBar } from "@nivo/bar";
import theme from "../../styles/theme";
import { AppTypography } from "../../styles/global";
import { TypographySize } from "../../types/styles.d";
import { TextBoxWithToolTip } from "../TextHoldersWithToolTip";
import { formatValue, setMaxString } from "../../utils/helpers";
import React from "react";

export type RuntimeGraphProps = Omit<BarSvgProps<any>, "height" | "width">;

export const RuntimeGraph: React.FC<RuntimeGraphProps> = ({
  data,
  ...props
}: {
  data: any[];
}) => {
  return (
    <ResponsiveBar
      data={data}
      keys={[
        "FirstRange",
        "SecondRange",
        "ThirdRange",
        "FourthRange",
        "FifthRange",
        "SixthRange",
      ]}
      indexBy="TableName"
      margin={{ top: 10, right: 10, bottom: 0, left: 70 }}
      colors={Object.values(theme.graphLegend)}
      padding={0.6}
      enableGridX={false}
      enableGridY={false}
      layout="horizontal"
      valueScale={{ type: "linear" }}
      indexScale={{ type: "band", round: true }}
      borderColor={""}
      axisTop={null}
      axisRight={null}
      axisBottom={null}
      tooltip={(props) => {
        return (
          <AppTypography
            style={{
              padding: "3px",
              borderRadius: "2px",
              backgroundColor: "#00000080",
            }}
            size={TypographySize.xs}
            textColor="#fff"
          >
            {formatValue(props?.value)}
          </AppTypography>
        );
      }}
      label={({ value }) => {
        const label = formatValue(value ?? "");
        return `${label}%`;
      }}
      labelSkipWidth={30}
      axisLeft={{
        tickSize: 5,
        tickPadding: 0,
        renderTick: ({ x, y, value }) => {
          return (
            <g transform={`translate(${x},${y})`}>
              <foreignObject x={x - 70} y={-10} width={70} height={20}>
                <TextBoxWithToolTip
                  toolTipValue={value}
                  size={TypographySize.sm}
                >
                  {setMaxString(value ?? "", 8)}
                </TextBoxWithToolTip>
              </foreignObject>
            </g>
          );
        },
      }}
      role="runtime per component"
      {...props}
    />
  );
};
