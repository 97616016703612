import { useCallback } from "react";
import { AppTypography } from "../../styles/global";
import theme from "../../styles/theme";
import { TypographyBold, TypographySize } from "../../types/styles.d";
import React from "react";
import CardComponent, { CardProps } from "../card/CardComponent";
import { customStyles } from "../FilterBar/FilterBar";
import {
  Dropdown,
  HeaderRow,
  HeaderUtilsContainer,
  Icon,
  Row,
  SearchAreaContainer,
  SearchInput,
  TableColumnContainer,
  TableDivWrapper,
  TableHead,
  TableRow,
  TablesAnalyticsTable,
  TBody,
  THead,
} from "./index.style";
import { useAnalyticsTable } from "./useAnalyticsTable";
import icons from "../../assets/images/reports";
import { Tooltip } from "@material-ui/core";
import { SortButton } from "../../atoms/SortButton";
import { EmptySearch } from "../EmptyData/EmptySearch";
import Loading from "../Loading/Loading";
import PaginationComponent from "../Pagination";
import {
  DurationOptionType,
  AnalyticsTableHeaderType,
  AnalyticsTableDefaultParams,
} from "./interfaces";

export interface AnalyticsTableProps extends AnalyticsTableDefaultParams {
  tableName: string;
  containerProps?: CardProps;
  durationOptions?: DurationOptionType;
  withSearch: boolean;
  withDuration: boolean;
  withSort: boolean;
  refetchData: any;
  searchInputProps?: React.HTMLAttributes<HTMLInputElement>;
  tableContainerProps?: React.HTMLAttributes<HTMLDivElement>;
  tableColGroup?: React.ReactNode;
  tableHeaders: AnalyticsTableHeaderType[];
  defaultDuration?: string;
  data?: any[];
  loading: boolean;
  dataPageSize: number;
  renderData: (data: any, idx?: number, arr?: any[]) => any;
}

export const AnalyticsTable: React.FC<AnalyticsTableProps> = ({
  containerProps,
  withDuration,
  withSearch,
  tableName,
  refetchData,
  searchInputProps,
  tableHeaders,
  tableColGroup,
  loading,
  data,
  renderData,
  durationOptions,
  defaultDuration,
  dataPageSize,
  tableContainerProps,
  defaultSortField,
}) => {
  const {
    searchInputRef,
    onChangeDuration,
    onSearchInputChange,
    paginationConfig,
    onSkipToNextPage,
    onSkipToPreviousPage,
    refetchMethod,
    duration,
  } = useAnalyticsTable({
    refetch: refetchData,
    dataPageSize,
    defaultSortField,
  });
  const emptyData = data?.length === 0;

  const renderHeaders = useCallback(
    (header: AnalyticsTableHeaderType, idx: number) => {
      const {
        header: mainheader,
        subHeaders,
        width,
        sort,
        sortKeyword,
      } = header;
      let tooltip = `${mainheader}`;
      if (subHeaders && subHeaders?.length > 0) {
        for (const subHeader of subHeaders) {
          if (subHeader) tooltip += ` - ${subHeader}`;
        }
      }
      return (
        <TableHead key={idx} style={{ width, height: "20px" }}>
          <Tooltip title={tooltip} placement="top-start">
            <TableColumnContainer style={{ height: "100%" }}>
              <Row>
                <AppTypography
                  bold={TypographyBold.lg}
                  size={TypographySize.sm}
                >
                  {mainheader}&nbsp;
                </AppTypography>
                {sort === true && sortKeyword && (
                  <SortButton
                    onClickUp={() => refetchMethod(sortKeyword as any, "asc")}
                    onClickDown={() =>
                      refetchMethod(sortKeyword as any, "desc")
                    }
                  />
                )}
              </Row>
              {subHeaders && (
                <AppTypography size={TypographySize.xs}>
                  {subHeaders[0]}
                </AppTypography>
              )}
            </TableColumnContainer>
          </Tooltip>
        </TableHead>
      );
    },
    [duration]
  );

  return (
    <CardComponent {...containerProps}>
      <>
        <HeaderRow>
          <AppTypography
            wrap="true"
            bold={TypographyBold.lg}
            size={TypographySize.md}
            textColor={theme.appColors.dark}
          >
            {tableName}
          </AppTypography>
          <HeaderUtilsContainer>
            {withSearch && (
              <SearchAreaContainer>
                <Icon src={icons.GraySearchIcon} />
                <SearchInput
                  ref={searchInputRef}
                  onChange={onSearchInputChange}
                  placeholder="press ctrl+f to search"
                  {...searchInputProps}
                />
              </SearchAreaContainer>
            )}
            {withDuration && durationOptions && (
              <Dropdown
                menuPortalTarget={document.body}
                menuPosition={"fixed"}
                styles={customStyles}
                onChange={onChangeDuration}
                placeholder={defaultDuration ?? durationOptions[0].label}
                options={durationOptions as any}
              />
            )}
          </HeaderUtilsContainer>
        </HeaderRow>
        <TableDivWrapper {...tableContainerProps}>
          <TablesAnalyticsTable>
            <colgroup>{tableColGroup}</colgroup>
            <THead>
              <TableRow>{tableHeaders.map(renderHeaders)}</TableRow>
            </THead>
            <TBody>
              {loading ? (
                <TableRow>
                  <td colSpan={8} style={{ padding: "5px" }}>
                    {loading && (
                      <div
                        style={{
                          width: "100%",
                          height: "40px",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Loading size={20} height={5} />
                      </div>
                    )}
                  </td>
                </TableRow>
              ) : (
                !loading &&
                emptyData && (
                  <TableRow>
                    <td colSpan={8}>
                      <div
                        style={{
                          height: "200px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <EmptySearch />
                      </div>
                    </td>
                  </TableRow>
                )
              )}
              {data?.map(renderData)}
            </TBody>
          </TablesAnalyticsTable>
        </TableDivWrapper>
        <PaginationComponent
          style={{ marginTop: "auto" }}
          onSkipToNextPage={!loading ? onSkipToNextPage : undefined}
          onSkipToPreviousPage={!loading ? onSkipToPreviousPage : undefined}
          currentPage={paginationConfig.start}
          totalPages={paginationConfig.end}
          showPrevious={paginationConfig.showPrevious}
          showNext
        />
      </>
    </CardComponent>
  );
};
export type {
  DurationOptionType,
  AnalyticsTableHeaderType,
} from "./interfaces";
