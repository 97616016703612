import { useQueryProfile } from "../../../hooks";
import {
  AssetProfileContentWrapper,
  AssetProfileName,
  AssetProfileTextContainer,
  AssetProfileContentUsageSectionHeader,
  AssetUtil,
  Badge,
  TopHeader,
  Typography,
  AssetProfileContentSectionDefaultCopyButton,
  DefualtSQLEditor,
  sqlQueryFormatter,
  AssetProfileDescription,
  ListFallBack,
  AssetProfileUtilLinkButton,
  AssetProfileContentSectionTextLink,
  ReadMe,
  StickyContainer,
  AssetProfileStickyHeaderComponent,
  SharedAssetProfileUtils,
} from "../../shared-components";
import {
  Container,
  EditorContainer,
  EditQueryInfoModal,
  QueryProfileRow,
  QueryStatistics,
  QueryUsageSection,
  QueryUsageUtilsWrapper,
  SearchContainer,
  UsedTablesAndFields,
  UtilsWrapper,
} from "./components";
import { RenderIf } from "../../lib";
import SearchInput from "../Home/components/search&Filter/SearchInput";

export const QueryProfile = () => {
  const {
    queryProfile,
    queryDescription,
    queryName,
    isError,
    isLoading,
    onClickCopy,
    onClickShare,
    queryIsInUserList,
    addQueryToMyList,
    removeQueryFromMyList,
    openEditProfileModal,
    setOpenEditQueryProfileModal,
    setOpenQueryStats,
    openQueryStatistics,
    onUpdateQueryInfo,
    onUpdateReadme,
    readMe,
    markers,
    onKeywordSearch,
    containerRef,
    sticky,
  } = useQueryProfile();

  return (
    <Container ref={containerRef}>
      <StickyContainer>
        <TopHeader
          headerUtils={RenderIf(
            sticky,
            <SharedAssetProfileUtils
              asset="query"
              inMyList={queryIsInUserList!}
              onClickEdit={() => setOpenEditQueryProfileModal(true)}
              onClickMyListOperation={
                queryIsInUserList ? removeQueryFromMyList : addQueryToMyList
              }
              onClickShare={onClickShare}
            />
          )}
          showBack={true}
        />
      </StickyContainer>
      <UtilsWrapper>
        <Badge backgroundColor="#FDCEB9">Query</Badge>
        <SharedAssetProfileUtils
          asset="query"
          inMyList={Boolean(queryIsInUserList)}
          onClickEdit={() => setOpenEditQueryProfileModal(true)}
          onClickMyListOperation={
            queryIsInUserList ? removeQueryFromMyList : addQueryToMyList
          }
          onClickShare={onClickShare}
        />
      </UtilsWrapper>
      {RenderIf(
        isLoading || isError,
        <ListFallBack {...{ isLoading, isError }} />
      )}
      {RenderIf(
        !isLoading && !isError,
        <>
          <AssetProfileStickyHeaderComponent>
            <AssetProfileTextContainer>
              <AssetProfileName>{queryName}</AssetProfileName>
              <AssetProfileDescription>
                {queryDescription}{" "}
              </AssetProfileDescription>
            </AssetProfileTextContainer>
          </AssetProfileStickyHeaderComponent>
          <QueryProfileRow data={queryProfile?.queryProfileRow} />
          <AssetProfileContentWrapper>
            <ReadMe onUpdate={onUpdateReadme} value={readMe} />
            <QueryUsageSection>
              <AssetProfileContentUsageSectionHeader>
                <Typography color="#707070" variant="h3">
                  Query Usage
                </Typography>
                <AssetProfileContentSectionTextLink
                  onClick={() => setOpenQueryStats(true)}
                >
                  View query statistics
                </AssetProfileContentSectionTextLink>
              </AssetProfileContentUsageSectionHeader>
              <QueryUsageUtilsWrapper>
                <SearchContainer>
                  <SearchInput
                    placeholder="Search by name or query content..."
                    onSearch={(e) => onKeywordSearch(e)}
                  />
                </SearchContainer>
                <AssetProfileContentSectionDefaultCopyButton
                  prefixHoverIcon="ic16copyHover"
                  prefixIcon="ic16copyPreview"
                  onClick={onClickCopy}
                >
                  copy
                </AssetProfileContentSectionDefaultCopyButton>
              </QueryUsageUtilsWrapper>
              <EditorContainer>
                <DefualtSQLEditor
                  value={sqlQueryFormatter(queryProfile?.queryString)}
                  style={{ backgroundColor: "#f1f1f1" }}
                  maxLines={20}
                  markers={markers}
                />
              </EditorContainer>
              <UsedTablesAndFields data={queryProfile?.usedTablesAndFields} />
            </QueryUsageSection>
          </AssetProfileContentWrapper>
        </>
      )}
      <EditQueryInfoModal
        open={openEditProfileModal}
        onClose={() => setOpenEditQueryProfileModal(false)}
        data={{
          querydescription: queryDescription,
          queryId: queryProfile.queryId,
          queryname: queryName,
        }}
        onSuccess={onUpdateQueryInfo}
      />
      {RenderIf(
        !!queryProfile?.queryStats,
        <QueryStatistics
          onClose={() => {
            setOpenQueryStats(false);
          }}
          data={queryProfile?.queryStats}
          open={openQueryStatistics}
        />
      )}
    </Container>
  );
};
