import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AuthState } from "./interfaces";
import { googleUserLogin } from "./authThunks";
import { IUser } from "../../../core";
import { GoogleLoginData } from "@/v2/application";

const initialState: AuthState = {
  isLoggedIn: false,
  loading: false,
  user: {} as any,
  details: {},
  error: undefined,
  routeBeforeAuth: "/dashboard/search",
  users: [],
  companyStructure: null,
  isLoggedInOnExtension: false,
  extensionAuth: null,
};

const authSlice = createSlice({
  initialState,
  name: "auth",
  reducers: {
    setLogin(state, action: PayloadAction<any>) {
      state.isLoggedIn = action.payload;
    },
    setUserDetails(state, action: PayloadAction<any>) {
      state.details = action.payload;
    },
    setRouteBeforeAuth(state, action: PayloadAction<any>) {
      state.routeBeforeAuth = action.payload;
    },
    syncUserCompanyData: (state, action) => {
      state.users = action.payload.users;
      state.companyStructure = action.payload.companyStructure;
    },
    reset: (state, _) => {
      state = { ...initialState };
    },
  },
  extraReducers: (builder) => {
    return (
      builder.addCase(googleUserLogin.pending, (state) => {
        state.loading = true;
      }),
      builder.addCase(googleUserLogin.fulfilled, (state, action: any) => {
        const authResponse = action.payload as GoogleLoginData;
        state.error = undefined;
        state.loading = false;
        state.user = authResponse.user;
        state.details = authResponse.user;
        state.isLoggedIn = true;
        state.isLoggedInOnExtension = true;
        state.extensionAuth = authResponse.extensionAuthUser;
      }),
      builder.addCase(googleUserLogin.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = (payload as any).message;
        state.isLoggedIn = false;
        state.isLoggedInOnExtension = false;
      })
    );
  },
});

export const { reducer: authReducer, actions: authActions } = authSlice;
