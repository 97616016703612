import {
  AssetProfileSectionHeaderWithInfo,
  BarChart,
  RadioLabel,
} from "../../../../shared-components";
import { RadioGroup } from "@mui/material";
import {
  FullWidthContainer,
  MostCommonFieldsHeader,
  MostCommonFieldsRadioContainer,
} from "..";
import {
  MostCommonFieldsGraphContainer,
  RadioButton,
  RadioButtonContainer,
} from "./components";
import { useMostCommonFields } from "../../../../../hooks";

interface Props {
  fieldsData: any[];
  operatorData: any[];
}

export const MostCommonFields = ({ fieldsData, operatorData }: Props) => {
  const { graphData, keys, indexBy, filterBy, onChangeFilterBy } =
    useMostCommonFields(fieldsData, operatorData);
  return (
    <FullWidthContainer>
      <MostCommonFieldsHeader>
        <AssetProfileSectionHeaderWithInfo
          tooltipText="By operator - The most common fields are used by each SQL operator. 
By field - the popularity of SQL operators that are used for each of the top 5 fields"
        >
          Most Common Fields
        </AssetProfileSectionHeaderWithInfo>
        <MostCommonFieldsRadioContainer>
          <RadioGroup
            row
            value={filterBy}
            onChange={onChangeFilterBy}
            aria-labelledby="demo-radio-buttons-group-label"
            name="radio-buttons-group"
          >
            <RadioButtonContainer
              style={{ border: "none" }}
              value="by field"
              control={<RadioButton />}
              label={<RadioLabel> By field</RadioLabel>}
            />
            <RadioButtonContainer
              value="by operator"
              control={<RadioButton />}
              label={<RadioLabel> By operator</RadioLabel>}
            />
          </RadioGroup>
        </MostCommonFieldsRadioContainer>
      </MostCommonFieldsHeader>
      <MostCommonFieldsGraphContainer>
        <BarChart
          data={graphData ?? []}
          keys={keys ?? []}
          indexBy={indexBy ?? ""}
          colors={
            filterBy === "by field"
              ? [
                  "#D64184",
                  "#eb4791",
                  "#f269a7",
                  "#7209B7",
                  "#9956F0",
                  "#9167F4",
                  "#938FE0",
                ]
              : undefined
          }
        />
      </MostCommonFieldsGraphContainer>
    </FullWidthContainer>
  );
};
