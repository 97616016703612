import styled from "styled-components";

export const CostGraphContainer = styled.div`
  height: 500px;
`
export const CostContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.lg};
`

export const MostExpensiveContainer = styled.div`
  height: 300px;
`

export const CostTableContainer = styled.div`
  height: 300px;
`