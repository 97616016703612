import { useEffect, useMemo } from "react";
import { usePagination } from "../Pagination";
import theme from "../../styles/theme";

const { graphLegend } = theme;

interface TableRuntimeAnalyticsParams {
  refetch?: (...args: any[]) => void;
  PageSize?: number;
}

export const useTableRuntimeAnalytics = ({
  PageSize,
  refetch,
}: TableRuntimeAnalyticsParams) => {
  const { currentPage, onSkipToNextPage, onSkipToPreviousPage } =
    usePagination();

  const paginationConfig = useMemo(() => {
    return {
      start: PageSize ? PageSize * currentPage - (PageSize - 1) : 0,
      end: currentPage * (PageSize ?? 0),
      showPrevious: currentPage > 1,
    };
  }, [currentPage, PageSize]);

  useEffect(() => {
    refetch?.(currentPage);
  }, [currentPage]);

  return {
    paginationConfig,
    onSkipToNextPage,
    onSkipToPreviousPage,
  };
};

export const TableRuntimeAnalyticsGraphLegendItems = [
  { label: "0-10", color: graphLegend.best },
  { label: "11-20", color: graphLegend.good },
  { label: "21-30", color: graphLegend.ok },
  { label: "31-40", color: graphLegend.warn },
  { label: "41-50", color: graphLegend.bad },
  { label: "50+", color: graphLegend.worst },
];
