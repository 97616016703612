import {
  AssetProfileContentSectionTextLink,
  AssetProfileSectionHeaderWithInfo,
  AssetProfileTableWrapper,
} from "../../../../shared-components";
import { Table, TableProfileSectionHeader } from "..";
import { ReactNode } from "react";

interface Props {
  children: ReactNode;
  onClickViewBreakDown?: () => void;
}

export const MostJoinedTablesAndFields = ({ children, onClickViewBreakDown }: Props) => {
  return (
    <AssetProfileTableWrapper>
      <Table>
        <TableProfileSectionHeader>
          <AssetProfileSectionHeaderWithInfo>
            Most Joined Tables
          </AssetProfileSectionHeaderWithInfo>
          <AssetProfileContentSectionTextLink onClick={onClickViewBreakDown}>
            Joined fields breakdown
          </AssetProfileContentSectionTextLink>
        </TableProfileSectionHeader>
        <tbody>{children}</tbody>
      </Table>
    </AssetProfileTableWrapper>
  );
};

export { MostJoinedTablesAndFieldsRow } from "./components";
