import {
  MultiDropdownSelector,
  MultiDropdownSelectorProps,
  RootSideViewContainer,
} from "../../../../../shared-components";
import styled from "styled-components";
import React from "react";
import { Button } from "@sherloq/design-system";

const Row = styled.div`
  width: 100%;
  display: flex;
`;

export const Container = styled(RootSideViewContainer)<{ open }>`
  width: 1124px;
  display: ${({ open }) => (open ? "block" : "none")};
`;

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TableHeader = styled.tr`
  display: grid;
  grid-template-columns: repeat(2, 180px) repeat(4, 100px) 90px;
  column-gap: 15px;
  width: 100%;
  background-color: #f1f1f1;
  height: 40px;
  align-items: center;
  padding: 0 5px;
`;

export const TableContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  padding-bottom: 24px;
  overflow-y: auto;
  box-sizing: border-box;
  overflow-x: hidden;
`;
export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

export const FilterColumn = styled.div`
  margin-top: 24px;
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
`;

export const LastQueriedBySelector = (props: MultiDropdownSelectorProps) =>
  React.createElement(MultiDropdownSelector, {
    containerProps: { style: { width: "442px" } },
    ...props,
  });

export const RuleSection = styled.div`
  margin-bottom: 40px;
`;

export const RulesContainer = styled.div`
  display: flex;
  margin-top: 8px;
  flex-direction: column;
  row-gap: 8px;
`;

export const LinkButton = styled(Button)`
  padding: 0;
  color: ${({ theme }) => theme.appColors.primary};
`;

export const ApplyButtonsRow = styled.div`
  display: flex;
  column-gap: 16px;
  margin-top: 32px;
`;

export * from "./queryUsageRow";
