import { StaticIcons } from "../../../../assets";
import {
  AddRuleButton,
  FilterRule,
  LabelTypography,
  ListFallBack,
  QueriedInFilter,
  ResetFilterButton,
  Typography,
} from "../../../../shared-components";
import {
  Container,
  Header,
  QueryUsageRow,
  FilterColumn,
  Table,
  TableContainer,
  TableHeader,
  LastQueriedBySelector,
  RuleSection,
  RulesContainer,
  ApplyButtonsRow,
} from "./components";
import { CancelIcon } from "../topFieldsSideView/components";
import { RenderIf } from "../../../../lib";
import {
  useSelectAndViewQuery,
  useTableProfileQueryUsageSideView,
} from "../../../../../hooks";
import { Button } from "@sherloq/design-system";
import SqlViewer from "../../../../../../components/SqlViewer/SqlViewer"; // refactor

interface Props {
  open?: boolean;
  onClose?: () => void;
}

export const QueryUsageSideView = ({ open, onClose }: Props) => {
  const { closeQuery, onViewQuery, queryIsSelected, selectedQuery } =
    useSelectAndViewQuery(); // move hook into useTableProfileQueryUsageSideView if its to be used with other logic!
  const {
    filter,
    addRule,
    onUpdateRules,
    removeRule,
    onChangeLastQueriedBy,
    onChangeQueriedIn,
    QueriedInFilterOptions,
    lastQueriedByOptions,
    getFilterRuleConditions,
    filterRuleColumns,
    onResetFilters,
    queries,
    onApplyFilters,
    isError,
    isLoading,
    getDropdownRuleValues,
    getRuleDropdownState,
  } = useTableProfileQueryUsageSideView();

  return (
    <Container open={open}>
      <Header>
        <Typography variant="h6">Queries Usage</Typography>
        <CancelIcon
          onClick={onClose}
          src={StaticIcons.ic24CancelPreview}
          alt=""
        />
      </Header>

      <FilterColumn>
        <QueriedInFilter
          label="Queried in"
          value={filter?.queriedIn?.value}
          options={QueriedInFilterOptions}
          onChange={onChangeQueriedIn}
        />
        <LastQueriedBySelector
          upadateSelectedMembers={() => null}
          selectedMembers={filter?.lastQueriedBy?.value ?? []}
          onChange={onChangeLastQueriedBy as any}
          options={lastQueriedByOptions}
          label="Last queried by"
        />
      </FilterColumn>
      <RuleSection>
        <LabelTypography>Rules</LabelTypography>
        {RenderIf(
          filter?.rules?.length > 0,
          <RulesContainer>
            {filter?.rules?.map((rule, idx) => (
              <FilterRule
                key={idx}
                columns={filterRuleColumns}
                conditions={getFilterRuleConditions(`${idx}`)}
                value={filter?.rules?.[idx]}
                onUpdateRule={(rule) => onUpdateRules(idx, rule)}
                onRemoveRule={() => removeRule(idx)}
                removeButtonProps={{ style: { marginLeft: "8px" } }}
                isDropdownValues={getRuleDropdownState(`${idx}`)}
                dropdownValues={getDropdownRuleValues(`${idx}`)}
              />
            ))}
          </RulesContainer>
        )}
        <AddRuleButton onClick={addRule} variant="link">
          + Add Rule
        </AddRuleButton>
        <ApplyButtonsRow>
          <Button onClick={onApplyFilters} variant="default">
            Apply
          </Button>
          <ResetFilterButton variant="link" onClick={onResetFilters}>
            Reset filters
          </ResetFilterButton>
        </ApplyButtonsRow>
      </RuleSection>
      <TableContainer>
        <Table>
          <thead style={{ position: "sticky", top: "0" }}>
            <TableHeader>
              <Typography variant="small">Query</Typography>
              <Typography variant="small">List of tables</Typography>
              <Typography variant="small"># of References</Typography>
              <Typography variant="small">Data Scanned</Typography>
              <Typography variant="small">Last run time</Typography>
              <Typography variant="small">Runtime duration</Typography>
              <Typography variant="small">Last user that referenced</Typography>
            </TableHeader>
          </thead>
          <tbody>
            {RenderIf(
              isLoading || isError || queries?.length === 0,
              <ListFallBack
                style={{ marginTop: "8px" }}
                {...{ isError, isLoading, noData: queries?.length === 0 }}
              />
            )}
            {RenderIf(
              !isLoading && queries?.length > 0,
              <>
                {queries?.map((query, idx) => (
                  <QueryUsageRow
                    onClickQueryString={onViewQuery}
                    key={idx}
                    data={query}
                  />
                ))}
              </>
            )}
          </tbody>
        </Table>
      </TableContainer>
      <SqlViewer
        show={queryIsSelected}
        query={selectedQuery ?? ""}
        onClose={closeQuery}
      />
    </Container>
  );
};
