import { Typography } from "@sherloq/design-system";
import styled from "styled-components";

export const FieldTableRowContainer = styled.td`
  display: grid;
  grid-template-columns: 3fr 1fr;
  height: 50px;
  width: 100%;
  align-items: center;
  border-bottom-style: solid;
  border-bottom-style: 1px;
  border-bottom-color: #f1f1f1;
  padding: 8px;
  box-sizing: border-box;
`;

export const TextValue = styled(Typography)`
  color: #f72585;
  margin-left: auto;
`;
