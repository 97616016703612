import { ITableProfileMostJoinedTableAndFields } from "../../../../../core";
import { StaticIcons } from "../../../../assets";
import {
  CancelIcon,
  Container,
  Header,
  RootHeaderText,
  DividerContainer,
  TableJoinRow,
  ContentContainer,
} from "./components";

interface MostJoinedTableData {
  tableName: string;
}

interface Props {
  open?: boolean;
  onClose?: () => void;
  tableData: MostJoinedTableData;
  data: ITableProfileMostJoinedTableAndFields[];
}

export const MostJoinedFieldsBreakdownSideViewComponent = ({
  open,
  onClose,
  tableData,
  data,
}: Props) => {
  return (
    <Container open={open}>
      <Header>
        <div>
          <RootHeaderText variant="h6">
            Joined Fields Breakdown from:
          </RootHeaderText>
          <br />
          <RootHeaderText variant="h6">
            {`"${tableData.tableName}"`} table
          </RootHeaderText>
        </div>
        <CancelIcon
          onClick={onClose}
          src={StaticIcons.ic24CancelPreview}
          alt=""
        />
      </Header>
      <DividerContainer />
      <ContentContainer>
        {data
          ? data.map((rowData, idx) => {
              return (
                <TableJoinRow
                  key={idx}
                  data={{ ...rowData, tableName: tableData.tableName }}
                />
              );
            })
          : ""}
      </ContentContainer>
    </Container>
  );
};
