import styled from "styled-components";
import { Button as Sherloqbutton } from "@sherloq/design-system";

export const Container = styled.div`
  width: 200px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  row-gap: 8px;
`;

export const Button = styled(Sherloqbutton)`
  width: 100%;
  padding: 0 5px;
`;
