import { ITableProfileRowType } from "../../../../../core";
import { AssetSource } from "../../../../shared-components";
import {
  TableDataContainer,
  TableDataTitle,
  TableDataValue,
} from "../../../../shared-components/TableDataContentRow/components";
import { TableContentContainer } from "./components";

export const TableProfileRow = ({ data }: { data: ITableProfileRowType }) => {
  const { dbName, lastQueried, source, tableOwner } = data;
  return (
    <TableContentContainer>
      <TableDataContainer>
        <TableDataTitle>Table Owner</TableDataTitle>
        <TableDataValue>{tableOwner || "-"}</TableDataValue>
      </TableDataContainer>
      <TableDataContainer>
        <TableDataTitle>DB</TableDataTitle>
        <TableDataValue>{dbName}</TableDataValue>
      </TableDataContainer>
      <TableDataContainer>
        <TableDataTitle>source</TableDataTitle>
        <AssetSource source={source} />
      </TableDataContainer>
      <TableDataContainer>
        <TableDataTitle>Last queried</TableDataTitle>
        <TableDataValue>{lastQueried}</TableDataValue>
      </TableDataContainer>
    </TableContentContainer>
  );
};
