import { authHttpClient } from "../../infrastructure";

export function makeGetAssetProfile<T = any>(
  assetType: "table" | "query",
  onError?: any
) {
  return async (AssetId: string) => {
    const params = {
      assetType,
      AssetId,
    };
    try {
      const response = await authHttpClient.get("/v1/getdata/getAssetProfile", {
        params,
      });
      return response?.data as T;
    } catch (error) {
      onError?.(error);
    }
  };
}
