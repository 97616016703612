import styled from "styled-components";
import Select from "react-select";

export const WorkgroupsContainerWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
`
export const WorkgroupsDropDown = styled.div`
    width: 150px;
`;
export const InputCheckbox = styled.input`
    accent-color: ${({ theme }) => theme.appColors.tertiary};
`
export const SmallIcon = styled.img`
    width: 15px;
    cursor: pointer;
`;
export const FilterDropdownContainer = styled.div`
    max-height: 260px;
    overflow-y: scroll;
    background: ${({ theme }) => theme.appColors.white};
    margin-bottom: 45px;
    width: 100%;
`
export const ResetButtonContainer = styled.div`
`
export const ButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    gap: 5px;
`
export const CheckboxContainer = styled.div`
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
    &:hover {
        cursor: pointer;
        background: #F5F4FD;
    }
`

export const DropDownFooter = styled.div`
    position:absolute;
    height: fit-content;
    bottom: 0;
    width: 100%;
    background: ${({ theme }) => theme.appColors.white};
    padding: 10px 0;
`
export const CheckboxAndText = styled.div`
    display: flex;
    align-items: center;
    gap: 6px;
`
export const Dropdown = styled(Select)`
  align-self: center;
  width:inherit;
  height: 30px;
  border-radius: 4px;
  font-size: 12px;
  height: 40px;
  z-index: 9999;
`