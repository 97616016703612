import { ITableProfileMostUsedAndLastUsedQuery } from "../../core";
import {
  AnalyticsClient,
  nativeCopyToClipboard,
  notificationSnackbar,
} from "../../infrastructure";

interface Props {
  data: ITableProfileMostUsedAndLastUsedQuery;
}

export const useTableProfileLastQueryRun = ({ data }: Props) => {
  const onClickCopy = () => {
    nativeCopyToClipboard(data.query);
    notificationSnackbar.success("Copied Last Query Run string to Clipboard");
    AnalyticsClient.trackAction(
      "main_table_profile_last_query_run_copy_button_click"
    );
  };
  return {
    ...data,
    onClickCopy,
  };
};
