import React from "react";
import { Icon } from "./QuerySourceIcon.styled";

interface Props {
  source?: string;
  style?: React.CSSProperties;
}
export const QuerySourceIcon: React.FC<Props> = ({ source, style }) => {
  if (!source) return null;
  return <Icon src={source} style={style} />;
};
