import React from "react";
import CardComponent from "../../../../components/card/CardComponent";
import * as Styled from "./Table.styled";
import { AppTypography } from "../../../../styles/global";
import { TypographySize, TypographyBold } from "../../../../types/styles.d";
import theme from "../../../../styles/theme";
import LineChartComponent from "../../../../components/Charts/Line";
import Workgroups from "../Workgroups/Workgroups";
import Loading from "../../../../components/Loading/Loading";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";

function WeeklyCost({ loading, data, type, callAPI, sumTitle, avgTitle }) {
  const [radioButtonValue, setRadioButtonValue] = React.useState("sum");
  const [workgroupsFilter, setWorkgroupsFilter] = React.useState("");
  const [title, setTitle] = React.useState(sumTitle);
  const handleWorkgroupsFilter = (filters: string) => {
    setWorkgroupsFilter(filters);
    callAPI(filters, radioButtonValue);
  };
  const handleRadioButtonChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const radioBtnValue = (event.target as HTMLInputElement).value
    setRadioButtonValue(radioBtnValue);
    setTitle(radioBtnValue == 'sum' ? sumTitle : avgTitle)
    callAPI(workgroupsFilter, radioBtnValue);
  };
  const titleByType = () => {
    switch (type){
     case "Runtime":
       return radioButtonValue === 'sum' ? 'Runtime in Hours' : 'Runtime in Seconds'
      case "Cost":
      return radioButtonValue === 'sum' ? 'Cost in $' : 'Cost in $'
      case "Data Scanned":
       return radioButtonValue === 'sum' ? 'Data Scanned in TB' : 'Data Scanned in GB'
     default:
       return ''
    }
}
  return (
    <CardComponent>
      <>
        <div>
          <Styled.CardComponentHeader>
            <AppTypography
              bold={TypographyBold.lg}
              size={TypographySize.md}
              textColor={theme.appColors.dark}
            >
              {`Weekly ${type}`}
            </AppTypography>
            <Workgroups
              reset={() => callAPI()}
              getWorkgroupFilteredString={handleWorkgroupsFilter}
            />
          </Styled.CardComponentHeader>
        </div>
        <FormControl>
          <RadioGroup
            row
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={radioButtonValue}
            onChange={handleRadioButtonChange}
          >
            <FormControlLabel value="sum" control={<Radio />} label="Sum" />
            <FormControlLabel
              value="average"
              control={<Radio />}
              label="Average"
            />
          </RadioGroup>
        </FormControl>
        {loading && <Loading size={20} height={5} />}
        {data.length > 0 && !loading && (
          <Styled.CostGraphContainer>
            <LineChartComponent data={data} type={titleByType()} />
          </Styled.CostGraphContainer>
        )}
      </>
    </CardComponent>
  );
}

export default WeeklyCost;
