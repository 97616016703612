import { FormControlLabel } from "@mui/material";
import {
  Button,
  ButtonProps,
  IconButton,
  TypographyProps,
  Input,
} from "@sherloq/design-system";
import React from "react";
import styled from "styled-components";
import { Typography } from "../Typography";

const Text = styled(Typography)`
  color: #252525;
`;

export const Row = styled.div`
  display: flex;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const RadioLabel = ({ children, ...props }: TypographyProps) =>
  React.createElement(
    Typography,
    { variant: "small", style: { marginLeft: "4px" }, ...props },
    children
  );

export const RadioButtonContainer = styled(FormControlLabel)`
  display: flex;
  height: 32px;
  background-color: white;
  border: 1px solid #d9d9d9;
  border-radius: 3px;
  justify-content: center;
  align-items: center;
  padding-right: 8px;
  box-sizing: border-box;
  margin-right: 8px !important;
  margin-left: 0 !important;
`;

export const AssetProfileName = ({ children, ...props }: TypographyProps) =>
  React.createElement(Text, { variant: "h2", ...props }, children);

const ProfileDescriptionText = styled(Text)`
  width: 600px;
`;

export const AssetProfileDescription = ({
  children,
  ...props
}: TypographyProps) =>
  React.createElement(
    ProfileDescriptionText,
    { variant: "p-light", ...props },
    children
  );

export const AssetProfileTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  margin-top: 8px;
`;

export const AssetProfileRowDataTitle: React.FC<
  Omit<TypographyProps, "variant">
> = ({ children, ...props }) => {
  return React.createElement(
    Typography,
    {
      variant: "p-light",
      style: { marginBottom: "4px" },
      color: "#707070",
      ...props,
    },
    children
  );
};

export const AssetProfileRowDataValue: React.FC<
  Omit<TypographyProps, "variant">
> = ({ children, ...props }) => {
  return React.createElement(
    Typography,
    { variant: "p-light", color: "#252525", ...props },
    children
  );
};

export const AssetProfileContentWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: min-content 1fr;
  grid-template-rows: 1fr;
  column-gap: 24px;
  padding-top: 40px;
`;

export const AssetProfileContentCommentsLayout = styled.aside`
  width: 414px;
  box-sizing: border-box;
  height: 500px;
`;

export const AssetProfileContentUsageSectionHeader = styled.header`
  display: flex;
  align-items: center;
  column-gap: 16px;
  margin-bottom: 40px;
`;

const TextLinkTypography = styled(Typography)`
  :hover {
    cursor: pointer;
    border: none;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: #0075e7;
  }
`;

export const AssetProfileContentSectionTextLink = ({
  children,
  ...props
}: TypographyProps) =>
  React.createElement(
    TextLinkTypography,
    { variant: "small", color: "#0075E7", ...props },
    children
  );

export const AssetProfileContentSectionDefaultCopyButton = styled(IconButton)`
  background-color: transparent !important;
  color: #707070;

  &:hover {
    color: #7209b7;
  }
`;

export const AssetProfileDefaultQueryEditorContainer = styled.div`
  height: 400px;
  widht: 100%;
  background-color: #f1f1f1;
  padding: 5px;
  margin-top: 8px;
`;

export const AssetProfileTableWrapper = styled.div`
  height: 400px;
`;

export const RootSideViewHeader = styled.header`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
  padding-right: 14px;
`;

export const ResetFilterButton = styled(Button)`
  color: #7209b7 !important;
`;

const AddRuleButtonRoot = styled(Button)`
  color: ${({ theme }) => theme.appColors.primary} !important;
  width: fit-content;
`;

export const AddRuleButton = ({ children, ...props }: ButtonProps) =>
  React.createElement(
    AddRuleButtonRoot,
    { variant: "link", ...props },
    children
  );

const UtilLinkButtonRoot = styled(Button)`
  padding: 0 !important;
  color: #252525 !important;
  &:hover {
    border-bottom-style: none !important;
  }
`;

export const AssetProfileUtilLinkButton = ({
  children,
  ...props
}: ButtonProps) =>
  React.createElement(
    UtilLinkButtonRoot,
    { variant: "link", ...props },
    children
  );

export const AssetProfileStatsItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
`;

const ValueTextRoot = styled(Typography)`
  margin-bottom: 4px;
`;

export const AssetProfileStatsItemValueText = ({
  children,
  ...props
}: TypographyProps) =>
  React.createElement(
    ValueTextRoot,
    { variant: "p-light", ...props },
    children
  );

const AssetStatsDescRoot = styled(Typography)`
  color: #707070 !important;
`;

export const AssetProfileStatsValueDescText = ({
  children,
  ...props
}: TypographyProps) =>
  React.createElement(
    AssetStatsDescRoot,
    { variant: "small", ...props },
    children
  );

export const EditAssetInfoTextInput = styled(Input)`
  width: 478px;
  background-color: #f1f1f1;
  color: #707070 !important;
`;

export const EditAssetInfoTextInputContentHeader = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin-top: 24px;
  width: 478px;
  margin-bottom: 4px;
`;

export const AssetFilterContainer = styled.div`
  margin-top: 24px;
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
`;

export const AssetFilterRulesContainer = styled.div`
  display: flex;
  margin-top: 8px;
  flex-direction: column;
  row-gap: 8px;
`;

export const AssetFilterFooterButtonsContainer = styled.div`
  display: flex;
  column-gap: 16px;
  margin-top: 32px;
`;

export const StickyContainer = styled.div`
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 100;
`;

export const AssetProfileStickyHeaderComponent = styled(StickyContainer)`
  width: 100%;
  top: 41px;
`;
export const PageRootContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding-right: 20px;
  box-sizing: border-box;
`;

export * from "./Modal";
export * from "./textarea";
export * from "./chip";
