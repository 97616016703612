export const formatSeconds = (d: string | number) => {
  d = Number(d);
  d = d / 1000;
  if (d == 0) return "0s";
  const h = Math.floor(d / 3600);
  const m = Math.floor((d % 3600) / 60);
  const s = Math.floor((d % 3600) % 60);

  const hDisplay = h > 0 ? h + (h == 1 ? "h " : "h ") : "";
  const mDisplay = m > 0 ? m + (m == 1 ? "m " : "m ") : "";
  const sDisplay = s > 0 ? s + (s == 1 ? "s" : "s") : "";
  return hDisplay + mDisplay + sDisplay;
};
export const formatBytes = (bytes: string | number, decimals = 2) => {
  // If the input is not a valid number, return "0 Bytes"
  if (!+bytes) return "0 Bytes";

  // Convert the input to an absolute number
  bytes = Math.abs(Number(bytes));

  // Define the constant to use for converting bytes to other units (1024 bytes in a kilobyte)
  const k = 1024;

  // Set the decimal places for the formatted result
  const dm = decimals < 0 ? 0 : decimals;

  // Define an array of units to use when formatting the result
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  // Calculate the index of the unit to use based on the logarithm of the input value
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  // Calculate the formatted value by dividing the input by the appropriate power of k, and round it to the desired number of decimal places
  const formattedValue = parseFloat((bytes / Math.pow(k, i)).toFixed(dm));

  // Return the formatted value with the appropriate unit from the sizes array
  return `${formattedValue} ${sizes[i]}`;
};

export const formatCostsToString = (value: number) => {
  return Number(value) >= 0.00095 ? Number(value).toFixed(3) : "~0";
};

export const formatCosts = (value: number) => {
  if (Number(value) > 1) {
    return value.toFixed(1);
  } else if (Number(value) >= 0.00095) {
    return Number(value).toFixed(3);
  } else {
    return "~0";
  }
};
