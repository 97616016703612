import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { authSlice } from "./AuthReducer";
import { appSlice } from "./AppReducer";
import storage from "redux-persist/lib/storage";
import { persistStore, persistReducer } from "redux-persist";

const persistConfig = {
  key: "root",
  storage,
};
const AuthDataReducer = persistReducer(persistConfig, authSlice.reducer);
const AppDataReducer = persistReducer(persistConfig, appSlice.reducer);
const store = configureStore({
  reducer: {
    auth: AuthDataReducer,
    app: AppDataReducer,
  },
  middleware: getDefaultMiddleware({
    serializableCheck: false,
  }),
});

const persistor = persistStore(store);

export { persistor };
export default store;
