import { TooltipProps } from "@mui/material";
import { TypographyProps } from "@sherloq/design-system";
import React from "react";
import { Typography } from "../Typography";
import { Tooltip } from "../tooltip";

interface Props extends TypographyProps {
  toolTipValue?: string | number;
  tooltipProps?: Omit<TooltipProps, "title">;
}

export const TypographyWithToolTip: React.FC<Props> = ({
  children,
  toolTipValue,
  tooltipProps = { placement: "top-start" },
  ...rest
}) => {
  return (
    <Tooltip title={toolTipValue ?? ""} {...tooltipProps}>
      <div>
        <Typography {...rest}>{children}</Typography>
      </div>
    </Tooltip>
  );
};
