import React from "react";
import CardComponent from "../../../../components/card/CardComponent";
import * as Styled from "./Table.styled";
import { AppTypography } from "../../../../styles/global";
import { TypographySize, TypographyBold } from "../../../../types/styles.d";
import searchPageImages from "../../../../assets/images/search";
import { setMaxString } from "../../../../utils/helpers/strings";
import Loading from "../../../../components/Loading/Loading";
import { Tooltip } from "@material-ui/core";
import { formatSeconds } from "../../../../utils/helpers/unit-converter";

function MostJoinedTablesTable({ data, loading, style = {} }) {
  const tableHeader = [
    {
      title: "Most joined tables",
      extra: "",
    },
    {
      title: "Most joined fields",
      extra: "",
    },
    {
      title: "Average Runtime",
      extra: "",
    },
  ];

  return (
    <CardComponent
      padding="20px 20px 0 20px"
      radius={1}
      title={"5 Most Joined Tables This Week"}
      style={style}
    >
      <>
        {loading && <Loading size={20} height={5} />}
        {data.length > 0 && !loading && (
          <>
            <Styled.Table
              style={{ borderSpacing: "0 15px", borderCollapse: "separate" }}
            >
              <Styled.THead>
                <Styled.TableRow>
                  {tableHeader.map(({ title, extra }, index) => (
                    <Styled.MostJoinedTableData key={index}>
                      <AppTypography
                        bold={TypographyBold.lg}
                        size={TypographySize.md}
                      >
                        {title}&nbsp;
                      </AppTypography>
                      <AppTypography size={TypographySize.xs}>
                        {extra}
                      </AppTypography>
                    </Styled.MostJoinedTableData>
                  ))}
                </Styled.TableRow>
              </Styled.THead>
              <Styled.TBody>
                {data?.map((value, index) => (
                  <Styled.TableRow key={index}>
                    <Styled.MostJoinedTableData
                      style={{
                        paddingLeft: 0,
                        position: "relative",
                        left: "-2px",
                      }}
                    >
                      <Styled.Row justifyContent="start">
                        <Styled.SmallTableIcon
                          src={searchPageImages.DBTableIcon}
                        />
                        <Tooltip
                          title={value?.MostJoinedTables}
                          placement="top-start"
                        >
                          <AppTypography underline size={TypographySize.sm}>
                            {value?.MostJoinedTables
                              ? setMaxString(value?.MostJoinedTables, 23)
                              : "N/A"}
                          </AppTypography>
                        </Tooltip>
                      </Styled.Row>
                    </Styled.MostJoinedTableData>
                    <Styled.MostJoinedTableData
                      style={{
                        paddingLeft: 0,
                        position: "relative",
                      }}
                    >
                      <Styled.Row justifyContent="start">
                        <Styled.SmallTableIcon
                          src={searchPageImages.DBFieldBlueIcon}
                        />
                        <Tooltip
                          title={value?.MostJoinedFields}
                          placement="top-start"
                        >
                          <AppTypography underline size={TypographySize.sm}>
                            {value?.MostJoinedFields
                              ? setMaxString(value?.MostJoinedFields, 23)
                              : "N/A"}
                          </AppTypography>
                        </Tooltip>
                      </Styled.Row>
                    </Styled.MostJoinedTableData>
                    <Styled.TableColumn>
                      <AppTypography size={TypographySize.sm}>
                        {value?.AverageRuntime
                          ? formatSeconds(value?.AverageRuntime)
                          : "N/A"}
                      </AppTypography>
                    </Styled.TableColumn>
                  </Styled.TableRow>
                ))}
              </Styled.TBody>
            </Styled.Table>
          </>
        )}
      </>
    </CardComponent>
  );
}

export default MostJoinedTablesTable;
