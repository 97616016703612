import mixpanel from "mixpanel-browser";
import { AnalyticsEvents } from "./analyticEvents";

const initUserToTrack = (identity: string) => {
  mixpanel.identify(identity);
};

const trackAction = (event: AnalyticsEvents, properties?: any) => {
  if (properties) mixpanel.track(event as any as string, properties);
  else mixpanel.track(event as any as string);
};

const setup = (token: string) => mixpanel.init(token);

const monitorSpecificUserActions = Object.freeze({
  setUser: (userProperties?: object) => {
    if (!userProperties) return;
    mixpanel.people.set(userProperties);
  },
});

export const AnalyticsClient = Object.freeze({
  initUserToTrack,
  trackAction,
  monitorSpecificUserActions,
  setup,
});

export type AnalyticsClientType = typeof AnalyticsClient;
