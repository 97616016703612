import { IQueryProfileQueryStats } from "../../../../../application";
import {
  AssetProfileStatsItemContainer,
  AssetProfileStatsItemValueText,
  AssetProfileStatsValueDescText,
  RootSideView,
  RootSideViewProps,
} from "../../../../shared-components";

interface Props extends Omit<RootSideViewProps, "header"> {
  data: IQueryProfileQueryStats;
}

export const QueryStatistics = ({ data, ...props }: Props) => {
  const { dataScanned, numReferences, runtime, cost } = data;
  return (
    <RootSideView header="Query Statistics" {...props}>
      <AssetProfileStatsItemContainer>
        <AssetProfileStatsItemValueText>
          {numReferences} use{numReferences > 1 ? "s" : ""}
        </AssetProfileStatsItemValueText>
        <AssetProfileStatsValueDescText>
          The # of times this query run
        </AssetProfileStatsValueDescText>
      </AssetProfileStatsItemContainer>
      <AssetProfileStatsItemContainer>
        <AssetProfileStatsItemValueText>
          {runtime}
        </AssetProfileStatsItemValueText>
        <AssetProfileStatsValueDescText>Runtime</AssetProfileStatsValueDescText>
      </AssetProfileStatsItemContainer>
      <AssetProfileStatsItemContainer>
        <AssetProfileStatsItemValueText>
          {dataScanned}
        </AssetProfileStatsItemValueText>
        <AssetProfileStatsValueDescText>
          Data scanned
        </AssetProfileStatsValueDescText>
      </AssetProfileStatsItemContainer>
      <AssetProfileStatsItemContainer>
        <AssetProfileStatsItemValueText>{cost}</AssetProfileStatsItemValueText>
        <AssetProfileStatsValueDescText>
          Average cost
        </AssetProfileStatsValueDescText>
      </AssetProfileStatsItemContainer>
    </RootSideView>
  );
};
