import globalImages from "../../../../assets/images/global";
import searchImages from "../../../../assets/images/search";
import * as Styled from "./SideList.styled";
import ListSideMenuItem from "../ListSideMenuItem/ListSideMenuItem";
import React, { useEffect, useState } from "react";
import { RootState, useReduxSelector } from "../../../../redux";
import { useLocation } from "react-router-dom";
import Loading from "../../../../components/Loading/Loading";
import { userService } from "../../../../v2/application";

export enum LIST_ROUTES {
  MY_LIST = "My List",
  ALL_LIST = "All Lists",
  USER_LIST = "User List",
}
function SideList() {
  const state = useReduxSelector((state: RootState) => state);
  const globalUser = state.auth.details;
  const [usersInCompany, setUsersInCompany] = useState<any>([]);
  const [searchValue, setSearchValue] = useState("");
  const [filteredResults, setFilteredResults] = useState<any>([]);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const location = useLocation();

  const routes = [
    {
      title: LIST_ROUTES.MY_LIST,
      icon: globalImages.usersManyPrimary,
      path: `/lists/${globalUser.SherloqUserId}`,
      selected: true,
    },
    {
      title: LIST_ROUTES.ALL_LIST,
      icon: globalImages.allListsIcon,
      path: "/lists/all",
      selected: false,
    },
  ];

  const getUsersInCompany = async () => {
    setLoadingUsers(true);
    const users = await userService.getUserCompanyUsers(globalUser.CompanyId);
    setUsersInCompany(users);
    setFilteredResults(users);
    setLoadingUsers(false);
  };

  const onSearchChange = () => {
    if (searchValue !== "") {
      const filteredData = usersInCompany.filter((item) => {
        return `${item.firstName}${item.lastName}`
          .toLowerCase()
          .includes(searchValue.toLowerCase());
      });
      setFilteredResults(filteredData);
    } else {
      setFilteredResults(usersInCompany);
    }
  };
  useEffect(() => {
    onSearchChange();
  }, [searchValue]);

  useEffect(() => {
    getUsersInCompany();
  }, []);

  return (
    <>
      {routes.map(({ icon, title, path }, i) => {
        return (
          <ListSideMenuItem
            selected={
              location.pathname === path ||
              location.pathname.startsWith(`${path}/extensionQueries`)
            }
            key={i}
            icon={icon}
            label={title}
            path={path}
          />
        );
      })}
      <Styled.SearchContainer>
        <Styled.SearchIcon src={globalImages.primarySearchIcon} />
        <Styled.SearchInput
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          placeholder="Search anyone..."
        />
      </Styled.SearchContainer>
      {loadingUsers && <Loading size={20} height={5} />}

      {filteredResults
        .filter(
          ({ sherloqUserId }) => sherloqUserId != globalUser.SherloqUserId
        )
        .map(({ firstName, lastName, sherloqUserId }, i) => {
          return (
            <ListSideMenuItem
              selected={location.pathname === `/lists/${sherloqUserId}`}
              key={i}
              icon={searchImages.UserIcon}
              label={`${firstName} ${lastName}`}
              path={`/lists/${sherloqUserId}`}
            />
          );
        })}
    </>
  );
}

export default SideList;
