import { QueryService } from "./query.service";

export const makeUpdateQueryInfoUsecase = async ({
  queryName,
  queryDescription,
  assetId,
  sherloqUserId,
}) => {
  return await Promise.allSettled([
    QueryService.updateQuerydescription(
      assetId,
      sherloqUserId,
      queryDescription
    ),
    QueryService.updateQueryName(assetId, sherloqUserId, queryName),
  ]);

  //   return res;
};
