import { createAsyncThunk } from "@reduxjs/toolkit";
import { DefaultLoginParams, GoogleLoginParams } from "./interfaces";

export const defaultUserLogin = createAsyncThunk(
  "user/getUser",
  async (
    {
      params,
      onSuccessCallBack,
      onFailedCallback,
      loginUserRequest,
    }: DefaultLoginParams,
    thunkApi
  ) => {
    const { password, username } = params;
    try {
      const data = await loginUserRequest(username, password);
      return onSuccessCallBack(data);
    } catch (e: any) {
      onFailedCallback(e, username);
      return thunkApi.rejectWithValue(e as ErrorEventInit);
    }
  }
);
export const googleUserLogin = createAsyncThunk(
  "user/getGoogleUser",
  async (
    {
      bearer,
      loginUserRequest,
      onFailedCallback,
      onSuccessCallBack,
    }: GoogleLoginParams,
    { rejectWithValue }
  ) => {
    try {
      const googleLoginResponseObj = await loginUserRequest(bearer);
      const transformedData = onSuccessCallBack(googleLoginResponseObj);
      return transformedData;
    } catch (e: any) {
      onFailedCallback(e, bearer);
      return rejectWithValue(e);
    }
  }
);
