import {
  transformMostCommonFieldsByFieldGraphDataBy,
  transformMostCommonFieldsByOperatorGraphDataBy,
} from "../../application";
import { useMemo, useState } from "react";

const defaultGraphData = {
  graphData: [],
  keys: [],
  indexBy: "",
};

export const useMostCommonFields = (fieldsData: any[], operatorData: any[]) => {
  const [filterBy, setFilterBy] = useState<"by operator" | "by field">(
    "by field"
  );

  const graphData = useMemo(() => {
    const fieldsDataIsValid =
      Array.isArray(fieldsData) && fieldsData.length > 0;
    const operatorDataIsValid =
      operatorData && Object.keys(operatorData).length > 0;

    if (!fieldsDataIsValid || !operatorDataIsValid) return defaultGraphData;
    if (filterBy === "by field") {
      if (operatorDataIsValid) {
        const data = transformMostCommonFieldsByFieldGraphDataBy(fieldsData);
        return data;
      }

      return defaultGraphData;
    } else if (filterBy === "by operator") {
      if (operatorDataIsValid)
        return transformMostCommonFieldsByOperatorGraphDataBy(operatorData);
      return defaultGraphData;
    }
  }, [filterBy, fieldsData, operatorData]);

  const onChangeFilterBy = (e) => setFilterBy(e.target.value);

  return {
    ...graphData,
    filterBy,
    setFilterBy,
    onChangeFilterBy,
  };
};
