import styled from "styled-components";

export const MostUsedTablesContainer = styled.div`
  width: 100%;
`;
export const FirstRow = styled.div`
  display: flex;
  gap: 10px;
  margin: 40px 0;
`;
export const ThirdRow = styled.div`
  display: flex;
  gap: 10px;
  margin: 40px 0;
  width: 100%;
`;
export const Row = styled.div`
  margin: 40px 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  column-gap: 50px;
`;
export const BarChartContainer = styled.div`
  width: 30%;
`;
export const MostUsedTableGraph = styled.div`
  height: 280px;
`;
export const MostJoinedTablesContainer = styled.div`
  display: flex;
  overflow-y: hidden;
  width: 50%;
`;
export const TrendingTablesContainer = styled.div`
  width: 40%;
`;

export const BaseRow = styled.div`
  display: flex;
`;

export const HeaderRow = styled(BaseRow)`
  width: 100%;
  justify-content: space-between;
`;

export const RowContainer = styled.div`
width
`;
