import { env } from "../env";
import { CreateClientConstructor } from "./axios";
import {
  addHeaderToRequest,
  defaultRequestErrorHandler,
  returnErrorResponse,
} from "./interceptors";
import { createBaseHttpClient } from "./createBase";

const noAuthHttpClient = createBaseHttpClient(
  env.serverBaseURl,
  CreateClientConstructor
);
const extensionServerNoAuthHttpClient = createBaseHttpClient(
  env.extServerBaseURl,
  CreateClientConstructor
);

noAuthHttpClient.addRequestInterceptor(
  addHeaderToRequest,
  defaultRequestErrorHandler
);
noAuthHttpClient.addResponseInterceptor(undefined, returnErrorResponse);

export { noAuthHttpClient, extensionServerNoAuthHttpClient };
