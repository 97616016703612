import { TableService } from "../../application";
import { AnalyticsClient, homeActions } from "../../infrastructure";
import { useEffect } from "react";
import { useQuery } from "react-query";
import { useHomeGlobalState } from "./homeReducerHooks";
import { useGlobalStateDispatch } from "../redux";
import { useGlobalAuthState } from "../user";
import { AssetSortValues } from "../../core";

export const useTableResultsSubpage = () => {
  const { searchKeyword, filters, sortBy } = useHomeGlobalState();
  const { user } = useGlobalAuthState();
  const dispatch = useGlobalStateDispatch();
  const { refetch, data, isLoading, isError } = useQuery({
    queryKey: ["homepage-search-table", searchKeyword, filters, user, sortBy],
    queryFn: () => TableService.searchTable(searchKeyword, filters, sortBy),
    enabled: false,
    onSuccess: () => {
      AnalyticsClient.trackAction("main_table_results_search_success", {
        keyword: searchKeyword,
        filters: { ...filters, sortBy },
      });
    },
    onError: () => {
      AnalyticsClient.trackAction("main_table_results_search_failed", {
        keyword: searchKeyword,
        filters: { ...filters, sortBy },
      });
    },
  });

  useEffect(() => {
    dispatch(homeActions.syncSortBy(AssetSortValues[1])); //setDefault sort value to the first. To persist Ewenson's logic
  }, []);

  useEffect(() => {
    refetch();
  }, [searchKeyword, filters, sortBy]);

  return {
    refetch,
    data,
    isLoading,
    isError,
  };
};
