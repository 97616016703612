import { AppRoutes } from "../../application";
import { useNavigate } from "react-router-dom";
import { ITable, Table } from "../../core";
import { AnalyticsClient } from "../../infrastructure";

export const useTableComponent = (data: ITable) => {
  const navigate = useNavigate();
  const transformedTable = new Table(data).data;

  const onClickTable = () => {
    navigate(`/${AppRoutes.TABLE_PROFILE}/${transformedTable.tableId}`);
    AnalyticsClient.trackAction("main_table_results_table_click", {
      id: transformedTable?.tableId,
    });
  };
  return { ...transformedTable, onClickTable };
};
