import { transformAssetDate, transformAssetUsername } from "../../core";

export interface TopFieldsTableFields {
  fieldName: string;
  fieldDescription: string;
  fieldType: string;
  percentageOfReferences: number;
  numOfReferences: number;
  numJoinReferences: number;
  numSelectReferences: number;
  numWhereReferences: number;
  lastReferencingQuery: string;
  lastReferencingUser: string;
  lastQueryReferencingDate: string;
}

export const transformTableFields = (data: any[]) => {
  const transformedData = data.map(
    ({
      fieldName,
      fieldDescription,
      fieldType,
      referencePercentageOutOfTableReferences,
      lastUsernameThatReferenced,
      lastQueryThatReferenced,
      lastReferenceDatetime,
      numJoinReferences,
      numOfReferences,
      numSelectReferences,
      numWhereReferences,
    }: any) =>
      ({
        fieldType,
        lastQueryReferencingDate: transformAssetDate(lastReferenceDatetime),
        lastReferencingQuery: lastQueryThatReferenced,
        lastReferencingUser: transformAssetUsername(lastUsernameThatReferenced),
        fieldName,
        fieldDescription,
        numJoinReferences,
        numOfReferences,
        numSelectReferences,
        numWhereReferences,
        percentageOfReferences: referencePercentageOutOfTableReferences,
      } as TopFieldsTableFields)
  );
  return transformedData;
};

export const getTotalTableFieldsRefs = (data: TopFieldsTableFields[]) => {
  return data.reduce((acc, prev) => {
    return (acc += prev.numOfReferences);
  }, 0);
};
