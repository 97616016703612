import CardComponent from "../../../../components/card/CardComponent";
import * as Styled from "./Table.styled";
import { AppTypography } from "../../../../styles/global";
import { TypographySize, TypographyBold } from "../../../../types/styles.d";
import theme from "../../../../styles/theme";
import Loading from "../../../../components/Loading/Loading";
import { useEffect, useMemo, useState } from "react";
import { authHttpClient } from "../../../../v2/infrastructure";
import { usePagination } from "../../../../components/Pagination";
import PaginationComponent from "../../../../components/Pagination";
import { formatCosts } from "../../../../utils/helpers";

interface MostExpensiveQueryProps {
  title: string;
  loading: boolean;
  itemsPerPage: number;
}

type UsedTableSortFields =
  | "workgroup"
  | "firstinterval"
  | "secondinterval"
  | "thirdinterval"
  | "fourthinterval"
  | "fifthinterval";

type UsedTableSortOrder = "desc" | "asc";

type WeeklyInterval = {
  startDate: string;
  endDate: string;
};

export const TotalCostPerQueryRun = ({
  title,
  loading,
  itemsPerPage,
}: MostExpensiveQueryProps) => {
  const { currentPage, onSkipToNextPage, onSkipToPreviousPage } =
    usePagination();
  const paginationConfig = useMemo(() => {
    return {
      start: itemsPerPage ? itemsPerPage * currentPage - (itemsPerPage - 1) : 0,
      end: currentPage * (itemsPerPage ?? 0),
      showPrevious: currentPage > 1,
    };
  }, [currentPage, itemsPerPage]);

  const [tableContent, setTableContent] = useState<any>([]);
  const tableHeader = [
    {
      title: "Workgroup",
      extra: "",
    },
    {
      title: "Week 1",
      extra: "",
    },
    {
      title: "Week 2",
      extra: "",
    },
    {
      title: "Week 3",
      extra: "",
    },
    {
      title: "Week 4",
      extra: "",
    },
    {
      title: "Week 5",
      extra: "",
    },
    {
      title: "% Of Total Cost",
      extra: "",
    },
  ];

  const getTotalCostPerQueryRun = async (Page: number) => {
    const path = "/v1/Analytics/getTotalCostPerQueryRun";
    const params = { Page };
    const { data } = await authHttpClient.get(path, { params });
    setTableContent(data.Data);
  };

  useEffect(() => {
    getTotalCostPerQueryRun(currentPage);
  }, [currentPage]);

  return (
    <CardComponent padding="20px 20px 0 20px" overflowVisible="visible">
      <>
        <Styled.CardComponentHeader>
          <AppTypography
            bold={TypographyBold.lg}
            size={TypographySize.md}
            textColor={theme.appColors.dark}
          >
            {title}
          </AppTypography>
        </Styled.CardComponentHeader>
        {loading && <Loading size={20} height={5} />}
        {tableContent && tableContent.length > 0 && !loading && (
          <Styled.Table>
            <Styled.THead>
              <Styled.TableRow>
                {tableHeader.map(({ title, extra }, index) => (
                  <Styled.TableHead key={index}>
                    <AppTypography
                      bold={TypographyBold.lg}
                      size={TypographySize.md}
                    >
                      {title}&nbsp;
                    </AppTypography>
                    <AppTypography size={TypographySize.xs}>
                      {extra}
                    </AppTypography>
                  </Styled.TableHead>
                ))}
              </Styled.TableRow>
            </Styled.THead>
            <Styled.TBody>
              {tableContent &&
                tableContent?.map((value, index) => (
                  <Styled.TableRow key={index}>
                    <Styled.TableColumn>
                      <AppTypography size={TypographySize.sm}>
                        {value.Workgroup}
                      </AppTypography>
                    </Styled.TableColumn>
                    <Styled.TableColumn>
                      <AppTypography size={TypographySize.sm}>
                        {"$" + formatCosts(value.FifthInterval)}
                      </AppTypography>
                    </Styled.TableColumn>

                    <Styled.TableColumn>
                      <AppTypography size={TypographySize.sm}>
                        {"$" + formatCosts(value.FourthInterval)}
                      </AppTypography>
                    </Styled.TableColumn>
                    <Styled.TableColumn>
                      <AppTypography size={TypographySize.sm}>
                        {"$" + formatCosts(value.ThirdInterval)}
                      </AppTypography>
                    </Styled.TableColumn>
                    <Styled.TableColumn>
                      <AppTypography size={TypographySize.sm}>
                        {"$" + formatCosts(value.SecondInterval)}
                      </AppTypography>
                    </Styled.TableColumn>
                    <Styled.TableColumn>
                      <AppTypography size={TypographySize.sm}>
                        {"$" + formatCosts(value.FirstInterval)}
                      </AppTypography>
                    </Styled.TableColumn>
                    <Styled.TableColumn>
                      <AppTypography size={TypographySize.sm}>
                        {formatCosts(value.TotalCostPercentage) + "%"}
                      </AppTypography>
                    </Styled.TableColumn>
                  </Styled.TableRow>
                ))}
            </Styled.TBody>
          </Styled.Table>
        )}
        <PaginationComponent
          currentPage={paginationConfig.start}
          totalPages={paginationConfig.end}
          onSkipToNextPage={onSkipToNextPage}
          onSkipToPreviousPage={onSkipToPreviousPage}
          showNext={true}
          showPrevious={paginationConfig.showPrevious}
        />
      </>
    </CardComponent>
  );
};
