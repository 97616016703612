import { ITableProfileTableStatics } from "@/v2/core";
import { RenderIf } from "../../../../lib";
import {
  AssetProfileStatsItemContainer,
  AssetProfileStatsItemValueText,
  RootSideView,
  Typography,
} from "../../../../shared-components";
import {
  DescriptionText,
  Header,
  StatisticsContainer,
  UnusedFieldsContainer,
} from "./components";

interface Props {
  onClose: () => void;
  open: boolean;
  data: ITableProfileTableStatics;
}

export const TableStatisticsSideView = ({ open, onClose, data }: Props) => {
  const {
    numOfActiveFields,
    numOfFields,
    numOfReferences,
    percentageOfActiveFields,
  } = data;
  return (
    <RootSideView open={open} header="Table Statistics" onClose={onClose}>
      <StatisticsContainer>
        <AssetProfileStatsItemContainer>
          <AssetProfileStatsItemValueText>
            {numOfReferences} query references
          </AssetProfileStatsItemValueText>
          <DescriptionText>
            The total number of queries accessing this Table.
          </DescriptionText>
        </AssetProfileStatsItemContainer>
        <AssetProfileStatsItemContainer>
          <AssetProfileStatsItemValueText>
            {numOfFields} fields
          </AssetProfileStatsItemValueText>
          <DescriptionText>
            The total number of fields in this Table.
          </DescriptionText>
        </AssetProfileStatsItemContainer>
        <AssetProfileStatsItemContainer>
          <AssetProfileStatsItemValueText>
            {percentageOfActiveFields}% of fields in this table are active
          </AssetProfileStatsItemValueText>
          <DescriptionText>
            The % of active fields in this Table
          </DescriptionText>
        </AssetProfileStatsItemContainer>
        <AssetProfileStatsItemContainer>
          <AssetProfileStatsItemValueText>
            {numOfActiveFields} active fields
          </AssetProfileStatsItemValueText>
          <DescriptionText>
            The # of active fields in this Table
          </DescriptionText>
        </AssetProfileStatsItemContainer>
      </StatisticsContainer>
      <UnusedFieldsContainer>
        <Header variant="h6">Unused Fields</Header>
        {RenderIf(
          true,
          <Typography variant="p-light">
            There is no unused fields in this table
          </Typography>
        )}
      </UnusedFieldsContainer>
    </RootSideView>
  );
};
