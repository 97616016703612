import React from "react";
import { TableProfileContextType, TableProfileGlobalState } from "./interface";

export const initialTableProfileState: TableProfileGlobalState = {
  openEditTableProfileModal: false,
  openQueriesUsageSideView: false,
  openTopFieldsSideView: false,
  openTopusersSideView: false,
  openAllJoinedFieldsBreakdownSideView: false,
  openOneJoinedFieldsBreakdownSideView: false,
  openTableStatisticsSideView: false,
  openMostJoinedFieldsSideView: false
};

export const TableProfileContext = React.createContext<TableProfileContextType>(
  [initialTableProfileState, () => null]
);
