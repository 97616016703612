import { authHttpClient } from "../../infrastructure";
import { IQuery } from "../../core";
import {
  makeAddAssetToUserListFactoryFn,
  makeChangeAssetInput,
  makeGetAssetProfile,
  makeIsAssetInUserListFactoryFn,
  makeRemoveAssetFromUserListFactoryFn,
  makeSearchAssetFactory,
} from "../asset";
import { WeeklyCostReportType } from "./interfaces";

const searchQuery = makeSearchAssetFactory<IQuery>("query");

const getTableProfile = makeGetAssetProfile("query");

const isQueryInUserList = makeIsAssetInUserListFactoryFn("query");
const removeQueryFromUserList = makeRemoveAssetFromUserListFactoryFn("query");

const addQueryToUserList = makeAddAssetToUserListFactoryFn("query");

const updateQuerydescription = makeChangeAssetInput("query", "description");
const updateQueryName = makeChangeAssetInput("query", "given_name");
const changeQueryReadMe = makeChangeAssetInput("query", "read_me");

const getWeeklyCostPerQueryRun = async (
  costgraphtoggle: WeeklyCostReportType,
  Workgroup: string | undefined
) => {
  const path = "/v1/Analytics/getWeeklyCostPerQueryRun";
  const params = {
    costgraphtoggle,
    Workgroup,
  };
  const { data } = await authHttpClient.get(path, { params });
  return data;
};

const getTop5MostExpensiveWeeklyQueries = async (
  assetType: WeeklyCostReportType,
  workgroups?: string | undefined
) => {
  const path = "/v1/Analytics/getTop5MostExpensiveThisWeek";
  const params = {
    workgroups,
    assetType,
  };
  const { data } = await authHttpClient.get(path, { params });
  return data;
};

const getQueryInsights = async () => {
  const path = "/v1/Analytics/GetQueryInsights";
  const { data } = await authHttpClient.get(path);
  return data;
};

const getExtensionQueries = async (folderId?: string) => {
  let queryParams = "";
  if (folderId) {
    queryParams = `?folderUid=${folderId}`;
  }
  const path = `/v1/getdata/getExtensionListByUser${queryParams}`;
  const { data } = await authHttpClient.get(path);
  const parsed = JSON.parse(data);
  return parsed;
};

export const QueryService = Object.freeze({
  searchQuery,
  getTableProfile,
  isQueryInUserList,
  removeQueryFromUserList,
  addQueryToUserList,
  updateQuerydescription,
  updateQueryName,
  getWeeklyCostPerQueryRun,
  getTop5MostExpensiveWeeklyQueries,
  getQueryInsights,
  getExtensionQueries,
  changeQueryReadMe,
});
