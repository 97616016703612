import styled from "styled-components";
import { AppTypography } from "../../styles/global";
import { InputContainer } from "../Input/Input.styled";
import Select from "react-select";

export const FilterBarContainer = styled.div`
  display: flex;
  flex-direction:row;
  gap: ${({theme})=>theme.spacing.sm};
  width: 400px;
`;

export const Dropdown = styled(Select)`
  align-self: center;
  width:inherit;
  height: 30px;
  border-radius: 4px;
  font-size: 12px;
  height: 40px;
  z-index: 9999;
`

export const HeaderText = styled(AppTypography)`
  align-self: center;
  width: 7vw;
  text-align: center;
`

export const ValueTextBox = styled(InputContainer)`
  align-self: center;
  font-size: 12px;
  margin-left: 0%;
  margin-bottom: 0%;
  margin-bottom: 2.5px;
  line-height: 30px;
  border: 1px solid #AAAAAA;
  box-sizing: border-box;
  border-radius: 4px;
  height: 37.5px;
  ::placeholder,
  ::-webkit-input-placeholder {
    color: grey;
  }
  :-ms-input-placeholder {
     color: grey;
  }
`