import React, { useState } from "react";
import * as Styled from "./Lists.styles";
import FeedbackComponent from "../../components/FeedbackComponent/FeedbackComponent";
import SideList from "./components/SideList/SideList";
import { Outlet } from "react-router-dom";
import CardComponent, {
  CardComponentHeader,
} from "../../components/card/CardComponent";
import globalImages from "../../assets/images/global";
import { Mixpanel } from "../../mixpanelWrapper";

function Lists() {
  const [showSideList, setShowSideList] = useState(true);
  const handleMainViewMenuIconClick = () => {
    setShowSideList((prev) => !prev);
    Mixpanel.track("main_expand_users_lists_bar_click");
  };
  return (
    <Styled.Container>
      <Styled.Content>
        <Styled.Wrapper>
          {showSideList && (
            <Styled.SideListViewContainer>
              <CardComponent showShadow={true} background={"white"} padding="0">
                <>
                  <CardComponentHeader>
                    <Styled.SideMenuIcon
                      src={globalImages.menuIcon}
                      onClick={handleMainViewMenuIconClick}
                    />
                  </CardComponentHeader>
                  <SideList />
                </>
              </CardComponent>
            </Styled.SideListViewContainer>
          )}

          <Styled.ListViewContainer fullscreen={!showSideList}>
            <CardComponent showShadow={true} background={"white"}>
              <>
                {!showSideList && (
                  <Styled.Icon
                    src={globalImages.menuIcon}
                    onClick={handleMainViewMenuIconClick}
                  />
                )}
                <Outlet />
              </>
            </CardComponent>
          </Styled.ListViewContainer>
        </Styled.Wrapper>
      </Styled.Content>
      <FeedbackComponent assetId={0} screenName={"lists"} />
    </Styled.Container>
  );
}

export default Lists;
