import { TextBoxWithToolTip } from "../../../../../components/TextHoldersWithToolTip";
import { TypographySize } from "../../../../../types/styles.d";
import {
  formatBytes,
  formatCostsToString,
  formatSeconds,
  setMaxString,
} from "../../../../../utils/helpers";
import { TableRow } from "@material-ui/core";

interface UserRowProps {
  data: {
    AverageCost: number;
    TotalCost: number;
    UserName: string;
    AverageDataScanned: number;
    AverageRuntime: number;
    NumOfReferences: number;
    TotalCostPercentage: number;
  };
}

export const UserTableRow = ({ data }: UserRowProps) => {
  const {
    AverageCost,
    AverageDataScanned,
    AverageRuntime,
    NumOfReferences,
    TotalCost,
    UserName,
    TotalCostPercentage
  } = data;

  return (
    <TableRow
      style={{
        height: "50px",
      }}
    >
      <td align="center">
        <TextBoxWithToolTip
          toolTipValue={UserName}
          typographyProps={{
            size: TypographySize.sm,
          }}
        >
          {setMaxString(UserName ?? "", 25)}
        </TextBoxWithToolTip>
      </td>
      <td align="center">
        <TextBoxWithToolTip
          toolTipValue={NumOfReferences}
          typographyProps={{
            size: TypographySize.sm,
          }}
        >
          {NumOfReferences}
        </TextBoxWithToolTip>
      </td>
      <td align="center">
        <TextBoxWithToolTip
          toolTipValue={TotalCost}
          typographyProps={{
            size: TypographySize.sm,
          }}
        >
          {"$"+formatCostsToString(TotalCost)}
        </TextBoxWithToolTip>
      </td>
      <td align="center">
        <TextBoxWithToolTip
          toolTipValue={AverageCost}
          typographyProps={{
            size: TypographySize.sm,
          }}
        >
          {"$"+formatCostsToString(AverageCost)}
        </TextBoxWithToolTip>
      </td>
      <td align="center">
        <TextBoxWithToolTip
          toolTipValue={AverageRuntime}
          typographyProps={{
            size: TypographySize.sm,
          }}
        >
          {formatSeconds(AverageRuntime)}
        </TextBoxWithToolTip>
      </td>
      <td align="center">
        <TextBoxWithToolTip
          toolTipValue={AverageDataScanned}
          typographyProps={{
            size: TypographySize.sm,
          }}
        >
          {formatBytes(AverageDataScanned)}
        </TextBoxWithToolTip>
      </td>
      {TotalCostPercentage ? 
      <td align="center">
        <TextBoxWithToolTip
          toolTipValue={TotalCostPercentage}
          typographyProps={{
            size: TypographySize.sm,
          }}
        >
          {`%${TotalCostPercentage === 0 ? TotalCostPercentage : TotalCostPercentage.toFixed(2)}`}
        </TextBoxWithToolTip>
      </td> : ''
      }
    </TableRow>
  );
};
