import "ace-builds/src-noconflict/ace";
import "ace-builds/src-noconflict/mode-mysql";
import "ace-builds/src-noconflict/theme-textmate";
import React from "react";
import AceEditor, { IAceEditorProps } from "react-ace";
export * as QueryEditorUtils from "./utils";

export const RootQueryEditor: React.FC<IAceEditorProps> = (props) => {
  return <AceEditor {...props} />;
};
