import { httpWithoutAuth } from "./base";

export const loginWithGoogleService = async (bearer: string) => {
    const path = "/Login/google";

    const payload = {
        bearer
    }
    try {
        const { data }  = await httpWithoutAuth.post(path, payload)
        return await Promise.resolve(data)
    } catch (err) {
        return await Promise.reject(err)
    }
}