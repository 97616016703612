import { IAceEditorProps } from "react-ace";
import { RootQueryEditor } from "./rootEditor";

type Props = IAceEditorProps;

export const DefualtSQLEditor: React.FC<Props> = (props) => {
  return (
    <RootQueryEditor
      placeholder="Placeholder Text"
      mode="mysql"
      theme="textmate"
      name=""
      fontSize={12}
      showPrintMargin={false}
      showGutter={false}
      highlightActiveLine={false}
      readOnly
      maxLines={12}
      wrapEnabled
      width="100%"
      {...props}
    />
  );
};
