export enum ASSET_TYPE {
  TABLE = "table",
  QUERY = "query",
  FIELD = "field",
}

export enum USER_INPUT_TYPE {
  DESCRIPTION = "description",
  COMMENT = "comment",
  GIVEN_OWNER = "given_owner",
  GIVEN_NAME = "given_name",
  READ_ME = "read_me",
}
export interface ITableItems {
  dbName: string;
  description: string;
  givenOwner?: string;
  lastReferenceDatetime: string;
  lastUsernameThatReferenced?: string;
  tableId: number;
  tableName: string;
  businessUnit?: string | null;
  deptName?: string | null;
  jobFamilyName?: string | null;
  jobTitle?: string | null;
  numOfReferences?: string | number | null;
  preferredName?: string | null;
  workEmail?: string | null;
  source?: string;
}
export interface IQueryItems {
  firstQueryThatReferencedDatetime: string;
  firstUsernameThatReferenced: string;
  lastQueryDataScannedInBytes: number;
  lastQueryDurationInMillis: number;
  lastReferenceDatetime: string;
  lastSherloqExecutionId: number;
  lastUsernameThatReferenced: string;
  listOfTablesAccessed: string[];
  listOfTablesAndFieldsIdsAccessed: string[];
  listOfTablesAndFieldsNamesAccessed: string[];
  numOfReferences: number;
  queryId: number;
  queryString: string;
  queryGivenName: string;
  description: string;
  businessUnit?: string | null;
  deptName?: string | null;
  jobFamilyName?: string | null;
  jobTitle?: string | null;
  preferredName?: string | null;
  workEmail?: string | null;
  querySource?: string;
}

export interface ITopFiveTableUsage {
  TableName: string;
  NumOfReferences: string | number;
  TableUsagePercentage: string | number;
  UserName: string;
  score?: string | number;
}
export interface IRelevantTablesForQueries {
  tableName: string;
  fields: string[];
}

export type AssetType = ASSET_TYPE.TABLE | ASSET_TYPE.QUERY | ASSET_TYPE.FIELD;
export type UserInputType =
  | USER_INPUT_TYPE.DESCRIPTION
  | USER_INPUT_TYPE.COMMENT
  | USER_INPUT_TYPE.GIVEN_OWNER
  | USER_INPUT_TYPE.GIVEN_NAME
  | USER_INPUT_TYPE.READ_ME;
