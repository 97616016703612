import { Link } from "react-router-dom";
import globalImages from "../../../assets/images/global";
import { AppTypography } from "../../../styles/global";
import styled from "styled-components";
import theme from "../../../styles/theme";
import { TypographyBold, TypographySize } from "../../../types/styles.d";

export enum ANALYTIC_ROUTES {
  USAGE = "Usage",
  COST = "Cost",
  RUNTIME = "Runtime",
  SCANNED_DATA = "Scanned Data",
  USERS = "Users",
}
interface ItabItem {
  icon?: string;
  label: string;
  selected?: boolean;
  path: string;
}

function CostTabs() {
  const routes = [
    {
      title: ANALYTIC_ROUTES.USAGE,
      icon: globalImages.chartIcon,
      path: "/analytics/usage",
    },
    {
      title: ANALYTIC_ROUTES.COST,
      icon: globalImages.currencyIcon,
      path: "/analytics/cost",
    },
    {
      title: ANALYTIC_ROUTES.RUNTIME,
      icon: globalImages.clockIcon,
      path: "/analytics/runtime",
    },
    {
      title: ANALYTIC_ROUTES.SCANNED_DATA,
      icon: globalImages.rotatingIcon,
      path: "/analytics/datascanned",
    },
    {
      title: ANALYTIC_ROUTES.USERS,
      icon: globalImages.User,
      path: "/analytics/users",
    },
  ];
  return (
    <>
      <TabsWrapper>
        {routes.map(({ icon, title, path }, i) => (
          <TabItem
            selected={location.pathname === path}
            key={i}
            icon={icon}
            label={title}
            path={path}
          />
        ))}
      </TabsWrapper>
    </>
  );
}

export default CostTabs;

interface TabContainerProps {
  selected?: boolean;
}

function TabItem({ icon, label, selected, path }: ItabItem) {
  return (
    <TabLinkContainer selected={selected} to={path}>
      <Icon src={icon} />
      <AppTypography
        size={TypographySize.md}
        bold={selected ? TypographyBold.lg : TypographyBold.md}
        textColor={selected ? theme.appColors.tertiary : theme.appColors.dark}
      >
        {label}
      </AppTypography>
    </TabLinkContainer>
  );
}

const TabLinkContainer = styled(Link)<TabContainerProps>`
  display: flex;
  align-items: center;
  gap: 5px;
  text-decoration: none;
  padding: 0 20px 10px 0;
  border-bottom: 3px solid
    ${({ selected }) =>
      selected ? theme.appColors.tertiary : theme.appColors.ash};
`;

const TabsWrapper = styled.div`
  display: flex;
  width: fit-content;
`;
const Icon = styled.img``;
