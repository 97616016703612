import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { subDays } from "date-fns";
import { usePagination } from "../Pagination";
import { debounce } from "../../utils/helpers";
import { AnalyticsTableDefaultParams } from "./interfaces";

interface Params extends AnalyticsTableDefaultParams {
  refetch: (
    StartDate,
    EndDate,
    Page,
    Search,
    SortColumn: UsedTableSortFields,
    SortOrder: UsedTableSortOrder
  ) => void;
}

type UsedTableSortFields =
  | "averagecost"
  | "totalcost"
  | "averagedatascanned"
  | "averageruntime"
  | "tablename"
  | "numOfReferences";

type UsedTableSortOrder = "desc" | "asc";

export const useAnalyticsTable = ({
  refetch,
  dataPageSize,
  defaultSortField,
}: Params) => {
  const { currentPage, onSkipToNextPage, onSkipToPreviousPage } =
    usePagination();
  const [searchKeyword, setSearchKeyword] = useState("");
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("desc");
  const [sortColumn, setSortColumn] = useState<UsedTableSortFields>(
    (defaultSortField as any) ?? "numOfReferences"
  );
  const [duration, setDuration] = useState<number>(7); // dropdown default value
  const durationRef = useRef<number>(duration);
  const navigate = useNavigate();
  const searchInputRef = useRef<HTMLInputElement>(null);
  const paginationConfig = useMemo(() => {
    return {
      start: dataPageSize * currentPage - (dataPageSize - 1),
      end: currentPage * dataPageSize,
      showPrevious: currentPage > 1,
    };
  }, [currentPage, dataPageSize]);
  const refetchMethod = (
    sortField: UsedTableSortFields,
    order: UsedTableSortOrder,
  ) => {
    const today = new Date();
    const selectedDuration = durationRef.current;
    setSortOrder(() => {
      refetch(
        subDays(today, selectedDuration),
        today,
        currentPage,
        searchKeyword,
        sortField,
        order
      );
      setSortColumn(sortField);
      return order;
    });
  };

  const onChangeDuration = (e: any) => {
    setDuration(e.value);
  };

  const onchangeSearchKeyword = (e?: any) => {
    const value = e.target.value;
    setSearchKeyword(value);
  };

  const onSearchInputChange = useCallback(
    debounce((e) => setSearchKeyword(e.target.value)),
    []
  );

  const handleTableClick = (tableId: number | string) => {
    const extras = {
      state: {
        fromPage: location.pathname,
      },
    };
    return navigate(`/dashboard/search/table/${tableId}`, extras);
  };

  useEffect(() => {
    durationRef.current = duration;
  }, [duration]);

  useEffect(() => {
    if (duration >= 7) {
      // To trigger refetch only when dropdown changes
      const today = new Date();
      refetch(
        subDays(today, duration),
        today,
        currentPage,
        searchKeyword,
        sortColumn,
        sortOrder
      );
    }
  }, [currentPage, duration, searchKeyword, sortColumn, sortOrder]);

  useEffect(() => {
    function focusSearchInput(e) {
      if (e.keyCode === 114 || (e.ctrlKey && e.keyCode === 70)) {
        e.preventDefault();
        if (!searchInputRef.current) return;
        searchInputRef.current.focus();
      }
    }
    document.addEventListener("keydown", focusSearchInput);

    return () => {
      document.onkeydown = null;
      document.removeEventListener("keydown", (e) => focusSearchInput(e));
    };
  }, []);

  return {
    refetchMethod,
    currentPage,
    paginationConfig,
    duration,
    searchKeyword,
    searchInputRef,
    onChangeDuration,
    onchangeSearchKeyword,
    onSearchInputChange,
    handleTableClick,
    onSkipToNextPage,
    onSkipToPreviousPage,
  };
};
