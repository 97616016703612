import moment from "moment-timezone";

export const transformRumtimeWeekylCostPerQuery = (
  data: any[],
  transformYIndex: (data: any) => number
) => {
  return data?.map(({ Workgroup, Values }) => {
    return {
      id: Workgroup,
      color: "#7209B7",
      data: Values.map((item) => {
        const startDate = new Date(item.Period.slice(0, 10));
        const endDate = new Date(item.Period.slice(13, item.Period.length));
        const startDateFormat = moment(startDate).format("DD/MM");
        const endDateFormat = moment(endDate).format("DD/MM");
        const y = transformYIndex(item);
        return {
          x: `${startDateFormat} - ${endDateFormat}`,
          y,
        };
      }),
    };
  });
};
