import mixpanel from 'mixpanel-browser';
mixpanel.init('ac6be5cdc270cdd27c35934355cf1c6e');

// let env_check = process.env.NODE_ENV === 'production';

let actions = {
  identify: (id) => {
    // if (env_check) mixpanel.identify(id);
    mixpanel.identify(id);
  },
  alias: (id) => {
    // if (env_check) mixpanel.alias(id);
    mixpanel.alias(id);
  },
  track: (name, props) => {
    // if (env_check) mixpanel.track(name, props);
    mixpanel.track(name, props);
  },
  people: {
    set: (props) => {
      // if (env_check) mixpanel.people.set(props);
      mixpanel.people.set(props);
    },
  },
};

export let Mixpanel = actions;