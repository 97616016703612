import { User, makeUser } from "../../../core";
import { useGlobalAuthState } from "../../../hooks";
import { useMemo, useState } from "react";
import { Navigate } from "react-router-dom";

export const CostsReportProtectedRoute = ({ children }: any) => {
  const { details } = useGlobalAuthState();
  const User = useState<User>(makeUser(details))[0];

  const authorized = useMemo(() => {
    return User.isAdmin;
  }, [User]);

  if (authorized) return children;
  return <Navigate to="/home" />;
};
