import * as Styled from "../styled";
import { AssetType, ASSET_TYPE } from "../../../../services/models";
import icons from "../../../../assets/images/search";

export function ItemIcon({ type, style }: { type: AssetType; style?: any }) {
  return (
    <>
      {type === ASSET_TYPE.TABLE && (
        <Styled.Icon style={style} src={icons.DBTableIcon} />
      )}
      {type === ASSET_TYPE.FIELD && (
        <Styled.Icon style={style} src={icons.DBFieldBlueIcon} />
      )}
      {type === ASSET_TYPE.QUERY && (
        <Styled.Icon style={style} src={icons.QueryIcon} />
      )}
    </>
  );
}
