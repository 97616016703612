import React from 'react'
import theme from '../../styles/theme';
import styled from "styled-components";

interface LoadingProps {
  size?: number
  color?: string
  height?: number
}
function Loading({ size = 50, color, height }: LoadingProps) {
  return (
    <LoaderContainer height={height}>
      <Loader size={size} color={color}/>
    </LoaderContainer>
  )
}

const LoaderContainer = styled.div<LoadingProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  height: ${({ height }) => height ? height : 50}vh;
`
const Loader = styled.div<LoadingProps>`
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid ${theme.appColors.primary};
  width: ${({ size }) => size ? size : 50}px;
  height: ${({ size }) => size ? size : 50}px;
  -webkit-animation: spin 10s linear infinite;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`
export default Loading