import theme from "../../styles/theme";
import styled from "styled-components";
import { AppTypography } from "../../styles/global";
import { TypographyBold, TypographySize } from "../../types/styles.d";

export interface CardProps extends React.HTMLAttributes<HTMLDivElement> {
  borderThickness?: number;
  children?: JSX.Element;
  showShadow?: boolean;
  padding?: string;
  background?: string;
  title?: string;
  titleColor?: string;
  maxHeight?: number;
  overflowVisible?: string;
  radius?: number;
  style?: React.CSSProperties;
  contentProps?: React.HTMLAttributes<HTMLDivElement>;
}

const CardComponent = ({
  borderThickness,
  children,
  padding,
  showShadow = true,
  background,
  title,
  titleColor,
  maxHeight,
  overflowVisible,
  radius,
  contentProps,
  ...props
}: CardProps) => {
  return (
    <Card
      padding={padding}
      showShadow={showShadow}
      borderThickness={borderThickness}
      background={background}
      maxHeight={maxHeight}
      overflowVisible={overflowVisible}
      radius={radius}
      {...props}
    >
      <>
        {title && (
          <TitleWrapper>
            <AppTypography
              bold={TypographyBold.lg}
              size={TypographySize.md}
              textColor={titleColor}
            >
              {title}
            </AppTypography>
          </TitleWrapper>
        )}
        <Content {...contentProps}>{children}</Content>
      </>
    </Card>
  );
};

const Card = styled.div<CardProps>`
  background: ${({ background }) =>
    background ? background : theme.appColors.white};
  border: ${({ borderThickness }) => (borderThickness ? borderThickness : 0)}
    ${theme.colors.tertiary} solid;
  max-width: 100%;
  height: 100%;
  max-height: ${({ maxHeight }) => (maxHeight ? maxHeight + "px" : "100%")};
  border-radius: ${({ radius }) => (radius ? radius : 10)}px;
  box-shadow: ${({ showShadow }) =>
    showShadow ? "0px 3px 3px #00000029" : "none"};
  overflow-y: ${({ overflowVisible }) =>
    overflowVisible ? overflowVisible : "auto"};
  overflow-x: ${({ overflowVisible }) =>
    overflowVisible ? overflowVisible : "auto"};
  padding-left: 25px;
  padding-right: 25px;
  padding: ${({ padding }) => (padding ? padding : "none")};
`;
const Content = styled.div`
  margin: 10px 0;
`;
const TitleWrapper = styled.div`
  position: sticky;
  top: 0;
  background: ${theme.appColors.white};
  padding: 10px 0;
`;
export const CardComponentHeader = styled.div`
  display: flex;
  justify-content: space-between;
  background: ${theme.appColors.white};
  position: sticky;
  top: 0;
  align-items: center;
  padding: 10px 0;
  z-index: 99;
`;
export default CardComponent;
