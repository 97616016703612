import { IUserDetails } from "./interfaces";

export const armisAdminValidator = ({ Email }: IUserDetails) => {
  const adminEmails = [
    "roy.franco@armis.com",
    "ora.k@armis.com",
    "tal@armis.com",
    "noy@sherloqdata.io"
  ];
  if (!Email) return false;
  if (adminEmails.includes(Email)) return true;
};
