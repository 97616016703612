/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  ITransformedQueryProfile,
  QueryService,
  transformQueryProfileFactory,
} from "../../application";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useMutation, useQueries } from "react-query";
import { useParams } from "react-router-dom";
import {
  nativeCopyToClipboard,
  QueryProfileGlobalState,
  notificationSnackbar,
  AnalyticsClient,
} from "../../infrastructure";
import { useGlobalAuthState } from "../user";
import {
  useQueryProfileGlobalState,
  useSetQueryProfileGlobalState,
} from "../context";
import { formatQuery } from "../../../components/SqlViewer/SqlViewer";
import { IMarker } from "react-ace";
import { useDOMDetectComponentScroll } from "../useDOMDetectComponentScroll";

export const useQueryProfile = () => {
  const { user } = useGlobalAuthState();
  const { openEditProfileModal, openQueryStatistics } =
    useQueryProfileGlobalState();
  const updateQueryProfileGlobalState = useSetQueryProfileGlobalState();
  const [readMe, setReadMe] = useState("");
  const [markers, setMarkers] = useState<IMarker[]>([]);
  const [queryName, setQueryName] = useState("");
  const [queryDescription, setQueryDesc] = useState("");
  const { isSatisfied, componentRef } = useDOMDetectComponentScroll({
    threshold: 41,
  });
  const { id } = useParams();

  const [
    {
      data: queryProfileData,
      isLoading,
      isError,
      refetch: fetchQueryProfileData,
    },
    { data: queryIsInUserList, refetch: fetchQueryIsInUserList },
  ] = useQueries([
    {
      queryKey: ["query-profile", id],
      queryFn: () => QueryService.getTableProfile(id ?? ""),
      enabled: false,
    },
    {
      queryKey: ["query-in-user-list", id],
      queryFn: () =>
        QueryService.isQueryInUserList(id ?? "", user.SherloqUserId),
      enabled: false,
    },
  ]);

  const { mutateAsync: addQueryToMyList } = useMutation({
    mutationKey: ["add-query-to-my-list", id],
    mutationFn: () => QueryService.addQueryToUserList(id!, user?.SherloqUserId),
    onSuccess: () => {
      fetchQueryIsInUserList();
      notificationSnackbar.success("Query added to My List");
      AnalyticsClient.trackAction("main_query_profile_add_to_my_list_success");
    },
    onError: () => {
      AnalyticsClient.trackAction("main_query_profile_add_to_my_list_failed");
    },
  });

  const { mutateAsync: removeQueryFromMyList } = useMutation({
    mutationKey: ["remove-query-from-my-list", id],
    mutationFn: () =>
      QueryService.removeQueryFromUserList(id!, user?.SherloqUserId),
    onSuccess: () => {
      fetchQueryIsInUserList();
      notificationSnackbar.success("Removed query from My List");
      AnalyticsClient.trackAction(
        "main_query_profile_remove_From_my_list_success"
      );
    },
    onError: () => {
      AnalyticsClient.trackAction(
        "main_query_profile_remove_from_my_list_failed"
      );
    },
  });

  const { mutateAsync: updateQueryReadMe } = useMutation({
    mutationKey: ["update-query-readme", id],
    mutationFn: (readMe: string) => {
      return new Promise((res, rej) => {
        QueryService.changeQueryReadMe(id!, user?.SherloqUserId, readMe)
          .then(() => res(readMe))
          .catch(() => rej(readMe));
      });
    },
    onSuccess: (value: any) => {
      notificationSnackbar.success(`Successfully updated Query comments`);
      setReadMe(value);
    },
    onError: () => {
      notificationSnackbar.error("Failed to update Query comments");
    },
  });

  const queryProfile = useMemo(() => {
    if (queryProfileData) {
      const transformedProfileData =
        transformQueryProfileFactory(queryProfileData);
      setQueryName(transformedProfileData.queryName);
      setQueryDesc(transformedProfileData.queryDescription);
      setReadMe(transformedProfileData.readme);
      return transformedProfileData;
    }
    return {} as ITransformedQueryProfile;
  }, [queryProfileData]);

  const onClickCopy = () => {
    nativeCopyToClipboard(queryProfile?.queryString);
    notificationSnackbar.success("Query string copied to Clipboard");
    AnalyticsClient.trackAction("main_query_profile_copy_button_click");
  };

  const onClickShare = () => {
    nativeCopyToClipboard(location.href);
    notificationSnackbar.success("Query link shared successfully");
    AnalyticsClient.trackAction("main_query_profile_share_button_click");
  };

  const createEditQueryProfileStateFactoryFn = useCallback(
    (componentState: keyof QueryProfileGlobalState) => {
      return function (state: boolean) {
        updateQueryProfileGlobalState((prev) => ({
          ...prev,
          [componentState]: state,
        }));
      };
    },
    [updateQueryProfileGlobalState]
  );

  const setOpenEditQueryProfileModal = (state: boolean) => {
    AnalyticsClient.trackAction("main_query_profile_edit_info_button_click", {
      state: state ? "open" : "close",
    });
    createEditQueryProfileStateFactoryFn("openEditProfileModal")(state);
  };

  const setOpenQueryStats = (state: boolean) => {
    AnalyticsClient.trackAction(
      "main_query_profile_view_statistics_button_click",
      { state: state ? "open" : "close" }
    );
    createEditQueryProfileStateFactoryFn("openQueryStatistics")(state);
  };

  const onUpdateQueryInfo = ({ queryName, queryDescription }) => {
    setQueryDesc(queryDescription);
    setQueryName(queryName);
  };

  const onKeywordSearch = (queryString: string) => {
    const formatedQuery = formatQuery(queryProfile.queryString);
    const queryMarkers: IMarker[] = [];
    const rows: number[] = [];
    const queryArray = formatedQuery.toLowerCase().split("\n");
    queryArray.forEach((el, i) => {
      if (el.includes(queryString)) return rows.push(i);
    });
    rows.forEach((row) => {
      queryMarkers.push({
        startRow: row,
        startCol: queryArray[row].indexOf(queryString),
        endRow: row,
        endCol: queryArray[row].indexOf(queryString) + queryString.length,
        className: "ace_highlight_marker",
        type: "text",
      });
    });

    setMarkers(queryMarkers);
  };

  useEffect(() => {
    fetchQueryProfileData();
    fetchQueryIsInUserList();
  }, [id]);

  return {
    queryProfile,
    queryName,
    queryDescription,
    isLoading,
    isError,
    onClickCopy,
    onClickShare,
    queryIsInUserList: Boolean(queryIsInUserList),
    addQueryToMyList: addQueryToMyList as any,
    removeQueryFromMyList: removeQueryFromMyList as any,
    openEditProfileModal,
    setOpenEditQueryProfileModal,
    setOpenQueryStats,
    openQueryStatistics,
    onUpdateQueryInfo,
    readMe,
    onUpdateReadme: updateQueryReadMe,
    markers,
    onKeywordSearch,
    containerRef: componentRef,
    sticky: isSatisfied,
  };
};
