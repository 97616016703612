import { ReactNode } from "react";

export const RenderCond = (
  condition: boolean,
  TrueNode: ReactNode,
  FalseNode: ReactNode
) => {
  if (condition) return TrueNode;
  return FalseNode;
};
