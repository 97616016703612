import { AssetFilterType } from "../../core";

export const parseSearchAssetFilters = (filters: AssetFilterType) => {
  const filtersArr: string[] = [];
  const rulesArr: string[] = [];

  if (filters.lastQueriedBy.value.length > 0) {
    const tmp = { ...filters.lastQueriedBy };
    tmp.value = tmp.value.toString().replaceAll(",", "|");
    filtersArr.push(JSON.stringify(tmp));
  }
  if (filters.queriedIn.value !== "") {
    filtersArr.push(JSON.stringify(filters.queriedIn));
  }
  if (filters.rules) {
    filters.rules.forEach((rule) => {
      if (rule.value !== "") {
        // in a queryFilter, the fieldName query value is the same as queryString
        if (rule?.column === "fieldName") {
          return rulesArr.push(
            JSON.stringify({ ...rule, column: "queryString" })
          );
        }
        rulesArr.push(JSON.stringify(rule));
      }
    });
    if (rulesArr.length) {
      filtersArr.push(rulesArr.join(";"));
    }
  }

  return filtersArr.join(";") || undefined;
};
