import { authHttpClient } from "../../infrastructure";
import { AssetType } from "../../core";

export const makeRemoveAssetFromUserListFactoryFn = (
  assetType: AssetType,
  onError?: any
) => {
  return async (assetId: number | string, sherloqUserId: number) => {
    const path = "/v1/getdata/removeSavedAssetFromList";
    try {
      const payload = {
        assetType,
        assetId,
        sherloqUserId,
      };
      return await authHttpClient.post(path, payload);
    } catch (error) {
      onError?.();
    }
  };
};
