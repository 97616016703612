import { transformAssetUsername } from "../../core";

export const transformUserList = (data: any) => {
  let userList = [...data.QueriesSaved, ...data.TablesSaved];
  userList = userList.map((list) => {
    if (list?.tableId !== undefined) {
      return {
        id: list.tableId,
        type: "table",
        title: list.tableName,
        description: list.description,
        userName: transformAssetUsername(list.lastUsernameThatReferenced),
        lastQueriedDate: list.lastReferenceDatetime,
        parentName: list.dbName,
        savedDatetime: list.savedDatetime,
        source: list.source,
      };
    }
    if (list?.queryId !== undefined) {
      return {
        id: list.queryId,
        type: "query",
        title: list.givenName || list.queryString,
        description: list.description,
        userName: transformAssetUsername(list.lastUsernameThatReferenced),
        lastQueriedDate: list.lastQueryThatReferencedDatetime,
        parentName: list.listOfTablesAccessed,
        savedDatetime: list.savedDatetime,
        source: list.source,
      };
    }
  });
  userList = userList.sort((a, b) => {
    return +new Date(b.savedDatetime) - +new Date(a.savedDatetime);
  });
  return userList;
};
