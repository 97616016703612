import { AssetsService } from "../asset";
import { userService } from "./userService";

export const makeGetUserCompanyInfo = async () => {
  const [{ value: companyUsers }, { value: companyStructure }] =
    (await Promise.allSettled([
      AssetsService.getAllCompanyUsers(),
      userService.getUserCompanyStructure(),
    ])) as { value; status: any }[];

  return { companyUsers, companyStructure };
};
