import { QueryService, transformQueryInsights } from "../../application";
import { useEffect, useState } from "react";

type HighestInsights = "cost" | "runtime" | "datascanned";

export const useCostReportInsights = () => {
  const [openSQLViewer, setOpenSQLViewer] = useState(false);
  const [highestInsightsQueries, setHighestInsightsQueries] = useState<
    Record<HighestInsights, string>
  >({
    cost: "",
    runtime: "",
    datascanned: "",
  });
  const [insightQuery, setInsightQuery] = useState("");

  const [insights, setInsights] = useState({
    AverageCost: "",
    AverageDataScanned: "",
    AverageRuntime: "",
    HighestCost: "",
    HighestDataScanned: "",
    HighestRuntime: "",
    AverageCostLastWeek: "",
    AverageCostPercentage: "",
    AverageDataScannedLastWeek: "",
    AverageDataScannedPercentage: "",
    AverageRuntimeLastWeek: "",
    AverageRuntimePercentage: "",
    HighestCostLastWeek: "",
    HighestCostPercentage: "",
    HighestDataScannedLastWeek: "",
    HighestDataScannedPercentage: "",
    HighestRuntimeLastWeek: "",
    HighestRuntimePercentage: "",
    AllUsersTotalCost: "",
    AllUsersTotalCostLastWeek: "",
    SherloqUsersTotalCost: "",
    SherloqUsersTotalCostLastWeek: "",
    AllUsersTotalCostPercentage: "",
    SherloqUsersTotalCostPercentage: "",
  });

  const getQueryInsights = async () => {
    try {
      const data = await QueryService.getQueryInsights();
      const transformedData = transformQueryInsights(data);
      setHighestInsightsQueries(transformedData);
      setInsights(data);
    } catch (error: any) {
      console.warn(error);
    }
  };

  const toggleSQLViewerOpenState = () => setOpenSQLViewer((p) => !p);

  const viewInsightsQuery = (insight: HighestInsights) => {
    setOpenSQLViewer(true);
    setInsightQuery(highestInsightsQueries[insight]);
  };

  useEffect(() => {
    getQueryInsights();
  }, []);
  return {
    insights,
    openSQLViewer,
    insightQuery,
    toggleSQLViewerOpenState,
    viewInsightsQuery,
  };
};
