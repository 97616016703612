import {
  AssetProfileContentSectionDefaultCopyButton,
  AssetProfileDefaultQueryEditorContainer,
  AssetProfileSectionHeaderWithInfo,
  DefualtSQLEditor,
  sqlQueryFormatter,
} from "../../../../shared-components";
import {
  MostUsedQuerySection,
  MostUsedQueryUtilsContainer,
  QueryUsageDesc,
} from "..";
import { ITableProfileMostUsedAndLastUsedQuery } from "../../../../../core";
import { useTableProfileLastQueryRun } from "../../../../../hooks";
import { useState } from "react";
import { IMarker } from "react-ace";
import { formatQuery } from "../../../../../../components/SqlViewer/SqlViewer";
import SearchInput from "../../../Home/components/search&Filter/SearchInput";
interface Props {
  data: ITableProfileMostUsedAndLastUsedQuery;
}

export const LastQueryRun = (props: Props) => {
  const { lastQueriedBy, lastQueriedOn, numberOfRuns, onClickCopy, query } =
    useTableProfileLastQueryRun(props);

  const [searchPointer, setSearchPointer] = useState(0);
  const [markers, setMarkers] = useState<IMarker[]>([]);

  const onKeywordSearch = (queryString: string) => {
    const formatedQuery = formatQuery(query);
    const queryMarkers: IMarker[] = [];
    const rows: number[] = [];
    const queryArray = formatedQuery.toLowerCase().split("\n");
    queryArray.forEach((el, i) => {
      if (el.includes(queryString)) return rows.push(i);
    });
    rows.forEach((row) => {
      queryMarkers.push({
        startRow: row,
        startCol: queryArray[row].indexOf(queryString),
        endRow: row,
        endCol: queryArray[row].indexOf(queryString) + queryString.length,
        className: "ace_highlight_marker",
        type: "text",
      });
    });

    setMarkers(queryMarkers);
  };

  return (
    <MostUsedQuerySection>
      <AssetProfileSectionHeaderWithInfo
        tooltipText="Last query run - The last query that used this table"
        style={{ marginBottom: "4px" }}
      >
        Last Query Run
      </AssetProfileSectionHeaderWithInfo>
      <QueryUsageDesc color="#707070">
        Last queried by{" "}
        <QueryUsageDesc inline color="#252525">
          {lastQueriedBy}
        </QueryUsageDesc>
        , on{" "}
        <QueryUsageDesc color="#252525" inline>
          {lastQueriedOn}
        </QueryUsageDesc>
        , # of runs{" "}
        <QueryUsageDesc color="#252525" inline>
          {numberOfRuns}
        </QueryUsageDesc>
      </QueryUsageDesc>
      <MostUsedQueryUtilsContainer>
        <SearchInput
          placeholder="Search by name or query content..."
          onSearch={(e) => onKeywordSearch(e)}
        />
        <AssetProfileContentSectionDefaultCopyButton
          prefixHoverIcon="ic16copyHover"
          prefixIcon="ic16copyPreview"
          onClick={onClickCopy}
        >
          copy
        </AssetProfileContentSectionDefaultCopyButton>
      </MostUsedQueryUtilsContainer>
      <AssetProfileDefaultQueryEditorContainer>
        <div id="ace-last-run-query">
          <DefualtSQLEditor
            markers={markers}
            value={sqlQueryFormatter(query)}
            style={{ backgroundColor: "#f1f1f1" }}
            maxLines={20}
          />
        </div>
      </AssetProfileDefaultQueryEditorContainer>
    </MostUsedQuerySection>
  );
};
