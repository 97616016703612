import { format } from "sql-formatter";

export const sqlQueryFormatter = (query: string) => {
  try {
    const formattedQuery = format(query)
      .replaceAll(" - ", "-")
      .replaceAll("@ ", "@")
      .replaceAll("* * * CENSORED * * *", "'***CENSORED***'")
      .replaceAll("| |", "||");

    return formattedQuery;
  } catch (error) {
    return query;
  }
};

export const getMaxLinesFromFormattedQuery = (query: string) => {
  if (!query) return 0;
  return query?.split("\n").length + 1; //Additional line to include the last query line
};
