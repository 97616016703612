import styled from "styled-components";
import { Badge, TypographyProps } from "@sherloq/design-system";
import React from "react";
import { Typography } from "../Typography";

export const TableContentWrapper = styled.div`
  display: flex;
`;

export const DefaultTableBadge = () =>
  React.createElement(
    Badge,
    { style: { backgroundColor: "#0075E720" } },
    "Table"
  );

export const TableContentContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 250px);
  margin-top: 16px;
  row-gap: 16px;
  column-gap: 16px;

  @media (min-width: 1200px) {
    grid-template-columns: repeat(4, 250px);
  }

  @media (min-width: 1300px) {
    grid-template-columns: repeat(5, 250px);
  }
`;

export const TableDataContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TableSourceContainer = styled.div`
  display: flex;
  column-gap: 4px;
`;

export const TableDataTitle: React.FC<Omit<TypographyProps, "variant">> = ({
  children,
  ...props
}) => {
  return React.createElement(
    Typography,
    {
      variant: "p-light",
      style: { marginBottom: "4px" },
      color: "#707070",
      ...props,
    },
    children
  );
};

export const TableDataValue: React.FC<Omit<TypographyProps, "variant">> = ({
  children,
  ...props
}) => {
  return React.createElement(
    Typography,
    { variant: "p-light", color: "#252525", ...props },
    children
  );
};
