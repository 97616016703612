import { Dropdown as SharedDropdown } from "../../../../shared-components";
import styled from "styled-components";
import { FieldTableRow } from "../topFieldsRow";

export const Dropdown = styled(SharedDropdown)`
  margin-top: 16px;
  z-index: 99;
`;

export const RowContainer = styled(FieldTableRow)`
  :hover {
    background-color: #f1f1f1;
    cursor: pointer;
  }
`;
