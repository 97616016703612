import { AppRoutes, handleUserTokenExpiredUseCase } from "../application";
import { Route, Routes, Navigate } from "react-router-dom";
import { useRootLayout } from "../hooks";
import {
  Home,
  HomeQueryResults,
  HomeTableResults,
  Login,
  RootLayout,
  TableProfile,
  QueryProfile,
  CostReport,
  Usage,
  Cost,
  Runtime,
  ScannedData,
  Users,
  Lists,
  UserList,
  AllLists,
  ExtensionQueriesRootView,
} from "./pages";
import {
  QueryProfileGlobalStateContextProvider,
  TableProfileProvider,
} from "../infrastructure";
import { AuthProtectedRoute, CostsReportProtectedRoute } from "./lib";

export const UIRoot = () => {
  const { globalAuthState, rootListRoute } = useRootLayout();

  return (
    <Routes>
      <Route path="/" element={<RootLayout />}>
        <Route
          index
          element={
            globalAuthState.isLoggedIn ? (
              <Navigate to={AppRoutes.HOME} />
            ) : (
              <Navigate to={AppRoutes.LOGIN} />
            )
          }
        />
        <Route path={AppRoutes.LOGIN} element={<Login />} />
        <Route
          path={`${AppRoutes.TABLE_PROFILE}/:id`}
          element={
            <TableProfileProvider>
              <TableProfile />
            </TableProfileProvider>
          }
        />
        <Route
          path={`${AppRoutes.QUERY_PROFILE}/:id`}
          element={
            <QueryProfileGlobalStateContextProvider>
              <QueryProfile />
            </QueryProfileGlobalStateContextProvider>
          }
        />

        <Route path={AppRoutes.HOME} element={<Home />}>
          <Route
            path={`/${AppRoutes.HOME}`}
            element={<Navigate to={AppRoutes.HOME_QUERY_RESULTS} />}
          />
          <Route
            path={AppRoutes.HOME_QUERY_RESULTS}
            element={<HomeQueryResults />}
          />
          <Route
            path={AppRoutes.HOME_TABLE_RESULTS}
            element={<HomeTableResults />}
          />
          <Route />
        </Route>
        <Route
          path={AppRoutes.ANALYTICS}
          element={
            <CostsReportProtectedRoute>
              <CostReport />
            </CostsReportProtectedRoute>
          }
        >
          <Route
            path="/analytics"
            element={<Navigate to={`usage`} replace />}
          />
          <Route path="usage" element={<Usage />} />
          <Route path="cost" element={<Cost />} />
          <Route path="runtime" element={<Runtime />} />
          <Route path="datascanned" element={<ScannedData />} />
          <Route path="users" element={<Users />} />
        </Route>
        <Route path={AppRoutes.LISTS} element={<Lists />}>
          <Route
            path={`/${AppRoutes.LISTS}`}
            element={<Navigate to={rootListRoute} replace />}
          />
          <Route
            path={`${rootListRoute}/extensionQueries/*`}
            element={<ExtensionQueriesRootView />}
          />
          <Route path=":userid" element={<UserList />} />
          <Route path="all" element={<AllLists />} />
        </Route>
      </Route>
      <Route
        path={`/*`}
        element={
          globalAuthState.isLoggedIn ? (
            <Navigate to={AppRoutes.HOME} />
          ) : (
            <Navigate to={AppRoutes.LOGIN} />
          )
        }
      />
    </Routes>
  );
};
