import { RenderIf } from "../../lib";
import { StaticImages } from "../../assets";
import { Container, EmptyDataContainer, Image, Text } from "./components";
import { LoadingComponent } from "../Loader";
import { ReactNode } from "react";

interface ListFallbackProps extends React.HTMLAttributes<HTMLDivElement> {
  isError?: boolean;
  errorMessage?: string;
  emptyDataMessage?: string;
  isLoading?: boolean;
  noData?: boolean;
  children?: ReactNode;
}

export const ListFallBack = ({
  noData,
  emptyDataMessage,
  isError,
  isLoading,
  ...props
}: ListFallbackProps) => {
  return (
    <Container {...props}>
      {RenderIf(
        !!noData && !isLoading,
        <EmptyDataContainer>
          <Text variant="p">{emptyDataMessage ?? "Empty Data"}</Text>
          <Image alt="" src={StaticImages.imEmptyData} />
        </EmptyDataContainer>
      )}
      {RenderIf(!!isError, <Image alt="" src={StaticImages.imGeneric} />)}
      {RenderIf(!!isLoading, <LoadingComponent />)}
    </Container>
  );
};
