import { useWeeklyCostReport } from "./useWeeklyCostsReport";

export function useDataScanned() {
  const report = useWeeklyCostReport({
    type: "datascanned",
    averageYIndex: (data) => data.WeeklyCost.AverageCost / Math.pow(1024, 3),
    sumYIndex: (data) => data.WeeklyCost.Sum / Math.pow(1024, 4),
  });
  return {
    ...report,
  };
}
