import { useCallback, useEffect, useRef, useState } from "react";

interface UseDetectComponentScrollProps {
  threshold: number;
}

export const useDOMDetectComponentScroll = ({
  threshold,
}: UseDetectComponentScrollProps) => {
  const [isSatisfied, setIsSatisfied] = useState(false);
  const componentRef = useRef<HTMLDivElement>(null);

  const onScrollHandler = useCallback(() => {
    const currentPosition = componentRef.current?.scrollTop ?? 0;
    if (currentPosition > threshold) {
      return setIsSatisfied(true);
    }
    setIsSatisfied(false);
  }, []);

  useEffect(() => {
    componentRef.current?.addEventListener("scroll", onScrollHandler);
    return () => {
      componentRef.current?.removeEventListener("scroll", onScrollHandler);
    };
  }, []);

  return {
    isSatisfied,
    componentRef,
  };
};
