import { useTableProfileQueryUsageRow } from "../../../../../../../hooks";
import { ITransformedTableProfileQueryUsageRow } from "../../../../../../../application";
import { Container, QueryString, TableField } from "./components";

interface Props {
  data: ITransformedTableProfileQueryUsageRow;
  onClickQueryString: (query: string) => void;
}

export const QueryUsageRow = ({ data, onClickQueryString }: Props) => {
  const {
    lastRunTime,
    lastUserThatReferenced,
    listOfTables,
    numOfUsers,
    query,
    onClickRow,
    dataScanned,
    runtime,
  } = useTableProfileQueryUsageRow(data);
  return (
    <tr onClick={onClickRow}>
      <Container>
        <QueryString
          onClick={(e) => {
            e?.stopPropagation();
            onClickQueryString(query);
          }}
          variant="small"
        >
          {query}
        </QueryString>
        <TableField variant="small">{listOfTables}</TableField>
        <TableField variant="small">{numOfUsers}</TableField>
        <TableField variant="small">{dataScanned}</TableField>
        <TableField variant="small">{lastRunTime}</TableField>
        <TableField variant="small">{runtime}</TableField>
        <TableField variant="small">{lastUserThatReferenced}</TableField>
      </Container>
    </tr>
  );
};
