import styled from "styled-components";
import { Typography } from "@sherloq/design-system";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Image = styled.img`
  object-fit: cover;
`;

export const EmptyDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  align-items: centent;
`;

export const Text = styled(Typography)`
  width: fit-content;
  margin: 0 auto;
`;
