import React, { useMemo, useState } from "react";
import { IconButton, IconButtonProps } from "@sherloq/design-system";
import StarHalfRoundedIcon from "@material-ui/icons/StarHalfRounded";

import { Tooltip } from "../../tooltip";
import { TooltipProps } from "@material-ui/core";
import { RenderCond } from "../../../lib";

interface MainSidebarMenuItemProps extends IconButtonProps {
  toolTipProps?: TooltipProps;
  tooltipValue?: string;
  iconComponent?: any;
  iconContainerProps?: React.HTMLAttributes<HTMLDivElement>;
  iconProps?: any;
}

interface WorkspaceIconProps extends React.HTMLAttributes<HTMLDivElement> {
  isActive: boolean;
}

export const MenuItemComponent = (props?: IconButtonProps) => {
  return (
    <IconButton
      style={{
        border: "none",
        backgroundColor: "transparent",
        marginTop: "16px",
        padding: 0,
      }}
      {...props}
    />
  );
};

export const MenuItem: React.FC<MainSidebarMenuItemProps> = ({
  tooltipValue,
  children,
  iconProps,
  iconContainerProps,
  ...buttonProps
}) => {
  return (
    <Tooltip title={tooltipValue} placement="right-end">
      <div {...iconContainerProps}>
        {RenderCond(
          !!children,
          children,
          <MenuItemComponent {...buttonProps} />
        )}
      </div>
    </Tooltip>
  );
};
