import React from "react";
import { DividerContainer, RootHeaderText } from "..";
import { JoinedWithContainer, JoinedWithDetailsContainer } from "./components";
import { ITableProfileMostJoinedTableAndFields } from "../../../../../../../core";

interface TableJoinRowProps {
  data: ITableProfileMostJoinedTableAndFields;
}

export const TableJoinRow = ({ data }: TableJoinRowProps) => {
  const { fieldName, fields } = data;
  return (
    <React.Fragment>
      <JoinedWithContainer>
        <RootHeaderText variant="p" color="#707070">
          Joined with{" "}
          <RootHeaderText variant="p" color="#F72585">
            {`${fieldName} `}
          </RootHeaderText>
          table
        </RootHeaderText>
        {fields.map(({ field_1, field_2, num_of_ref }, key) => (
          <JoinedWithDetailsContainer key={key}>
            <RootHeaderText variant="p-light" color="#252525">
              {`${field_1} & `}
              <RootHeaderText variant="p-light" color="#F72585">
                {`${field_2}`}
              </RootHeaderText>
            </RootHeaderText>
            <RootHeaderText variant="p-light" color="#252525">
              {num_of_ref}
            </RootHeaderText>
          </JoinedWithDetailsContainer>
        ))}
      </JoinedWithContainer>
      <DividerContainer />
    </React.Fragment>
  );
};
