import {
  EditAssetInfoTextInputContentHeader,
  Textarea,
  Typography,
} from "../../../../shared-components";
import React from "react";
import { RootModal } from "../../../../shared-components";
import { Input, TableNameInput } from "./components";
import { Button } from "@sherloq/design-system";
import { useEditTableinfoModal } from "../../../../../hooks";

interface TableModalData {
  tableId: string;
  tableName: string;
  description: string;
  tableOwner: string;
}

interface EditTableInfoModalProps {
  open: boolean;
  onClose?: () => void;
  onClickCancel?: () => void;
  data: TableModalData;
  onSuccess: (updatedDesc: string, updatedOwner: string) => void;
}

export const EditTableInfoModal: React.FC<EditTableInfoModalProps> = ({
  data,
  onSuccess,
  ...props
}) => {
  const { description, tableId, tableName, tableOwner } = data;
  const {
    updatedDesc,
    updatedOwner,
    onChangeOwnerInput,
    onChangeDescInput,
    onSaveDescription,
    isLoading,
  } = useEditTableinfoModal({
    onSuccess: (owner, description) => {
      onSuccess?.(owner, description);
      props?.onClose?.();
    },
    tableId,
    description,
    tableOwner,
  });
  return (
    <RootModal
      {...props}
      tintColor="default"
      showCancelButton
      title="Table Info"
      description="Manage table information"
      contentElement={
        <div>
          <TableNameInput
            aria-disabled
            defaultValue={tableName}
            helperText="Table name"
            onChange={undefined}
            disabled
          />
          <Textarea
            helperText={
              <EditAssetInfoTextInputContentHeader>
                <Typography variant="small" color="#707070">
                  Table description (recommended)
                </Typography>
                {/* <Typography color="#0075E7" variant="small">
                  Generate description
                </Typography> */}
              </EditAssetInfoTextInputContentHeader>
            }
            placeholder="e.g New users"
            value={updatedDesc}
            onChange={onChangeDescInput}
          />
          <Input
            onChange={onChangeOwnerInput}
            helperText="Table owner"
            defaultValue={updatedOwner}
          />
        </div>
      }
      actionButton={
        <Button
          onClick={() => {
            if (!isLoading) onSaveDescription();
          }}
          variant={"default"}
        >
          {isLoading ? "saving..." : "Save"}
        </Button>
      }
    />
  );
};
