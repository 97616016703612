import http, { httpWithoutAuth } from "./base";
import { AssetType, UserInputType } from "./models";

const serverRequest = async (
  url: string,
  command: string,
  payload: any,
  token?: boolean | null
) => {
  const axiosRequest = token ? http : httpWithoutAuth;

  if (command == "GET") {
    const response = await axiosRequest.get(url);
    return await Promise.resolve(response?.data);
  }
  if (command == "POST") {
    const response = await axiosRequest.post(url, payload);
    return await Promise.resolve(response?.data);
  }
};

export const serverLogin = async (username: string, password: string) => {
  const path = "login";
  return await serverRequest(path, "POST", { username, password });
};

export const getTablesData = async (filters?: string[]) => {
  let path = "";
  if (filters) {
    const filtersUrlParams = new URLSearchParams(
      "filters" + "=" + filters.join(";")
    ).toString();
    path = "v1/getdata/getTableOfTables?" + filtersUrlParams;
  } else {
    path = "v1/getdata/getTableOfTables";
  }
  return await serverRequest(path, "GET", null, true);
};

export const getFieldsData = async (tableId: number, filters?: string[]) => {
  let path = "";
  if (filters) {
    const filtersUrlParams = new URLSearchParams(
      "filters" + "=" + filters.join(";")
    ).toString();
    path =
      `v1/getdata/getTableOfFields?tableId=${tableId}` + "&" + filtersUrlParams;
  } else {
    path = `v1/getdata/getTableOfFields?tableId=${tableId}`;
  }

  return await serverRequest(path, "GET", null, true);
};

export const getInsightsData = async () => {
  const path = "v1/getdata/getInsights";
  return await serverRequest(path, "GET", null, true);
};

export const getQueriesData = async (filters?: string[]) => {
  let path = "";
  if (filters) {
    const filtersUrlParams = new URLSearchParams(
      "filters" + "=" + filters.join(";")
    ).toString();
    path = "v1/getdata/getTableOfQueries?" + filtersUrlParams;
  } else {
    path = "v1/getdata/getTableOfQueries";
  }
  return await serverRequest(path, "GET", null, true);
};

export const searchAssets = async (
  assetType: AssetType,
  searchString: string,
  filters?: string[]
) => {
  const path = "v1/getdata/getSearch";
  const params = {
    assetType,
    searchString,
    filters: filters ? filters.join(";").toString() : undefined,
  };
  try {
    const { data } = await http.get(path, { params });
    return await Promise.resolve(data);
  } catch (err) {
    return await Promise.reject(err);
  }
};
export const getAssetProfile = async (
  assetType: AssetType,
  AssetId: number
) => {
  const path = "/v1/getdata/getAssetProfile";
  const params = {
    assetType,
    AssetId,
  };
  try {
    const { data } = await http.get(path, { params });
    return await Promise.resolve(data);
  } catch (err) {
    return await Promise.reject(err);
  }
};

export const postUserInput = async (
  userInputType: UserInputType,
  assetType: AssetType,
  assetId: number,
  sherloqUserId: number,
  inputString: string
) => {
  const path = "/v1/getdata/postUserInput";

  const payload = {
    userInputType,
    assetType,
    assetId,
    sherloqUserId,
    inputString,
  };
  try {
    const { data } = await http.post(path, payload);
    return await Promise.resolve(data);
  } catch (err) {
    return await Promise.reject(err);
  }
};

export const postUserFeedback = async (
  screenName:
    | "search"
    | "query_profile"
    | "table_profile"
    | "table_of_tables"
    | "table_of_fields"
    | "table_of_queries"
    | "insights",
  sherloqUserId: number,
  feedback: string
) => {
  const path = "/v1/getdata/postUserFeedback";

  const payload = {
    screenName,
    sherloqUserId,
    feedback,
  };
  try {
    const { data } = await http.post(path, payload);
    return await Promise.resolve(data);
  } catch (err) {
    return await Promise.reject(err);
  }
};

export const getAllUsers = async (companyId: string | number) => {
  const path = "/v1/getdata/getAllUsers";

  const params = {
    companyId,
  };
  try {
    const { data } = await http.get(path, { params });
    return await Promise.resolve(data);
  } catch (err) {
    return await Promise.reject(err);
  }
};

export const getCompanyStructureService = async () => {
  const path = "/v1/GetData/getCompanyStructure";

  try {
    const { data } = await http.get(path);
    return await Promise.resolve(data);
  } catch (err) {
    return await Promise.reject(err);
  }
};

export const getUserExtensionQueries = async (folderId?: string) => {
  let queryParams = "";
  if (folderId) {
    queryParams = `?folderUid=${folderId}`;
  }
  const path = `/v1/getdata/getExtensionListByUser${queryParams}`;

  try {
    const { data } = await http.get(path);
    return await Promise.resolve(JSON.parse(data));
  } catch (err) {
    return await Promise.reject(err);
  }
};
