import * as Styled from "../styled";
import { TypographyBold, TypographySize } from "../../../../types/styles.d";
import { AppTypography } from "../../../../styles/global";
import React from "react";

interface Props {
  header?: string;
  iconSrc?: string;
  iconStyle?: React.CSSProperties;
  value?: any;
}

export const DetailItem: React.FC<Props> = (props) => {
  return (
    <Styled.QueryDetailItemContainer>
      <Styled.Icon
        style={{ height: "24px", ...props?.iconStyle }}
        src={props?.iconSrc}
      />
      <Styled.QueryDetailItemTextContainer>
        <AppTypography>{props?.header ?? ""}</AppTypography>
        <AppTypography size={TypographySize.sm} bold={TypographyBold.lg}>
          {props?.value ?? ""}
        </AppTypography>
      </Styled.QueryDetailItemTextContainer>
    </Styled.QueryDetailItemContainer>
  );
};
