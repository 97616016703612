import { Input } from "@sherloq/design-system";
import React from "react";
import { Label, LastQueriedContainer } from "./components";
import { Tooltip } from "../../../../../../shared-components";

interface Props {
  value?: string;
  onSelect?: (vals: string[]) => void;
}

export const LastQueriedFilter: React.FC<Props> = ({ onSelect, value }) => {
  return (
    <LastQueriedContainer>
      <Label variant="small">Last queried by</Label>
      <Tooltip title={"Usernames separated by commas"} placement="right">
        <Input
          name="search-rule-string"
          value={value}
          onChange={(e: any) => {
            onSelect?.(e.target.value.replace(/\s*,\s*/g, ',').trim());
          }}
          placeholder="Sherlock, Watson, Moriarty"
          style={{ height: "33px", boxSizing: "border-box" }}
        />
      </Tooltip>
    </LastQueriedContainer>
  );
};
