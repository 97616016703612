export const createJobTitleFilterFromCompanyData = (data) => {
  return data.map((job: string) => {
    return {
      label: job,
      value: job,
    };
  });
};

export const createBusinessUnitFilterFromCompanyData = (data) => {
  return data?.map((comp) => {
    return {
      label: comp.BusinessUnit,
      value: comp.BusinessUnit,
    };
  });
};

export const createDepartmentFilterFromCompanyData = (data, unit?: string) => {
  for (const structure of data) {
    if (structure?.BusinessUnit === unit) {
      return structure?.Departments.map((el) => {
        return {
          label: el,
          value: el,
        };
      });
    } else {
      const allDepartments: any = [];
      data.forEach((element) => {
        allDepartments.push(
          ...element.Departments.map((dep) => {
            return {
              label: dep,
              value: dep,
            };
          })
        );
      });
      return allDepartments;
    }
  }
};
