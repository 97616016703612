import { TextBoxWithToolTip } from "../../../../../components/TextHoldersWithToolTip";
import searchPageImages from "../../../../../assets/images/search";

import { TypographySize } from "../../../../../types/styles.d";
import { Row, SmallTableIcon, TableRow } from "../Table.styled";
import {
  formatBytes,
  formatCostsToString,
  formatSeconds,
  setMaxString,
} from "../../../../../utils/helpers";
import { UsageAnalytics } from "../UsageAnalytics";
import { useNavigate } from "react-router-dom";


interface UsageUsedTablesProps {
  data: {
    TableName: string;
    NumOfReferences: number;
    AverageCost: number;
    AverageRuntime: number;
    AverageDataScanned: number;
    TableUsagePercentage: number;
    UserName: string;
    TableId: string;
    TotalCost: number;
    UserCount: number;
  };
  onClickTableName?: (tableId: string) => void;
}

export const UsageUsedTablesRow = ({
  data,
  onClickTableName,
}: UsageUsedTablesProps) => {
  const {
    UserCount,
    AverageCost,
    AverageDataScanned,
    AverageRuntime,
    NumOfReferences,
    TableId,
    TableName,
    TableUsagePercentage,
    TotalCost,
    UserName,
  } = data;

  const navigate = useNavigate();
  const handleTableClick = (tableId: string | number) => {
    const extras = {
      state: {
        fromPage: location.pathname,
      },
    };
    return navigate(`/dashboard/search/table/${tableId}`, extras);
  }
  return (
    <TableRow style={{ height: "50px" }}>
      <td align="center">
        <Row justifyContent="start">
          <SmallTableIcon src={searchPageImages.DBTableIcon} />
          <TextBoxWithToolTip
            toolTipValue={TableName}
            typographyProps={{
              underline: true,
              size: TypographySize.sm,
              clickable: true,
            }}
            onClick={() => handleTableClick(TableId)}
          >
            {/* {TableName && setMaxString(TableName, 35)} */}
            {TableName}
          </TextBoxWithToolTip>
        </Row>
      </td>
      <td align="center">
        <TextBoxWithToolTip
          typographyProps={{
            size: TypographySize.sm,
          }}
        >
          {`${NumOfReferences ?? 0} references`}
        </TextBoxWithToolTip>
      </td>
      <td align="center">
        <TextBoxWithToolTip
          typographyProps={{
            size: TypographySize.sm,
          }}
        >
          {formatCostsToString(TotalCost ?? 0)}
        </TextBoxWithToolTip>
      </td>
      <td align="center">
        <TextBoxWithToolTip
          typographyProps={{
            size: TypographySize.sm,
          }}
        >
          {formatCostsToString(AverageCost ?? 0)}
        </TextBoxWithToolTip>
      </td>
      <td align="center">
        <TextBoxWithToolTip
          typographyProps={{
            size: TypographySize.sm,
          }}
        >
          {formatSeconds(AverageRuntime ?? 0) || "0"}
        </TextBoxWithToolTip>
      </td>
      <td align="center">
        <TextBoxWithToolTip
          typographyProps={{
            size: TypographySize.sm,
          }}
        >
          {formatBytes(AverageDataScanned ?? 0) || "0B"}
        </TextBoxWithToolTip>
      </td>
      <td align="center">
        <UsageAnalytics value={TableUsagePercentage} />
      </td>
      <td>
        <TextBoxWithToolTip
          typographyProps={{
            size: TypographySize.sm,
          }}
        >
          {UserCount}
        </TextBoxWithToolTip>
      </td>
      <td>
        <TextBoxWithToolTip
          typographyProps={{
            size: TypographySize.sm,
          }}
        >
          {UserName || "N/A"}
        </TextBoxWithToolTip>
      </td>
    </TableRow>
  );
};
