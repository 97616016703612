import React from "react";
import { DropdownDurationOptions } from "../Table";
import { useUsageUsedTablesAnalytics } from "./useUsageUsedQueries";
import {
  AnalyticsTable,
  AnalyticsTableHeaderType,
} from "../../../../../components/AnalyticsTable";
import { UsageUsedQueriesRow } from "./UsageUsedQueriesRow";
import SqlViewer from "../.../../../../../../components/SqlViewer/SqlViewer";
import { useReduxSelector, RootState } from "../../../../../redux";
const UsageUsedQueriesTableHeaders: AnalyticsTableHeaderType[] = [
  {
    header: "Query",
    subHeaders: [""],
    width: "45%",
    sortKeyword: "",
    sort: false,
  },
  {
    header: "Average Cost",
    subHeaders: [""],
    width: "10%",
    sort: true,
    sortKeyword: "averagecost",
  },
  {
    header: "Average Runtime",
    subHeaders: [""],
    width: "10%",
    sort: true,
    sortKeyword: "averageruntime",
  },
  {
    header: "# of Runs",
    subHeaders: [""],
    width: "10%",
    sort: true,
    sortKeyword: "numOfReferences",
  },
  {
    header: "Data Scanned",
    subHeaders: [""],
    width: "10%",
    sort: true,
    sortKeyword: "averagedatascanned",
  },
  // {
  //   header: "Referenced Tables",
  //   subHeaders: [""],
  //   width: "10%",
  //   sortKeyword: "",
  //   sort: false,
  // },
];

export function UsageUsedQueriesTable() {
  const { details } = useReduxSelector((state: RootState) => state.auth);
  const {
    getUsageUsedQueriesData,
    loading,
    usageUsedQueriesData,
    onClickQuery,
    selectedQuery,
  } = useUsageUsedTablesAnalytics();

  return (
    <>
      <AnalyticsTable
        withDuration
        withSort
        withSearch={false}
        defaultSortField="averagecost"
        tableName="Used Queries"
        tableHeaders={UsageUsedQueriesTableHeaders}
        containerProps={{
          style: { width: "100%", overflowX: "hidden" },
          radius: 1,
        }}
        tableContainerProps={{
          style: {
            height: "202px",
          },
        }}
        loading={loading}
        data={usageUsedQueriesData}
        durationOptions={details.CompanyId != 4 ? DropdownDurationOptions as any: DropdownDurationOptions as any}
        renderData={(data, key) => (
          <UsageUsedQueriesRow {...{ data, key, onClickQuery }} />
        )}
        dataPageSize={5}
        refetchData={getUsageUsedQueriesData}
      />
      <SqlViewer
        onClose={() => onClickQuery()}
        show={selectedQuery ? true : false}
        query={selectedQuery?.Query ?? ""}
        query_id={
          selectedQuery?.QueryId ? selectedQuery?.QueryId.toString() : ""
        }
      />
    </>
  );
}
