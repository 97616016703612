import { Typography, TypographyProps } from "@sherloq/design-system";
import React from "react";
import styled from "styled-components";

const BaseLinkComponent = styled(Typography)`
  display: inline;
  color: #707070;
`;
const LinkBreakComponent = styled(BaseLinkComponent)`
  margin: 0 8px;
`;

const Link = styled(BaseLinkComponent)``;

export const LinkButton = ({ children, ...props }: TypographyProps) =>
  React.createElement(Link, { variant: "p-light", ...props }, children);

export const LinkBreak = (props?: TypographyProps) =>
  React.createElement(LinkBreakComponent, props, "/");

export const Container = styled.div`
  display: flex;
  align-items: center;
`;
