import { useQueryComponent } from "../../../../../hooks";
import { IQuery } from "../../../../../core";
import {
  Typography,
  DefualtSQLEditor,
  QueryEditorUtils,
} from "../../../../shared-components";
import {
  DefaultQueryBadge,
  DefaultQueryContainer,
  QueryMetaContainer,
  QueryMetaSection,
  QuerySectionDivider,
  QueryStringSection,
} from "./components";

const { sqlQueryFormatter } = QueryEditorUtils;

export const DefaultQuery: React.FC<{ data: IQuery }> = ({ data }) => {
  const {
    queryString,
    queryGivenName,
    lastReferencedDateTime,
    lastReferencedUsername,
    associatedTables,
    onClickQuery,
  } = useQueryComponent(data);
  const getLastRefDate = (lastDateRef: string) => {
    if (!lastDateRef) return "-";
    const dateObj = new Date(lastDateRef);
    const day = dateObj.getDate().toString().padStart(2, "0");
    const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
    const year = dateObj.getFullYear().toString().substring(2);
    return `${day}.${month}.${year}`;
  };
  return (
    <DefaultQueryContainer onClick={onClickQuery}>
      <QueryMetaSection style={{ backgroundColor: "white" }}>
        <DefaultQueryBadge />
        <QueryMetaContainer>
          <Typography color="#252525" variant="p">
            {queryGivenName}
          </Typography>
        </QueryMetaContainer>
        <QueryMetaContainer>
          <Typography variant="small" color="#707070">
            Last queried at
          </Typography>
          <Typography color="#252525">
            {getLastRefDate(lastReferencedDateTime)}
          </Typography>
        </QueryMetaContainer>
        <QueryMetaContainer>
          <Typography variant="small" color="#707070">
            Last queried by
          </Typography>
          <Typography color="#252525">
            {lastReferencedUsername ? lastReferencedUsername : "-"}
          </Typography>
        </QueryMetaContainer>
        <QueryMetaContainer>
          <Typography variant="small" color="#707070">
            Associated tables
          </Typography>
          <Typography style={{ wordBreak: "break-all" }} color="#252525">
            {associatedTables}
          </Typography>
        </QueryMetaContainer>
      </QueryMetaSection>
      <QuerySectionDivider />
      <QueryStringSection>
        <DefualtSQLEditor value={sqlQueryFormatter(queryString)} />
      </QueryStringSection>
    </DefaultQueryContainer>
  );
};
