import { AssetSortValues } from "../../core";

export const DefualtQueriedInFilterOptions = [
  {
    label: "All",
    value: "",
  },
  {
    label: "Last day",
    value: "1",
  },
  {
    label: "Last week",
    value: "7",
  },
  {
    label: "Last Month",
    value: "30",
  },
];

export const AssetSortOptions = [
  { label: "Last Used", value: AssetSortValues[0] },
  { label: "Most Used", value: AssetSortValues[1] },
  { label: "Cost", value: AssetSortValues[2] },
];
