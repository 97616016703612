import { Modal } from "@material-ui/core";
import styled from "styled-components";

export const SqlViewerModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SqlViewerContainer = styled.div`
  width: 60%;
  height: auto;
  position: relative;
`;
export const DialogCloseButton = styled.img`
  right: ${({ theme }) => theme.spacing.lg};
  top: ${({ theme }) => theme.spacing.lg};
  position: absolute;
  cursor: pointer;
`;

export const CopyContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  background: ${({ theme }) => theme.colors.primary};
`;

export const CopyIcon = styled.img`
  width: 26px;
`;

export const CopyButton = styled.button`
  display: flex;
  align-items: center;
  border: none;
  background: transparent;
  margin: 10px;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
`;
