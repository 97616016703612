import styled from "styled-components";

const Row = styled.div`
  display: flex;
`;

export const SearchAndFilterContainer = styled(Row)`
  column-gap: 16px;
`;

export const FilterContainer = styled(Row)`
  column-gap: 8px;
  align-items: center;
`;

export const SearchContainer = styled(Row)`
  width: 450px;
`;

export const ClickableIcon = styled(Row)`
  &:hover {
    cursor: pointer;
  }
`;
