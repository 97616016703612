import { useState } from "react";
import { TableService } from "../../../../../v2/application";

export interface UsageUsedQueryDataType {
  Query: string;
  AverageCost: number;
  AverageRuntime: number;
  AverageDataScanned: number;
  // TableReferences: string;
  NumberOfRuns: number;
  QueryId: number;
}

export const useUsageUsedTablesAnalytics = () => {
  const [selectedQuery, setSelectedQuery] =
    useState<UsageUsedQueryDataType | null>(null);
  const [loading, setLoading] = useState(false);
  const [usageUsedQueriesData, setUsageUsedQueriesData] = useState<any>([]);

  const getUsageUsedQueriesData = async (...args: any) => {
    const { 0: StartDate, 1: EndDate, 2: Page, 4: SortField, 5: Sortby } = args;
    try {
      setLoading(true);
      setUsageUsedQueriesData([]);
      const { Data } = await TableService.getMostUsedQueries(
        StartDate,
        EndDate,
        Page,
        SortField,
        Sortby
      );
      setUsageUsedQueriesData(Data);
    } catch (er) {
      console.error(er);
    } finally {
      setLoading(false);
    }
  };

  const onClickQuery = (query?: UsageUsedQueryDataType) => {
    setSelectedQuery(query ?? null);
  };

  return {
    loading,
    selectedQuery,
    usageUsedQueriesData,
    getUsageUsedQueriesData,
    onClickQuery,
  };
};
