import React from "react";
import { Icons, Typography, TypographyProps } from "@sherloq/design-system";
import { Modal, ModalProps } from "../atoms";
import {
  CancelButton,
  Container,
  DescriptionText,
  Header,
  IconContainer,
  ModalContentElementContainer,
  ModalFooter,
  ModalFooterText,
  RootModalContent,
  TextContainer,
} from "./components";

type ButtonEventHandler = React.MouseEventHandler<HTMLButtonElement>;

export interface RootModalProps extends ModalProps {
  title?: string;
  description?: string;
  showCancelButton?: boolean;
  actionButton?: React.ReactElement;
  contentElement?: React.ReactNode;
  footerTextElement?: React.ReactNode;
  onClickCancel?: ButtonEventHandler;
  descriptionProps?: TypographyProps;
  titleProps?: TypographyProps;
}

export const RootModal: React.FC<RootModalProps> = ({
  title,
  description,
  contentElement,
  actionButton: ActionButton,
  footerTextElement,
  showCancelButton,
  tintColor,
  descriptionProps,
  titleProps,
  ...rest
}) => {
  return (
    <Modal {...rest}>
      <Container {...{ tintColor }}>
        <RootModalContent>
          <Header>
            <TextContainer>
              <Typography {...titleProps} variant="h4">
                {title}
              </Typography>
              <DescriptionText {...descriptionProps} variant="p-light">
                {description}
              </DescriptionText>
            </TextContainer>
            <IconContainer>
              <Icons
                onClick={rest.onClose as any}
                src="ic16closePreview"
                className="icon"
              />
            </IconContainer>
          </Header>
          <ModalContentElementContainer>
            {contentElement}
          </ModalContentElementContainer>
          <ModalFooter>
            <ModalFooterText>{footerTextElement}</ModalFooterText>
            {showCancelButton && (
              <CancelButton
                name="cancel-button"
                color="secondary"
                onClick={rest.onClose as any}
              >
                Cancel
              </CancelButton>
            )}
            {ActionButton}
          </ModalFooter>
        </RootModalContent>
      </Container>
    </Modal>
  );
};
