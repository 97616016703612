import { authHttpClient } from "../../infrastructure";
import { ITable } from "../../core";
import {
  makeAddAssetToUserListFactoryFn,
  makeChangeAssetInput,
  makeGetAssetProfile,
  makeIsAssetInUserListFactoryFn,
  makeRemoveAssetFromUserListFactoryFn,
  makeSearchAssetFactory,
  parseSearchAssetFilters,
} from "../asset";

const searchTable = makeSearchAssetFactory<ITable>("table");

const getTableProfile = makeGetAssetProfile("table");

const isTableInUserList = makeIsAssetInUserListFactoryFn("table");

const changeTableDescription = makeChangeAssetInput("table", "description");
const changeTableOwner = makeChangeAssetInput("table", "given_owner");

const changeTableReadMe = makeChangeAssetInput("table", "read_me");

const removeTableFromUserList = makeRemoveAssetFromUserListFactoryFn("table");

const addTableToUserList = makeAddAssetToUserListFactoryFn("table");

const getTableFields = async (id: string, rawFilters?: any) => {
  const path = `v1/getdata/getTableOfFields?tableId=${id}`;
  let filters;
  if (rawFilters) {
    filters = parseSearchAssetFilters(rawFilters);
  }
  const params = { filters };
  const { data } = await authHttpClient.get(path, { params });
  return data;
};

const getTableTopUsers = async (id: string) => {
  const path = `v1/getdata/getTopUsers?AssetId=${id}`;
  const data = await authHttpClient.get(path);
  return data;
};

const getTableProfileQueriesUsage = async (rawFilters: any) => {
  const filters = parseSearchAssetFilters(rawFilters);
  const params = { filters };
  const path = "v1/getdata/getTableOfQueries";
  const response = await authHttpClient.get(path, { params });
  return Promise.resolve(response?.data);
};

const getTop5TablesJoinsInPastWeek = async () => {
  const path = "/v1/Analytics/getTop5JoinsInPastWeek";
  const { data } = await authHttpClient.get(path);
  return data;
};

const getTrendingTablePerTimeFrame = async (
  StartDate: Date | string,
  EndDate: Date | string
) => {
  const path = "/v1/Analytics/getTrendingTablePerTimeFrame";
  const params = {
    StartDate,
    EndDate,
  };
  const { data } = await authHttpClient.get(path, { params });
  return data;
};

const getTop5TablesUsedPastMonth = async () => {
  const path = "/v1/Analytics/getTop5TablesUsedPastMonth";
  const res = await authHttpClient.get(path);
  return res.data;
};

const getTop5TableUsage = async (
  StartDate,
  EndDate,
  Page,
  Search,
  SortColumn,
  SortOrder
) => {
  const path = "/v1/Analytics/getTop5TableUsageNew";
  const params = {
    StartDate,
    EndDate,
    Page,
    Search,
    SortColumn,
    SortOrder,
  };
  const { data } = await authHttpClient.get(path, { params });
  return data;
};

const getRuntimePerTable = async (page: number) => {
  const path = "/v1/Analytics/getRuntimePerTable";
  const params = {
    page,
  };
  const { data } = await authHttpClient.get(path, { params });
  return data;
};

const getMostUsedQueries = async (
  StartDate,
  EndDate,
  Page,
  SortColumn,
  SortOrder
) => {
  const path = "/v1/Analytics/getMostUsedQueriesNew";
  const params = {
    StartDate,
    EndDate,
    Page,
    SortColumn,
    SortOrder,
  };
  const { data } = await authHttpClient.get(path, { params });
  return data;
};

export const getWorkgroups = async () => {
  const path = "/v1/Analytics/workgroups";
  const { data } = await authHttpClient.get(path);
  return data
};

export const TableService = Object.freeze({
  searchTable,
  getTableProfile,
  getTableFields,
  getTableTopUsers,
  changeTableDescription,
  changeTableReadMe,
  getTableProfileQueriesUsage,
  isTableInUserList,
  removeTableFromUserList,
  addTableToUserList,
  getTop5TablesJoinsInPastWeek,
  getTrendingTablePerTimeFrame,
  getTop5TablesUsedPastMonth,
  getTop5TableUsage,
  getRuntimePerTable,
  getMostUsedQueries,
  changeTableOwner,
  getWorkgroups
});
