import { useHomeTopHeader } from "../../../hooks";
import { IconButton } from "@sherloq/design-system";
import {
  AvatarPopup,
  BackIconContainer,
  Breadcrumbs,
  HeaderContainer,
  HeaderUtilsContainer,
  Icon,
  InjectedUtilsContainer,
  NavContainer,
} from "./components";
import { UserAvatar } from "./components/avatar";
import { StaticIcons } from "../../assets";
import { Typography } from "../Typography";
import { RenderIf } from "../../lib";
import { Popover } from "@mui/material";
import React from "react";

interface TopHeaderProps {
  showBack: boolean;
  headerUtils?: React.ReactNode;
}

export const TopHeader = ({ showBack, headerUtils }: TopHeaderProps) => {
  const {
    breadcrumbsTrace,
    initials,
    onClickBack,
    anchor,
    onClickOptions,
    onLogout,
    onViewDocumentation,
    onClosePopup,
  } = useHomeTopHeader();
  return (
    <HeaderContainer>
      <NavContainer>
        {RenderIf(
          showBack,
          <BackIconContainer onClick={onClickBack}>
            <Icon alt="" src={StaticIcons.ic16ArrowLeft} />
            <Typography variant="p-light" color="#252525">
              Back
            </Typography>
          </BackIconContainer>
        )}
        <Breadcrumbs trace={breadcrumbsTrace} />
      </NavContainer>
      <HeaderUtilsContainer>
        <InjectedUtilsContainer>
          {RenderIf(Boolean(headerUtils), headerUtils as any)}
        </InjectedUtilsContainer>
        <UserAvatar initials={initials} />
        <IconButton
          style={{ backgroundColor: "transparent", border: "none" }}
          icon="ic24moreMenuActionPreview"
          hoverIcon="ic24moreMenuActionHover"
          onClick={onClickOptions}
        />
      </HeaderUtilsContainer>
      <Popover
        open={Boolean(anchor)}
        anchorEl={anchor}
        onClose={onClosePopup}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <AvatarPopup
          onClickDocumentation={onViewDocumentation}
          onClickLogout={onLogout}
        />
      </Popover>
    </HeaderContainer>
  );
};
