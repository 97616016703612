import styled from "styled-components";

export const Container = styled.div`
  padding: 20px 20px 0 20px;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  background: linear-gradient(
      0deg,
      rgba(207, 206, 243, 0.4),
      rgba(207, 206, 243, 0.4)
    ),
    linear-gradient(0deg, #ffffff, #ffffff),
    linear-gradient(0deg, #3f37c9, #3f37c9),
    linear-gradient(0deg, #3a0ca3, #3a0ca3),
    linear-gradient(0deg, #7209b7, #7209b7), #f72585;
`;

export const Content = styled.div``;
export const Heading = styled.div``;
export const SummaryCards = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  margin-top: 40px;
`;
export const TabContainer = styled.div`
  margin: 50px;
`;
export const OutletContainer = styled.div`
  margin-top: 50px;
`;
