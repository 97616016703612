import styled from "styled-components";

interface TextAreaProps {
  error?: boolean;
}

export const TextAreaComponent = styled.textarea<TextAreaProps>`
  color: #252525;
  border-radius: 3px;
  border: ${({ error }) => `1px solid ${error ? "#B91646" : "#d9d9d9"}`};
  font-family: "rubik";
  padding: 9px;
  width: 478px;
  height: 82px;
  box-sizing: border-box;
  margin-bottom: 24px;
  &:focus {
    outline: none;
    border: ${({ theme, error }) =>
      `1px solid ${error ? "#B91646" : theme.appColors.primary}`};
  }
  &:hover {
    outline: none;
    border: 1px solid #d9d9d9;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
  }
  &::placeholder {
    font-style: italic;
    font-family: "rubik";
    font-weight: 300;
    font-size: 12px;
    line-height: 14px;
  }
`;
