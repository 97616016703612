import React from "react";
import {
  default as MUITooltip,
  TooltipProps,
  tooltipClasses,
} from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

export const Tooltip = styled(
  ({ className, PopperProps, ...props }: TooltipProps) => (
    <MUITooltip
      {...props}
      classes={{ popper: className }}
      PopperProps={
        PopperProps ?? {
          style: {
            zIndex: 9000,
            opacity: 0.9,
            maxWidth: "250px",
          },
        }
      }
    />
  )
)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    paddingTop: "8px",
    paddingBottom: "8px",
    backgroundColor: "#252525",
    color: "#ffffff",
    opacity: 0.9,
    borderRadius: "2px",
    fontSize: "12px",
    fontWeight: 300,
    lineHeight: "14.22px",
    fontStyle: "normal",
  },
}));
