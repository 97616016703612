import React from "react";
import { BarSvgProps, ResponsiveBar, BarDatum } from "@nivo/bar";
import { AxisTickProps } from "@nivo/axes";
import { TypographyWithToolTip } from "../TypographWithToolTip";
import { Typography } from "../Typography";
import { setMaxString } from "../../lib";

const LeftTick = ({ value, x, y }) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <foreignObject x={x - 38} y={-25} width={70} height={20}>
        <Typography variant="small">{value}</Typography>
      </foreignObject>
    </g>
  );
};
const CustomTick = ({ x, y, value }: AxisTickProps<string>) => {
  return (
    <g transform={`translate(${x},${y + 18})`}>
      <foreignObject x={-15} y={-15} width={40} height={35}>
        <TypographyWithToolTip
          variant="small"
          style={{
            width: "40px",
            inlineSize: "35px",
            lineHeight: "10px",
            textAlign: "center",
            display: "block",
          }}
          toolTipValue={value}
        >
          {setMaxString(value, 15)}
        </TypographyWithToolTip>
      </foreignObject>
    </g>
  );
};

type Chart = <RawDatum extends BarDatum>(
  props: Omit<BarSvgProps<RawDatum>, "height" | "width">
) => JSX.Element;

export const BarChart: Chart = (props) => {
  return (
    <ResponsiveBar
      theme={{
        grid: {
          line: {
            stroke: "#BDBDBD",
            strokeDasharray: 3,
          },
        },
        axis: {
          domain: {
            line: {
              strokeWidth: 1,
              stroke: "#BDBDBD",
              strokeDasharray: 2,
            },
          },
          ticks: {
            line: {
              strokeWidth: 0,
            },
          },
        },
      }}
      axisTop={{
        renderTick: () => <span />,
        tickPadding: 20,
      }}
      axisRight={{}}
      enableGridX
      margin={{ top: 20, right: 0, bottom: 35, left: 40 }}
      padding={0.6}
      valueScale={{ type: "linear" }}
      indexScale={{ type: "band", round: true }}
      colors={["#F94144", "#90BE6D", "#2D9CDB"]}
      axisBottom={{
        renderTick: CustomTick,
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "",
        legendPosition: "middle",
        legendOffset: -40,
        renderTick: LeftTick,
      }}
      tooltip={({ id }) => {
        return (
          <Typography
            style={{
              padding: "3px",
              borderRadius: "2px",
              backgroundColor: "#00000080",
            }}
            color="#fff"
          >
            {id}
          </Typography>
        );
      }}
      label={({ id }) => {
        return setMaxString(`${id ?? ""}` ?? "", 6);
      }}
      labelTextColor={"#515151"}
      labelSkipWidth={12}
      labelSkipHeight={12}
      role="application"
      ariaLabel="most common field"
      {...props}
    />
  );
};
