import { authHttpClient } from "../../infrastructure";
import { AssetFilterType } from "../../core";
import { parseSearchAssetFilters } from "./parseSearchAssetFilter";

interface paramType {
  assetType: "table" | "query",
  searchString: string,
  filters: string | undefined,
  OrderByField?: string | undefined,
  SortOrder?: string  | undefined
  offset?: number
}

export function makeSearchAssetFactory<T = any>(assetType: "table" | "query") {
  return async (searchString: string, rawFilters: AssetFilterType, sortBy?: string, offset?: number) => {
    const filters = parseSearchAssetFilters(rawFilters);
    const params : paramType = {
      assetType,
      searchString,
      filters,
      offset
    };
    if(sortBy){
      params.OrderByField = sortBy;
      params.SortOrder = 'desc'
    }
    try {
      const response = await authHttpClient.get("/v1/getData/getSearch", {
        params,
      });
      return response?.data as T[];
    } catch (error) {
      console.error(error);
    }
  };
}
