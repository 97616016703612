import { useMemo } from "react";
import { QueryService } from "../../application";
import { useInfiniteQuery } from "react-query";
import { useEffect } from "react";
import { AnalyticsClient, homeActions } from "../../infrastructure";
import { useHomeGlobalState } from "./homeReducerHooks";
import { useGlobalAuthState } from "../user";
import { useGlobalStateDispatch } from "../redux";
import { AssetSortValues } from "../../core";

export const useQueryResultsSubpage = () => {
  const dispatch = useGlobalStateDispatch();
  const { user } = useGlobalAuthState();
  const { searchKeyword, filters, sortBy } = useHomeGlobalState();

  const {
    data,
    isError,
    refetch,
    hasNextPage,
    fetchNextPage,
    isLoading,
    isFetching,
  } = useInfiniteQuery({
    queryKey: ["homepage-search-query", searchKeyword, filters, user, sortBy],
    queryFn: async ({ pageParam = 0 }) => {
      const data = await QueryService.searchQuery(
        searchKeyword,
        filters,
        sortBy,
        pageParam
      );
      return { data, offset: pageParam };
    },
    getNextPageParam: (data) => {
      return data.offset + 1;
    },
    onSuccess: () => {
      AnalyticsClient.trackAction("main_query_results_search_success", {
        keyword: searchKeyword,
        filters: { ...filters, sortBy },
      });
    },
    onError: () => {
      AnalyticsClient.trackAction("main_query_results_search_failed", {
        keyword: searchKeyword,
        filters: { ...filters, sortBy },
      });
    },
    enabled: false,
  });

  const allData = useMemo(() => {
    if (data) {
      return data.pages.reduce((acc, prev) => {
        return acc.concat(prev.data as any);
      }, []);
    }
    return [];
  }, [data]);

  useEffect(() => {
    dispatch(homeActions.syncSortBy(AssetSortValues[0])); // to maintain Ewenson's logic
  }, []);

  useEffect(() => {
    refetch();
  }, [searchKeyword, filters, sortBy]);

  const fetchMoreData = async () => {
    fetchNextPage();
  };

  return {
    data: allData,
    isLoading: isFetching || isLoading,
    isError,
    fetchMoreData,
    hasMore: !!hasNextPage,
  };
};
