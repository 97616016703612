import CardComponent from '../../../../components/card/CardComponent'
import * as Styled from './Table.styled'
import { AppTypography } from '../../../../styles/global'
import { TypographySize, TypographyBold, TypographyColor } from '../../../../types/styles.d'
import searchPageImages from '../../../../assets/images/search';
import globalImages from '../../../../assets/images/global';
import theme from '../../../../styles/theme'
import Workgroups from '../Workgroups/Workgroups'
import Loading from "../../../../components/Loading/Loading";
import { Tooltip } from '@material-ui/core';
import { trimStringAtCharacter } from '../../../../utils/helpers/strings';
import { formatBytes, formatSeconds } from '../../../../utils/helpers/unit-converter';
import SqlViewer from "../../../../components/SqlViewer/SqlViewer";
import { useState } from 'react';

interface IMostExpensiveQueryData {
    ExpenseValue: number
    ExpensePercentage: number
    NumOfReferences: number
    Query: string
    QueryId: number | string
    User: string
}
interface MostExpensiveQueryProps {
    title: string
    mostExpensiveQueryData: IMostExpensiveQueryData[]
    loading: boolean
    callAPI: any
    assetType: 'cost' | 'runtime' | 'datascanned'
}

function MostExpensiveQuery({ title, mostExpensiveQueryData, loading, callAPI, assetType }: MostExpensiveQueryProps) {
    const [selectedQuery, setSelectedQuery] = useState<IMostExpensiveQueryData | null>(null)
    const tableHeader = [{
        title: 'Most Expensive',
        extra: ''
    },
    {
        title: 'User',
        extra: ''
    },
    {
        title: (assetType == 'datascanned') ? 'Data Scanned' : (assetType == 'runtime') ? 'Runtime' : 'Cost',
        extra: 'This Week'
    },
    {
        title: (assetType == 'datascanned') ? 'Data Scanned' : (assetType == 'runtime') ? 'Runtime' : 'Cost',
        extra: 'Compared to last week'
    },
    {
        title: '# of Runs',
        extra: ''
    }]
    const handleQueryClick = (query: IMostExpensiveQueryData) => {
        setSelectedQuery(query)
    }
    return (
        <CardComponent padding='20px 20px 0 20px' overflowVisible='visible'>
            <>
                <Styled.CardComponentHeader>
                    <AppTypography
                        bold={TypographyBold.lg}
                        size={TypographySize.md}
                        textColor={theme.appColors.dark}>
                        {title}
                    </AppTypography>
                    <Workgroups reset={() => callAPI()} getWorkgroupFilteredString={(filters: string) => callAPI(filters)} />
                </Styled.CardComponentHeader>
                {loading && <Loading size={20} height={5} />}
                {(mostExpensiveQueryData.length > 0) && !loading && <Styled.Table>
                    <Styled.THead>
                        <Styled.TableRow>
                            {
                                tableHeader.map(({ title, extra }, index) => (
                                    <Styled.TableHead key={index}>
                                        <AppTypography
                                            bold={TypographyBold.lg}
                                            size={TypographySize.md}>
                                            {title}&nbsp;
                                        </AppTypography>
                                        <AppTypography size={TypographySize.xs}>{extra}</AppTypography>
                                    </Styled.TableHead>
                                ))
                            }
                        </Styled.TableRow>
                    </Styled.THead>
                    <Styled.TBody>
                        {mostExpensiveQueryData?.map((value, index) => (
                            <Styled.TableRow key={index}>
                                <Styled.TableColumn>
                                    <Styled.Row justifyContent='start'>
                                        <Styled.SmallTableIcon src={searchPageImages.QueryIcon} />
                                        <Tooltip title={value?.Query} placement='top-start'>
                                            <Styled.QueryTextOverflow onClick={(() => handleQueryClick(value))}>
                                                <AppTypography
                                                    underline
                                                    clickable
                                                    size={TypographySize.sm}>
                                                    {value?.Query}
                                                </AppTypography>
                                            </Styled.QueryTextOverflow>
                                        </Tooltip>
                                    </Styled.Row>
                                </Styled.TableColumn>
                                <Styled.TableColumn>
                                    <AppTypography
                                        size={TypographySize.sm}>
                                        {value?.User ? trimStringAtCharacter(value?.User, '@') : 'N/A'}
                                    </AppTypography>
                                </Styled.TableColumn>
                                <Styled.TableColumn>
                                    <AppTypography
                                        size={TypographySize.sm}>
                                        {assetType === 'datascanned' && formatBytes(value?.ExpenseValue)}
                                        {assetType === 'runtime' && formatSeconds(value?.ExpenseValue)}
                                        {
                                            assetType === 'cost' && 
                                            (Number(value?.ExpenseValue) > 1 ?
                                                "$"+Number(value?.ExpenseValue).toFixed(1) :
                                                "$"+Number(value?.ExpenseValue).toFixed(3))
                                        }
                                    </AppTypography>
                                </Styled.TableColumn>

                                <Styled.TableColumn>
                                    {(value?.ExpensePercentage) > 0 &&
                                        <Styled.Row>
                                            <Styled.SmallTableIcon src={globalImages.trendingUpIcon} />
                                            <AppTypography
                                                textColor={TypographyColor.ok}
                                                size={TypographySize.sm}>
                                                {value?.ExpensePercentage} usage
                                            </AppTypography>
                                        </Styled.Row>
                                    }
                                    {(value?.ExpensePercentage) < 0 &&
                                        <Styled.Row>
                                            <Styled.SmallTableIcon src={globalImages.trendingDownIcon} />
                                            <AppTypography
                                                textColor={TypographyColor.danger}
                                                size={TypographySize.sm}>
                                                {value?.ExpensePercentage} usage
                                            </AppTypography>
                                        </Styled.Row>
                                    }
                                    {(value?.ExpensePercentage) == 0 &&
                                        <AppTypography
                                            size={TypographySize.sm}>
                                            no change
                                        </AppTypography>}
                                </Styled.TableColumn>
                                <Styled.TableColumn>
                                    <AppTypography
                                        size={TypographySize.sm}>
                                        {value?.NumOfReferences}
                                    </AppTypography>
                                </Styled.TableColumn>
                            </Styled.TableRow>

                        ))
                        }
                    </Styled.TBody>
                </Styled.Table>}
                <SqlViewer 
                    onClose={() => setSelectedQuery(null)} 
                    show={selectedQuery ? true : false} 
                    query={selectedQuery?.Query ?? ""} 
                    query_id={selectedQuery?.QueryId ? selectedQuery?.QueryId.toString() : ""} />
            </>
        </CardComponent>
    )
}

export default MostExpensiveQuery