import { transformAssetUsername } from "../../core";

const checkIfObjectExist = (arr, id): [boolean, number] => {
  let found = false;
  let index = -1;
  for (let i = 0; i < arr.length; i++) {
    if (arr[i].id == id) {
      found = true;
      index = i;
      break;
    }
  }
  return [found, index];
};

const transformCompanyAllLists = (data: any[]) => {
  return data.map((list) => {
    if (list?.tableId !== undefined) {
      return {
        id: `table${list.tableId}`,
        savedBy: [`${list.firstName} ${list.lastName}`],
        type: "table",
        title: list.tableName,
        description: list.description,
        userName: transformAssetUsername(list.lastUsernameThatReferenced),
        lastQueriedDate: list.lastReferenceDatetime,
        parentName: list.dbName,
        savedDatetime: list.savedDatetime,
        source: list.source,
      };
    }
    if (list?.queryId !== undefined) {
      return {
        id: `query${list.queryId}`,
        savedBy: [`${list.firstName} ${list.lastName}`],
        type: "query",
        title: list.givenName || list.queryString,
        description: list.description,
        userName: transformAssetUsername(list.lastUsernameThatReferenced),
        lastQueriedDate: list.lastQueryThatReferencedDatetime,
        parentName: list.listOfTablesAccessed,
        savedDatetime: list.savedDatetime,
        source: list.source,
      };
    }
  });
};

const sortCompanyLists = (data: any[]) => {
  return data.sort((a, b) => {
    return +new Date(b.savedDatetime) - +new Date(a.savedDatetime);
  });
};

export const makeTransformCompanyAllListsAssets = (data: any) => {
  const allGroupsAssets: any[] = [];
  const companyAllLists = [...data.QueriesSaved, ...data.TablesSaved];
  const transformedCompanyLists = transformCompanyAllLists(companyAllLists);
  transformedCompanyLists.forEach((list) => {
    const [found, index] = checkIfObjectExist(allGroupsAssets, list!.id);
    if (found) {
      allGroupsAssets[index].savedBy.push(list!.savedBy[0]);
    } else {
      allGroupsAssets.push(list);
    }
  });
  const sortedAllGroupsAssets = sortCompanyLists(allGroupsAssets);
  return sortedAllGroupsAssets;
};
