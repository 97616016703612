import styled from "styled-components";

export const InputContainer = styled.input`
  margin-bottom: ${({ theme }) => theme.spacing.lg};
  font-size: ${({ theme }) => theme.typography.size.md};
  outline: none;
  border: none;
  line-height: 45px;
  width: 80%;
  margin-left: 10%;
  border-bottom: 1px solid #000000;
  color: #000;
  ::placeholder {
    color: #000;
  }
`;