import React from 'react'
import { Mixpanel } from '../../mixpanelWrapper';
import { useState } from "react";
import FeedbackModal from "../FeedbackModal";
import { RootState } from '@reduxjs/toolkit';
import { useReduxSelector } from '../../redux'
import Button from "../Button/button-component";
import theme from "../../styles/theme";
import * as Styled from "./FeedbackComponent.styled";
import globalImages from "../../assets/images/global";

function FeedbackComponent({ assetId, screenName }) {
    const state = useReduxSelector((state: RootState) => state);
    const globalUser = state.auth.user;
    const [openFeedbackModal, setOpenFeedbackModal] = useState(false)

    const openFeedbackOnClick = () => {
        Mixpanel.track(`main_feedback_button_${screenName}_click`, { "asset_id": assetId });
        setOpenFeedbackModal(true);
    }
    return (
        <>
            <Styled.FeebackButtonContainer>
                    <Button 
                        preIcon={globalImages.messageAddIcon}
                        onClick={openFeedbackOnClick}
                        shadow
                        color={theme.appColors.primary}
                        border={`1px solid ${theme.appColors.primary}`}
                        text={'We\'d love to hear from you'} type={'submit'} 
                    />
            </Styled.FeebackButtonContainer>
            <FeedbackModal
                open={openFeedbackModal}
                screenName={screenName}
                assetId={assetId}
                sherloqUserId={globalUser.SherloqUserId}
                handleClose={() => setOpenFeedbackModal(false)} 
            />
        </>
    )
}

export default FeedbackComponent