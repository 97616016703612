import styled from "styled-components";
import { z_rootSideView } from "../../lib";

export const RootSideViewContainer = styled.section<{ open?: boolean }>`
  width: 550px;
  box-sizing: border-box;
  padding: 24px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  position: fixed;
  right: 0;
  top: 0;
  height: 100vh;
  z-index: ${z_rootSideView};
  display: ${({ open }) => (open ? "flex" : "none")};
  flex-direction: column;
`;

export const SideViewHeader = styled.header`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-right: 14px;
`;

export const SideViewCancelIcon = styled.img`
  :hover {
    cursor: pointer;
  }
`;

export const ContentContainer = styled.div`
  width: 100%;
  padding-top: 24px;
`;
