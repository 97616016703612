import { UsersUsageComponent } from "./UsersUsage";
import { Container } from "./styled";
import { RuntimePerUser } from "./RuntimePerUser";

export const Users = () => {
  return (
    <Container>
      <UsersUsageComponent />
      <RuntimePerUser />
    </Container>
  );
};
