import styled from "styled-components";

export const SmallTableIcon = styled.img`
  height: 35px;
  margin-right: 1px;
`;
export const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const ItemCardWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 0;
  width: 100%;
`;
export const Row = styled.div`
  margin: 0 10px;
  display: flex;
  align-items: center;
  gap: 5px;
`;
export const IconWrapper = styled.div``;
export const TopArea = styled.div`
  display: flex;
  align-items: center;
`;
export const Icon = styled.img``;

export const BottomArea = styled.div`
  display: flex;
  align-items: center;
  margin: 3px 0;
`;
export const UserList = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 20px;
`;

export const CardContainer = styled.div`
  border-bottom: 2px solid;
  border-color: #e8e8e8;
  padding: 10px 0 0px 0;
`;

export const IconContainer = styled.img`
  object-fit: contain;
  height: 20px;
  width: 20px;
`;

export const FolderCardContainer = styled.div`
  border-bottom: 1px solid;
  border-color: #e8e8e8;
  padding: 10px 0 18px 15px;
  display: flex;
  column-gap: 13px;
  align-items: center;
  height: 35px;
  text-decoration: none;
`;
