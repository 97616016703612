import { Grid } from "@material-ui/core";
import * as Styled from "./ItemCard.styled";
import searchPageImages from "../../assets/images/search";
import { AppTypography } from "../../styles/global";
import { TypographyBold, TypographySize } from "../../types/styles.d";
import { AssetType, ASSET_TYPE } from "../../services/models";
import { Tooltip } from "@material-ui/core";
import { setMaxString } from "../../utils/helpers/strings";
import { SupportedIDEsType } from "@/types";
import { QuerySourceIcon } from "../QuerySource";
import { AthenaIcon, BigQueryIcon } from "../../assets/IDE";

function ItemIcon({ type }: { type: AssetType }) {
  return (
    <>
      {type === ASSET_TYPE.TABLE && (
        <Styled.SmallTableIcon src={searchPageImages.DBTableIcon} />
      )}
      {type === ASSET_TYPE.FIELD && (
        <Styled.SmallTableIcon src={searchPageImages.DBFieldBlueIcon} />
      )}
      {type === ASSET_TYPE.QUERY && (
        <Styled.SmallTableIcon src={searchPageImages.QueryIcon} />
      )}
    </>
  );
}
interface ItemCardProps {
  type: AssetType;
  title: string;
  description?: string;
  userName: string;
  lastQueriedDate: string;
  tableName: string | string[];
  users: string[];
  onClick?: () => void;
  source?: SupportedIDEsType;
}

function AllItemCard({
  type,
  title,
  description,
  userName,
  lastQueriedDate,
  tableName,
  onClick,
  users,
  source,
}: ItemCardProps) {
  return (
    <Styled.CardContainer style={{ width: "90%" }}>
      <Styled.ItemCardWrapper>
        <Grid container spacing={0} alignItems="center">
          <Grid item xs={3} md={3}>
            <Styled.Row>
              <Styled.IconWrapper>
                <ItemIcon type={type} />
              </Styled.IconWrapper>
              <Styled.Column>
                <Styled.TopArea>
                  <Tooltip title={title} placement="top-start">
                    <AppTypography
                      onClick={onClick}
                      clickable
                      wrap="true"
                      size={TypographySize.md}
                      bold={TypographyBold.lg}
                    >
                      {type === ASSET_TYPE.QUERY && setMaxString(title, 25)}
                      {type === ASSET_TYPE.TABLE && setMaxString(title, 11)}
                    </AppTypography>
                  </Tooltip>
                </Styled.TopArea>
                <Styled.BottomArea>
                  <AppTypography wrap="true" size={TypographySize.sm}>
                    {description}
                  </AppTypography>
                </Styled.BottomArea>
              </Styled.Column>
            </Styled.Row>
          </Grid>
          <Grid item xs={3} md={3}>
            <Styled.Row>
              <Styled.IconWrapper>
                <Styled.Icon src={searchPageImages.UserIcon} />
              </Styled.IconWrapper>
              <Styled.Column>
                <Styled.TopArea>
                  <AppTypography size={TypographySize.sm}>
                    Last queried by
                  </AppTypography>
                </Styled.TopArea>
                <Styled.BottomArea>
                  <Tooltip title={userName} placement="top-start">
                    <AppTypography
                      wrap="true"
                      bold={TypographyBold.lg}
                      size={TypographySize.sm}
                    >
                      {setMaxString(userName, 13)}
                    </AppTypography>
                  </Tooltip>
                </Styled.BottomArea>
              </Styled.Column>
            </Styled.Row>
          </Grid>
          <Grid item xs={2} md={2}>
            <Styled.Row>
              <Styled.IconWrapper>
                <Styled.Icon src={searchPageImages.ClockIcon} />
              </Styled.IconWrapper>
              <Styled.Column>
                <Styled.TopArea>
                  <AppTypography size={TypographySize.sm}>
                    Last queried
                  </AppTypography>
                </Styled.TopArea>
                <Styled.BottomArea>
                  <AppTypography
                    wrap="true"
                    bold={TypographyBold.lg}
                    size={TypographySize.sm}
                  >
                    {lastQueriedDate}
                  </AppTypography>
                </Styled.BottomArea>
              </Styled.Column>
            </Styled.Row>
          </Grid>
          <Grid item xs={2} md={2}>
            <Styled.Row>
              <Styled.IconWrapper>
                {type === ASSET_TYPE.TABLE && (
                  <Styled.Icon src={searchPageImages.IngredientIcon} />
                )}
                {type === ASSET_TYPE.QUERY && (
                  <Styled.Icon src={searchPageImages.TableBlackIcon} />
                )}
              </Styled.IconWrapper>
              <Styled.Column>
                <Styled.TopArea>
                  <Tooltip title={tableName} placement="top-start">
                    <AppTypography size={TypographySize.sm}>
                      {setMaxString(
                        Array.isArray(tableName) ? tableName[0] : tableName,
                        12
                      )}
                    </AppTypography>
                  </Tooltip>
                </Styled.TopArea>
              </Styled.Column>
            </Styled.Row>
            <Styled.Row style={{ paddingTop: " 5px" }}>
              <Styled.IconWrapper>
                <QuerySourceIcon
                  source={
                    source === "athena"
                      ? AthenaIcon
                      : source === "bigquery"
                      ? BigQueryIcon
                      : undefined
                  }
                  style={{ height: "18px" }}
                />
              </Styled.IconWrapper>
              <Styled.Column>
                <Styled.TopArea>
                  <Tooltip title={tableName} placement="top-start">
                    <AppTypography size={TypographySize.sm}>
                      {source}
                    </AppTypography>
                  </Tooltip>
                </Styled.TopArea>
              </Styled.Column>
            </Styled.Row>
          </Grid>
          <Grid item xs={2} md={2}>
            <Styled.Row>
              <Styled.IconWrapper>
                <Styled.Icon src={searchPageImages.ClockIcon} />
              </Styled.IconWrapper>
              <Styled.Column>
                <Styled.TopArea>
                  <AppTypography size={TypographySize.md}>
                    added to list by
                  </AppTypography>
                </Styled.TopArea>
                <Styled.BottomArea>
                  <Styled.Column>
                    <Styled.UserList>
                      {users?.map((user, i) => (
                        <AppTypography
                          key={i}
                          bold={TypographyBold.lg}
                          size={TypographySize.sm}
                        >
                          {user}
                        </AppTypography>
                      ))}
                    </Styled.UserList>
                  </Styled.Column>
                </Styled.BottomArea>
              </Styled.Column>
            </Styled.Row>
          </Grid>
        </Grid>
      </Styled.ItemCardWrapper>
    </Styled.CardContainer>
  );
}

export default AllItemCard;
