import { userService } from "../../application";
import { useState } from "react";

interface UsersUsageDataType {
  AverageCost: number;
  AverageDataScanned: number;
  AverageRuntime: number;
  UserName: string;
  NumOfReferences: number;
  TotalCost: number;
  UserCount: number;
  TotalCostPercentage: number;
}

export const useUsersUsage = () => {
  const [loading, setLoading] = useState(false);
  const [usersUsageData, setUsersUsageData] = useState<UsersUsageDataType[]>();

  const getTop5TableUsage = async (
    StartDate,
    EndDate,
    Page,
    Search,
    SortBy,
    SortOrder
  ) => {
    try {
      setLoading(true);
      setUsersUsageData([]);
      const { Data } = await userService.getUsersUsageService(
        StartDate,
        EndDate,
        Page,
        Search,
        SortBy,
        SortOrder
      );
      setUsersUsageData(Data);
    } catch (er) {
      console.error(er);
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    usersUsageData,
    getTop5TableUsage,
  };
};
