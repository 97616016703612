import { useNavigate } from "react-router-dom";
import {
  AppRoutes,
  transformTableProfileQueriesUsageFactoryFn,
} from "../../application";

export const useTableProfileQueryUsageRow = (data: any) => {
  const navigate = useNavigate();
  const { id, ...queryUsage } =
    transformTableProfileQueriesUsageFactoryFn(data);

  const onClickRow = () => {
    navigate(`/${AppRoutes.QUERY_PROFILE}/${id}`);
  };

  return {
    ...queryUsage,
    onClickRow,
  };
};
