import {
  AssetProfileContentSectionTextLink,
  AssetProfileSectionHeaderWithInfo,
} from "../../../../shared-components";
import { Table, TableProfileSectionHeader } from "..";
import { ReactNode } from "react";

interface Props {
  onClickViewAll?: () => void;
  children?: ReactNode;
  numberOfItems: number;
}

export const TopFields = ({
  onClickViewAll,
  children,
  numberOfItems,
}: Props) => {
  return (
    <div style={{ height: "400px" }}>
      <Table style={{ height: "fit-content" }}>
        <TableProfileSectionHeader>
          <AssetProfileSectionHeaderWithInfo tooltipText="Top fields - The most used fields in this table. The percentage is based on all queries that have been made on this table.">
            Top Fields
          </AssetProfileSectionHeaderWithInfo>
          <AssetProfileContentSectionTextLink onClick={onClickViewAll}>
            View all {numberOfItems} fields
          </AssetProfileContentSectionTextLink>
        </TableProfileSectionHeader>
        <tbody>{children}</tbody>
      </Table>
    </div>
  );
};
