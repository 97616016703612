import styled from "styled-components";
import { AppTypographyProps } from "../types/styles";

export const AppTypography = styled.span<AppTypographyProps>`
  font-size: ${({ size, theme }) => size ?? theme.typography.size.sm};
  font-weight: ${({ bold, theme }) => bold ?? theme.typography.bold.sm};
  color: ${({ theme, textColor }) =>
    textColor ?? theme.typography.colors.primary};
  white-space: ${({ wrap }) => (wrap == "true" ? "normal" : "nowrap")};
  text-decoration: ${({ underline }) => (underline ? "underline" : "none")};
  font-style: ${({ italic }) => (italic ? "italic" : "normal")};
  outline: none;
  text-transform: ${({ capitalize }) => (capitalize ? "capitalize" : "none")};

  &:hover {
    opacity: ${({ clickable }) => (clickable ? 0.7 : undefined)};
    cursor: ${({ clickable }) => (clickable ? "pointer" : undefined)};
    text-decoration: ${({ clickable }) =>
      clickable ? "underline" : undefined};
  }
`;
