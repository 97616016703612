import styled from "styled-components";

export const ChipContainer = styled.div`
  background: #f1f1f1;
  margin: 0px;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "rubik";
  height: 6px;
  width: fit-content;
  padding: 8px;

  &:hover {
    background-color: #d9d9d9;
  }
`;

export const CloseIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 8px;
`;
