import { useDataScanned, useGlobalAuthState } from "../../../../v2/hooks";
import React from "react";
import MostExpensiveQuery from "../Tables/MostExpensiveQuery";
import WeeklyCost from "../Tables/WeeklyCost";
import * as Styled from "./ScannedData.styled";
function ScannedData() {
  const { details } = useGlobalAuthState();
  const {
    mostExpensiveQueries,
    loadingMostExpensiveQueries,
    weeklyCost,
    loadingWeeklyCost,
    getWeeklyCostPerQueryRun,
    getTop5MostExpensiveThisWeek,
  } = useDataScanned();
  return (
    <Styled.CostContainer>
      <Styled.CostGraphContainer>
        <WeeklyCost
          type="Data Scanned"
          callAPI={getWeeklyCostPerQueryRun}
          loading={loadingWeeklyCost}
          data={weeklyCost} 
          sumTitle='Data Scanned in TB'
          avgTitle='Data Scanned in GB'
        />
      </Styled.CostGraphContainer>
      {details.CompanyId != 4 ? (
        <Styled.MostExpensiveContainer>
          <MostExpensiveQuery
            assetType="datascanned"
            title={"5 Queries With Most Data Scanned This Week"}
            callAPI={getTop5MostExpensiveThisWeek}
            loading={loadingMostExpensiveQueries}
            mostExpensiveQueryData={mostExpensiveQueries}
          />
        </Styled.MostExpensiveContainer>
      ) : (
        <></>
      )}
    </Styled.CostContainer>
  );
}

export default ScannedData;
