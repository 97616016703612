import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  width: 100%;
  border-bottom-style: solid;
  border-bottom-color: #f1f1f1;
  border-bottom-width: 1px;
  margin-bottom: 8px;
`;
