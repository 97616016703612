import { useWeeklyCostReport } from "./useWeeklyCostsReport";

export function useCostsReport() {
  const report = useWeeklyCostReport({
    type: "cost",
    averageYIndex: (data) => data.WeeklyCost.AverageCost,
    sumYIndex: (data) => data.WeeklyCost.Sum,
  });
  return {
    ...report,
  };
}
