import { Badge, LabelTypography } from "../../../../shared-components";
import React from "react";
import styled from "styled-components";

type DivProps = React.HTMLAttributes<HTMLDivElement> & {
  isActive?: boolean;
};

const Row = styled.div`
  display: flex;
`;

export const NavContainer = styled(Row)`
  width: 100%;
  margin-top: 40px;
  margin-bottom: 16px;
  align-items: center;
`;

export const NavBadgesContainer = styled(Row)`
  column-gap: 8px;
  align-items: center;
`;

export const NavBadge: React.FC<DivProps> = ({
  isActive,
  children,
  ...props
}) =>
  React.createElement(
    Badge,
    {
      style: {
        backgroundColor: isActive ? "#0075E720" : "transparent",
        color: "red",
        cursor: "pointer",
      },
      ...props,
    },
    children
  );

export const NavUtilsContainer = styled(Row)`
  margin-left: auto;
  align-items: center;
  margin-top: 2px; // to center the dropdown within navbar
`;

export const SortLabel = styled(LabelTypography)`
  margin-right: 8px;
`;
