import {
  AssetProfileDefaultQueryEditorContainer,
  StickyContainer,
} from "../../../shared-components";
import styled from "styled-components";

const Row = styled.div`
  display: flex;
`;
export const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  padding-right: 20px;
  box-sizing: border-box;
`;

export const UtilsWrapper = styled(Row)`
  column-gap: 12px;
  align-items: center;
`;

export const UtilsContainer = styled(Row)`
  column-gap: 16px;
  align-items: center;
`;

export const QueryUsageSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const QueryUsageUtilsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
`;

export const SearchContainer = styled.div`
  width: 321px;
  display: inline-block;
`;

export const EditorContainer = styled(AssetProfileDefaultQueryEditorContainer)`
  height: 320px;
  flex-shrink: 0;
`;

export * from "./queryProfileRow";
export * from "./queryinfo";
export * from "./usedTablesAndFields";
export * from "./editQueryInfoModal";
export * from "./queryStatistics";
