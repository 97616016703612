import React from "react";
import styled from "styled-components";
import { Badge } from "../../../../../shared-components/Badge";
import { DefaultContainer } from "../../shared";

export const DefaultQueryContainer = styled(DefaultContainer)`
  height: 200px;
  :hover {
    cursor: pointer;
    background-color: #f1f1f120;
  }
`;

const QuerySectionDiv = styled.div`
  display: flex;
  flex-direction: column;
`;

export const QuerySectionDivider = styled.div`
  width: 1px;
  background-color: #f1f1f1;
  margin: 0 16px;
`;

export const QueryMetaContainer = styled.div`
  margin-top: 16px;
  > * {
    :nth-child(2) {
      margin-top: 4px;
    }
  }
`;

export const QueryMetaSection = styled(QuerySectionDiv)`
  width: 350px;
`;

export const QueryStringSection = styled(QuerySectionDiv)`
  width: 100%;
  height: fit-content;
`;

export const DefaultQueryBadge = () =>
  React.createElement(Badge, { backgroundColor: "#FDCEB9" }, "Query");
