/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/no-inferrable-types */
import React from "react";
import { TableRuntimeAnalyticsGraphLegendItems } from "../Table";
import Runtime from "../../../../../components/Runtime";
import { useUsageRuntimePerTable } from "./useUsageRuntimePerComponent";
import { GetActualTableNameFromTransformedTableName } from "./dataTransformer";
import { TextBoxWithToolTip } from "../../../../../components/TextHoldersWithToolTip";
import { setMaxString } from "../../../../../utils/helpers";
import { TypographySize } from "../../../../../types/styles.d";

const GraphAxisLeftConfig = {
  tickSize: 5,
  tickPadding: 0,
  renderTick: ({ x, y, value }) => {
    const tableName = GetActualTableNameFromTransformedTableName(value);
    return (
      <g transform={`translate(${x},${y})`}>
        <foreignObject x={x - 70} y={-10} width={70} height={20}>
          <TextBoxWithToolTip toolTipValue={tableName} size={TypographySize.sm}>
            {setMaxString(tableName ?? "", 8)}
          </TextBoxWithToolTip>
        </foreignObject>
      </g>
    );
  },
};

export const TableRuntimeAnalytics: React.FC = () => {
  const { data, getUsageRuntimePerTableData, loading } =
    useUsageRuntimePerTable();

  return (
    <Runtime
      loading={loading}
      graphProps={{
        indexBy: "TableName",
        axisLeft: GraphAxisLeftConfig,
      }}
      radius={0.1}
      legendMetric="minutes"
      legend={TableRuntimeAnalyticsGraphLegendItems}
      data={data ?? []}
      pageSize={5}
      header="Runtime per Table"
      refetchData={getUsageRuntimePerTableData}
      style={{ width: "45%", height: "100%" }}
    />
  );
};
