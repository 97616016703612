import logo from "./logo.png";
import chevronLeft from "./chevronLeft.svg";
import chevronBottom from "./ChevronBottom.svg";
import closeIcon from "./x.svg";
import backButton from "./arrow-left-circle.svg";
import circlePlus from "./circle-plus.svg";
import copyIcon from "./copy-icon.svg";
import searchIcon from "./search.svg";
import comingSoonIcon from "./coming-soon.svg";
import messageIcon from "./message.png";
import messageAddIcon from "./message-add.png";
import checkIcon from "./check.png";
import closeIcon2 from "./close.png";
import filterIcon from "./filter.svg";
import fileIcon from "./file_icon.svg";
import menuIcon from "./menu.svg";
import allListsIcon from "./list-icon.svg";
import primarySearchIcon from "./searchPrimary.svg";
import usersManyPrimary from "./usersManyPrimary.svg";
import emptyFolder from "./empty_folder.svg";
import copyLinkIcon from "./link.svg";
import chevronBottomBlack from "./chevronDownBlack.svg";
import ArrowRight from "./arrow-right.png";
import HelpIcon from "./help-icon.svg";
import sendIcon from "./sendIcon.svg";
import LoginSVGPlaceholder from "./login-svg-placeholder.svg";
import resetIcon from "./reset-icon.svg";
import currencyIcon from "./currency_cost.svg";
import clockIcon from "./time_icon.svg";
import rotatingIcon from "./rotating-icon.svg";
import chartIcon from "./chart-icon.svg";
import arrowUpRedIcon from "./arrow-red-up.svg";
import arrowDownGreenIcon from "./arrow-green-down.svg";
import trendingUpIcon from "./trending-up.svg";
import trendingDownIcon from "./trending-down.svg";
import RightArrow from "./arrow-right.svg";
import User from "./user.svg";

export default {
  logo,
  User,
  backButton,
  closeIcon,
  chevronLeft,
  chevronBottom,
  circlePlus,
  copyIcon,
  searchIcon,
  comingSoonIcon,
  messageIcon,
  messageAddIcon,
  checkIcon,
  closeIcon2,
  filterIcon,
  fileIcon,
  menuIcon,
  allListsIcon,
  primarySearchIcon,
  usersManyPrimary,
  emptyFolder,
  copyLinkIcon,
  chevronBottomBlack,
  ArrowRight,
  LoginSVGPlaceholder,
  HelpIcon,
  sendIcon,
  resetIcon,
  currencyIcon,
  clockIcon,
  rotatingIcon,
  chartIcon,
  arrowUpRedIcon,
  arrowDownGreenIcon,
  trendingDownIcon,
  trendingUpIcon,
  RightArrow,
};
