import { DialogProps } from "@mui/material";
import React from "react";
import { Dialog } from "./component";

export interface ModalProps extends DialogProps {
  tintColor?: "default" | "success" | "error";
}

export const Modal: React.FC<ModalProps> = ({ children, ...rest }) => {
  return <Dialog {...rest}>{children}</Dialog>;
};
