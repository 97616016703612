import { ClientConstructorType } from "./interface";

export const createBaseHttpClient = (
  baseURL: string,
  clientConstructor: ClientConstructorType
) => {
  if (!baseURL)
    console.warn("There is no base url. Include the server url in app setup"); // throw warning when server url env has not been set
  return clientConstructor.create(baseURL);
};
