import { makeGetUserCompanyInfo } from "../../application";
import { authActions } from "../../infrastructure";
import { useGlobalStateDispatch } from "../redux";

export const useInitUserCompanyUsers = () => {
  const dispatch = useGlobalStateDispatch();

  const exec = async () => {
    try {
      const { companyUsers: users, companyStructure } =
        await makeGetUserCompanyInfo();
      dispatch(
        authActions.syncUserCompanyData({
          users,
          companyStructure,
        })
      );
    } catch (error) {
      // silently. Dispatch to analytics client
    }
  };

  return {
    exec,
  };
};
