import { debounce } from "../../ui/lib";
import { useCallback, useEffect, useState } from "react";

export const useAssetReadMe = ({ onUpdate, value: defaultValue }) => {
  const [value, setValue] = useState("");
  const [open, setOpenState] = useState(true);

  const updateReadMe = useCallback(
    debounce((input) => onUpdate(input)),
    []
  );

  const onChangeReadme = (e: any) => {
    setValue(e.target.value);
    updateReadMe(e.target.value);
  };

  useEffect(() => {
    if (defaultValue) {
      if (defaultValue !== value) setValue(defaultValue); // to set the initial value of the readme when there is a default value
    }
  }, [defaultValue]);

  return {
    onChangeReadme,
    value,
    open,
    setOpenState,
  };
};
