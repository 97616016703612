import { ITable } from "../../../../../core";
import {
  DefaultTableBadge,
  DefaultTableContainer,
  DefaultTableName,
} from "./components";
import { useTableComponent } from "../../../../../hooks";
import { TableDataContentRow } from "../../../../shared-components";
import { RootState, useReduxSelector } from "../../../../../../redux";

export const DefaultTable: React.FC<{ data: ITable }> = ({ data }) => {
  const { onClickTable, ...tableData } = useTableComponent(data);
  const state = useReduxSelector((state: RootState) => state);
  const globalUser = state.auth.details;
  if (
    tableData.tableName === "<unknown>" ||
    (globalUser.CompanyId != 1 && tableData.dbName === "public")
  ) {
    return <></>;
  } else {
    return (
      <DefaultTableContainer onClick={onClickTable}>
        <DefaultTableBadge />
        <DefaultTableName variant="p">{`${tableData.dbName}.${tableData.tableName}`}</DefaultTableName>
        <TableDataContentRow data={tableData} />
      </DefaultTableContainer>
    );
  }
};
