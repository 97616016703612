export const SupportedCostsReportCompanies = ["sherloq", "armis"];

const userCompanies = [
  "armis",
  "appsflyer",
  "sherloq",
  "lighttricks",
  "public",
] as const;

export interface IUser {
  Username: string;
  Token: string;
  ExpirationDateTime: string;
  SherloqUserId: number;
}

export interface IUserDetails {
  Username?: string;
  Email?: string;
  FirstName?: string;
  LastName?: string;
  Role?: string;
  SherloqUserId?: number;
  CompanyId?: number | string;
  exp?: number;
  iss?: string;
  aud?: string;
}

export type UserCompanyType = typeof userCompanies[number];
