import { serverLogin } from "../services/serverApi";
import { IUser } from "../types/user";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Mixpanel } from "../mixpanelWrapper";
import { GetUserInfo } from "../services/getUserInfo";
import { toast } from 'react-toastify'
import { loginWithGoogleService } from "../services/authServices";

type AuthState = {
  isLoggedIn: boolean;
  loading: boolean;
  user: IUser;
  details: any;
  routeBeforeAuth: string;
  error?: string;
};
type loginObject = {
  username: string;
  password: string;
};

const initialState: AuthState = {
  isLoggedIn: false,
  loading: false,
  user: {},
  details: {},
  error: undefined,
  routeBeforeAuth: '/dashboard/search'
};
export const userLogin = createAsyncThunk("user/getUser", async ({ username, password }: loginObject, thunkApi) => {
  try {
    const data = await serverLogin(username, password);
    if (data?.Token) {
      const { FirstName, LastName, Email } = GetUserInfo(data.Token);
      localStorage.setItem("user", JSON.stringify(data));
      Mixpanel.identify(username);
      Mixpanel.track("main_log_in_success");
      Mixpanel.people.set({
        $first_name: FirstName,
        $last_name: LastName,
        $email: Email
      });

      return data;
    } else {
      Mixpanel.identify(username);
      Mixpanel.track("main_log_in_fail");
      throw data;
    }
  } catch (e: any) {
    Mixpanel.identify(username);
    Mixpanel.track("main_log_in_fail");
    toast.error(e?.data || 'Something Went Wrong')
    return thunkApi.rejectWithValue(e as ErrorEventInit);
  }
});
export const googleUserLogin = createAsyncThunk("user/getGoogleUser", async ({ bearer }: {bearer: string}, thunkApi) => {
  try {
    const data = await loginWithGoogleService(bearer);
    if (data?.Token) {
      const { FirstName, LastName, Email } = GetUserInfo(data.Token);
      localStorage.setItem("user", JSON.stringify(data));
      Mixpanel.identify(`${FirstName} ${LastName}`);
      Mixpanel.track("main_log_in_success_google");
      Mixpanel.people.set({
        $first_name: FirstName,
        $last_name: LastName,
        $email: Email
      });
      return data;
    } else {
      Mixpanel.identify(bearer);
      Mixpanel.track("main_log_in_fail_google");
      throw data;
    }
  } catch (e: any) {
    Mixpanel.identify(bearer);
    Mixpanel.track("main_log_in_fail_google");
    toast.error(e?.data || 'Something Went Wrong')
    return thunkApi.rejectWithValue(e as ErrorEventInit);
  }
});

export const authSlice = createSlice({
  name: "Auth",
  initialState,
  reducers: {
    setLogin(state, action: PayloadAction<any>) {
      state.isLoggedIn = action.payload;
    },
    setUserDetails(state, action: PayloadAction<any>) {
      state.details = action.payload;
    },
    setRouteBeforeAuth(state, action: PayloadAction<any>) {
      state.routeBeforeAuth = action.payload;
    },
  },
  extraReducers: (builder) => {
    return builder.addCase(googleUserLogin.pending, (state) => {
      state.loading = true;
    }),
      builder.addCase(googleUserLogin.fulfilled, (state, { payload }) => {
        const details = GetUserInfo(payload.Token);
        state.error = undefined;
        state.loading = false;
        state.user = payload;
        state.details = details;
        state.isLoggedIn = true;
      }),
      builder.addCase(googleUserLogin.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = (payload as ErrorEventInit).error;
        state.isLoggedIn = false;
      });
  },
});

export const userReducer = authSlice.reducer;
export const userActions = authSlice.actions;
