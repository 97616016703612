import * as Styled from "../Table.styled";
import searchPageImages from "../../../../../assets/images/search";
import { TypographySize } from "../../../../../types/styles.d";
import { AppTypography } from "../../../../../styles/global";
import { Tooltip } from "@material-ui/core";
import {
  formatBytes,
  formatSeconds,
  setMaxString,
} from "../../../../../utils/helpers";
import { UsageUsedQueryDataType } from "./useUsageUsedQueries";

interface UsageUsedQueriesRowProps {
  data: UsageUsedQueryDataType;
  onClickQuery?: (query: UsageUsedQueryDataType) => void;
}

export const UsageUsedQueriesRow = ({
  data,
  onClickQuery,
}: UsageUsedQueriesRowProps) => {
  const {
    AverageCost,
    AverageDataScanned,
    AverageRuntime,
    NumberOfRuns,
    // TableReferences,
    Query,
  } = data;

  return (
    <Styled.TableRow>
      <Styled.TableColumn>
        <Styled.Row justifyContent="start">
          <Styled.SmallTableIcon src={searchPageImages.QueryIcon} />
          <Tooltip title={Query ?? ""} placement="top-start">
            <Styled.QueryTextOverflow onClick={() => onClickQuery?.(data)}>
              <AppTypography underline clickable size={TypographySize.sm}>
                {Query ?? ""}
              </AppTypography>
            </Styled.QueryTextOverflow>
          </Tooltip>
        </Styled.Row>
      </Styled.TableColumn>
      <Styled.TableColumn>
        <AppTypography size={TypographySize.sm}>
          {Number(AverageCost ?? 0) >= 0.00095
            ? "$" +Number(AverageCost ?? 0).toFixed(3)
            : "~0"}
        </AppTypography>
      </Styled.TableColumn>
      <Styled.TableColumn>
        <AppTypography size={TypographySize.sm}>
          {AverageRuntime ? formatSeconds(AverageRuntime ?? 0) : "N/A"}
        </AppTypography>
      </Styled.TableColumn>
      <Styled.TableColumn>
        <AppTypography size={TypographySize.sm}>{NumberOfRuns}</AppTypography>
      </Styled.TableColumn>
      <Styled.TableColumn>
        <AppTypography size={TypographySize.sm}>
          {formatBytes(AverageDataScanned ?? 0) ?? "no data_scanned"}
        </AppTypography>
      </Styled.TableColumn>
      {/* (<Styled.TableColumn
        style={{
          padding: 0,
        }}
      >
        <Styled.Row justifyContent="start">
          {/* {TableReferences ? (
            <>
              <Styled.SmallTableIcon src={searchPageImages.DBTableIcon} />
              <AppTypography size={TypographySize.sm}>
                {setMaxString(TableReferences ?? "", 12)}
              </AppTypography>
            </>
          ) : (
            <AppTypography size={TypographySize.sm}>N/A</AppTypography>
          )} */}
        {/* </Styled.Row> */}
      {/* </Styled.TableColumn>) */}
    </Styled.TableRow>
  );
};
