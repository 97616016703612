import { useUsersRuntimePerUsers } from "../../../../../v2/hooks";
import Runtime from "../../../../../components/Runtime";
import theme from "../../../../../styles/theme";

const { graphLegend } = theme;

const TableRuntimeAnalyticsGraphLegendItems = [
  { label: "0-0.5", color: graphLegend.best },
  { label: "0.5-1", color: graphLegend.good },
  { label: "1-3", color: graphLegend.ok },
  { label: "3-5", color: graphLegend.warn },
  { label: "5-10", color: graphLegend.bad },
  { label: "10+", color: graphLegend.worst },
];

export const RuntimePerUser = () => {
  const {
    data: userData,
    getRuntimePerUser,
    loading,
  } = useUsersRuntimePerUsers();

  return (
    <Runtime
      loading={loading}
      graphProps={{
        indexBy: "UserName",
      }}
      radius={0.1}
      legendMetric="minutes"
      legend={TableRuntimeAnalyticsGraphLegendItems}
      data={userData ?? []}
      pageSize={5}
      header="Runtime per User"
      refetchData={getRuntimePerUser}
      style={{ width: "637px", marginTop: "67px" }}
    />
  );
};
