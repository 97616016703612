import React from "react";
import * as Styled from "./Usage.styled";
import MostJoinedTablesTable from "../Tables/MostJoinedTablesTable";
import BarChartComponent from "../../../../components/Charts/Bar";
import CardComponent, {
  CardComponentHeader,
} from "../../../../components/card/CardComponent";
import useUsage from "./useUsage";
import TrendingTables from "../Tables/TrendingTables";
import Loading from "../../../../components/Loading/Loading";
import { AppTypography } from "../../../../styles/global";
import { TypographyBold, TypographySize } from "../../../../types/styles.d";
import theme from "../../../../styles/theme";
import { UsageUsedTables } from "../Tables/UsageUsedTables";
import { UsageUsedQueriesTable } from "../Tables/UsageUsedQueriesTable";
import { TableRuntimeAnalytics } from "../Tables/TableRuntimeAnalytics";
import { useReduxSelector, RootState } from "../../../../redux";
function Usage() {
  const { details } = useReduxSelector((state: RootState) => state.auth);
  const {
    topFiveTablesUsedPastMonth,
    loadingTopFiveTablesUsedPastMonth,
    topFiveJoins,
    loadingTopFiveJoins,
    trendingTables,
    loadingTrendingTables,
    getTrendingTablePerTimeFrame,
  } = useUsage();

  return (
    <>
      <UsageUsedTables />
      <Styled.Row style={{ columnGap: "40px" }}>
        <MostJoinedTablesTable
          loading={loadingTopFiveJoins}
          data={topFiveJoins}
          style={{ width: "55%", height: "inherit" }}
        />
        <TableRuntimeAnalytics />
      </Styled.Row>
      <Styled.Row>
        <UsageUsedQueriesTable />
      </Styled.Row>
      <Styled.Row style={{ justifyContent: "flex-start" }}>
        {/* <TrendingTables
          recallAPI={getTrendingTablePerTimeFrame}
          loading={loadingTrendingTables}
          data={trendingTables}
        /> */}
        {/* <CardComponent
          padding="20px 20px 0 20px"
          radius={1}
          overflowVisible="scroll"
        >
          <>
            <CardComponentHeader>
              <AppTypography
                wrap="true"
                bold={TypographyBold.lg}
                size={TypographySize.md}
                textColor={theme.appColors.dark}
              >
                {"Most Used Tables In the past month"}
              </AppTypography>
            </CardComponentHeader>
            {loadingTopFiveTablesUsedPastMonth && (
              <Loading size={20} height={5} />
            )}
            {topFiveTablesUsedPastMonth.length >= 0 && (
              <Styled.MostUsedTableGraph>
                <BarChartComponent data={topFiveTablesUsedPastMonth} />
              </Styled.MostUsedTableGraph>
            )}
          </>
        </CardComponent> */}
      </Styled.Row>
    </>
  );
}

export default Usage;
