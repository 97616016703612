import styled from "styled-components";

interface ContainerProps {
  align?: React.CSSProperties["alignItems"];
  justify?: React.CSSProperties["justifyContent"];
}

const BaseContainer = styled.div<ContainerProps>`
  display: flex;
  width: 100%;
  justify-content: ${({ justify }) => justify};
  align-items: ${({ align }) => align};
`;

export const LoadingContainer = styled.div`
  height: 300px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const ColumnContainer = styled(BaseContainer)<ContainerProps>`
  flex-direction: column;
`;

export const RowContainer = styled(BaseContainer)<ContainerProps>`
  flex-direction: row;
`;

export const Heading = styled.div`
  display: flex;
  flex-direction: column;
  margin: 35px 20px;
  width: 100%;
`;

export const EmptyListContainer = styled.div`
  margin: 50px 0;
`;
