import {
  RootSideViewContainer,
  Typography,
} from "../../../../../shared-components";
import styled from "styled-components";

export const Header = styled.header`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  padding-right: 14px;
`;

export const RootHeaderText = styled(Typography)`
  display: inline;
`;

export const CancelIcon = styled.img`
  :hover {
    cursor: pointer;
  }
`;

export const Content = styled.tbody`
  padding-top: 50px;
  width: 100%;
  overflow-y: scroll;
  row-gap: 0;
`;

export const TableContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  padding-bottom: 24px;
  overflow-y: auto;
  box-sizing: border-box;
  overflow-x: hidden;
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

export const TableHeader = styled.th`
  position: sticky;
  top: 0;
  display: grid;
  grid-template-columns: repeat(11, 1fr);
  column-gap: 10px;
  width: 100%;
  background-color: #f1f1f1;
  height: 40px;
  align-items: center;
  padding: 0 5px;
`;

export const Col = styled.div`
  display: flex;
  flex-direction: column;
  column-gap: 4px;
`;

export const Container = styled(RootSideViewContainer)<{ open?: boolean }>`
  padding: 24px 8px 8px 24px;
  width: 1124px;
`;

export const FilterContainer = styled.div`
  display: flex;
  column-gap: 40px;
`;

export const RulesWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TotalRefsContainer = styled.div`
  margin: 24px 0 16px 0;
`;

export * from "./topFieldsRow";
