import { TypographyProps } from "@sherloq/design-system";
import React from "react";
import styled from "styled-components";
import { LabelTypography } from "../../Typography";

export * from "./chip";

export const ChipContainer = styled.div`
  display: flex;
  gap: 8px;
  width: 100%;
  flex-wrap: wrap;
`;
const LabelTypographyRoot = styled(LabelTypography)`
  margin-bottom: 8px;
`;
export const Label = ({ children, ...props }: TypographyProps) =>
  React.createElement(
    LabelTypographyRoot,
    { variant: "small", ...props },
    children
  );
