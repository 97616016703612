/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Box } from "@mui/material";
import { useTableProfile } from "../../../hooks";
import { RenderIf } from "../../lib";
import {
  AssetProfileContentWrapper,
  AssetProfileDescription,
  AssetProfileName,
  AssetProfileContentUsageSectionHeader,
  Badge,
  TopHeader,
  Typography,
  AssetProfileContentSectionTextLink,
  ListFallBack,
  ReadMe,
  StickyContainer,
  AssetProfileStickyHeaderComponent,
  SharedAssetProfileUtils,
  TypographyWithToolTip,
} from "../../shared-components";
import {
  EditTableInfoModal,
  FieldTableRow,
  LastQueryRun,
  MostCommonFields,
  MostJoinedTablesAndFields,
  MostJoinedTablesAndFieldsRow,
  MostUsedQuery,
  QueryUsageContentContainer,
  QueryUsageSideView,
  ReadMeContainer,
  TableProfileContainer,
  TableProfileRow,
  TableStatisticsSideView,
  TableTextContainer,
  TableUsageContainer,
  TableUtilsWrapper,
  TopFields,
  TopFieldsSideViewComponent,
  TopUsers,
} from "./components";
import { MostJoinedFieldsBreakdownSideViewComponent } from "./components/mostJoinedSideView";
import { setMaxString } from "../../lib";

export const TableProfile = () => {
  const {
    openEditTableProfileModal,
    openQueriesUsageSideView,
    openTopFieldsSideView,
    openTableStatisticsSideView,
    openMostJoinedFieldsSideView,
    setOpenEditTableProfileModal,
    setOpenQueriesUsageSideView,
    setOpenTopFieldsSideView,
    setOpenTopusersSideView,
    setOpenTableStatisticsSideView,
    setOpenMostJoinedFieldsBreakdownSideView,
    tableProfile,
    minTopFields,
    minTopUsers,
    mostCommonFieldsByFieldData,
    id,
    onUpdateTableInfo,
    tableDescription,
    loadingTableProfile,
    failedToLoadTableProfile,
    onShareTable,
    tableIsInUserList,
    removeTableFromUserListAsync,
    addTableToUserList,
    updateTableProfileReadMe,
    readMe,
    tableOwner,
    minMostJoinedTablesAndFields,
    componentRef,
    isSticky,
    displayedTableName,
  } = useTableProfile();

  return (
    <TableProfileContainer ref={componentRef}>
      <StickyContainer>
        <TopHeader
          headerUtils={RenderIf(
            isSticky,
            <SharedAssetProfileUtils
              asset="table"
              inMyList={tableIsInUserList!}
              onClickEdit={() => setOpenEditTableProfileModal(true)}
              onClickMyListOperation={
                tableIsInUserList
                  ? removeTableFromUserListAsync
                  : addTableToUserList
              }
              onClickShare={onShareTable}
            />
          )}
          showBack={true}
        />
      </StickyContainer>
      <TableUtilsWrapper>
        <Badge backgroundColor="#0075E720">Table</Badge>
        <SharedAssetProfileUtils
          asset="table"
          inMyList={tableIsInUserList!}
          onClickEdit={() => setOpenEditTableProfileModal(true)}
          onClickMyListOperation={
            tableIsInUserList
              ? removeTableFromUserListAsync
              : addTableToUserList
          }
          onClickShare={onShareTable}
        />
      </TableUtilsWrapper>
      {RenderIf(
        loadingTableProfile,
        <ListFallBack
          isLoading={loadingTableProfile}
          isError={failedToLoadTableProfile}
        />
      )}
      {RenderIf(
        !loadingTableProfile && Object.keys(tableProfile).length > 0,
        <>
          <AssetProfileStickyHeaderComponent>
            <TableTextContainer>
              <AssetProfileName>{displayedTableName}</AssetProfileName>
              {RenderIf(
                !isSticky,
                <AssetProfileDescription>
                  {tableDescription}
                </AssetProfileDescription>
              )}
            </TableTextContainer>
          </AssetProfileStickyHeaderComponent>
          <TableProfileRow
            data={{ ...tableProfile!.tableProfileRow, tableOwner }}
          />
          <AssetProfileContentWrapper>
            <ReadMeContainer>
              <ReadMe onUpdate={updateTableProfileReadMe} value={readMe} />
            </ReadMeContainer>
            <div>
              <AssetProfileContentUsageSectionHeader>
                <Typography color="#707070" variant="h3">
                  Table Usage
                </Typography>
                <AssetProfileContentSectionTextLink
                  onClick={() => setOpenTableStatisticsSideView(true)}
                >
                  View table statistics & unused fields
                </AssetProfileContentSectionTextLink>
              </AssetProfileContentUsageSectionHeader>
              <div>
                <TableUsageContainer>
                  <TopFields
                    numberOfItems={tableProfile?.topFields?.length ?? 0}
                    onClickViewAll={() => setOpenTopFieldsSideView(true)}
                  >
                    {minTopFields?.map(({ fieldName, value, fieldDescription }, idx) => (
                      <FieldTableRow value={`${value}%`} key={idx}>
                        <Box>
                        <Typography variant="p-light">{fieldName}</Typography>
                        <TypographyWithToolTip toolTipValue={fieldDescription} variant="small" color="#707070">{setMaxString(fieldDescription, 50)}</TypographyWithToolTip>
                        </Box>
                      </FieldTableRow>
                    ))}
                  </TopFields>
                  <TopUsers
                    onClickViewAll={() => setOpenTopusersSideView(true)}
                    data={minTopUsers}
                  />
                </TableUsageContainer>
                <TableUsageContainer style={{ marginTop: "40px" }}>
                  <MostCommonFields
                    operatorData={tableProfile?.mostCommonFieldsByOperator}
                    fieldsData={mostCommonFieldsByFieldData}
                  />
                  <MostJoinedTablesAndFields
                    onClickViewBreakDown={() =>
                      setOpenMostJoinedFieldsBreakdownSideView(true)
                    }
                  >
                    {minMostJoinedTablesAndFields?.map((data, key) => (
                      <MostJoinedTablesAndFieldsRow key={key} data={data} />
                    ))}
                  </MostJoinedTablesAndFields>
                </TableUsageContainer>
              </div>
              <AssetProfileContentUsageSectionHeader>
                <Typography color="#707070" variant="h3">
                  Query Usage
                </Typography>
                <AssetProfileContentSectionTextLink
                  onClick={() => setOpenQueriesUsageSideView(true)}
                >
                  View all
                </AssetProfileContentSectionTextLink>
              </AssetProfileContentUsageSectionHeader>
              <QueryUsageContentContainer>
                <MostUsedQuery data={tableProfile!.mostUsedQuery} />
                <LastQueryRun data={tableProfile!.lastQueryRun} />
              </QueryUsageContentContainer>
            </div>
          </AssetProfileContentWrapper>
        </>
      )}
      <TopFieldsSideViewComponent
        onClose={() => setOpenTopFieldsSideView(false)}
        open={openTopFieldsSideView}
        tableData={{ tableName: tableProfile.tableName }}
      />
      <MostJoinedFieldsBreakdownSideViewComponent
        onClose={() => setOpenMostJoinedFieldsBreakdownSideView(false)}
        open={openMostJoinedFieldsSideView}
        tableData={{ tableName: tableProfile.tableName }}
        data={tableProfile.mostJoinedTablesAndFields}
      />
      {RenderIf(
        !!id,
        <EditTableInfoModal
          onClose={() => setOpenEditTableProfileModal(false)}
          open={openEditTableProfileModal}
          data={{
            tableId: id!,
            tableName: tableProfile.tableName,
            description: tableDescription,
            tableOwner,
          }}
          onSuccess={onUpdateTableInfo}
        />
      )}
      <QueryUsageSideView
        onClose={() => setOpenQueriesUsageSideView(false)}
        open={openQueriesUsageSideView}
      />
      {RenderIf(
        !!tableProfile?.tableStatistics,
        <TableStatisticsSideView
          data={tableProfile?.tableStatistics}
          open={openTableStatisticsSideView}
          onClose={() => setOpenTableStatisticsSideView(false)}
        />
      )}
    </TableProfileContainer>
  );
};
