import { transformAssetDate, transformAssetUsername } from "../../core";
import {
  formatAssetDataScanned,
  formatAssetDuration,
  formatCost,
} from "../asset";
import {
  IQueryProfileRow,
  IQueryProfileUsedQueriesAndFieldsData,
  ITransformedQueryProfile,
} from "./interfaces";

const transformTablesAndFieldsUsed = (data: string[]) => {
  if (!data) return [];

  const resultsAsObj: any = data.reduce((acc, prev) => {
    const [fieldStr, tableStr] = prev.split(",");
    const tableName = tableStr.slice(6);
    const fieldName = fieldStr.slice(6);
    if (acc[tableName] !== undefined) {
      acc[tableName].push(fieldName);
    } else {
      acc[tableName] = [fieldName];
    }
    return acc;
  }, {});
  const results: IQueryProfileUsedQueriesAndFieldsData[] = [];

  for (const table in resultsAsObj) {
    const fields = resultsAsObj[table]?.join(", ");
    results.push({ table, fields });
  }
  return results;
};

const createQueryName = (queryId: string, GivenName: any[]) => {
  if (Array.isArray(GivenName) && GivenName.length > 0) {
    return GivenName[0]?.input;
  } else {
    return `query_${queryId}`;
  }
};

export const transformQueryProfileFactory = (data: any) => {
  const { QueryInfo, Description, GivenName, ReadMe } = data;
  const {
    queryString,
    query_source,
    queryId,
    lastReferenceDatetime,
    lastUsernameThatReferenced,
    listOfTablesAndFieldsNamesAccessed,
    numOfReferences,
    lastQueryDataScannedInBytes,
    lastQueryDurationInMillis,
    queryCost,
  } = QueryInfo?.[0] ?? {};
  const queryDescription = Description[0]?.input;
  const readme = ReadMe ? ReadMe[0]?.input : "";
  const queryName = createQueryName(queryId, GivenName);
  const lastRunAt = transformAssetDate(lastReferenceDatetime);
  const lastRunBy = transformAssetUsername(lastUsernameThatReferenced);
  const queryProfileRow: IQueryProfileRow = {
    createdBy: "-",
    lastRunAt,
    lastRunBy,
    source: query_source,
  };
  const transformedData: ITransformedQueryProfile = {
    queryDescription,
    queryId,
    queryName,
    queryString,
    queryProfileRow,
    readme,
    usedTablesAndFields: transformTablesAndFieldsUsed(
      listOfTablesAndFieldsNamesAccessed
    ),
    queryStats: {
      dataScanned: formatAssetDataScanned(lastQueryDataScannedInBytes),
      numReferences: numOfReferences,
      runtime: formatAssetDuration(lastQueryDurationInMillis),
      cost: formatCost(queryCost, 2),
    },
  };

  return transformedData;
};
