import {
  getFilterQueryConditionsFromColumn,
  getFilterTableConditionsFromColumn,
  makeUser,
} from "../../core";
import { useCallback, useEffect, useMemo } from "react";
import {
  AppRoutes,
  QueryDropdownRules,
  TableDropdownRules,
} from "../../application";
import { useLocation } from "react-router-dom";
import { useHomeGlobalState } from "./homeReducerHooks";
import { useAssetFilters } from "../useAssetFilters";
import { useGlobalAuthState } from "../user";

export const useFilterSideView = () => {
  const { filters: defaultFilter } = useHomeGlobalState();
  const { user } = useGlobalAuthState();
  const {
    addRule,
    filter,
    onChangeLastQueriedBy,
    onChangeQueriedIn,
    onUpdateRules: onChangeFilterRule,
    removeRule,
    resetFilters,
    setFilter,
    getDropdownRuleValues,
  } = useAssetFilters();
  const location = useLocation();

  const currentSubPage = useMemo(() => {
    if (
      location.pathname === `/${AppRoutes.HOME}/${AppRoutes.HOME_QUERY_RESULTS}`
    ) {
      return "query";
    } else return "table";
  }, [location.pathname]);

  const getRuleConditions = useCallback(
    (id: string) => {
      const rule = filter.rules[id];
      if (currentSubPage === "query" && rule.column)
        return getFilterQueryConditionsFromColumn(rule.column) ?? [];
      if (currentSubPage === "table" && rule.column)
        return getFilterTableConditionsFromColumn(rule.column);
      return [];
    },
    [filter.rules, currentSubPage]
  );

  const getRuleIsDropdown = useCallback(
    (id: string) => {
      const rule = filter.rules[id];
      if (rule?.column && currentSubPage === "table") {
        return TableDropdownRules.includes(rule.column);
      } else if (rule?.column && currentSubPage === "query") {
        return QueryDropdownRules.includes(rule.column);
      }
      return false;
    },
    [currentSubPage, filter.rules]
  );

  const filterColumns = useMemo(() => {
    if (!user) return [];
    const SherloqUserInstance = makeUser(user);
    switch (currentSubPage) {
      case "query":
        return SherloqUserInstance.queryRulesColumns;
      case "table":
        return SherloqUserInstance.tableRulesColumns;
      default:
        return [];
    }
  }, [currentSubPage, user]);

  const onUpdateRules = (id, updatedRule) => {
    const rule = { ...updatedRule };
    let conditions: any[];
    if (currentSubPage === "query") {
      conditions = getFilterQueryConditionsFromColumn(rule.column);
    } else {
      conditions = getFilterTableConditionsFromColumn(rule.column);
    }

    if (!rule?.condition) {
      rule.condition = conditions[0]?.value; // set condition if there is none
    } else if (rule.condition && !conditions.includes(rule.condition)) {
      rule.condition = conditions[0]?.value; // if column changes but there is a default condition that isn't in the rules conditions, set it to a default condition
    }
    onChangeFilterRule(id, rule);
  };

  useEffect(() => {
    setFilter(defaultFilter); // to reset the filters when the subPages change. a syncFilters to reset homepage filters is dispatched before navigation to subpages
  }, [defaultFilter]);

  return {
    addRule,
    filter,
    onChangeQueriedIn,
    onChangeLastQueriedBy,
    onUpdateRules,
    removeRule,
    resetFilters,
    filterColumns,
    getRuleConditions,
    getDropdownRuleValues,
    getRuleIsDropdown,
  };
};
