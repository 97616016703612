import styled from "styled-components";

export const DefaultContainer = styled.div`
  border: none;
  border-bottom-width: 1px;
  border-bottom-color: #f1f1f1;
  border-bottom-style: solid;
  display: flex;
  padding: 16px;
`;
