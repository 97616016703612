import { ToastContainer } from "react-toastify";

export const RootNotificationComponent = () => {
  return (
    <ToastContainer
      position="top-right"
      autoClose={4000}
      newestOnTop={false}
      closeOnClick
    />
  );
};
