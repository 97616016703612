import {
  authHttpClient,
  extensionServerNoAuthHttpClient,
  noAuthHttpClient,
} from "../../infrastructure";
import { GoogleLoginData } from "./interface";

const googleLogin = async (bearer: string): Promise<GoogleLoginData> => {
  const payload = {
    bearer,
  };
  const [{ data: mainServerRes }, { data: extensionServerRes }] =
    await Promise.all([
      noAuthHttpClient.post("/Login/google", payload),
      extensionServerNoAuthHttpClient.post("accounts/auth/web-login", {
        token: bearer,
      }),
    ]);
  return { user: mainServerRes, extensionAuthUser: extensionServerRes };
};

const getUserRuntimePerTable = async (page: number) => {
  const path = "/v1/Analytics/getRuntimePerUser";
  const params = {
    page,
  };

  try {
    const { data } = await authHttpClient.get(path, { params });
    return await Promise.resolve(data);
  } catch (error: any) {
    return await Promise.reject(error);
  }
};

const getUsersUsageService = async (
  StartDate,
  EndDate,
  Page,
  Search,
  SortColumn,
  SortOrder
) => {
  const path = "/v1/Analytics/getTopUserUsage";
  const params = {
    StartDate,
    EndDate,
    Page,
    Search,
    SortColumn,
    SortOrder,
  };
  const { data } = await authHttpClient.get(path, { params });
  return data;
};

const getUserCompanyUsers = async (companyId: string | number) => {
  const path = "/v1/getdata/getAllUsers";

  const params = {
    companyId,
  };
  const { data } = await authHttpClient.get(path, { params });
  return data;
};

const getUserLists = async (sherloqUserId: number | string) => {
  const path = "/v1/getdata/getListsByUser";

  const params = {
    sherloqUserId,
  };
  const { data } = await authHttpClient.get(path, { params });
  return data;
};

const getUserCompanyStructure = async () => {
  const path = "/v1/GetData/getCompanyStructure";
  const { data } = await authHttpClient.get(path);
  return data;
};

export const userService = Object.freeze({
  googleLogin,
  getUserRuntimePerTable,
  getUsersUsageService,
  getUserCompanyUsers,
  getUserLists,
  getUserCompanyStructure,
});
