import { useState } from "react";
import { RuntimePerTableDataTransformer } from "./dataTransformer";
import { TableService } from "../../../../../v2/application";

export const useUsageRuntimePerTable = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>();

  const getUsageRuntimePerTableData = async (page: number) => {
    try {
      setLoading(true);
      const { Data } = await TableService.getRuntimePerTable(page);
      const transformedData = RuntimePerTableDataTransformer(Data);
      setData(transformedData);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  return {
    data,
    getUsageRuntimePerTableData,
    loading,
  };
};
