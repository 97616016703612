import React from "react";
import { RenderIf } from "../../../../lib";
import { LinkButton, LinkBreak, Container } from "./components";

interface BreadcrumbsProps {
  trace: string[];
}

export const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ trace }) => {
  return (
    <Container>
      {trace.map((path, idx, arr) => {
        return (
          <React.Fragment key={idx}>
            <LinkButton>{path}</LinkButton>
            {RenderIf(idx + 1 < arr.length, <LinkBreak />)}
          </React.Fragment>
        );
      })}
    </Container>
  );
};
