import { useSidebar } from "../../../hooks";
import React from "react";
import {
  MainMenuLine,
  MainSidebarContainer,
  MenuItem,
  MenuItemComponent,
} from "./components";
import { RenderIf } from "../../lib";

export const MainSidebar: React.FC = () => {
  const {
    activeAnalyticsIcon,
    activeHomeIcon,
    activeListsIcon,
    onClickCostsReport,
    onClickMyQueries,
    onClickHome,
    showCostReport,
  } = useSidebar();

  return (
    <MainSidebarContainer>
      <MenuItemComponent icon="icSherloqSearchIconLogo" />
      <MainMenuLine />
      <MenuItem
        tooltipValue="Home"
        icon={activeHomeIcon}
        hoverIcon="ic24mainHomeSelected"
        onClick={onClickHome}
        key={activeHomeIcon}
      />
      <MenuItem
        icon="ic24mainMyQueriesPreview"
        hoverIcon="ic24mainMyQueriesSelected"
        tooltipValue="Lists"
        onClick={onClickMyQueries}
        key={activeListsIcon}
      />
      {RenderIf(
        showCostReport,
        <MenuItem
          icon={activeAnalyticsIcon}
          hoverIcon="ic24mainCostReportPreview"
          tooltipValue="Cost Report"
          onClick={onClickCostsReport}
          key={activeAnalyticsIcon}
        />
      )}
    </MainSidebarContainer>
  );
};
