import axios from "axios";
import { toast } from "react-toastify";
const serverUrl = "https://appsflyer.sherloqdata.com/api/";

const http = axios.create({
  baseURL: serverUrl,
});

// Add the authorization header on requests
http.interceptors.request.use(
  (config: any) => {
    const user = JSON.parse(localStorage.getItem("user") ?? "{}");
    config.headers["X-Requested-With"] = "XMLHttpRequest";
    config.headers["accept"] = "application/json, text/plain, */*";
    if (!user?.Token) return;
    config.headers.Authorization = `Bearer ${user?.Token}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Redirect request if 401 is returned
http.interceptors.response.use(undefined, async (error) => {
  const errorResponse = error.response;
  if (!errorResponse) return;

  if (errorResponse && errorResponse.status === 401) {
    localStorage.clear();
    window.location.href = "/login";
    toast.error("Token Expired, please relogin");
    return Promise.resolve();
  }

  return Promise.reject(errorResponse);
});

const httpWithoutAuth = axios.create({
  baseURL: serverUrl,
});

// For requests without headers
httpWithoutAuth.interceptors.request.use(
  (config: any) => {
    config.headers["X-Requested-With"] = "XMLHttpRequest";
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

httpWithoutAuth.interceptors.response.use(undefined, (error) => {
  const errorResponse = error.response;
  return Promise.reject(errorResponse);
});

export default http;
export { httpWithoutAuth };
