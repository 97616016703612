import React, { useState } from "react";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-mysql";
import "ace-builds/src-noconflict/theme-textmate";
import {
  DialogCloseButton,
  SqlViewerContainer,
  SqlViewerModal,
  CopyContainer,
  CopyIcon,
  CopyButton,
} from "./SqlViewer.styled";
import globalImages from "../../assets/images/global";
import { Tooltip, Snackbar } from "@material-ui/core";
import { format } from "sql-formatter";
import { Mixpanel } from "../../mixpanelWrapper";

interface SqlViewProps {
  query: string;
  query_id?: string;
  show: boolean;
  onClose: (e: any) => void;
}

const determineSqlDialect = (query: string) => {
  let newQuery = "";
  query = query.replace(/\\'/g, "'");

  try {
    // Trying regular SQL
    newQuery = format(query);
    return newQuery;
  } catch {
    try {
      // Trying postgresql
      newQuery = format(`${query}`, { language: "postgresql" });
      return newQuery;
    } catch {
      try {
        // Trying bigquery
        newQuery = format(query, { language: "bigquery" });
        return newQuery;
      } catch {
        newQuery = query;
        return newQuery;
      }
    }
  }
};
export const formatQuery = (query: string) => {
  return determineSqlDialect(query)
    .replaceAll(" - ", "-")
    .replaceAll("@ ", "@")
    .replaceAll("* * * CENSORED * * *", "'***CENSORED***'")
    .replaceAll("| |", "||");
};

const SqlViewer: React.FC<SqlViewProps> = ({
  query_id,
  query,
  onClose,
  show,
}) => {
  const [openToast, setOpenToast] = useState(false);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(query);
    Mixpanel.track("main_copy_query_button_click", {
      query_id: query_id,
      location: location.pathname,
    });
    setOpenToast(true);
  };

  return (
    <SqlViewerModal onClose={onClose} open={show}>
      <SqlViewerContainer>
        <AceEditor
          placeholder="Placeholder Text"
          mode="mysql"
          theme="textmate"
          name=""
          fontSize={14}
          showPrintMargin={false}
          readOnly
          value={formatQuery(query)}
          wrapEnabled
          width="100%"
          setOptions={{
            enableBasicAutocompletion: false,
            enableLiveAutocompletion: false,
            enableSnippets: false,
            showLineNumbers: true,
            tabSize: 2,
          }}
        />
        <CopyContainer>
          <Tooltip title="Copy query" placement="top" arrow>
            <CopyButton onClick={copyToClipboard}>
              <CopyIcon src={globalImages.copyIcon} alt={"copy-icon"} />
              Copy
            </CopyButton>
          </Tooltip>
        </CopyContainer>
        <DialogCloseButton onClick={onClose} src={globalImages.closeIcon} />
        <Snackbar
          open={openToast}
          autoHideDuration={2000}
          onClose={() => setOpenToast(false)}
          message="Query successfully copied!"
        />
      </SqlViewerContainer>
    </SqlViewerModal>
  );
};

export default SqlViewer;
