/* eslint-disable @typescript-eslint/adjacent-overload-signatures */
import {
  AppsFlyerQueryFilterRulesColumns,
  PublicQueryFilterRulesColumns,
} from "../query";
import { AppsFlyerTableColumns, PublicTableFilterColumns } from "../table";
import { IUser, IUserDetails, UserCompanyType } from "./interfaces";
import { armisAdminValidator } from "./userAdminValidators";

export class User {
  constructor(
    public user: IUser,
    private tokenDecoder: (token) => IUserDetails
  ) {}

  get details() {
    return this.tokenDecoder(this.user.Token);
  }

  get isAdmin() {
    const company = this.company;
    if (company === "armis") return armisAdminValidator(this.details);
    return true;
  }

  get company(): UserCompanyType {
    const { CompanyId } = this.details;
    switch (CompanyId) {
      case "1":
        return "sherloq";
      case "2":
        return "appsflyer";
      case "3":
        return "armis";
      case "4":
        return "lighttricks";
      default:
        return "public";
    }
  }

  get tableRulesColumns() {
    const { company } = this;
    if (company === "appsflyer" || company === "sherloq")
      return AppsFlyerTableColumns;
    return PublicTableFilterColumns;
  }

  get queryRulesColumns() {
    const { company } = this;
    if (company === "appsflyer" || company === "sherloq") {
      return AppsFlyerQueryFilterRulesColumns;
    }
    return PublicQueryFilterRulesColumns;
  }
}
