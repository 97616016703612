import { defaultAssetFilter } from "../../../core";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  openFilterSideView: false,
  filters: defaultAssetFilter,
  searchKeyword: "",
  numberOfQueryResults: 0,
  numberOfTableResults: 0,
  sortBy: null,
};

const homeSlice = createSlice({
  name: "home",
  initialState,
  reducers: {
    syncSortBy: (state, action) => {
      state.sortBy = action.payload;
    },
    syncFilters: (state, action) => {
      state.filters = action.payload;
    },
    syncKeyword: (state, action) => {
      state.searchKeyword = action.payload;
    },
    toggleFilterSideView: (state, action) => {
      state.openFilterSideView = action.payload;
    },
    updateNumberOfQueryResults: (state, action) => {
      state.numberOfQueryResults = action.payload;
    },
    updateNumberOfTableResults: (state, action) => {
      state.numberOfTableResults = action.payload;
    },
    reset: (state, _) => {
      state = { ...initialState };
    },
  },
});

export const { reducer: homeReducer, actions: homeActions } = homeSlice;
