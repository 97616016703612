import { TableAnalyticsColumn } from "../../Tables/Table.styled";
import { DropdownDurationOptions } from "../../Tables/Table";
import {
  AnalyticsTable,
  AnalyticsTableHeaderType,
} from "../../../../../components/AnalyticsTable";
import { UserTableRow } from "./UserTableRow";
import { useGlobalAuthState, useUsersUsage } from "../../../../../v2/hooks";

const UsersUsageTableHeaders: AnalyticsTableHeaderType[] = [
  {
    header: "User name",
    sort: true,
    width: "15%",
    sortKeyword: "username",
  },
  {
    header: "User Usage",
    sort: true,
    width: "10%",
    subHeaders: ["# of Queries Ran"],
    sortKeyword: "numOfReferences",
  },
  {
    header: "Total Cost",
    sort: true,
    width: "10%",
    sortKeyword: "totalcost",
  },
  {
    header: "Cost",
    sort: true,
    width: "10%",
    subHeaders: ["Average"],
    sortKeyword: "averagecost",
  },
  {
    header: "Runtime",
    sort: true,
    width: "15%",
    subHeaders: ["Average"],
    sortKeyword: "averageruntime",
  },
  {
    header: "Data Scanned",
    sort: true,
    width: "15%",
    subHeaders: ["Average"],
    sortKeyword: "averagedatascanned",
  },
  {
    header: "Cost Ratio",
    subHeaders: ["%"],
    sort: true,
    width: "10%",
    sortKeyword: "totalcostpercentage",
  },
];

export const UsersUsageComponent = () => {
  const { details } = useGlobalAuthState();
  const { getTop5TableUsage, loading, usersUsageData } = useUsersUsage();

  return (
    <AnalyticsTable
      refetchData={getTop5TableUsage}
      dataPageSize={10}
      tableName="Users Usage"
      withDuration
      withSearch
      withSort
      tableContainerProps={{ style: { height: "530px" } }}
      durationOptions={
        details.CompanyId != 4
          ? (DropdownDurationOptions as any)
          : (DropdownDurationOptions as any)
      }
      containerProps={{
        showShadow: true,
        padding: "20px",
        radius: 1,
        style: { width: "936px" },
      }}
      tableColGroup={
        <>
          <TableAnalyticsColumn span={1}></TableAnalyticsColumn>
          <TableAnalyticsColumn span={1}></TableAnalyticsColumn>
          <TableAnalyticsColumn span={1}></TableAnalyticsColumn>
          <TableAnalyticsColumn span={1}></TableAnalyticsColumn>
          <TableAnalyticsColumn span={1}></TableAnalyticsColumn>
          <TableAnalyticsColumn span={1}></TableAnalyticsColumn>
        </>
      }
      loading={loading}
      tableHeaders={UsersUsageTableHeaders}
      data={usersUsageData ?? []}
      renderData={(data, key) => {
        return <UserTableRow {...{ data, key }} />;
      }}
    />
  );
};
