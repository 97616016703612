import { IRelevantTablesForQueries } from "../../services/models";

export const setMaxString = (str: string, length: number) => {
  if (!str) return "";
  if (str.length < length) return str;
  const substring = str.substring(0, length);
  return substring + "...";
};

export const joinArray = (arr: any[]) => {
  if (!Array.isArray(arr)) return "N/A";
  const join = arr.join(", ");
  return join.slice(0, -2);
};

export const getPercent = (str: number | string) => {
  if (!str) return "N/A";
  const round = Math.round(Number(str));
  return round + "%";
};

export const convertMillsecondsToSeconds = (seconds: number) => {
  return seconds / 1000;
};

export const convertBytesToKilobytes = (bytes: number) => {
  return bytes / 1000;
};

const groupByKey = (array, key) => {
  return array.reduce((hash, obj) => {
    if (obj[key] === undefined) return hash;
    return Object.assign(hash, {
      [obj[key]]: (hash[obj[key]] || []).concat(obj.fields),
    });
  }, {});
};

export const convertRelevantData = (listOfTablesAndFieldsNamesAccessed) => {
  const relevantTablesObj: IRelevantTablesForQueries[] = [];
  for (const item of listOfTablesAndFieldsNamesAccessed) {
    const tableName = item.substring(item.indexOf("table:") + 6, item.length);
    const fields = item.substring(
      item.indexOf("field:") + 6,
      item.indexOf(",")
    );

    relevantTablesObj.push({
      tableName,
      fields,
    });
  }
  const grouped = groupByKey(relevantTablesObj, "tableName");
  const relevantTables = Object.keys(grouped).map((el) => {
    return {
      tableName: el,
      fields: grouped[el],
    };
  });
  return relevantTables;
};
export const trimStringAtCharacter = (text: string, char: string) => {
  if (!text.includes(char)) return text;
  return text.substring(0, text.indexOf(char));
};

export const formatValue = (value?: string | number) => {
  if (!value) return null;
  return Number(value) > 1
    ? Number(value).toFixed(1)
    : Number(value).toFixed(3);
};
