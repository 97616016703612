import React from 'react'
import globalImages from '../../../../assets/images/global';
import styled from "styled-components";
import { AppTypography } from '../../../../styles/global';
import { TypographyBold, TypographySize } from '../../../../types/styles.d';

function EmptyList({ firstName, lastName }: { firstName?: string, lastName?: string}) {
const userName = `${firstName} ${lastName}`
  return (
    <EmptyListContainer>
        <AppTypography size={TypographySize.lg} bold={TypographyBold.lg}>The list is empty</AppTypography>
        <Desc>
            {firstName && lastName ? <AppTypography size={TypographySize.md} bold={TypographyBold.lg}>
                {userName} doesn&apos;t have any assets listed currently
            </AppTypography> :
            <AppTypography size={TypographySize.md}>
                You dont have any assets listed currently
            </AppTypography>
            }

            { firstName && lastName? <AppTypography size={TypographySize.md}>
                You can share anything that might be relevant to {firstName}
            </AppTypography> :
            <AppTypography size={TypographySize.md}>
                You can add anything that is relevant to you.
            </AppTypography>
            }
        </Desc>

        <Icon src={globalImages.emptyFolder} />
    </EmptyListContainer>
  )
}

export default EmptyList

export const Icon = styled.img`
    width: 280px;
`;
export const Desc = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

export const EmptyListContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 20px;
`;