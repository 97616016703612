import imLoginBackground from "./im_loginBackground.svg";
import imLogo from "./im_logo.png";
import imEmptyData from "./im_EmptyList.svg";
import imGeneric from "./im_GenericError.svg";

export const StaticImages = {
  imLoginBackground,
  imLogo,
  imEmptyData,
  imGeneric,
};
