import { useState } from "react";
import { TableService } from "../../../../../v2/application";

export const useUsageUsedTablesAnalytics = () => {
  const [loading, setLoading] = useState(false);
  const [usageUsedTablesData, setUsageUsedTablesData] = useState<any>([]);

  const getTop5TableUsage = async (
    StartDate,
    EndDate,
    Page,
    Search,
    SortBy,
    SortOrder
  ) => {
    try {
      setLoading(true);
      setUsageUsedTablesData([]);
      const { Data } = await TableService.getTop5TableUsage(
        StartDate,
        EndDate,
        Page,
        Search,
        SortBy,
        SortOrder
      );
      if (!Data)
        throw Error(
          `${SortBy} - ${SortOrder} - ${Page} - ${StartDate} - ${EndDate} returned undefined response`
        );
      setUsageUsedTablesData(Data);
    } catch (er) {
      console.error(er);
      setUsageUsedTablesData([]);
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    usageUsedTablesData,
    getTop5TableUsage,
  };
};
