import React from "react";
import * as Styled from "./components";
import { theme, TypographySize } from "../../lib";
import { AppTypography } from "../../shared-components";
import { useLoginWithGoogle } from "../../../hooks";
import { StaticImages } from "../../assets";

export const Login = () => {
  useLoginWithGoogle();
  return (
    <Styled.Container>
      <Styled.LeftPane>
        <Styled.LoginGoogleContainer>
          <Styled.TopSection>
            <Styled.LogoIcon src={StaticImages.imLogo} />
          </Styled.TopSection>

          <Styled.BottomSection>
            <Styled.LoginGoogleButton id="googleButtonDiv"></Styled.LoginGoogleButton>
            <Styled.ContactContainer>
              <AppTypography
                size={TypographySize.md}
                textColor={theme.appColors.ash}
              >
                Any problem? contact us at hello@sherloqdata.io
              </AppTypography>
            </Styled.ContactContainer>
          </Styled.BottomSection>
        </Styled.LoginGoogleContainer>
      </Styled.LeftPane>
      <Styled.RightPane>
        <Styled.IconPlaceholderContainer>
          <Styled.PlaceholderIcon src={StaticImages.imLoginBackground} />
        </Styled.IconPlaceholderContainer>
        <Styled.PlaceholderText>
          And may the data be ever in your favor
        </Styled.PlaceholderText>
      </Styled.RightPane>
    </Styled.Container>
  );
};
