interface RuntimePerTableDataType {
  DbId: string;
  DbName: string;
  TableId: string;
  TableName: string;
  FirstRange: number;
  SecondRange: number;
  ThirdRange: number;
  FourthRange: number;
  FifthRange: number;
  SixthRange: number;
}

export interface RuntimePerTableType {
  Page: number;
  PageSize: number;
  Data: RuntimePerTableDataType[];
}

export const RuntimePerTableDataTransformer = (
  data: RuntimePerTableDataType[]
): RuntimePerTableDataType[] => {
  return data.map((runtimeData) => ({
    ...runtimeData,
    TableName: `${runtimeData.DbName}.${runtimeData.TableName}`,
  }));
};

export const GetActualTableNameFromTransformedTableName = (
  transformedTableName: string
) => {
  if (!transformedTableName) return "";
  return transformedTableName.split(".")?.[1];
};
