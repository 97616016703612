import { TypographyProps } from "@sherloq/design-system";
import React from "react";
import styled from "styled-components";
import { Typography } from "../../../../../shared-components/Typography";

export * from "./lastQueriedFilter";
export * from "./queriedInFilter";

export const SideViewContainer = styled.section`
  width: 550px;
  box-sizing: border-box;
  padding: 24px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  position: absolute;
  right: 0;
  top: 0;
  height: 100vh;
  z-index: 101;
  display: flex;
  flex-direction: column;
`;

export const SideViewTitle = (props?: TypographyProps) =>
  React.createElement(Typography, {
    variant: "h3",
    ...props,
    style: { marginBottom: "24px" },
  });

export const SideViewFooter = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: auto;
`;

export const SideViewFooterButtonsContainer = styled.div`
  column-gap: 8px;
  display: flex;
`;

export const RulesSection = styled.section`
  display: flex;
  flex-direction: column;
  gap-y: 8px;
`;

export const RuleContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
`;
