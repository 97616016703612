import styled from "styled-components";

export const CardContainer = styled.div`
  border-bottom: 2px solid;
  border-color: #e8e8e8;
  padding: 10px 0 18px 0;
`;

export const CardRow = styled.div`
  display: flex;
  margin: 0 10px;
  gap: 5px;
  align-items: center;
`;

export const QueryTitleContainer = styled.div`
  width: 40%;
  display: flex;
  column-gap: 5px;
  align-items: center;
`;

export const QueryDetailsContainer = styled.div`
  display: flex;
  width: 50%;
  justify-content: space-between;
`;

export const QueryDescriptionContainer = styled.span`
  font-size: 13px;
  color: #00000068;
  padding-left: 5px;
  padding-top: 7px;
`;

export const QueryDetailItemContainer = styled.div`
  display: flex;
  column-gap: 10px;
`;

export const QueryDetailItemTextContainer = styled.span`
  display: flex;
  flex-direction: column;
  row-gap: 3px;
`;

export const Icon = styled.img`
  height: 25px;
`;

export const Title = styled.p`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin: 0;
  padding: 0;
  font-weight: 800;
  font-size: 15px;
  width: 80%;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;
