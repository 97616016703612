/**
 * To hold shared data for Table components
 */

import { AnalyticsTableHeaderType } from "../../../../components/AnalyticsTable";
import theme from "../../../../styles/theme";

const { graphLegend } = theme;
export const DropdownDurationOptions = [
  {
    label: "Last 7 days",
    value: 7,
  },
  {
    label: "Last 14 days",
    value: 14,
  },
  {
    label: "Last Month",
    value: 30,
  },
];

export const UsedTablesHeaders: AnalyticsTableHeaderType[] = [
  {
    header: "Most used table",
    subHeaders: [""],
    width: "10%",
    sort: true,
    sortKeyword: "tablename",
  },
  {
    header: "Table Usage",
    subHeaders: [""],
    width: "10%",
    sort: true,
    sortKeyword: "numOfReferences",
  },
  {
    header: "Table Cost",
    subHeaders: ["($)"],
    width: "8.5%",
    sort: true,
    sortKeyword: "totalcost",
  },
  {
    header: "Cost",
    subHeaders: ["Average($)"],
    width: "7%",
    sort: true,
    sortKeyword: "averagecost",
  },
  {
    header: "Runtime",
    subHeaders: ["Average"],
    width: "7%",
    sort: true,
    sortKeyword: "averageruntime",
  },
  {
    header: "Data Scanned",
    subHeaders: ["Average"],
    width: "10%",
    sort: true,
    sortKeyword: "averagedatascanned",
  },
  {
    header: "Table Usage",
    subHeaders: ["Compared to last week"],
    width: "15%",
    sort: true,
    sortKeyword: "",
  },
  {
    header: "# Users",
    subHeaders: ["Distinct"],
    width: "8%",
    sort: true,
    sortKeyword: "usercount",
  },
  { header: "Last User", subHeaders: [""], width: "8%", sort: false },
];

export const TableRuntimeAnalyticsGraphLegendItems = [
  { label: "0-0.5", color: graphLegend.best },
  { label: "0.5-1", color: graphLegend.good },
  { label: "1-3", color: graphLegend.ok },
  { label: "3-5", color: graphLegend.warn },
  { label: "5-10", color: graphLegend.bad },
  { label: "10+", color: graphLegend.worst },
];
