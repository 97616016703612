import {
  FieldTableRowContainer,
  TextValue,
  TextValueWithHover,
} from "./components";
import { TopFieldsTableFields } from "../../../../../../../application";
import { TypographyWithToolTip } from "../../../../../../shared-components/TypographWithToolTip/";

interface Props extends React.HTMLAttributes<HTMLTableCellElement> {
  data: TopFieldsTableFields;
  onClickQuery: (query: string) => void;
}

export const FieldTableRow: React.FC<Props> = ({
  data,
  onClickQuery,
  ...props
}) => {
  const {
    fieldName,
    fieldDescription,
    fieldType,
    lastQueryReferencingDate,
    lastReferencingQuery,
    lastReferencingUser,
    numJoinReferences,
    numOfReferences,
    numSelectReferences,
    numWhereReferences,
    percentageOfReferences,
  } = data;
  return (
    <tr>
      <FieldTableRowContainer {...props}>
        <TextValue variant="p-light">{fieldName}</TextValue>
        <TypographyWithToolTip toolTipValue={fieldDescription}>
          <TextValue variant="p-light">{fieldDescription}</TextValue>
        </TypographyWithToolTip>
        <TextValue variant="p-light">{fieldType}</TextValue>
        <TextValue variant="p-light">{percentageOfReferences}%</TextValue>
        <TextValue variant="p-light">{numOfReferences}</TextValue>
        <TextValue variant="p-light">{numWhereReferences}</TextValue>
        <TextValue variant="p-light">{numSelectReferences}</TextValue>
        <TextValue variant="p-light">{numJoinReferences}</TextValue>
        <TextValue variant="p-light">{lastQueryReferencingDate}</TextValue>
        <TextValue variant="p-light">{lastReferencingUser}</TextValue>
        <TextValueWithHover
          onClick={() => onClickQuery(lastReferencingQuery)}
          variant="p-light"
        >
          {lastReferencingQuery}
        </TextValueWithHover>
      </FieldTableRowContainer>
    </tr>
  );
};
