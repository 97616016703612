import React from "react";
import {
  Select as MUISelect,
  SelectProps as MuiSelectProps,
} from "@mui/material";
import { FormControlComponent, Label, MenuItemComponent } from "./components";

interface InputSelectorOption {
  label: string;
  value: number | string;
  state?: boolean;
}

export type SelectProps = MuiSelectProps & {
  options?: InputSelectorOption[];
};

export const Select: React.FC<SelectProps> = ({ options, ...rest }) => {
  return (
    <FormControlComponent id="select">
      <MUISelect
        MenuProps={{
          MenuListProps: {
            disablePadding: true,
          },
        }}
        {...rest}
      >
        {options?.map(({ label, value }, idx) => (
          <MenuItemComponent key={idx} value={value}>
            <Label variant="small">{label}</Label>
          </MenuItemComponent>
        ))}
      </MUISelect>
    </FormControlComponent>
  );
};
