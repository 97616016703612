import moment from "moment-timezone";

export const getDateFormat = (date: Date) => {
  return moment(date).add(3, "hours").format("DD/MM/YYYY HH:mm:ss");
};

export const getAppDateFormat = (date: Date | string) => {
  if (!date) return "";
  return moment(date).add(3, "hours").format("MMM D, YY HH:mm");
};

export const getDateonlyFormat = (date: Date) =>
  moment(date).format("MMM D,YY");

export const formatQueryListDate = (date?: Date) => {
  if (!date) return null;
  return new Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "short",
    day: "2-digit",
  }).format(date);
};
