import React from "react";
import { BackButtonContainer, BackIcon, BackText } from "./BackButton.styled";
import globalImages from "../../assets/images/global";
import { TypographySize } from "../../types/styles.d";

interface BackButtonProps {
  title: string;
  onClick: React.MouseEventHandler<HTMLDivElement>;
}
const BackButton: React.FC<BackButtonProps> = ({ title, onClick }) => {
  return (
    <BackButtonContainer onClick={onClick}>
      <BackIcon src={globalImages.backButton} alt={title} />
      <BackText size={TypographySize.md}>{title}</BackText>
    </BackButtonContainer>
  );
};

export default BackButton;
