import { useAssetReadMe } from "../../../hooks";
import React from "react";
import { StaticIcons } from "../../assets";
import { Typography } from "../Typography";
import {
  Body,
  ButtonIcon,
  Container,
  Header,
  Icon,
  PreHeaderRow,
} from "./components";
import { RenderIf } from "../../lib";

interface ReadMeProps extends React.HTMLAttributes<HTMLDivElement> {
  onUpdate: (input: string) => void;
  value: string;
}

export const ReadMe = ({
  onUpdate,
  value: defaultValue,
  ...props
}: ReadMeProps) => {
  const { onChangeReadme, value, open, setOpenState } = useAssetReadMe({
    onUpdate,
    value: defaultValue,
  });

  return (
    <Container opened={open} {...props}>
      <Header>
        {RenderIf(
          open,
          <PreHeaderRow>
            <Icon src={StaticIcons.ic24Comment} />
            <Typography color="#000" variant="b">
              Read Me
            </Typography>
          </PreHeaderRow>
        )}
        <ButtonIcon
          onClick={() => {
            setOpenState((state) => !state);
          }}
          rotate={open}
          src={StaticIcons.ic24DoubleLeftArrow}
        />
      </Header>
      <Body
        {...(!open
          ? {
              disabled: true,
            } // to disable viewing or editing when its not opened
          : {
              value,
              onChange: onChangeReadme,
              placeholder: "Simply type any note or comment...",
            })}
      />
    </Container>
  );
};
