import React from "react";
import { AssetUtil } from "../AssetUtil";
import { AssetType } from "../../../core";
import { AssetProfileUtilLinkButton } from "../atoms";
import { Container } from "./components";

type Func = () => void;

interface SharedAssetProfileUtils {
  onClickEdit;
  onClickShare;
  onClickMyListOperation: Func;
  asset: AssetType;
  inMyList: boolean;
}
export const SharedAssetProfileUtils = ({
  onClickEdit,
  onClickMyListOperation,
  onClickShare,
  inMyList,
  asset,
}: SharedAssetProfileUtils) => {
  return (
    <Container>
      <AssetUtil onClick={onClickEdit} prefixIcon="ic16EditIconPreview">
        {`Edit ${asset} info`}
      </AssetUtil>
      <AssetUtil onClick={onClickShare} prefixIcon="ic16DefaultSharePreview">
        {`Share ${asset}`}
      </AssetUtil>
      <AssetProfileUtilLinkButton onClick={onClickMyListOperation}>
        {inMyList ? "- Remove from My List" : "+ Add to My List"}
      </AssetProfileUtilLinkButton>
    </Container>
  );
};
