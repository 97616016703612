import React from "react";
import CardComponent from "../../../components/card/CardComponent";
import styled from "styled-components";
import { AppTypography } from "../../../styles/global";
import { TypographyBold, TypographySize } from "../../../types/styles.d";
import theme from "../../../styles/theme";
import globalImages from "../../../assets/images/global";
import { Divider } from "@material-ui/core";
import {
  formatBytes,
  formatSeconds,
} from "../../../utils/helpers/unit-converter";

type CardNames = "Costs" | "ScannedData" | "Runtime";
interface ICostSummary {
  title: string;
  subtitle: string;
  name: CardNames;
  highest: string;
  average: string;
  lastWeekAverage: string;
  averagePercentage: string;
  lastWeekHighest: string;
  highestPercentage: string;
  highestClickable?: boolean;
  onClickHighest?: () => void;
  leftNodeHeader?: string;
  rightNodeHeader?: string;
}
const scannedDataString = (leftValue: string, rightValue: string) => {
  const rightValuePercentage =
    Number(rightValue) > 1
      ? Number(rightValue).toFixed(1)
      : Number(rightValue).toFixed(3);
  return `${formatBytes(leftValue)} (${rightValuePercentage}%)`;
};
const runtimeString = (leftValue: string, rightValue: string) => {
  const rightValuePercentage =
    Number(rightValue) > 1
      ? Number(rightValue).toFixed(1)
      : Number(rightValue).toFixed(3);
  return `${formatSeconds(leftValue)} (${rightValuePercentage}%)`;
};
const costString = (leftValue: string, rightValue: string) => {
  const rightValuePercentage =
    Number(rightValue) > 1
      ? Number(rightValue).toFixed(1)
      : Number(rightValue).toFixed(3);
  return `$${Number(leftValue).toFixed(1)} (${rightValuePercentage}%)`;
};
function SummaryCard({
  title,
  subtitle,
  name,
  highest,
  average,
  lastWeekAverage,
  averagePercentage,
  lastWeekHighest,
  highestPercentage,
  highestClickable,
  onClickHighest,
  leftNodeHeader,
  rightNodeHeader,
}: ICostSummary) {
  return (
    <CardComponent radius={1} padding="20">
      <Container>
        <HeaderTitle>
          <AppTypography bold={TypographyBold.lg} size={TypographySize.md}>
            {title}
          </AppTypography>
          <AppTypography bold={TypographyBold.md} size={TypographySize.sm}>
            {subtitle}
          </AppTypography>
        </HeaderTitle>
        <Content>
          <LeftPane>
            <AppTypography bold={TypographyBold.lg} size={TypographySize.md}>
              {leftNodeHeader ?? "Highest"}
            </AppTypography>
            <AppTypography
              textColor={theme.appColors.tertiary}
              bold={TypographyBold.lg}
              size={TypographySize.md}
              clickable={highestClickable}
              onClick={() => onClickHighest?.()}
            >
              {name === "ScannedData" && formatBytes(highest)}
              {name === "Runtime" && formatSeconds(highest)}
              {name === "Costs" && "$" + Number(highest).toFixed(1)}
            </AppTypography>
            <Bottom>
              <>
                {Number(highestPercentage) > 0 && (
                  <Icon src={globalImages.arrowUpRedIcon} />
                )}
                {Number(highestPercentage) == 0 && (
                  <Icon src={globalImages.arrowUpRedIcon} />
                )}
                {Number(highestPercentage) < 0 && (
                  <Icon src={globalImages.arrowDownGreenIcon} />
                )}
                <AppTypography
                  textColor={
                    Number(highestPercentage) > 0 ||
                    Number(highestPercentage) == 0
                      ? theme.statusColors.danger
                      : theme.statusColors.ok
                  }
                  bold={TypographyBold.md}
                  size={TypographySize.sm}
                >
                  {name === "ScannedData" &&
                    scannedDataString(lastWeekHighest, highestPercentage)}
                  {name === "Runtime" &&
                    runtimeString(lastWeekHighest, highestPercentage)}
                  {name === "Costs" &&
                    costString(lastWeekHighest, highestPercentage)}
                </AppTypography>
              </>
            </Bottom>
          </LeftPane>
          <Divider orientation="vertical" flexItem />
          <RightPane>
            <AppTypography bold={TypographyBold.lg} size={TypographySize.md}>
              {rightNodeHeader ?? "Average"}
            </AppTypography>
            <AppTypography
              textColor={theme.appColors.tertiary}
              bold={TypographyBold.lg}
              size={TypographySize.md}
            >
              {name === "ScannedData" && formatBytes(average)}
              {name === "Runtime" && formatSeconds(average)}
              {name === "Costs" && "$" + Number(average).toFixed(3)}
            </AppTypography>
            <Bottom>
              <>
                {Number(averagePercentage) > 0 && (
                  <Icon src={globalImages.arrowUpRedIcon} />
                )}
                {Number(averagePercentage) == 0 && (
                  <Icon src={globalImages.arrowUpRedIcon} />
                )}
                {Number(averagePercentage) < 0 && (
                  <Icon src={globalImages.arrowDownGreenIcon} />
                )}
                <AppTypography
                  textColor={
                    Number(averagePercentage) > 0 ||
                    Number(averagePercentage) == 0
                      ? theme.statusColors.danger
                      : theme.statusColors.ok
                  }
                  bold={TypographyBold.md}
                  size={TypographySize.sm}
                >
                  {name === "ScannedData" &&
                    scannedDataString(lastWeekAverage, averagePercentage)}
                  {name === "Runtime" &&
                    runtimeString(lastWeekAverage, averagePercentage)}
                  {name === "Costs" &&
                    costString(lastWeekAverage, averagePercentage)}
                </AppTypography>
              </>
            </Bottom>
          </RightPane>
        </Content>
      </Container>
    </CardComponent>
  );
}

export default SummaryCard;

export const Container = styled.div`
  min-width: 100px;
`;
export const HeaderTitle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 12px;
`;
export const Content = styled.div`
  display: flex;
  justify-content: center;
  gap: 16px;
`;
export const LeftPane = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;
export const RightPane = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;
export const Bottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const Icon = styled.img``;
