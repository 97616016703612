import React from "react";
import * as Styled from "./styled";
import { AssetType } from "../../../services/models";
import { DetailItem, ItemIcon } from "./subcomponents";
import { formatQueryListDate } from "../../../utils/helpers";
import icons from "../../../assets/images/search";
import { AthenaIcon, BigQueryIcon } from "../../../assets/IDE/index";

import { Tooltip } from "@material-ui/core";

interface ExtensionQueryParams {
  data: {
    type: AssetType;
    title: string;
    description?: string;
    userName: string;
    savedDate: string;
    source?: string;
    queryString?: string;
  };
  onClick?: () => void;
}

export const ExtensionQueryItemsCard: React.FC<ExtensionQueryParams> = ({
  data,
  onClick,
}) => {
  if (!data) return null;
  const { type, title, queryString, userName, savedDate, description, source } =
    data;
  const formattedSavedDate = formatQueryListDate(
    savedDate ? new Date(savedDate) : undefined
  );
  return (
    <Styled.CardContainer>
      <Styled.CardRow>
        <Styled.QueryTitleContainer>
          <ItemIcon style={{ height: "35px" }} type={type} />
          <Tooltip title={queryString ?? ""} placement="top-start">
            <Styled.Title onClick={onClick}>{title}</Styled.Title>
          </Tooltip>
        </Styled.QueryTitleContainer>
        <Styled.QueryDetailsContainer>
          <DetailItem
            iconSrc={icons.UserIcon}
            header="saved by"
            value={userName}
          />
          <DetailItem
            iconSrc={icons.ClockIcon}
            header="saved on"
            value={formattedSavedDate}
          />
          <DetailItem
            iconSrc={
              source === "athena"
                ? AthenaIcon
                : source === "bigquery"
                ? BigQueryIcon
                : ""
            }
            iconStyle={{ height: "20px" }}
            header={source}
          />
        </Styled.QueryDetailsContainer>
      </Styled.CardRow>
      {description && (
        <Styled.QueryDescriptionContainer>
          {description}
        </Styled.QueryDescriptionContainer>
      )}
    </Styled.CardContainer>
  );
};
