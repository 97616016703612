import React from "react";
import styled from "styled-components";
import icons from "../../assets/images/global";
export { usePagination } from "./usePagination";

interface PaginationComponentProps
  extends React.HtmlHTMLAttributes<HTMLDivElement> {
  currentPage?: number;
  totalPages?: number;
  showPrevious?: boolean;
  showNext?: boolean;
  onSkipToNextPage?: (e?: any) => void;
  onSkipToPreviousPage?: (e?: any) => void;
}

const TablePaginationContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: end;
  align-items: center;
  margin-top: 30px;
`;
const Icon = styled.img`
  height: 25px;
`;
export default ({
  currentPage = 0,
  totalPages = 0,
  onSkipToNextPage,
  onSkipToPreviousPage,
  showPrevious,
  showNext,
  ...props
}: PaginationComponentProps) => {
  return (
    <TablePaginationContainer {...props}>
      {showPrevious && (
        <Icon
          src={icons.RightArrow}
          style={{ rotate: "180deg", width: "20px" }}
          onClick={onSkipToPreviousPage}
        />
      )}
      {currentPage} - {totalPages}
      {showNext && (
        <Icon
          src={icons.RightArrow}
          style={{ width: "20px" }}
          onClick={onSkipToNextPage}
        />
      )}
    </TablePaginationContainer>
  );
};
