import styled from "styled-components";
import Select from "react-select";
import { z_dropdown } from "../../lib";

export const Dropdown = styled(Select)`
  align-self: center;
  width: inherit;
  height: 30px;
  border-radius: 4px;
  font-size: 12px;
  height: 40px;
  z-index: ${z_dropdown};
`;
