import "styled-components";
import theme from "../styles/theme";

interface IPalette {
  main: string;
  contrastText: string;
}
declare module "styled-components" {
  interface DefaultTheme {
    colors: {
      primary: string;
      secondary: string;
      tertiary: string;
      addFilterButton: string;
    };
    appColors: {
      primary: string;
      secondary: string;
      tertiary: string;
      white: string;
      dark: string;
      ash: string;
      transparent: string;
      pinkBackground: string;
    };
    tableColors: {
      headerBackground: string;
    };
    statusColors: {
      danger: string;
      warning: string;
      ok: string;
    };
    graphLegend: {
      best: string;
      good: string;
      ok: string;
      warn: string;
      bad: string;
      worst: string;
    };
    typography: {
      size: { xs: string; sm: string; md: string; lg: string; xl: string };
      colors: { primary: string; secondary: string; tertiary: string };
      bold: { sm: string; md: string; lg: string };
    };
    inputsColors: {
      background: string;
      border: string;
    };
    spacing: {
      xs: string;
      sm: string;
      md: string;
      lg: string;
    };
    borderRadius: {
      sm: string;
      md: string;
      lg: string;
    };
    borderColor: string;
    breakpoints: {
      xs: string;
      sm: string;
      md: string;
      lg: string;
      xl: string;
    };
    appBar: {
      height: string;
    };
  }
}

export enum TypographySize {
  xs = theme.typography.size.xs,
  sm = theme.typography.size.sm,
  md = theme.typography.size.md,
  lg = theme.typography.size.lg,
}

export enum TypographyColor {
  primary = theme.typography.colors.primary,
  secondary = theme.typography.colors.secondary,
  tertiary = theme.typography.colors.tertiary,
  danger = theme.statusColors.danger,
  ok = theme.statusColors.ok,
  warning = theme.statusColors.warning,
}

interface AppTypographyProps {
  bold?: TypographyBold;
  size?: TypographySize;
  textColor?: TypographyColor | string;
  wrap?: string;
  underline?: boolean;
  clickable?: boolean;
  italic?: boolean;
  capitalize?: boolean;
}
export enum TypographyBold {
  sm = theme.typography.bold.sm,
  md = theme.typography.bold.md,
  lg = theme.typography.bold.lg,
}
