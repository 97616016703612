import { FormControl, MenuItem } from "@mui/material";
import styled from "styled-components";
import { Typography } from "../Typography";

export const MenuItemComponent = styled(MenuItem)`
  display: flex;
  align-items: Center;
`;
export const FormControlComponent = styled(FormControl)`
  display: flex;
  align-items: center;
  padding: 0;
`;

export const Label = styled(Typography)`
  margin-top: 4px;
`;
