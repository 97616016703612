import styled from "styled-components";

export const RootDiv = styled.div`
  height: 100vh;
  width: 100vw;
  overflow: hidden;
`;

export const ContentContainer = styled.main<{ auth?: boolean }>`
  padding: ${({ auth }) => (auth ? "20px 0 24px 128px" : "20px 0 0 0")};
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  overflow-y: auto;
`;
