import { Typography } from "..";
import { TypographyProps } from "@sherloq/design-system";
import React from "react";
import styled from "styled-components";

export const HeaderWithInfoIconContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 4px;
  margin-bottom: 16px;
  height: fit-content;
`;

export const InfoIconContainer = styled.div<{ backgroundColor?: string }>`
  padding: 4px 6px;
  background-color: ${({ backgroundColor }) => backgroundColor ?? "#D9D9D9"};
  border-radius: 10px;
  width: 15px;
  height: 15px;
  display: flex;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;

  :hover {
    cursor: pointer;
  }
`;

export const Text = ({ children, ...props }: TypographyProps) =>
  React.createElement(
    styled(Typography)``,
    { variant: "h6", ...props },
    children
  );
