import { useState } from "react";

export const usePagination = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const onSkipToNextPage = () => {
    setCurrentPage(currentPage + 1);
  };
  const onSkipToPreviousPage = () => {
    setCurrentPage(currentPage - 1);
  };
  return {
    currentPage,
    onSkipToNextPage,
    onSkipToPreviousPage,
  };
};
