import styled from "styled-components";

export const TableContentContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 167px);
  margin-top: 25px;
  margin-bottom: 40px;
  column-gap: 17px;
  border: none;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #d9d9d9;
  padding-bottom: 16px;
`;
