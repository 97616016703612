import { TableService } from "./table.service";

export interface UpdateTableInfoParams {
  description: string;
  assetId: number;
  sherloqUserId: number;
  tableOwner: string;
}

export const makeUpdateTableInfo = async (params: UpdateTableInfoParams) => {
  const { tableOwner, description, assetId, sherloqUserId } = params;
  const promises: any[] = [];
  if (description) {
    promises.push(
      TableService.changeTableDescription(assetId, sherloqUserId, description)
    );
  }
  if (tableOwner) {
    promises.push(
      TableService.changeTableOwner(assetId, sherloqUserId, tableOwner)
    );
  }
  return await Promise.allSettled(promises);
};
