import { env } from "../env";
import { CreateClientConstructor } from "./axios";
import {
  addUserTokenToRequests,
  defaultRequestErrorHandler,
} from "./interceptors";
import { createBaseHttpClient } from "./createBase";
import { clientStorage } from "../clientStorage";

const authHttpClient = createBaseHttpClient(
  env.serverBaseURl,
  CreateClientConstructor
);
authHttpClient.addRequestInterceptor(
  (config) => addUserTokenToRequests(config, clientStorage),
  defaultRequestErrorHandler
);

export { authHttpClient };
