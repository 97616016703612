import React from "react";
import { RenderIf } from "../../lib";
import { SherloqChip, TopHeader, Typography } from "../../shared-components";
import {
  AppliedFiltersContainer,
  Container,
  FiltersChipContainter,
  FilterSideView,
  HeaderStickyComponent,
  HomeNestedNavbar,
  PageTitle,
  Row,
  SearchAndFilter,
  SearchKeyword,
  SearchResultsContainer,
  TitleDescriptionContainer,
  TopHeaderContainer,
} from "./components";
import { Outlet } from "react-router-dom";
import { useGlobalAuthState, useHomePage } from "../../../hooks";
import { AssetSortOptions } from "../../../application";

export const Home = () => {
  const {
    toggleFilterView,
    homeState,
    onClickSearch,
    onSearching,
    onChangeSortBy,
    activeSubpage,
    redirectToQueryResults,
    redirectToTableResults,
    onRemoveAppliedFilter,
    onUpdateLastQueriedBy,
    onUpdateQueriedIn,
    selectedSortbyValue,
  } = useHomePage();
  const transformQueriedInFilterValue = (numberOfDays: string) => {
    // must move to application layer
    switch (numberOfDays) {
      case "1":
        return "Last Day";
      case "7":
        return "Last Week";
      case "30":
        return "Last Month";
      default:
        return "";
    }
  };
  const { details } = useGlobalAuthState();
  return (
    <Container id="homepage">
      <TopHeaderContainer>
        <TopHeader showBack={false} />
      </TopHeaderContainer>
      <TitleDescriptionContainer>
        <PageTitle>Discover queries and tables</PageTitle>
        <Typography variant="h4">
          Search & filter the list and find exactly what you looking for...
        </Typography>
      </TitleDescriptionContainer>
      <HeaderStickyComponent>
        <SearchAndFilter
          onSearchChange={onSearching}
          onClickFilterIcon={toggleFilterView}
        />
        <SearchResultsContainer>
          {RenderIf(
            !!homeState?.searchKeyword,
            <Row>
              <Typography variant="p-light">Searching:</Typography>
              <SearchKeyword color="#252525" variant="p-light">
                {homeState.searchKeyword}
              </SearchKeyword>
            </Row>
          )}
          {RenderIf(
            homeState.filters?.rules?.length > 0 ||
              homeState.filters?.lastQueriedBy.value.length > 0 ||
              homeState?.filters.queriedIn.value,
            <AppliedFiltersContainer>
              <Typography variant="small" color="#707070">
                Applied Filters:
              </Typography>
              <FiltersChipContainter>
                {homeState?.filters.rules.map((rule, idx) => (
                  <SherloqChip
                    onClick={onRemoveAppliedFilter}
                    content={`${rule?.column}:${rule?.value}`}
                    key={idx}
                    data={rule}
                  />
                ))}
                {RenderIf(
                  homeState?.filters.queriedIn.value,
                  <SherloqChip
                    onClick={onUpdateQueriedIn}
                    content={transformQueriedInFilterValue(
                      homeState?.filters.queriedIn.value
                    )}
                    key={1}
                    data={{
                      column: "lastReferenceDatetime",
                      condition: "pastDays",
                      value: homeState?.filters.queriedIn.value,
                    }}
                  />
                )}
                {RenderIf(
                  homeState.filters?.lastQueriedBy?.value.length > 0,
                  <SherloqChip
                    onClick={onUpdateLastQueriedBy}
                    content={`Last Queried By: ${homeState?.filters?.lastQueriedBy?.value}`}
                    key={1}
                    data={{
                      column: "lastUsernameThatReferenced",
                      condition: "inList",
                      value: homeState?.filters?.lastQueriedBy?.value,
                    }}
                  />
                )}
              </FiltersChipContainter>
            </AppliedFiltersContainer>
          )}
        </SearchResultsContainer>

        <HomeNestedNavbar
          activeSubpage={activeSubpage}
          redirectToQueryResultsPage={redirectToQueryResults}
          redirectToTableResultsPage={redirectToTableResults}
          sortOptions={
            details.CompanyId !== "3"
              ? AssetSortOptions.slice(0, 2)
              : AssetSortOptions
          }
          onChangeSortBy={onChangeSortBy}
          numberOfQueryResults={homeState.numberOfQueryResults}
          numberOfTableResults={homeState.numberOfTableResults}
          sortByValue={selectedSortbyValue}
        />
      </HeaderStickyComponent>
      <Outlet />
      {RenderIf(
        homeState.openFilterSideView,
        <FilterSideView
          onClickCancel={() => toggleFilterView()}
          onClickSearch={onClickSearch}
        />
      )}
    </Container>
  );
};
