import { makeUpdateTableInfo } from "../../application";
import {
  AnalyticsClient,
  AuthState,
  notificationSnackbar,
} from "../../infrastructure";
import { useEffect, useState } from "react";
import { useGlobalStateSelector } from "../redux";
import { useMutation } from "react-query";

interface EditTableinfoModalProps {
  onSuccess?: (owner: string, desc: string) => void;
  tableId: string;
  description: string;
  tableOwner: string;
}

export const useEditTableinfoModal = ({
  onSuccess,
  tableId,
  description: defaultDesc,
  tableOwner: defaultOwner,
}: EditTableinfoModalProps) => {
  const { user } = useGlobalStateSelector(
    (state: any) => state.auth as AuthState
  );
  const [description, setDescription] = useState("");
  const [owner, setOwner] = useState("");

  const { mutateAsync, isLoading } = useMutation(
    () => {
      const params: any = {
        assetId: tableId,
        sherloqUserId: user.SherloqUserId,
      };
      if (owner !== defaultOwner) {
        params.tableOwner = owner;
      }
      if (description !== defaultDesc) {
        params.description = description;
      }
      return makeUpdateTableInfo(params);
    },
    {
      mutationKey: `update-table-profile-description`,
      onSuccess: () => {
        let ownerParams = defaultOwner;
        let descParams = defaultDesc;
        let isNewDesc = false;
        let isNewOwner = false;
        if (owner && owner !== defaultOwner) {
          ownerParams = owner;
          isNewOwner = true;
        }
        if (description && description !== defaultDesc) {
          descParams = description;
          isNewDesc = true;
        }
        //the check above is to check whether to send the updated description
        notificationSnackbar.success("Successfully updated Table info");
        AnalyticsClient.trackAction("main_table_profile_edit_info_success", {isNewDesc: isNewDesc, description: description, isNewOwner: isNewOwner, owner: owner});
        onSuccess?.(ownerParams, descParams);
      },
      onError: () => {
        notificationSnackbar.success("Failed to update Table info");
        AnalyticsClient.trackAction("main_table_profile_edit_info_failed");
      },
    }
  );

  const onChangeDescInput = (e?: any) => {
    setDescription(e.target.value);
  };
  const onChangeOwnerInput = (e?: any) => {
    setOwner(e.target.value);
  };

  useEffect(() => {
    setDescription(defaultDesc);
    setOwner(defaultOwner);
  }, [defaultDesc, defaultOwner]);

  return {
    onChangeDescInput,
    onChangeOwnerInput,
    updatedDesc: description,
    onSaveDescription: mutateAsync,
    isLoading,
    updatedOwner: owner,
  };
};

/*
makeUpdateTableInfo(
    validateUpdateTableInfoParams({
      assetId: tableId,
      description,
      sherloqUserId: user.SherloqUserId,
      tableOwner: owner,
    })
  )
*/
