export const numberConditions = [
  { label: "<", value: "smaller" },
  { label: "<=", value: "smallerEqual" },
  { label: ">", value: "greater" },
  { label: ">=", value: "greaterEqual" },
  { label: "=", value: "equalNum" },
];
export const textConditions = [{ label: "CONTAINS", value: "like" }];
export const sourceConditions = [{ label: "is", value: "type" }];
export const datetimeConditions = [
  { label: "In the past days", value: "pastDays" },
];
export const dropdownConditions = [{ label: "=", value: "equalText" }];
