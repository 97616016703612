import { Typography } from "@sherloq/design-system";
import styled from "styled-components";

export const Container = styled.td`
  display: grid;
  grid-template-columns: repeat(2, 180px) repeat(4, 100px) 90px;
  column-gap: 15px;
  height: 60px;
  align-items: center;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #f1f1f1;
  :hover {
    cursor: pointer;
    background-color: #f1f1f160;
  }
`;

export const QueryString = styled(Typography)`
  width: 95%;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  line-height: 20px !important;

  :hover {
    cursor: pointer;
    background-color: #f1f1f1;
  }
`;

export const TableField = styled(Typography)`
  width: 100%;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipses;
`;
