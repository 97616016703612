import { useDispatch, useSelector, TypedUseSelectorHook } from "react-redux";
import store from "./rootReducer";

export type RootState = ReturnType<any>;

export type AppDispatch = typeof store.dispatch;
export const useReduxDispatch = (): AppDispatch => useDispatch<AppDispatch>();

export const useReduxSelector: TypedUseSelectorHook<RootState> = useSelector;
export default store;
