import React from "react";
import { ThemeProvider } from "styled-components";
import { BrowserRouter } from "react-router-dom";
import {
  AnalyticsClient,
  authHttpClient,
  env,
  GlobalStateProvider,
  QueryClientProvider,
  requestHandlerOn401Error,
  RootNotificationComponent,
} from "./infrastructure";
import { theme, UIRoot } from "./ui";
import { handleUserTokenExpiredUseCase } from "./application";

// infrastructure setup
AnalyticsClient.setup(env.mixPanelToken);
authHttpClient.addResponseInterceptor(undefined, (error: any) =>
  requestHandlerOn401Error(error, handleUserTokenExpiredUseCase)
);

function App() {
  return (
    <ThemeProvider theme={theme}>
      <QueryClientProvider>
        <GlobalStateProvider>
          <BrowserRouter>
            <UIRoot />
            <RootNotificationComponent />
          </BrowserRouter>
        </GlobalStateProvider>
      </QueryClientProvider>
    </ThemeProvider>
  );
}

export default App;
