import { IconButton, Typography } from "@sherloq/design-system";
import React, { MouseEventHandler } from "react";
import { ChipContainer, CloseIconContainer } from "./components";

export interface Props {
  name?: string;
  content: string;
  onClick?: MouseEventHandler<HTMLOrSVGElement>;
  className?: string;
}

export const SherloqChip: React.FC<Props> = ({
  className,
  content,
  onClick,
  ...rest
}) => {
  return (
    <ChipContainer className={`sherloq-chip ${className}`} {...rest}>
      <Typography variant="small">{content}</Typography>
      <CloseIconContainer>
        <IconButton
          onMouseDown={(event) => event.stopPropagation()}
          className="link"
          icon="ic16closePreview"
          hoverIcon="ic16closeHover"
          onClick={onClick}
          name="chip-close"
        />
      </CloseIconContainer>
    </ChipContainer>
  );
};
