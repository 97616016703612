import styled from "styled-components";

interface ListViewProps {
  fullscreen: boolean
}
export const Container = styled.div`
  height: 93vh;
  overflow: hidden;
  background: linear-gradient(0deg, rgba(207, 206, 243, 0.4), rgba(207, 206, 243, 0.4)), linear-gradient(0deg, #FFFFFF, #FFFFFF), linear-gradient(0deg, #3F37C9, #3F37C9), linear-gradient(0deg, #3A0CA3, #3A0CA3), linear-gradient(0deg, #7209B7, #7209B7), #F72585;
`;

export const Content = styled.div`
  margin: 25px auto auto auto;
  width: 80vw;
  height: 90%;
  overflow: hidden;
`;

export const Icon = styled.img`
  margin: 10px 0;
  cursor: pointer;
`;
export const SideMenuIcon = styled.img`
  margin: 12px 18px;
  cursor: pointer;
`;

export const Wrapper = styled.div`
  display: flex;
  gap: 15px;
`;

export const ListViewContainer = styled.div<ListViewProps>`
  width: ${({ fullscreen }) => (fullscreen ? 100 : 75)}%;
  min-height: 80vh;
  max-height: 80vh;
`;
export const SideListViewContainer = styled.div`
  width: 25%;
  height: 60vh;
`;
