import { useState } from "react";

export const useSelectAndViewQuery = () => {
  const [selectedQuery, setSelectedQuery] = useState<string>();

  const onViewQuery = (query: string | undefined) => {
    setSelectedQuery(query);
  };

  const closeQuery = () => {
    setSelectedQuery(undefined);
  };

  return {
    queryIsSelected: Boolean(selectedQuery),
    onViewQuery,
    closeQuery,
    selectedQuery,
  };
};
