import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { appStore, persistedAppStore } from "./store";

export const GlobalStateProvider = ({ children }: any) => {
  return (
    <Provider store={appStore}>
      <PersistGate persistor={persistedAppStore}>{children}</PersistGate>
    </Provider>
  );
};
