import styled from "styled-components";
export * from "./menuItem";
export const MainSidebarContainer = styled.div`
  width: 80px;
  position: fixed;
  margin: 24px 24px;
  height: calc(100vh - 48px);
  border-radius: 20px;
  background-color: #f1f1f1;
  display: flex;
  flex-direction: column;
  paddinge-top: 24px;
  align-items: center;
`;

export const MainMenuLine = styled.div`
  width: 48px;
  height: 1px;
  background-color: #d9d9d9;
  margin-top: 24px;
  margin-bottom: 8px; // (24px - menuitem margin)
`;
