import { Button, ButtonProps } from "@sherloq/design-system";
import React from "react";
import { LabelValue, RenderCond } from "../../lib";
import { Container, Input, Select } from "./components";

export interface RuleProps {
  onUpdateRule: (e?: any) => void;
  onRemoveRule: (e?: any) => void;
  columns: LabelValue<string, string>[];
  conditions: LabelValue<string, string>[];
  value: any;
  removeButtonProps?: Omit<ButtonProps, "variant" | "onClick">;
  isDropdownValues?: boolean;
  dropdownValues?: any[];
}

export const FilterRule: React.FC<RuleProps> = ({
  onUpdateRule,
  onRemoveRule,
  columns,
  conditions,
  value,
  removeButtonProps,
  dropdownValues,
  isDropdownValues = false,
}) => {
  const onChangeColumn = (val) => {
    //
    onUpdateRule({ ...value, column: val });
  };
  const onChangeCondition = (val) => {
    //
    onUpdateRule({ ...value, condition: val });
  };
  const onChangeValue = (val) => {
    //
    onUpdateRule({ ...value, value: val });
  };

  return (
    <Container>
      <Select
        onChange={(e) => onChangeColumn(e.target.value as any)}
        options={columns}
        value={value.column}
      />
      <Select
        onChange={(e) => onChangeCondition(e.target.value as any)}
        options={conditions}
        value={value.condition}
      />
      {RenderCond(
        isDropdownValues,
        <Select
          onChange={(e) => onChangeValue(e.target.value as any)}
          options={dropdownValues ?? []}
          value={value.value ?? ""}
        />,
        <Input
          name="search-rule-string"
          value={value?.value ?? ""}
          onChange={(e: any) => onChangeValue(e.target.value)}
          placeholder=""
        />
      )}
      <Button
        style={{
          marginLeft: "auto",
          color: "#7209B7",
          ...removeButtonProps?.style,
        }}
        variant="link"
        onClick={onRemoveRule}
      >
        Remove
      </Button>
    </Container>
  );
};
