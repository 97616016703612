import { clientStorage, authActions, homeActions } from "../../infrastructure";
import { useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useGlobalStateDispatch } from "../redux";
import { useGlobalAuthState } from "../user";

export const useHomeTopHeader = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useGlobalStateDispatch();
  const { details } = useGlobalAuthState();
  const [anchor, setAnchor] = useState<any>(null);
  const initials = useMemo(() => {
    const { FirstName, LastName } = details;
    return `${FirstName?.charAt(0)}${LastName?.charAt(0)}`;
  }, [details]);

  const breadcrumbsTrace = location.pathname.split("/").slice(1);

  const onClickBack = () => {
    navigate(-1);
  };

  const onClickOptions = (e: any) => {
    const anchorRef = e.currentTarget;
    setAnchor(anchorRef);
  };

  const onClosePopup = () => {
    setAnchor(null);
  };

  const onViewDocumentation = () => {
    window.open("https://sherloqdata.io/documentation", "_blank");
  };

  const onLogout = () => {
    navigate("/login");
    dispatch(authActions.reset);
    dispatch(homeActions.reset);
    onClosePopup();
    clientStorage.resetAll();
  };

  return {
    breadcrumbsTrace,
    initials,
    onClickBack,
    anchor,
    onClickOptions,
    onLogout,
    onClosePopup,
    onViewDocumentation,
  };
};
