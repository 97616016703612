import React, { ReactNode } from "react";
import { Typography } from "@sherloq/design-system";
import { TextAreaComponent } from "./components";

export interface TextareaProps
  extends React.HTMLAttributes<HTMLTextAreaElement> {
  value?: string;
  name?: string;
  error?: boolean;
  helperText?: string | ReactNode;
  errorMessage?: string;
}

export const Textarea: React.FC<TextareaProps> = ({
  helperText,
  error,
  errorMessage,
  ...props
}) => {
  return (
    <div>
      {typeof helperText === "string" ? (
        <Typography variant="helper">{helperText}</Typography>
      ) : (
        helperText
      )}
      <TextAreaComponent {...props} />
      {error && <Typography variant="error">{errorMessage}</Typography>}
    </div>
  );
};
