import { RenderCond, RenderIf } from "../../../../lib";
import {
  useSelectAndViewQuery,
  useTopFieldsSideView,
} from "../../../../../hooks";
import { StaticIcons } from "../../../../assets";
import { Button } from "@sherloq/design-system";
import {
  AddRuleButton,
  AssetFilterFooterButtonsContainer,
  AssetFilterRulesContainer,
  FilterRule,
  LabelTypography,
  ListFallBack,
  QueriedInFilter,
  ResetFilterButton,
  Typography,
} from "../../../../shared-components";
import {
  CancelIcon,
  Col,
  Container,
  FieldTableRow,
  FilterContainer,
  Header,
  RootHeaderText,
  RulesWrapper,
  Table,
  TableContainer,
  TableHeader,
  TotalRefsContainer,
} from "./components";
import { LastQueriedBySelector } from "../queryUsageSideView/components";
import { TopTableFieldsRules } from "../../../../../application";
import SqlViewer from "../../../../../../components/SqlViewer/SqlViewer"; // refactor to v2 when there is a new design

interface TopFieldsTableData {
  tableName: string;
}
interface Props {
  open?: boolean;
  onClose?: () => void;
  tableData: TopFieldsTableData;
}

export const TopFieldsSideViewComponent = ({
  open,
  onClose,
  tableData,
}: Props) => {
  const { tableName } = tableData;
  const { closeQuery, onViewQuery, queryIsSelected, selectedQuery } =
    useSelectAndViewQuery(); // move hook into useTopFieldsSideView if its to be used with other logic!
  const {
    isLoading,
    isError,
    filter,
    onChangeQueriedIn,
    onChangeLastQueriedBy,
    users,
    data,
    QueriedInFilterOptions,
    addRule,
    onApply,
    getConditions,
    onUpdateRules,
    removeRule,
    onResetFilters,
    totalRefs,
  } = useTopFieldsSideView();

  return (
    <Container open={open}>
      <Header>
        <div>
          <RootHeaderText variant="h6">
            All fields in{" "}
            <RootHeaderText variant="h6" color="#F72585">
              {tableName}
            </RootHeaderText>{" "}
            table
          </RootHeaderText>
        </div>
        <CancelIcon
          onClick={onClose}
          src={StaticIcons.ic24CancelPreview}
          alt=""
        />
      </Header>
      <FilterContainer>
        <QueriedInFilter
          label="Queried in"
          value={filter?.queriedIn?.value}
          options={QueriedInFilterOptions}
          onChange={onChangeQueriedIn}
        />
        <LastQueriedBySelector
          upadateSelectedMembers={() => null}
          selectedMembers={filter?.lastQueriedBy?.value ?? []}
          onChange={onChangeLastQueriedBy as any}
          options={users}
          label="Last queried by"
        />
      </FilterContainer>
      <RulesWrapper>
        <LabelTypography>Rules</LabelTypography>
        {RenderIf(
          filter?.rules?.length > 0,
          <AssetFilterRulesContainer>
            {filter?.rules?.map((_, idx) => (
              <FilterRule
                key={idx}
                columns={TopTableFieldsRules}
                conditions={getConditions(`${idx}`)}
                value={filter?.rules?.[idx]}
                onUpdateRule={(rule) => onUpdateRules(idx, rule)}
                onRemoveRule={() => removeRule(idx)}
              />
            ))}
          </AssetFilterRulesContainer>
        )}
        <AddRuleButton onClick={addRule} variant="link">
          + Add Rule
        </AddRuleButton>
        <AssetFilterFooterButtonsContainer>
          <Button onClick={onApply} variant="default">
            Apply
          </Button>
          <ResetFilterButton variant="link" onClick={onResetFilters}>
            Reset filters
          </ResetFilterButton>
        </AssetFilterFooterButtonsContainer>
      </RulesWrapper>
      <TotalRefsContainer>
        <Typography variant="p-light">
          Total number of query references for {tableName}: {totalRefs}
        </Typography>
      </TotalRefsContainer>
      <TableContainer>
        <Table>
          <thead style={{ position: "sticky", top: "0" }}>
            <TableHeader>
              <Typography variant="small">Name</Typography>
              <Typography variant="small">Field description</Typography>
              <Typography variant="small">Field type</Typography>
              <Col>
                <Typography variant="small">% Query</Typography>
                <Typography variant="small" color="#252525">
                  referenced
                </Typography>
              </Col>
              <Col>
                <Typography variant="small"># Query</Typography>
                <Typography variant="small" color="#252525">
                  referenced
                </Typography>
              </Col>
              <Col>
                <Typography variant="small"># Where</Typography>
                <Typography variant="small" color="#252525">
                  referenced
                </Typography>
              </Col>
              <Col>
                <Typography variant="small"># Select</Typography>
                <Typography variant="small" color="#252525">
                  references
                </Typography>
              </Col>
              <Col>
                <Typography variant="small"># Join</Typography>
                <Typography variant="small" color="#252525">
                  references
                </Typography>
              </Col>
              <Typography variant="small">Last query run</Typography>
              <Typography variant="small">Last user that referenced</Typography>
              <Typography variant="small" color="#252525">
                Last query that referenced
              </Typography>
            </TableHeader>
          </thead>
          <tbody>
            {RenderCond(
              isLoading || isError || data.length < 1,
              <ListFallBack
                {...{ isError, isLoading, noData: data.length === 0 }}
                style={{ marginTop: "20px" }}
              />,
              <>
                {data.map((row, idx) => (
                  <FieldTableRow
                    onClickQuery={onViewQuery}
                    data={row}
                    key={idx}
                  />
                ))}
              </>
            )}
          </tbody>
        </Table>
      </TableContainer>
      <SqlViewer
        show={queryIsSelected}
        query={selectedQuery ?? ""}
        onClose={closeQuery}
      />
    </Container>
  );
};
