/* eslint-disable @typescript-eslint/ban-types */
import React, { ChangeEvent } from "react";
import { MenuItem, Select } from "@mui/material";
import { Typography } from "@sherloq/design-system";
import { ChipContainer, Label, SherloqChip } from "./components";
import { getChipContentFromValue } from "./util";
import { LabelValue, RenderIf } from "../../lib";

export interface MultiDropdownSelectorProps {
  options: LabelValue<string, string | number>[];
  onChange: (value?: any[]) => void;
  selectedMembers: any[];
  upadateSelectedMembers: Function;
  label?: string;
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
}

export const MultiDropdownSelector: React.FC<MultiDropdownSelectorProps> = ({
  options,
  onChange,
  selectedMembers,
  label,
  containerProps,
}) => {
  const handleDelete = (value: any) => {
    onChange(selectedMembers.filter((val) => val !== value) as any);
  };

  return (
    <div {...containerProps}>
      {RenderIf(!!label, <Label>{label}</Label>)}
      <Select
        placeholder="Select"
        fullWidth
        labelId="multiple-select-label"
        id="multiple-select"
        multiple
        value={selectedMembers}
        onChange={(event: any) => {
          onChange?.(event.target.value);
        }}
        renderValue={(selected) => (
          <ChipContainer>
            {(selected as any[]).map((value, idx) => (
              <SherloqChip
                onClick={() => handleDelete(value)}
                key={idx}
                content={getChipContentFromValue(options, value)}
              />
            ))}
          </ChipContainer>
        )}
        style={{
          maxHeight:
            selectedMembers.length > 4 ? selectedMembers.length * 32 + 32 : 32,
          color: "red",
        }}
      >
        {options.map((option, idx) => (
          <MenuItem key={idx} value={option.value}>
            <Typography variant="small"> {option.label}</Typography>
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};
