import { StickyContainer, Typography } from "../../../shared-components";
import React from "react";
import styled from "styled-components";
import { TypographyProps } from "@sherloq/design-system";
export * from "./defaultQuery";
export * from "./defaultTable";
export * from "./nestedNavbar";
export * from "./NavOutlet";
export * from "./FilterSideView";
export * from "./search&Filter";

export const TitleDescriptionContainer = styled.div`
  margin-bottom: 40px;
`;

export const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  box-sizing: border-box;
  padding-right: 20px;
  box-sizing: border-box;
`;

export const HomeHeader = styled.header`
  top: 0;
  z-index: 5;
  box-sizing: border-box;
  background-color: #fff;
`;

export const HomeContent = styled.div`
  padding-bottom: 20px;
  z-index: 2;
  flex: 1;
  border: 1px solid #f1f1f1;
  border-radius: 5px;
`;

export const PageTitle: React.FC<TypographyProps> = ({ children, ...props }) =>
  React.createElement(
    Typography,
    {
      variant: "h1",
      style: { marginBottom: "16px" },
      ...props,
    },
    children
  );

export const SearchKeyword = styled(Typography)`
  font-style: italic;
  font-weight: 400;
  margin-left: 8px;
`;

export const Row = styled.div`
  display: flex;
`;

export const SearchResultsContainer = styled.div`
  margin-top: 16px;
`;

export const AppliedFiltersContainer = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  row-gap: 4px;
`;

export const FiltersChipContainter = styled.div`
  display: flex;
  column-gap: 4px;
`;

export const TopHeaderContainer = styled(StickyContainer)``;

export const HeaderStickyComponent = styled(StickyContainer)`
  width: 100%;
  top: 41px;
`;
