import {
  EditAssetInfoTextInput,
  EditAssetInfoTextInputContentHeader,
  RootModal,
  Textarea,
  Typography,
} from "../../../../shared-components";
import { Button } from "@sherloq/design-system";
import { useEditQueryInfoModal } from "../../../../../hooks";

interface QueryModalData {
  queryId: string;
  queryname: string;
  querydescription: string;
}

interface EditQueryInfoProps {
  onClose: () => void;
  open: boolean;
  onSuccess: ({ queryDescription, queryName }) => void;
  data: QueryModalData;
}

export const EditQueryInfoModal = ({
  onSuccess,
  data,
  ...props
}: EditQueryInfoProps) => {
  const {
    queryId,
    querydescription: defaultdesc,
    queryname: defaultname,
  } = data;
  const {
    onChangeDescInput,
    onChangeNameInput,
    queryDescription,
    queryName,
    onUpdateQueryInfo,
    isLoading,
  } = useEditQueryInfoModal({
    queryId,
    currentDescription: defaultdesc,
    currentName: defaultname,
    onSuccess: ({ queryDescription, queryName }) => {
      onSuccess({ queryDescription, queryName });
      props?.onClose();
    },
  });
  return (
    <RootModal
      {...props}
      tintColor="default"
      title="Query Info"
      description="Manage query information"
      showCancelButton
      onClickCancel={props?.onClose}
      actionButton={
        <Button
          onClick={() => {
            if (!isLoading) onUpdateQueryInfo();
          }}
          variant="default"
        >
          {isLoading ? "saving..." : "Save"}
        </Button>
      }
      contentElement={
        <div>
          <EditAssetInfoTextInput
            onChange={onChangeNameInput}
            value={queryName}
            helperText="Query name"
          />
          <Textarea
            helperText={
              <EditAssetInfoTextInputContentHeader>
                <Typography variant="small" color="#707070">
                  Query description (recommended)
                </Typography>
                {/* <Typography color="#0075E7" variant="small">
                  Generate description
                </Typography> */}
              </EditAssetInfoTextInputContentHeader>
            }
            placeholder="e.g New users"
            value={queryDescription}
            onChange={onChangeDescInput}
          />
        </div>
      }
    />
  );
};
