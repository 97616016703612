import { userService } from "../../application";
import { useState } from "react";

export const useUsersRuntimePerUsers = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>();

  const getRuntimePerUser = async (page: number) => {
    try {
      setLoading(true);
      const { Data } = await userService.getUserRuntimePerTable(page);
      setData(Data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  return {
    data,
    getRuntimePerUser,
    loading,
  };
};
