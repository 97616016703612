import React from "react";
import * as Styled from "./ItemCard.styled";
import { Tooltip } from "@material-ui/core";
import icons from "../../assets/images/search";
import { AppTypography } from "../../styles/global";
import { TypographyBold, TypographySize } from "../../types/styles.d";
import { useNavigate } from "react-router-dom";

interface Props {
  name: string;
  path: string;
}

export const ExtensionFolderCard: React.FC<Props> = ({ name, path }) => {
  const navigate = useNavigate();

  function onClick() {
    navigate(path);
  }

  return (
    <Styled.FolderCardContainer>
      <Styled.IconContainer src={icons.FolderIcon} />
      <Tooltip title={name} placement="top-start">
        <AppTypography
          clickable
          onClick={onClick}
          size={TypographySize.md}
          bold={TypographyBold.lg}
        >
          {name}
        </AppTypography>
      </Tooltip>
    </Styled.FolderCardContainer>
  );
};
