import React from "react";
import { TableAnalyticsColumn } from "../Table.styled";
import { DropdownDurationOptions, UsedTablesHeaders } from "../Table";
import { AnalyticsTable } from "../../../../../components/AnalyticsTable";
import { UsageUsedTablesRow } from "./UsageUsedTablesRow";
import { useUsageUsedTablesAnalytics } from "./useUsageUsedTables";
import { useReduxSelector, RootState } from "../../../../../redux";
export const UsageUsedTables = () => {
  const { details } = useReduxSelector((state: RootState) => state.auth);
  const { getTop5TableUsage, loading, usageUsedTablesData } =
    useUsageUsedTablesAnalytics();

  return (
    <AnalyticsTable
      dataPageSize={5}
      tableName="Used Tables"
      withDuration
      withSearch
      withSort
      containerProps={{
        showShadow: true,
        padding: "20px",
        radius: 1,
        style: { overflow: "hidden" },
      }}
      tableContainerProps={{ style: { height: "275px" } }}
      tableHeaders={UsedTablesHeaders}
      loading={loading}
      refetchData={getTop5TableUsage}
      data={usageUsedTablesData}
      renderData={(data, key) => {
        return <UsageUsedTablesRow {...{ data, key }} />;
      }}
      durationOptions={
        details.CompanyId != 4
          ? (DropdownDurationOptions as any)
          : (DropdownDurationOptions as any)
      }
      tableColGroup={
        <>
          <TableAnalyticsColumn span={1}></TableAnalyticsColumn>
          <TableAnalyticsColumn span={1}></TableAnalyticsColumn>
          <TableAnalyticsColumn span={1}></TableAnalyticsColumn>
          <TableAnalyticsColumn span={1}></TableAnalyticsColumn>
          <TableAnalyticsColumn span={1}></TableAnalyticsColumn>
          <TableAnalyticsColumn span={1}></TableAnalyticsColumn>
          <TableAnalyticsColumn span={1}></TableAnalyticsColumn>
          <TableAnalyticsColumn span={1}></TableAnalyticsColumn>
          <TableAnalyticsColumn span={1}></TableAnalyticsColumn>
        </>
      }
    />
  );
};
