import { Button } from "@sherloq/design-system";
import {
  LastQueriedFilter,
  QueriedInFilter,
  RuleContainer,
  RulesSection,
  SideViewContainer,
  SideViewFooter,
  SideViewFooterButtonsContainer,
  SideViewTitle,
} from "./components";
import { useFilterSideView } from "../../../../../hooks";
import { AssetFilterType } from "../../../../../core";
import {
  AddRuleButton,
  FilterRule,
  LabelTypography,
} from "../../../../shared-components";

interface FilterSideViewProps {
  onClickSearch?: (filters: AssetFilterType) => void;
  onClickCancel?: () => void;
}

export const FilterSideView: React.FC<FilterSideViewProps> = ({
  onClickCancel,
  onClickSearch,
}) => {
  const {
    addRule,
    filter,
    onChangeLastQueriedBy,
    onChangeQueriedIn,
    removeRule,
    onUpdateRules,
    resetFilters,
    filterColumns,
    getRuleConditions,
    getRuleIsDropdown,
    getDropdownRuleValues,
  } = useFilterSideView();
  return (
    <SideViewContainer>
      <SideViewTitle>
        Search any query or table, <br /> yours or your teammates&apos;...
      </SideViewTitle>
      <QueriedInFilter
        value={filter.queriedIn.value}
        onChange={onChangeQueriedIn}
      />
      <LastQueriedFilter
        onSelect={onChangeLastQueriedBy}
        value={filter.lastQueriedBy.value}
      />
      <RulesSection>
        <LabelTypography>Rules</LabelTypography>
        <RuleContainer>
          {filter?.rules?.map((rule, idx) => (
            <FilterRule
              columns={filterColumns as any}
              conditions={getRuleConditions(`${idx}`) ?? []}
              onUpdateRule={(rule) => onUpdateRules(idx, rule)}
              onRemoveRule={() => removeRule(idx)}
              value={filter?.rules?.[idx]}
              key={`${idx}`}
              isDropdownValues={getRuleIsDropdown(`${idx}`)}
              dropdownValues={getDropdownRuleValues(`${idx}`)}
            />
          ))}
        </RuleContainer>
        <AddRuleButton onClick={addRule}>+ Add Rule</AddRuleButton>
      </RulesSection>
      <SideViewFooter>
        <Button
          style={{
            color: "#7209B7",
          }}
          variant="link"
          onClick={resetFilters}
        >
          Reset filters
        </Button>
        <SideViewFooterButtonsContainer>
          <Button onClick={onClickCancel} variant="outlined">
            Cancel
          </Button>
          <Button
            onClick={onClickSearch?.bind(onClickSearch, filter)}
            variant="default"
          >
            Search
          </Button>
        </SideViewFooterButtonsContainer>
      </SideViewFooter>
    </SideViewContainer>
  );
};
