import { AppTypography } from "../../styles/global";
import theme from "../../styles/theme";
import styled from "styled-components";
import { TypographySize, TypographyBold } from "../../types/styles.d";

type ButtonTypes = 'submit' | 'button'

type ButtonStyleProps = {
  color?: string
  background?: string
  border?: string
  preIcon?: string
  postIcon?: string
  id?: string
  radius?: number
  size?: {
    width: string
    height: string
  }
  shadow?: boolean
  textSize?: 'xs' | 'sm' | 'md' | 'lg'
  textBold?: 'xs' | 'sm' | 'md' | 'lg'
}

interface ButtonProps extends ButtonStyleProps {
  text: string
  type: ButtonTypes
  onClick?: () => void
}

const Button = ({
  id,
  text,
  color,
  background,
  border,
  type,
  preIcon,
  postIcon,
  onClick,
  radius,
  size,
  textSize,
  textBold,
  shadow
}: ButtonProps) => {
  return (
    <ButtonStyle
      id={id}
      onClick={onClick}
      color={color}
      background={background}
      type={type}
      border={border}
      radius={radius}
      size={size}
      shadow={shadow}
      textSize={textSize}
      textBold={textBold}
    >   <ButtonContent>
            {preIcon && <Icon src={preIcon} />}
                <AppTypography 
                  size={textSize ? TypographySize?.[textSize] : TypographySize.md} 
                  bold={textBold ? TypographyBold?.[textBold] : TypographyBold.md} 
                  textColor={color ? color : theme.appColors.dark}>
                    {text}
                </AppTypography>
            {postIcon && <Icon src={postIcon} />}
        </ButtonContent>
    </ButtonStyle>
  )
}

const ButtonStyle = styled.button<ButtonStyleProps>`
  width: ${({ size }) => (size?.width ? size?.width : '100%')};
  height: ${({ size }) => (size?.height ? size?.height : '6px')};
  color: ${({ color }) => (color ? color : theme.appColors.dark)};
  border: ${({ border }) => (border ? border : 'none')};
  background: ${({ background }) =>
    background ? background : theme.appColors.white};
  padding: 1rem 1rem 1rem 1rem;
  text-align: center;
  border-radius: ${({ radius }) => (radius ? radius : 18)}px;
  outline: none;
  cursor: pointer;
  display: flex;
  justify-content: space-around;
  align-items: center;
  &:hover {
    opacity: 0.7;
  }
  filter: drop-shadow(${({ shadow }) => (shadow ? '0px 4px 4px rgba(0, 0, 0, 0.25)' : 'none')});
`

const ButtonContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3px;
`

const Icon = styled.img`
`
export default Button
