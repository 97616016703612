import React from "react";
import { TypographyBold, TypographySize } from "../../../../types/styles.d";
import { AppTypography } from "../../../../styles/global";
import { SelectedLine, SideMenuIconContainer, SideMenuItemContainer, SideMenuItemIcon } from "./ListSideMenuItem.styled";
import theme from "../../../../styles/theme";

interface SideMenuItemProps {
  icon?: string;
  label: string
  selected?: boolean;
  extras?: string;
  path: string;
  onClick?: () => void;
}

const ListSideMenuItem: React.FC<SideMenuItemProps> = ({ icon, label, selected, extras, onClick, path }) => {
  const bold = selected ? TypographyBold.lg : TypographyBold.sm;
  return (
    <SideMenuItemContainer selected={selected} to={path} onClick={onClick}>
      <SelectedLine selected={selected} />
      <SideMenuIconContainer>{icon && <SideMenuItemIcon alt={label} src={icon} />}</SideMenuIconContainer>
      <AppTypography textColor={theme.appColors.primary} size={TypographySize.sm} bold={bold}>
        {label}
      </AppTypography>
      <AppTypography textColor={theme.appColors.primary} size={TypographySize.xs} bold={bold}>
        {extras}
      </AppTypography>
    </SideMenuItemContainer>
  );
};

export default ListSideMenuItem