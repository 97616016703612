import { transformAssetDate } from "./transformAssetDate";
import { transformAssetUsername } from "./transformAssetUsername";

export class Asset<T> {
  protected _asset: T;

  constructor(_asset: T) {
    this._asset = _asset;
  }

  private getLastRefDate(lastDateRef: string) {
    if (!lastDateRef) return "-";
    return transformAssetDate(lastDateRef);
  }
  private getLastRefName(username: string | undefined) {
    return transformAssetUsername(username);
  }

  protected getAssetReference(
    lastRefDate: string,
    lastRefName: string | undefined
  ) {
    return `${this.getLastRefName(lastRefName)} ${this.getLastRefDate(
      lastRefDate
    )}`;
  }
}
