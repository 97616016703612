import styled from "styled-components";
import { Input as SherloqInput } from "@sherloq/design-system";

export const Input = styled(SherloqInput)`
  width: 250px;
`;

export const TableNameInput = styled(SherloqInput)<any>`
  width: 478px;
  background-color: #f1f1f1;
  color: #707070 !important;
`;
