import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useLoggedInUserDetails } from "../../redux/hooks";
import { appSlice } from "../../redux/AppReducer";
import {
  AppRoutes,
  QueryService,
  transformExtensionQueries,
  transformExtensionQueryFolders,
} from "../../v2/application";

export const useExtensionQueries = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loggedInUserDetails = useLoggedInUserDetails();
  const location = useLocation();
  const [loadingExtensionQueries, setLoadingExtensionQueries] = useState(false);
  const [queryFolders, setQueryFolders] = useState<any[]>([]);
  const [queryItems, setQueryItems] = useState<any[]>([]);
  const totalItems = queryFolders.length + queryItems.length;
  const myListRoute = `/lists/${loggedInUserDetails.SherloqUserId}`;
  const absoluteFolderRootPath = `${myListRoute}/extensionQueries`;
  const currentFolderPath = location.pathname.split(absoluteFolderRootPath)[1];
  const currentFolderParentPath = currentFolderPath
    .split("/")
    .slice(0, -1)
    .join("/");
  const currentFolderId = currentFolderPath.split("/").slice(-1)[0];
  //

  const onClickQuery = (query: any) => {
    const queryPageParams = {
      state: {
        assetPropData: {
          queryId: query.id,
          queryGivenName: query.title,
          description: query.description,
          listOfTablesAccessed: query.parentName,
          lastReferenceDatetime: query.lastQueriedDate,
          lastUsernameThatReferenced: query.userName,
        },
        fromPage: location.pathname,
      },
    };
    // Mixpanel.track("main_query_profile_from_user_extension_click", {
    //   from_useer: loggedInUserDetails.id,
    //   table_name: query.title,
    //   table_id: query.id,
    //   last_queried_by: query.userName,
    //   last_query_date: query.lastQueriedDate,
    // });
    navigate(`/${AppRoutes.QUERY_PROFILE}/${query.id}`);
  };

  const gotoPreviousPath = () => {
    if (currentFolderId === "root") {
      navigate(myListRoute);
    } else {
      const prevoiusAbsoluteFolder = `${absoluteFolderRootPath}${currentFolderParentPath}`;
      navigate(prevoiusAbsoluteFolder);
    }
  };

  useEffect(() => {
    if (!location.pathname.includes("root/"))
      navigate(`${absoluteFolderRootPath}/root`);
  }, []);

  useEffect(() => {
    (async () => {
      setLoadingExtensionQueries(true);
      let extensionQueries: any[] = [];
      if (currentFolderId === "root") {
        const { list } = await QueryService.getExtensionQueries();
        extensionQueries = list;
      } else {
        // fetch subfolder items
        const { list } = await QueryService.getExtensionQueries(
          currentFolderId
        );
        extensionQueries = list ?? [];
      }

      const fetchedQueryItems: any[] =
        transformExtensionQueries(extensionQueries);
      const fetchedQueryFolders: any[] =
        transformExtensionQueryFolders(extensionQueries);

      dispatch(appSlice.actions.setExtensionQueries(fetchedQueryItems));
      setQueryFolders(fetchedQueryFolders);
      setQueryItems(fetchedQueryItems);
      setLoadingExtensionQueries(false);
    })();
  }, [currentFolderId, loggedInUserDetails.Email]);

  return {
    loadingExtensionQueries,
    totalItems,
    queryFolders,
    queryItems,
    absoluteFolderRootPath,
    currentFolderPath,
    loggedInUserDetails,
    onClickQuery,
    gotoPreviousPath,
  };
};
