export enum AppRoutes {
  HOME = "home",
  HOME_QUERY_RESULTS = "query-results",
  HOME_TABLE_RESULTS = "table-results",
  LOGIN = "login",
  TABLE_PROFILE = "home/table-profile",
  QUERY_PROFILE = "home/query-profile",
  ANALYTICS = "analytics",
  LISTS = "lists"
}
