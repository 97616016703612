import { authHttpClient } from "../../infrastructure";

export type UserInputType =
  | "description"
  | "given_name"
  | "read_me"
  | "given_owner";

export function makeChangeAssetInput(
  assetType: "table" | "query",
  userInputType: UserInputType,
  onError?: any
) {
  return async function (assetId, sherloqUserId, inputString) {
    const payload = {
      userInputType,
      assetType,
      assetId,
      sherloqUserId,
      inputString,
    };
    const path = "/v1/getdata/postUserInput";
    try {
      const { data } = await authHttpClient.post(path, payload);
      return Promise.resolve(data);
    } catch (error) {
      //
      onError?.();
    }
  };
}
