import { RenderIf } from "../../../lib";
import { ListFallBack } from "../../../shared-components";
import { useTableResultsSubpage } from "../../../../hooks";
import { DefaultTable, HomeNestedNavOutlet } from "../components";

export const HomeTableResults = () => {
  const { data, isError, isLoading } = useTableResultsSubpage();
  return (
    <>
      {RenderIf(
        !!data?.length,
        <HomeNestedNavOutlet
          data={data}
          renderData={(table, key) => <DefaultTable data={table} key={key} />}
        />
      )}
      {RenderIf(
        isLoading || isError || data?.length === 0,
        <ListFallBack
          {...{
            isError,
            isLoading,
            noData: data?.length === 0,
          }}
        />
      )}
    </>
  );
};
