const parseDateToTimeSeries = (datestr: string) => {
  const dateObj = new Date(datestr);
  const day = dateObj.getDate().toString().padStart(2, "0");
  const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
  const year = dateObj.getFullYear().toString().substring(2);
  const hour = dateObj.getHours();
  const minute = dateObj.getMinutes();
  const sec = dateObj.getSeconds();
  return {
    day,
    month,
    year,
    minute,
    sec,
    hour,
    dateObj,
  };
};

export const transformAssetDate = (datestr: string) => {
  const { day, month, year } = parseDateToTimeSeries(datestr);
  return `${day}.${month}.${year}`;
};

export const transformAssetDatetoFullFormat = (datestr: string) => {
  const { day, hour, minute, month, sec, dateObj } =
    parseDateToTimeSeries(datestr);
  return `${month}/${day}/${dateObj.getFullYear()} ${hour}:${minute}:${sec}`;
};
