import React from "react";
import { AppTypography } from "../../../styles/global";
import { TypographySize, TypographyBold } from "../../../types/styles.d";
import * as Styled from "./AllLists.styled";
import AllItemCard from "../../../components/ItemCard/AllListItemCard";
import { getDateonlyFormat } from "../../../utils/helpers/date";
import Loading from "../../../components/Loading/Loading";
import { useAllLists } from "../../../v2/hooks";

function AllLists() {
  const { allList, handleAssetClick, loadingAllList } = useAllLists();
  const size = 3;
  if (loadingAllList) return <Loading size={30} />;
  return (
    <>
      <Styled.Heading>
        <AppTypography bold={TypographyBold.lg} size={TypographySize.lg}>
          All Lists
        </AppTypography>
        <AppTypography>{allList.length} Total</AppTypography>
      </Styled.Heading>
      {allList?.map((listItem, i) => (
        <>
          <AllItemCard
            key={i}
            type={listItem.type}
            title={listItem.title}
            description={listItem.description}
            userName={listItem.userName || "N/A"}
            lastQueriedDate={getDateonlyFormat(listItem.lastQueriedDate)}
            tableName={listItem.parentName}
            users={listItem.savedBy.slice(0, size)}
            onClick={() => handleAssetClick(listItem.type, listItem)}
            source={listItem.source}
          />
        </>
      ))}
    </>
  );
}

export default AllLists;
