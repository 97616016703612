import React from "react";
import * as Styled from "./Cost.styled";
import WeeklyCost from "../Tables/WeeklyCost";
import MostExpensiveQuery from "../Tables/MostExpensiveQuery";
import { useCostsReport, useGlobalAuthState } from "../../../../v2/hooks";
import { TotalCostPerQueryRun } from "../Tables/TotalCostPerQueryRunTable";
function Cost() {
  const { details } = useGlobalAuthState();
  const {
    mostExpensiveQueries,
    loadingMostExpensiveQueries,
    weeklyCost,
    loadingWeeklyCost,
    getWeeklyCostPerQueryRun,
    getTop5MostExpensiveThisWeek,
  } = useCostsReport();
  return (
    <Styled.CostContainer>
      <Styled.CostGraphContainer>
        <WeeklyCost
          type="Cost"
          sumTitle="Cost"
          avgTitle="Cost"
          callAPI={getWeeklyCostPerQueryRun}
          loading={loadingWeeklyCost}
          data={weeklyCost}
        />
      </Styled.CostGraphContainer>
      {details.CompanyId != 4 ? (
        <Styled.CostTableContainer>
          <TotalCostPerQueryRun
            itemsPerPage={5}
            title={"Total cost per workgroup"}
            loading={loadingMostExpensiveQueries}
          />
        </Styled.CostTableContainer>
      ) : (
        <></>
      )}
      {details.CompanyId != 4 ? (
        <Styled.MostExpensiveContainer>
          <MostExpensiveQuery
            assetType="cost"
            title={"5 Most Expensive Queries This Week"}
            callAPI={getTop5MostExpensiveThisWeek}
            loading={loadingMostExpensiveQueries}
            mostExpensiveQueryData={mostExpensiveQueries}
          />
        </Styled.MostExpensiveContainer>
      ) : (
        <></>
      )}
    </Styled.CostContainer>
  );
}

export default Cost;
