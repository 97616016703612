export const formatAssetDataScanned = (
  bytes: string | number,
  decimals = 2
) => {
  if (!+bytes) return "0 Bytes";
  bytes = Math.abs(Number(bytes));
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const formatAssetDuration = (d: string | number) => {
  d = Number(d);
  d = d / 1000;
  if (d == 0) return "0s";
  const h = Math.floor(d / 3600);
  const m = Math.floor((d % 3600) / 60);
  const s = Math.floor((d % 3600) % 60);

  const hDisplay = h > 0 ? h + (h == 1 ? "h " : "h ") : "";
  const mDisplay = m > 0 ? m + (m == 1 ? "m " : "m ") : "";
  const sDisplay = s > 0 ? s + (s == 1 ? "s" : "s") : "";
  return hDisplay + mDisplay + sDisplay;
};

export const formatCost = (cost: number, dp?: number) => {
  if (cost) {
    return `$${cost.toFixed(dp ?? 9)}`;
  }
  return "N/A";
};
