import { ITable } from "@/v2/core";
import { Icons } from "@sherloq/design-system";
import { RenderIf } from "../../lib";
import {
  TableContentContainer,
  TableDataContainer,
  TableDataTitle,
  TableDataValue,
  TableSourceContainer,
} from "./components";

interface TableDataContentRowProps {
  data: ITable;
}

export const TableDataContentRow: React.FC<TableDataContentRowProps> = ({
  data,
}) => {
  const { dbName, source, lastReferenced, givenOwner } = data;

  return (
    <TableContentContainer>
      <TableDataContainer>
        <TableDataTitle>DB</TableDataTitle>
        <TableDataValue>{dbName}</TableDataValue>
      </TableDataContainer>
      <TableDataContainer>
        <TableDataTitle>Owner</TableDataTitle>
        <TableDataValue>{givenOwner}</TableDataValue>
      </TableDataContainer>
      <TableDataContainer>
        <TableDataTitle>Source</TableDataTitle>
        <TableSourceContainer>
          {RenderIf(source === "athena", <Icons src="ic16athena" />)}
          {RenderIf(source === "bigquery", <Icons src="ic16bigquery" />)}
          {RenderIf(source === "snowflake", <Icons src="ic16snowflakes" />)}
          <TableDataValue>{source}</TableDataValue>
        </TableSourceContainer>
      </TableDataContainer>
      <TableDataContainer>
        <TableDataTitle>Last queried</TableDataTitle>
        <TableDataValue>{lastReferenced}</TableDataValue>
      </TableDataContainer>
    </TableContentContainer>
  );
};
