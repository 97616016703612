import dataReportIcon from "./dataReport.svg";
import dataAssetsIcon from "./dataAssets.svg";
import searchIcon from "./search.svg";
import fileIcon from "./file_icon.svg";

export default {
  dataReportIcon,
  dataAssetsIcon,
  searchIcon,
  fileIcon
};
