import React from "react";
import { AppTypography } from "../../../styles/global";
import { TypographySize, TypographyBold } from "../../../types/styles.d";
import * as Styled from "./UserList.styled";
import ItemCard from "../../../components/ItemCard/ItemCard";
import { getDateonlyFormat } from "../../../utils/helpers/date";
import EmptyList from "../components/Empty/EmptyList";
import Loading from "../../../components/Loading/Loading";
import { ExtensionFolderCard } from "../../../components/ItemCard/ExtensionFolderCard";
import { useUserList } from "../../../v2/hooks";

function UserList() {
  const {
    handleAssetClick,
    loadingUserList,
    userList,
    viewLoggedInUserExtension,
    userProfile,
    listTitle,
  } = useUserList();

  if (loadingUserList) return <Loading size={30} />;

  return (
    <>
      <Styled.Heading>
        <AppTypography bold={TypographyBold.lg} size={TypographySize.lg}>
          {listTitle}
        </AppTypography>
        <AppTypography>{userList.length} Total</AppTypography>
      </Styled.Heading>
      {userList.length <= 0 && (
        <EmptyList
          firstName={userProfile?.firstName}
          lastName={userProfile?.lastName}
        />
      )}
      {viewLoggedInUserExtension && (
        <ExtensionFolderCard
          name="Extension Queries Folder"
          path="extensionQueries/root"
        />
      )}
      {userList.length > 0 &&
        userList?.map((listItem, i) => (
          <ItemCard
            key={i}
            type={listItem.type}
            title={listItem.title}
            description={listItem.description}
            userName={listItem.userName || "N/A"}
            lastQueriedDate={getDateonlyFormat(listItem.lastQueriedDate)}
            tableName={listItem.parentName}
            onClick={() => handleAssetClick(listItem.type, listItem)}
            source={listItem.source}
          />
        ))}
    </>
  );
}

export default UserList;
