import { Typography } from "@sherloq/design-system";
import styled from "styled-components";

export const AvatarContainer = styled.div`
  padding: 9px 4px;
  border-radius: 100px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #529776;
  height: 32px;
  width: 32px;
`;

export const AvatarText = styled(Typography)`
  color: #ffffff;
`;
