import React from "react";
import { AppTypography } from "../../styles/global";
import * as Styled from "./AnalyticReport.styled";
import { TypographyBold, TypographySize } from "../../types/styles.d";
import SummaryCard from "./components/SummaryCard";
import CostTabs from "./components/AnalyticTabs";
import { Outlet } from "react-router-dom";
import SqlViewer from "../../components/SqlViewer/SqlViewer";
import { useCostReportInsights } from "../../v2/hooks";

function CostReport() {
  const {
    insights,
    openSQLViewer,
    insightQuery,
    toggleSQLViewerOpenState,
    viewInsightsQuery,
  } = useCostReportInsights();

  return (
    <Styled.Container>
      <Styled.Content>
        <Styled.Heading>
          <AppTypography bold={TypographyBold.lg} size={TypographySize.lg}>
            Your Dashboard
          </AppTypography>
        </Styled.Heading>
        <Styled.SummaryCards>
          <SummaryCard
            title={"This Week's Total Cost"}
            subtitle={"all queries from:"}
            name={"Costs"}
            highest={insights?.AllUsersTotalCost}
            average={insights?.SherloqUsersTotalCost}
            lastWeekAverage={insights?.SherloqUsersTotalCostLastWeek}
            averagePercentage={insights?.SherloqUsersTotalCostPercentage ?? ""}
            lastWeekHighest={insights?.AllUsersTotalCostLastWeek}
            highestPercentage={insights?.AllUsersTotalCostPercentage ?? ""}
            leftNodeHeader="All Users"
            rightNodeHeader="Known Users"
          />
          <SummaryCard
            title={"This Week's Costs"}
            subtitle={"per query"}
            name={"Costs"}
            highest={insights?.HighestCost}
            average={insights?.AverageCost}
            lastWeekAverage={insights?.AverageCostLastWeek}
            averagePercentage={insights?.AverageCostPercentage}
            lastWeekHighest={insights?.HighestCostLastWeek}
            highestPercentage={insights?.HighestCostPercentage}
            highestClickable
            onClickHighest={() => {
              viewInsightsQuery("cost");
            }}
          />
          <SummaryCard
            title={"This Week's Runtime"}
            subtitle={"per query"}
            name={"Runtime"}
            highest={insights?.HighestRuntime}
            average={insights?.AverageRuntime}
            lastWeekAverage={insights?.AverageRuntimeLastWeek}
            averagePercentage={insights?.AverageRuntimePercentage}
            lastWeekHighest={insights?.HighestRuntimeLastWeek}
            highestPercentage={insights?.HighestRuntimePercentage}
            highestClickable
            onClickHighest={() => {
              viewInsightsQuery("runtime");
            }}
          />
          <SummaryCard
            title={"This Week's Scanned Data"}
            subtitle={"per query"}
            name={"ScannedData"}
            highest={insights?.HighestDataScanned}
            average={insights?.AverageDataScanned}
            lastWeekAverage={insights?.AverageDataScannedLastWeek}
            averagePercentage={insights?.AverageDataScannedPercentage}
            lastWeekHighest={insights?.HighestDataScannedLastWeek}
            highestPercentage={insights?.HighestDataScannedPercentage}
            highestClickable
            onClickHighest={() => {
              viewInsightsQuery("datascanned");
            }}
          />
        </Styled.SummaryCards>
        <Styled.TabContainer>
          <CostTabs />
        </Styled.TabContainer>
        <Styled.OutletContainer>
          <Outlet />
        </Styled.OutletContainer>
      </Styled.Content>
      <SqlViewer
        onClose={toggleSQLViewerOpenState}
        show={openSQLViewer}
        query={insightQuery}
        query_id={""}
      />
    </Styled.Container>
  );
}

export default CostReport;
