import { DefaultTheme } from "styled-components";

export const theme: DefaultTheme = {
  colors: {
    primary: "#E8EBF7",
    secondary: "#FFFCBB",
    tertiary: "#0B2A97",
    addFilterButton: "#F72585",
  },
  appColors: {
    primary: "#7209B7",
    secondary: "#F72585",
    tertiary: "#3A0CA3",
    white: "#FFFFFF",
    transparent: "transparent",
    ash: "#B1B5BB",
    dark: "#18191A",
    pinkBackground: "#F72585",
  },
  graphLegend: {
    best: "#43AA8B",
    good: "#90BE6D",
    ok: "#F9C74F",
    warn: "#F8961E",
    bad: "#F3722C",
    worst: "#F94144",
  },
  tableColors: {
    headerBackground: "#EEEEEE",
  },
  inputsColors: {
    background: "#EDF0F9",
    border: "#AFC0F9",
  },
  statusColors: {
    danger: "#F94144",
    warning: "#FFD500",
    ok: "#43AA8B",
  },

  typography: {
    size: { xs: "10px", sm: "12px", md: "15px", lg: "24px", xl: "35px" },
    colors: { primary: "black", secondary: "#031277", tertiary: "#F72585" },
    bold: { sm: "400", md: "500", lg: "800" },
  },
  spacing: {
    xs: "6px",
    sm: "12px",
    md: "18px",
    lg: "30px",
  },
  borderColor: "rgba(0, 0, 0, 0.5)",
  borderRadius: {
    sm: "8px",
    md: "12px",
    lg: "25px",
  },
  breakpoints: {
    xs: "600px",
    sm: "800px",
    md: "1000px",
    lg: "1200px",
    xl: "1400px",
  },
  appBar: {
    height: "60px",
  },
};
