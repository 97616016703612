import React from "react";
import { Typography } from "../../../../shared-components";
import styled from "styled-components";

export const StatisticsContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  border-bottom-width: 1px;
  border-bottom-color: #d9d9d9;
  border-bottom-style: solid;
  padding-bottom: 24px;
`;

export const UnusedFieldsContainer = styled.div`
  margin-top: 24px;
`;

export const Header = styled(Typography)`
  margin-bottom: 16px;
`;

export const DescriptionText = ({ children, ...props }) =>
  React.createElement(
    Typography,
    { variant: "small", color: "#707070", ...props },
    children
  );
