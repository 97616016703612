import React, { ReactNode, useState } from "react";
import { initialTableProfileState, TableProfileContext } from "./contexts";
import { TableProfileGlobalState } from "./interface";

export const TableProfileProvider = ({ children }: { children: ReactNode }) => {
  const [state, setState] = useState<TableProfileGlobalState>(
    initialTableProfileState
  );

  return (
    <TableProfileContext.Provider value={[state, setState]}>
      {children}
    </TableProfileContext.Provider>
  );
};

export * from "./contexts";

export type {
  TableProfileGlobalState,
  TableProfileContextType,
} from "./interface";
