import React from "react";
import styled from "styled-components";
import { Badge, Typography } from "../../../../../shared-components";

import { DefaultContainer } from "../../shared";

export const DefaultTableContainer = styled(DefaultContainer)`
  flex-direction: column;
  :hover {
    cursor: pointer;
    background-color: #f1f1f1;
  }
`;

export const DefaultTableBadge = () =>
  React.createElement(Badge, { backgroundColor: "#0075E720" }, "Table");

export const DefaultTableName = styled(Typography)`
  color: #252525;
  margin-top: 16px;
`;
