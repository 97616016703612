import { AppTypography } from "../../styles/global";
import { TypographyBold, TypographySize } from "../../types/styles.d";
import { FlexRow } from "../../atoms/Row/row.styled";
import routeImages from "../../assets/images/routes";
import styled from "styled-components";

export const EmptySearch = () => {
  return (
    <Container>
      <img src={routeImages.searchIcon} />
      <AppTypography bold={TypographyBold.md} size={TypographySize.lg}>
        We couldn’t find anything that matches your search :(
      </AppTypography>
    </Container>
  );
};

const Container = styled(FlexRow)`
  & > img {
    margin-right: 10px;
  }
`;
