export function GetUserInfo(token: string) {
    // const state = useReduxSelector((state: RootState) => state);
    // const user = state.auth.user.Username;
    // const token = state.auth.user.Token;
    const userInfo = parseJwt(token);
    return userInfo;
}

// export const { user, firstName, lastName } = GetUserInfo();

function parseJwt(token: string) {
  if (!token) { return; }
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace('-', '+').replace('_', '/');
  return JSON.parse(window.atob(base64));
}