import React from "react";
import { Badge as SherloqBadge } from "@sherloq/design-system";

interface BadgeProps extends React.HTMLAttributes<HTMLDivElement> {
  backgroundColor?: string;
  color?: string;
}

export const Badge: React.FC<BadgeProps> = ({
  backgroundColor,
  color,
  style,
  ...props
}) => {
  return (
    <SherloqBadge style={{ backgroundColor, color, ...style }} {...props} />
  );
};
