import * as Styled from "./extensionQueries.styled";
import { AppTypography } from "../../styles/global";
import { TypographyBold, TypographySize } from "../../types/styles.d";
import EmptyList from "../Lists/components/Empty/EmptyList";
import Loading from "../../components/Loading/Loading";
import { ExtensionQueryItemsCard } from "../../components/ItemCard/ExtensionQueryItemCards";
import { ExtensionFolderCard } from "../../components/ItemCard/ExtensionFolderCard";
import BackButton from "../../components/BackButton/BackButton";
import { useExtensionQueries } from "./useExtensionQueries";

export const ExtensionQueriesRootView = () => {
  const {
    loadingExtensionQueries,
    totalItems,
    queryFolders,
    queryItems,
    absoluteFolderRootPath,
    currentFolderPath,
    loggedInUserDetails,
    gotoPreviousPath,
    onClickQuery,
  } = useExtensionQueries();

  if (loadingExtensionQueries)
    return (
      <Styled.LoadingContainer>
        <Loading size={20} height={5} />
      </Styled.LoadingContainer>
    );
  return (
    <>
      <Styled.Container>
        <Styled.Heading>
          <AppTypography bold={TypographyBold.lg} size={TypographySize.lg}>
            {`${loggedInUserDetails.FirstName} ${loggedInUserDetails.LastName}'s List`}
          </AppTypography>
          <AppTypography>{totalItems} Total</AppTypography>
        </Styled.Heading>
        <Styled.RowContainer align="center" justify="flex-start">
          <BackButton title="go back" onClick={() => gotoPreviousPath()} />
        </Styled.RowContainer>
        {totalItems < 1 && (
          <Styled.EmptyListContainer>
            <EmptyList />
          </Styled.EmptyListContainer>
        )}
        {queryFolders.map((folder, i) => {
          return (
            <ExtensionFolderCard
              key={i}
              name={folder.name}
              path={`${absoluteFolderRootPath}${currentFolderPath}/${folder.uid}`}
            />
          );
        })}
        {queryItems.map((query, i) => {
          return (
            <ExtensionQueryItemsCard
              onClick={() => onClickQuery(query)}
              data={query}
              key={i}
            />
          );
        })}
      </Styled.Container>
    </>
  );
};
