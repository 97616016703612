import React from "react";
import {
  IconButton,
  IconButtonProps,
  TypographyProps,
} from "@sherloq/design-system";
import styled from "styled-components";
import { Typography } from "../../../shared-components";

const Row = styled.div`
  display: flex;
`;

export const TableProfileContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  padding-right: 20px;
  box-sizing: border-box;
`;

export const TableUtilsWrapper = styled(Row)`
  column-gap: 12px;
  align-items: center;
`;

const TableUtilIconButton = styled(IconButton)`
  border: none !important;
  color: #252525 !important;
  background-color: transparent !important;
  padding: 0 !important;
`;
export const TableUtil = ({ children, ...props }: IconButtonProps) =>
  React.createElement(
    TableUtilIconButton,
    { variant: "outlined", ...props },
    children
  );

export const TableTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  margin-top: 8px;
`;

export const ScrollableTableContentContainer = styled.main`
  padding-right: 2px;
  width: 100%;
  height: 60vh;
  margin-bottom: 20px;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const TableUsageContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 24px;
`;

export const TableProfileSectionHeader = styled.thead`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  > * {
    margin: auto 0;
  }
`;

export const Table = styled.table`
  width: 100%;
  height: fit-content;
`;

export const TopUserRowDescContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 4px;
`;

export const FullWidthContainer = styled.div`
  width: 100%;
`;

export const MostCommonFieldsHeader = styled.header`
  display: flex;
  justify-content: space-between;

  & > * {
    margin: auto 0;
  }
`;

export const MostCommonFieldsRadioContainer = styled.div`
  display: flex;
  column-gap: 7px;
`;

export const QueryUsageContentContainer = styled(TableUsageContainer)`
  margin: 40px 0 20px 0;
`;

export const MostUsedQuerySection = styled.div`
  width: 100%;
`;

const QueryUsageDescTypograhy = styled(Typography)<{ inline?: boolean }>`
  display: ${({ inline }) => (inline ? "inline" : "block")};
`;

export const QueryUsageDesc = ({
  children,
  inline,
  ...props
}: TypographyProps & { inline?: boolean }) =>
  React.createElement(
    QueryUsageDescTypograhy,
    { variant: "small", inline, ...props },
    children
  );

export const MostUsedQueryUtilsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
`;

export const MostCommonFieldsGraphContainer = styled.div`
  width: 100%;
  height: 390px;
`;

export const ReadMeContainer = styled.div`
  height: calc(100vh - 200px);
`;

export * from "./tableProfileRow";
export * from "./topFieldsRow";
export * from "./topFieldsSideView";
export * from "./queryUsageSideView";
export * from "./topFields";
export * from "./topUsers";
export * from "./mostCommonFields";
export * from "./mostJoinedTables&Fields";
export * from "./mostUsedQuery";
export * from "./lastQueryRun";
export * from "./editTableInfoModal";
export * from "./tableStatisticsSideView";
