import {
  TableService,
  transformTableFields,
  DefualtQueriedInFilterOptions,
  getTopFieldsRuleConditions,
  getTotalTableFieldsRefs,
} from "../../application";
import { useEffect, useMemo } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { useAssetFilters } from "../useAssetFilters";
import { useGlobalAuthState } from "../user";
import { LabelValue } from "../../ui/lib";

export const TopFieldsFilterColumns: LabelValue[] = [
  { label: "fields", value: "" },
];

export const useTopFieldsSideView = () => {
  const {
    addRule,
    filter,
    onChangeLastQueriedBy,
    onChangeQueriedIn,
    onUpdateRules,
    removeRule,
    resetFilters,
  } = useAssetFilters();
  const { users } = useGlobalAuthState();

  const { id } = useParams();
  const {
    data: fetchedData,
    refetch,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["table-fields", id],
    enabled: false,
    queryFn: () => TableService.getTableFields(id ?? "", filter),
    initialData: [],
  });

  const data = useMemo(() => {
    const transformedData = transformTableFields(fetchedData);
    return transformedData;
  }, [fetchedData]);

  const totalRefs = useMemo(() => {
    if (data.length > 0) return getTotalTableFieldsRefs(data);
    return 0;
  }, [data]);

  const onApply = () => {
    refetch();
  };

  const onResetFilters = () => {
    resetFilters();
    refetch();
  };

  const getConditions = (idx: string) => {
    const rule = filter.rules[idx];
    const cond = getTopFieldsRuleConditions(rule?.column);
    return cond ?? [];
  };

  useEffect(() => {
    refetch();
  }, [id, filter]);

  return {
    data,
    isError,
    isLoading,
    filter,
    addRule,
    onChangeLastQueriedBy,
    onChangeQueriedIn,
    onUpdateRules,
    onApply,
    removeRule,
    resetFilters,
    QueriedInFilterOptions: DefualtQueriedInFilterOptions,
    users,
    getConditions,
    onResetFilters,
    totalRefs,
  };
};
