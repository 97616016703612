import { makeUpdateQueryInfoUsecase } from "../../application";
import {
  AnalyticsClient,
  AuthState,
  notificationSnackbar,
} from "../../infrastructure";
import { useEffect, useState } from "react";
import { useGlobalStateSelector } from "../redux";
import { useMutation } from "react-query";

interface EditQueryInfoModalProps {
  onSuccess?: ({ queryName, queryDescription }) => void;
  queryId: string;
  currentName: string;
  currentDescription: string;
}

export const useEditQueryInfoModal = ({
  onSuccess,
  queryId,
  currentDescription,
  currentName,
}: EditQueryInfoModalProps) => {
  const { user } = useGlobalStateSelector(
    (state: any) => state.auth as AuthState
  );
  const [queryName, setQueryName] = useState("");
  const [queryDescription, setDescription] = useState("");
  const { mutateAsync, isLoading } = useMutation(
    () =>
      makeUpdateQueryInfoUsecase({
        assetId: queryId,
        sherloqUserId: user?.SherloqUserId,
        queryDescription,
        queryName,
      }),
    {
      mutationKey: `update-table-profile-description`,
      onError: () => {
        notificationSnackbar.error("Failed to update Query Info");
        AnalyticsClient.trackAction("main_query_profile_edit_info_failed");
      },
      onSuccess: () => {
        notificationSnackbar.success("Successfully updated Query info");
        AnalyticsClient.trackAction("main_query_profile_edit_info_success");
        onSuccess?.({
          queryDescription,
          queryName,
        });
      },
    }
  );

  const onChangeDescInput = (e?: any) => setDescription(e.target.value);
  const onChangeNameInput = (e?: any) => setQueryName(e.target.value);
  useEffect(() => {
    setQueryName(currentName);
    setDescription(currentDescription);
  }, [currentDescription, currentName]);

  return {
    onChangeDescInput,
    queryDescription,
    onChangeNameInput,
    queryName,
    onUpdateQueryInfo: mutateAsync,
    isLoading,
  };
};
