import { Icons } from "../../assets/icons";

export interface SortButtonProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, "onClick"> {
  onClickUp?: any;
  onClickDown?: any;
}

export const SortButton: React.FC<SortButtonProps> = ({
  onClickDown,
  onClickUp,
  ...props
}) => {
  return (
    <div {...props}>
      <img
        style={{ display: "block", cursor: "pointer" }}
        onClick={onClickUp}
        src={Icons.SortButtonUp}
      />
      <img
        style={{ display: "block", cursor: "pointer" }}
        onClick={onClickDown}
        src={Icons.SortButtonDown}
      />
    </div>
  );
};
