interface ITableProfileMostCommonFieldsByField {
  FieldName: number;
  GroupBy: number;
  Having: number;
  Join: number;
  OrderBy: number;
  PartitionOver: number;
  Select: number;
  Where: number;
}

const operatorKeys = {
  Where: "Where",
  Select: "Select",
  Join: "Join",
  Order: "Order",
  Groupby: "Group By",
  PartitionOver: "Partition Over",
  Having: "Having",
};

export const transformMostCommonFieldsByFieldGraphDataBy = (data: any[]) => {
  const graphData = data.map(
    (fieldData: any): ITableProfileMostCommonFieldsByField => ({
      FieldName: fieldData.fieldName,
      GroupBy: fieldData.numGroupByReferences,
      Having: fieldData.numHavingReferences,
      Join: fieldData.numJoinReferences,
      OrderBy: fieldData.numOrderByReferences,
      PartitionOver: fieldData.numPartitionOverReferences,
      Select: fieldData.numSelectReferences,
      Where: fieldData.numWhereReferences,
    })
  );
  const keys = Object.keys(graphData[0]).filter((el) => el != "FieldName");
  if (graphData.length > 5) graphData.length = 5;
  return { graphData, keys, indexBy: "FieldName" };
};

export const transformMostCommonFieldsByOperatorGraphDataBy = (data: any) => {
  const newData: any = [];
  const finalData: any = [];

  if (Object.keys(data).length === 0) return;
  for (const name in operatorKeys) {
    newData.push({
      nivoGraphName: operatorKeys[name],
      keys: data[name].map((el: { field_name: string }) => el.field_name),
      values: data[name].map((el: { [x: string]: any }) => {
        const valueKeys = Object.keys(el);
        return el[valueKeys[1]];
      }),
    });
  }
  newData.forEach(
    (el: { keys: any[]; values: { [x: string]: any }; nivoGraphName: any }) => {
      const obj = {};
      el.keys.forEach(
        (key: string | number, i: string | number) => (obj[key] = el.values[i])
      );
      finalData.push({
        nivoGraphName: el.nivoGraphName,
        ...obj,
      });
    }
  );
  let keys = finalData
    .map((el: any) => [...Object.keys(el)])
    .flat()
    .filter((el: any) => el != "nivoGraphName");
  keys = new Set(keys);
  keys = [...keys];

  return {
    graphData: finalData,
    keys,
    indexBy: "nivoGraphName",
  };
};
