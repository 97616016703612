import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import { authReducer } from "./auth";
import { homeReducer } from "./home";

const persistConfig = {
  key: "main",
  storage,
};

const AuthReducer = persistReducer(persistConfig, authReducer);

export const appStore = configureStore({
  reducer: {
    auth: AuthReducer,
    home: homeReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});
export const persistedAppStore = persistStore(appStore);
