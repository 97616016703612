import React, { useMemo } from "react";
import { AssetSourcePlatformsType } from "../../../core";

interface AssetSourceParams {
  source: AssetSourcePlatformsType;
  render: (iconSrc: string | null, source) => JSX.Element | null;
}

export const RenderAssetSource: React.FC<AssetSourceParams> = ({
  source,
  render,
}: AssetSourceParams) => {
  const iconSource = useMemo(() => {
    switch (source) {
      case "athena":
        return "ic16athena";
      case "bigquery":
        return "ic16bigquery";
      case "snowflake":
        return "ic16snowflakes";
      default:
        return null;
    }
  }, [source]);

  return <>{render(iconSource, source)}</>;
};
