import { AppTypography } from '../../styles/global';
import React, { useEffect, useRef, useState } from 'react'
import styled from "styled-components";
import theme from '../../styles/theme';
import { TypographySize } from '../../types/styles.d';
import { Mixpanel } from "../../mixpanelWrapper";

type SelectStyleProps = {
    color?: string
    background?: string
    border?: string
    showIcon?: boolean
    preIcon?: string
    postIcon?: string
    postIconSize?: string
    preIconSize?: string
    positionLeft?: string
    positionRight?: string
    radius?: number
}

type DropdownProps = {
    show?: boolean
    positionLeft?: string
    positionRight?: string
}

interface ButtonProps extends SelectStyleProps {
    text: string | JSX.Element,
    children?: JSX.Element,
    wrapper?: JSX.Element,
    options?: Array<{
        value: string
        handler?: () => void
        dropdownTextColor?: string
    }>
}

const SelectDropdownButton = ({
    options,
    color,
    background,
    border,
    text,
    children,
    wrapper,
    radius,
    preIcon,
    postIcon,
    postIconSize,
    preIconSize,
    positionLeft,
    positionRight,
}: ButtonProps) => {
    const [show, setShow] = useState(false)
    const wrapperRef = useRef(null)

    useOutsideAlerter(wrapperRef, show)

    function closeDropdown() {
        Mixpanel.track("main_filter_table_top_users_close");
        setShow(false)   
    }
    function useOutsideAlerter(ref: any, show: boolean) {
        useEffect(() => {
            function handleClickOutside(event: { target: any }) {
                if (ref.current && !ref.current.contains(event.target)) {
                    if (show == true) {
                        Mixpanel.track("main_filter_table_top_users_close");
                    }
                    setShow(!show);
                }
            }
            const element = document.getElementById("closeBtnForDrop")
            if (show) {
                document.addEventListener("mousedown", handleClickOutside)
                element?.addEventListener("click", closeDropdown)
            }
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
                element?.removeEventListener("click", closeDropdown)
            };
        }, [ref, show]);
    }
    
    useEffect(() => {
        const cancelEl = document.querySelector('#SelectDropdownCancel')
        cancelEl?.addEventListener('click', closeDropdown)
        return () => {
          cancelEl?.removeEventListener('click', closeDropdown)
        }
      }, [])

    return (
        <SelectWrapper ref={wrapperRef}>
            <ButtonStyle
                onClick={() => setShow(!show)}
                color={color}
                background={background}
                border={border}
                radius={radius}
            >

                <ButtonContent>
                    {preIcon && <Icon width={preIconSize} src={preIcon} />}
                    {
                        typeof text === 'string' && <AppTypography size={TypographySize.md} textColor={color ? color : theme.appColors.dark}>
                            {text}
                        </AppTypography>
                    }
                    {
                        typeof text === 'object' && <span onClick={() => setShow(!show)}>{text}</span>
                    }
                    {postIcon && <Icon width={postIconSize} src={postIcon} />}
                </ButtonContent>
                {children}
            </ButtonStyle>
            <DropDownContent show={show} positionLeft={positionLeft} positionRight={positionRight}>
                {options?.map((option, i) => (
                    <OptionValueWrapper key={i}>
                        <div onClick={option.handler}>
                            <OptionValue color={option.dropdownTextColor}>{option.value}</OptionValue>
                        </div>
                    </OptionValueWrapper>
                ))}
                {wrapper && wrapper}

            </DropDownContent>
        </SelectWrapper>
    )
}

const SelectWrapper = styled.div`
 `
const ButtonContent = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3px;
`
const DropDownContent = styled.div<DropdownProps>`
  display: ${({ show }) => (show ? 'block' : 'none')};
  position: absolute;
  right: ${({ positionRight }) => (positionRight ? positionRight+'px' : '30px')};
  background-color: ${theme.appColors.white};
  min-width: 160px;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.2);
  margin-top: 2px;
  z-index: 9999;
  border: 1px solid #BFBFBF;
`
const OptionValue = styled.div`
  color: ${({ color }) => (color ? color : 'black')};
  padding: 12px 16px;
  text-decoration: none;
  display: block;
`
const Icon = styled.img`
    width: ${({ width }) => (width ? width+'px' : '1.3rem')};
`
const OptionValueWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px;
  &:hover {
    background-color: ${theme.appColors.ash};
    cursor: pointer;
  }
`
const ButtonStyle = styled.button<SelectStyleProps>`
  width: 100%;
  height: 10px;
  color: ${({ color }) => (color ? color : theme.appColors.dark)};
  border: ${({ border }) => (border ? border : 'none')};
  background: ${({ background }) =>
        background ? background : theme.appColors.white};
  padding: 1rem 1rem 1rem 1rem;
  text-align: center;
  border-radius: ${({ radius }) => (radius ? radius : 18)}px;  outline: none;
  cursor: pointer;
  display: flex;
  justify-content: space-around;
  align-items: center;

  &:hover {
    opacity: 0.7;
  }
`

export default SelectDropdownButton