import {
  AssetFilterRule,
  AssetFilterType,
  AssetSourcePlatformsType,
} from "./interfaces";

export const AssetSourcePlatforms: AssetSourcePlatformsType[] = [
  "athena",
  "bigquery",
  "snowflake",
];

export const defaultAssetFilterRule: AssetFilterRule = Object.freeze({
  column: "",
  condition: "",
  value: "",
});

export const AssetLastQueriedByDefaultFilter: AssetFilterRule = {
  column: "lastUsernameThatReferenced",
  condition: "inList",
  value: [],
};

export const AssetLastQueriedInDefaultFilter: AssetFilterRule = {
  column: "lastReferenceDatetime",
  condition: "pastDays",
  value: "",
};

export const defaultAssetFilter: AssetFilterType = Object.freeze({
  queriedIn: AssetLastQueriedInDefaultFilter,
  lastQueriedBy: AssetLastQueriedByDefaultFilter,
  rules: [],
});

export const AssetSortValues = ["lastUsed", "numOfReferences", "cost"];
