import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  QueryService,
  transformRumtimeWeekylCostPerQuery,
  WeeklyCostReportType,
} from "../../application";

interface UserQeeklyCostReportParams {
  averageYIndex: (data: any) => number;
  sumYIndex: (data: any) => number;
  type: WeeklyCostReportType;
}

export const useWeeklyCostReport = ({
  type,
  averageYIndex,
  sumYIndex,
}: UserQeeklyCostReportParams) => {
  const location = useLocation();
  const [mostExpensiveQueries, setMostExpensiveQueries] = useState([]);
  const [loadingMostExpensiveQueries, setLoadingMostExpensiveQueries] =
    useState(false);
  const [weeklyCost, setWeeklyCost] = useState<any>([]);
  const [loadingWeeklyCost, setLoadinWeeklyCost] = useState(false);

  const getTop5MostExpensiveThisWeek = async (filters?: string) => {
    try {
      setLoadingMostExpensiveQueries(true);
      const queries = await QueryService.getTop5MostExpensiveWeeklyQueries(
        type,
        filters
      );
      setMostExpensiveQueries(queries);
    } catch (err) {
      console.error(err);
    } finally {
      setLoadingMostExpensiveQueries(false);
    }
  };

  const getWeeklyCostPerQueryRun = async (
    workgroupsFilter?: string | undefined,
    field?: "average" | "sum"
  ) => {
    const indexFn = field === "average" ? averageYIndex : sumYIndex;
    try {
      setLoadinWeeklyCost(true);
      const weeklyCostData = await QueryService.getWeeklyCostPerQueryRun(
        type,
        workgroupsFilter
      );
      const weeklyCostDataStructure = transformRumtimeWeekylCostPerQuery(
        weeklyCostData,
        indexFn
      );
      setWeeklyCost(weeklyCostDataStructure);
    } catch (err) {
      console.error(err);
    } finally {
      setLoadinWeeklyCost(false);
    }
  };

  useEffect(() => {
    const path = `/analytics/${type}`;
    if (location.pathname.includes(path)) getTop5MostExpensiveThisWeek();
    getWeeklyCostPerQueryRun();
  }, [type, location.pathname]);
  return {
    mostExpensiveQueries,
    loadingMostExpensiveQueries,
    weeklyCost,
    loadingWeeklyCost,
    getWeeklyCostPerQueryRun,
    getTop5MostExpensiveThisWeek,
  };
};
