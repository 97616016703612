import styled from "styled-components";
import { Link } from 'react-router-dom'
interface SideMenuItemContainerProps {
  selected?: boolean;
}
interface SelectedLineProps {
  selected?: boolean;
}

export const SideMenuItemContainer = styled(Link)<SideMenuItemContainerProps>`
  gap: ${({ theme }) => theme.spacing.sm};
  background-color: ${({ theme, selected }) => (selected ? theme.colors.primary : "transparent")};
  border-top-right-radius: ${({ theme }) => theme.borderRadius.lg};
  border-bottom-right-radius: ${({ theme }) => theme.borderRadius.lg};
  height: 45px;
  width: 100%;
  display: flex;
  flex-direction: row;
  text-decoration: none;
  align-items: center;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;

export const SideMenuItemIcon = styled.img`
  transform: scale(0.8);
  padding-left: ${({ theme }) => theme.spacing.md}; ;
`;

export const SelectedLine = styled.div<SelectedLineProps>`
  background-color: ${({ theme, selected }) => (selected ? theme.colors.tertiary : "transparent")};
  border-radius: ${({ theme }) => theme.borderRadius.lg};
  width: 5px;
  height: 100%;
`;

export const SideMenuIconContainer = styled.div`
  width: 35px;
  align-items: center;
  display: flex;
  height: 100%;
`;
