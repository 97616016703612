import React from "react";
import "./index.scss";
import { StaticIcons } from "../../../../../assets/icons";
import { SearchInputWrapper, Icon, IconWrapper } from "./components";

export interface SearchInput {
  inputProps?: React.HtmlHTMLAttributes<HTMLInputElement>;
  className?: string;
  onSearch: (query: string) => void;
  placeholder?: string;
  isClearable?: boolean;
  defaultValue?: string;
}

const SearchInput: React.FC<SearchInput> = ({
  inputProps,
  className,
  onSearch,
  placeholder,
  isClearable,
  defaultValue,
}) => {
  const [value, setValue] = React.useState(defaultValue ?? "");
  const [isHovered, setIshovered] = React.useState(false);
  const handleSetHover = (state: boolean) => {
    setIshovered(state);
  };
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    onSearch(e.target.value);
  };

  const clear = () => {
    setValue("");
  };

  return (
    <SearchInputWrapper className={`${"search-input"} ${className ?? ""}`}>
      <Icon src={StaticIcons.ic_24searchPreview} />
      <input
        id="searchString"
        onChange={onChange}
        value={value}
        {...inputProps}
        className="input"
        placeholder={placeholder}
        autoComplete="off"
      />
      {isClearable && (
        <IconWrapper>
          <Icon
            onClick={() => clear()}
            onMouseOver={() => handleSetHover(true)}
            onMouseOut={() => handleSetHover(false)}
            src={isHovered ? "Ic16closeHover" : "Ic16closePreview"}
          />
        </IconWrapper>
      )}
    </SearchInputWrapper>
  );
};

export default SearchInput;
