import { dropdownConditions, sourceConditions, textConditions } from "../asset";

export const filters = [
  "businessUnit",
  "deptName",
  "jobTitle",
  "tableName",
  "dbName",
  "deptName",
  "source",
] as const;

export const PublicTableFilterColumns = [
  {
    label: "Table Name",
    value: "tableName",
  },
  {
    label: "Database Name",
    value: "dbName",
  },
] as const;

export const AppsFlyerTableColumns = [
  ...PublicTableFilterColumns,
  {
    label: "Department",
    value: "deptName",
  },
  {
    label: "Business Unit",
    value: "businessUnit",
  },
  {
    label: "Job Title",
    value: "jobTitle",
  },
  {
    label: "Source",
    value: "source",
  },
];

export const getFilterTableConditionsFromColumn = (
  column: (typeof filters)[number]
) => {
  //
  switch (column) {
    case "tableName":
      return textConditions;
    case "dbName":
      return textConditions;
    case "deptName":
      return dropdownConditions;
    case "businessUnit":
      return dropdownConditions;
    case "jobTitle":
      return dropdownConditions;
    case "source":
      return sourceConditions;

    default:
      return [];
  }
};
