import React from "react";
import { Loader, LoaderContainer } from "./components";

interface LoadingProps {
  size?: number;
  color?: string;
  height?: number;
}
export const LoadingComponent = ({
  size = 50,
  color,
  height,
}: LoadingProps) => {
  return (
    <LoaderContainer height={height}>
      <Loader size={size} color={color} />
    </LoaderContainer>
  );
};
