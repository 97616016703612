import styled from "styled-components";

export const Container = styled.div<{ opened?: boolean }>`
  display: flex;
  flex-direction: column;
  border: 1px solid #00000023;
  height: 100%;
  border-radius: 3px;
  width: ${({ opened }: any) => (opened ? "414px" : "58px")};
  box-sizing: border-box;
  transition: width 0.2s;
`;

export const Header = styled.header`
  display: flex;
  height: 72px;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
  background-color: #f1f1f1;
  padding: 0 16px 0 16px;
  border-bottom-width: 1px;
  border-bottom-color: #00000023;
  border-bottom-style: solid;
`;

export const PreHeaderRow = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
`;

export const Icon = styled.img`
  height: 24px;
  width: 24px;
`;

export const ButtonIcon = styled(Icon)<{ rotate?: boolean }>`
  transform: ${({ rotate }: any) => (rotate ? " " : "rotate(180deg)")};

  :hover {
    cursor: pointer;
  }
`;

export const Body = styled.textarea`
  resize: none;
  padding: 16px;
  flex: 1;
  min-height: 300px;
  flex-shrink: 0;
  border: none;
  border-radius: 0;
  font-weight: 300;
  font-family: "Rubik";
  line-height: 20.02px;
  font-size: 14px;

  ::placeholder {
    color: #00000080;
  }
  :focus {
    outline: none;
  }
`;
