import {
  ITableProfileQueryUsage,
  transformAssetDatetoFullFormat,
  transformAssetUsername,
} from "../../core";
import { formatAssetDataScanned, formatAssetDuration } from "../asset";

export interface ITransformedTableProfileQueryUsageRow {
  query: string;
  listOfTables: string;
  numOfUsers: number;
  lastRunTime: string;
  lastUserThatReferenced: string;
  id: number;
  runtime: string;
  dataScanned: string;
}

export const transformTableProfileQueriesUsageFactoryFn = (
  data: ITableProfileQueryUsage
) => {
  const {
    queryString: query,
    numOfReferences,
    listOfTablesAccessed,
    lastUsernameThatReferenced,
    lastReferenceDatetime,
    queryId,
    lastQueryDurationInMillis,
    lastQueryDataScannedInBytes,
  } = data;
  const transformedRuntime = transformAssetDatetoFullFormat(
    lastReferenceDatetime
  );
  const runtime = formatAssetDuration(lastQueryDurationInMillis);
  const dataScanned = formatAssetDataScanned(lastQueryDataScannedInBytes);
  const transformedData: ITransformedTableProfileQueryUsageRow = {
    query,
    lastRunTime: transformedRuntime,
    lastUserThatReferenced: transformAssetUsername(lastUsernameThatReferenced),
    listOfTables: listOfTablesAccessed?.join(", "),
    numOfUsers: numOfReferences,
    id: queryId,
    dataScanned,
    runtime,
  };
  return transformedData;
};
