import { StaticIcons } from "../../assets";
import { TypographyProps } from "@sherloq/design-system";
import {
  HeaderWithInfoIconContainer,
  InfoIconContainer,
  Text,
} from "./components";
import { Tooltip, TooltipProps } from "@mui/material";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  iconBackgroundColor?: string;
  iconContainerProps?: React.HTMLAttributes<HTMLDivElement>;
  textProps?: TypographyProps;
  tooltipText?: string;
  tooltipProps?: Omit<TooltipProps, "title">;
}

export const AssetProfileSectionHeaderWithInfo: React.FC<Props> = ({
  children,
  iconBackgroundColor,
  iconContainerProps,
  textProps,
  tooltipProps,
  tooltipText,
  ...props
}) => {
  return (
    <HeaderWithInfoIconContainer {...props}>
      <Text {...textProps}>{children}</Text>
      <Tooltip title={tooltipText} placement="top" {...tooltipProps}>
        <InfoIconContainer
          {...iconContainerProps}
          backgroundColor={iconBackgroundColor}
        >
          <img src={StaticIcons.ic16InfoPreview} />
        </InfoIconContainer>
      </Tooltip>
    </HeaderWithInfoIconContainer>
  );
};
