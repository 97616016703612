import { ReactNode } from "react";
import { FieldTableRowContainer, TextValue } from "./components";

interface Props extends React.HTMLAttributes<HTMLTableCellElement> {
  children: ReactNode;
  value?: string | number;
  onClick?: () => void | undefined;
}

export const FieldTableRow: React.FC<Props> = ({
  children,
  value,
  onClick,
  ...props
}) => {
  return (
    <tr>
      <FieldTableRowContainer onClick={onClick} {...props}>
        {children}
        <TextValue variant="p-light">{value}</TextValue>
      </FieldTableRowContainer>
    </tr>
  );
};
