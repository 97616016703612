import {
  AnalyticsClient,
  clientStorage,
  decodeToken,
  env,
  googleUserLogin,
  notificationSnackbar,
} from "../../infrastructure";
import { useEffect, useLayoutEffect } from "react";
import { useNavigate } from "react-router-dom";
import { makeUserLoginFactory, userService } from "../../application";
import { useGlobalStateDispatch } from "../redux";
import { useGlobalAuthState } from "./useGlobalAuth";

const google = window.google;

const loginUserManager = makeUserLoginFactory(
  AnalyticsClient,
  clientStorage,
  decodeToken
);

export const useLoginWithGoogle = () => {
  const navigate = useNavigate();
  const dispatch = useGlobalStateDispatch();
  const { isLoggedIn } = useGlobalAuthState();

  async function handleCredentialResponse(response) {
    try {
      await dispatch(
        googleUserLogin({
          bearer: response.credential,
          onSuccessCallBack: loginUserManager.onSuccess,
          onFailedCallback: (error, data) =>
            Promise.reject(loginUserManager.onFailure(error, data)),
          loginUserRequest: userService.googleLogin as any,
        })
      );
      notificationSnackbar.success("Login successful");
      navigate("/home", { replace: true });
    } catch (error: any) {
      notificationSnackbar.error("Login failed");
    }
  }

  useLayoutEffect(() => {
    if (!isLoggedIn) {
      google?.accounts?.id?.initialize({
        client_id: env.googleClientId,
        callback: handleCredentialResponse,
      });
      google?.accounts?.id?.renderButton(
        document.getElementById("googleButtonDiv"),
        { theme: "outline", size: "large", width: 1100, height: 50 }
      );
      google?.accounts?.id?.prompt();
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/home", { replace: true });
    }
  }, [isLoggedIn]);
};
