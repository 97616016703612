import { IconButton, IconButtonProps } from "@sherloq/design-system";
import React from "react";
import styled from "styled-components";

export const AssetUtil = ({ children, ...props }: IconButtonProps) =>
  React.createElement(
    styled(IconButton)`
      border: none !important;
      color: #252525 !important;
      background-color: transparent !important;
      padding: 0 !important;
    `,
    { variant: "outlined", ...props },
    children
  );
