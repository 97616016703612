import styled from "styled-components";

export const SearchInputWrapper = styled.div`
  width: 100%;
  flex-grow: auto;
  height: 40px;
  border: 1px solid #d9d9d9;
  border-radius: 30px;
  display: flex;
  font-family: "rubik";
  &:hover {
    border: 1px solid #7209b7;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
    cursor: pointer !important;
  }
`;

export const Icon = styled.img`
  height: 43px;
  width: 24px;
  padding-left: 16px;
`;

export const IconWrapper = styled.div`
  padding-right: 0.5em;
  display: grid;
  place-items: center;
`;
