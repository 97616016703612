import { useState, useEffect } from "react";
import { subDays } from "date-fns";
import { TableService } from "../../../../v2/application";

function useUsage() {
  const [
    loadingTopFiveTablesUsedPastMonth,
    setLoadingTopFiveTablesUsedPastMonth,
  ] = useState(false);
  const [topFiveTablesUsedPastMonth, setTopFiveTablesUsedPastMonth] = useState(
    []
  );
  const [topFiveJoins, setTopFiveJoins] = useState<any>([]);
  const [loadingTopFiveJoins, setLoadingTopFiveJoins] = useState(false);
  const [trendingTables, setTrendingTables] = useState<any>([]);
  const [loadingTrendingTables, setLoadingTrendingTables] = useState(true);

  const getTop5JoinsInPastWeek = async () => {
    try {
      setLoadingTopFiveJoins(true);
      const topFiveJoinsData =
        await TableService.getTop5TablesJoinsInPastWeek();

      setTopFiveJoins(topFiveJoinsData);
    } catch (er) {
      console.error(er);
    } finally {
      setLoadingTopFiveJoins(false);
    }
  };
  const getTrendingTablePerTimeFrame = async (startDate, endDate) => {
    try {
      setLoadingTrendingTables(true);
      const trendingTableData = await TableService.getTrendingTablePerTimeFrame(
        startDate,
        endDate
      );
      setTrendingTables(trendingTableData);
    } catch (er) {
      console.error(er);
    } finally {
      setLoadingTrendingTables(false);
    }
  };

  const getTop5TablesUsedPastMonth = async () => {
    try {
      setLoadingTopFiveTablesUsedPastMonth(true);
      const data = await TableService.getTop5TablesUsedPastMonth();
      const table = data.map((el, index) => {
        return {
          id: index + 1,
          label: el.TableName,
          value: el.NumOfReferences,
        };
      });
      setTopFiveTablesUsedPastMonth(table);
    } catch (er) {
      console.error(er);
    } finally {
      setLoadingTopFiveTablesUsedPastMonth(false);
    }
  };
  useEffect(() => {
    const defaultStartDate = subDays(new Date(), 7);
    const defaultEndDate = new Date();
    if (!location.pathname.includes("/analytics/usage")) return;
    getTop5TablesUsedPastMonth();
    getTop5JoinsInPastWeek();
    getTrendingTablePerTimeFrame(defaultStartDate, defaultEndDate);
  }, [location.pathname]);

  return {
    topFiveTablesUsedPastMonth,
    topFiveJoins,
    trendingTables,
    loadingTopFiveTablesUsedPastMonth,
    loadingTopFiveJoins,
    loadingTrendingTables,
    getTrendingTablePerTimeFrame,
  };
}

export default useUsage;
