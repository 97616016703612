import { ReactNode } from "react";
import { StaticIcons } from "../../assets";
import { Typography } from "../Typography";
import {
  ContentContainer,
  RootSideViewContainer,
  SideViewCancelIcon,
  SideViewHeader,
} from "./components";

export interface RootSideViewProps {
  header: string;
  open: boolean;
  onClose: () => void;
  children?: ReactNode;
  content?: ReactNode;
}

export const RootSideView = ({
  header,
  onClose,
  open,
  children,
}: RootSideViewProps) => {
  return (
    <RootSideViewContainer open={open}>
      <SideViewHeader>
        <Typography variant="h6">{header}</Typography>
        <SideViewCancelIcon
          onClick={onClose}
          src={StaticIcons.ic24CancelPreview}
          alt=""
        />
      </SideViewHeader>
      <ContentContainer>{children}</ContentContainer>
    </RootSideViewContainer>
  );
};

export * from "./components";
