import { Box, Button, Typography } from "@sherloq/design-system";
import React from "react";
import styled from "styled-components";
import { ModalProps } from "../atoms";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  tintColor: ModalProps["tintColor"];
}

export const Container = styled(Box)<Props>`
  width: 550px;
  background-color: white;
  border-bottom: 5px solid
    ${({ tintColor, theme }) =>
      tintColor === "default" ? theme.appColors.primary : "#000"};
`;

export const RootModalContent = styled(Box)`
  margin: 24px;
`;

export const Header = styled.header`
  display: flex;
`;

export const TextContainer = styled(Box)`
  flex-basis: 100%;
  margin-right: $space * 2;
  :first-child {
    margin-bottom: $space;
  }
  :last-child {
    white-space: pre-wrap;
  }
`;

export const IconContainer = styled(Box)`
  position: relative;
  margin-left: auto;
  right: -$space;
  top: -$space;
  > * {
    cursor: pointer;
  }
`;

export const ModalFooter = styled(Box)`
  display: flex;
  align-items: center;
  .rm-footer-text {
    margin-top: auto;
    flex-basis: 100%;
  }
  :not(:last-child) {
    margin-right: $space;
  }
`;

export const ModalContentElementContainer = styled(Box)`
  margin-top: 24px;
  margin-bottom: 48px;
`;

export const ModalFooterText = styled(Box)`
  margin-top: auto;
  flex-basis: 100%;
`;

export const CancelButton = styled(Button)`
  margin-right: 8px;
`;

export const DescriptionText = styled(Typography)`
  margin-top: 8px !important;
`;
