import { authHttpClient } from "../../infrastructure";

const getAllCompanyUsers = async () => {
  const users = await authHttpClient.get("/v1/getdata/getAllUsers");
  return users.data.map((user: any) => ({
    label: user.firstName + " " + user.lastName,
    value: user.email,
  }));
};

const getCompanyAssets = async (companyId: string | number) => {
  const path = "/v1/getdata/getListsByCompany";

  const params = {
    companyId,
  };
  const { data } = await authHttpClient.get(path, { params });
  return data;
};

export const AssetsService = Object.freeze({
  getAllCompanyUsers,
  getCompanyAssets,
});
