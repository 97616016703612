import x from "./x.svg";
import thumbUp from "./thumbs-up.svg";
import thumbDown from "./thumbs-down.svg";
import star from "./star.svg";
import GraySearchIcon from "./graySearch.svg";
import TrendingDown from "./trending-down.svg";

export default {
  star,
  thumbDown,
  x,
  thumbUp,
  GraySearchIcon,
  TrendingDown,
};
