import styled from "styled-components";

export const LoginContainer = styled.div`
  width: 100%;
  height: 99vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const LoginForm = styled.form`
  border: 1px solid #000000;
  width: 30vw;
  min-width: 400px;
  box-sizing: border-box;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.25);
  padding: ${({ theme }) => theme.spacing.lg};
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const Logo = styled.img``;

export const LoginSubmit = styled.button`
  padding: ${({ theme }) => theme.spacing.md};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  font-size: ${({ theme }) => theme.typography.size.md};
  margin-top: ${({ theme }) => theme.spacing.lg};
  background-color: #7209b7;
  border: none;
  color: white;
  width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  align-self: flex-end;
  &:active {
    opacity: 0.2;
  }
`;

/* Google Login */

export const Container = styled.div`
  display: flex;
  max-height: 100vh;
  max-width: 100vw;
  overflow: hidden;
  text-align: center;
`;
export const LeftPane = styled.div`
  background: linear-gradient(
    176.56deg,
    rgba(123, 58, 146, 0.62) -31.3%,
    rgba(244, 244, 244, 0) 102.89%
  );
  border-radius: 0px 30px 30px 0px;
  width: 50vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
export const IconPlaceholderContainer = styled.div``;

export const LogoIcon = styled.img`
  object-fit: cover;
  width: 400px;
  margin-bottom: 50px;
`;
export const PlaceholderIcon = styled.img`
  object-fit: cover;
  width: auto;
  height: auto;
`;

export const RightPane = styled.div`
  width: 50vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
export const PlaceholderText = styled.span`
  font-size: 1.6rem;
  background: -webkit-linear-gradient(90deg, #f72585 -28.85%, #7209b7 121.43%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;
export const LoginGoogleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
export const ContactContainer = styled.div``;
export const LoginGoogleButton = styled.div``;
export const TopSection = styled.div``;
export const BottomSection = styled.div``;
