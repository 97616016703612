import { AssetType } from "../../core";
import { authHttpClient } from "../../infrastructure";

const transformResults = (data: any[]): boolean => {
  const { exists } = data?.[0] ?? {};
  return !!exists as boolean;
};

export function makeIsAssetInUserListFactoryFn(
  assetType: AssetType,
  onError?: any
) {
  const path = "/v1/getdata/getIsItemSavedByUser";
  return async (assetId: number | string, sherloqUserId: number) => {
    try {
      const params = {
        assetType,
        assetId,
        sherloqUserId,
      };
      const { data } = await authHttpClient.get(path, { params });
      return transformResults(data);
    } catch (error) {
      //
      onError?.();
    }
  };
}
