import { Typography } from "@sherloq/design-system";
import styled from "styled-components";

export const FieldTableRowContainer = styled.td`
  display: grid;
  grid-template-columns: repeat(11, 1fr);
  height: 50px;
  width: 100%;
  align-items: center;
  border-bottom-style: solid;
  border-bottom-style: 1px;
  border-bottom-color: #f1f1f1;
  padding: 4px;
  box-sizing: border-box;
`;

export const TextValue = styled(Typography)`
  color: #252525;
  width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
`;

export const TextValueWithHover = styled(TextValue)`
  :hover {
    cursor: pointer;
    background-color: #f1f1f1;
  }
`;
