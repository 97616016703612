import styled from "styled-components";
import { Typography } from "../../../../../../shared-components";

export const LastQueriedContainer = styled.div`
  width: 442px;
  margin-bottom: 24px;
`;

export const Label = styled(Typography)`
  margin-bottom: 8px;
`;
