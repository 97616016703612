import { LabelTypography } from "../../../../shared-components";
import { Icons } from "@sherloq/design-system";
import {
  FilterContainer,
  SearchAndFilterContainer,
  SearchContainer,
  ClickableIcon,
} from "./components";
import { useCallback } from "react";
import { debounce } from "../../../../lib";
import SearchInput from "./SearchInput";
import { useHomepageSearchAndFilter } from "../../../../../hooks";

interface SearchAndFilterParams {
  onClickFilterIcon: () => void;
  onSearchChange: (val: string) => void;
}

export const SearchAndFilter: React.FC<SearchAndFilterParams> = ({
  onClickFilterIcon,
  onSearchChange,
}) => {
  const { value } = useHomepageSearchAndFilter();
  const searchHandler = useCallback(
    debounce((input) => onSearchChange(input)),
    []
  );

  return (
    <SearchAndFilterContainer>
      <SearchContainer>
        <SearchInput
          defaultValue={value}
          onSearch={(val) => searchHandler(val)}
        />
      </SearchContainer>
      <FilterContainer>
        <LabelTypography
          style={{ cursor: "pointer" }}
          onClick={onClickFilterIcon}
        >
          Filters
        </LabelTypography>
        <ClickableIcon>
          <Icons onClick={onClickFilterIcon} src="ic24FilterSearchPreview" />
        </ClickableIcon>
      </FilterContainer>
    </SearchAndFilterContainer>
  );
};
