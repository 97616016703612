import { Asset } from "../asset";
import { ITable } from "./interfaces";

export class Table extends Asset<ITable> {
  constructor(_table: ITable) {
    super(_table);
  }

  get data() {
    return {
      ...this._asset,
      givenOwner: this._asset.givenOwner ?? "-",
      lastReferenced: `${this.getAssetReference(
        this._asset.lastReferenceDatetime,
        this._asset.lastUsernameThatReferenced
      )}`,
    } as ITable;
  }
}
