import React from "react";
import { Dropdown, LabelTypography } from "../../../../shared-components";
import {
  NavBadge,
  NavBadgesContainer,
  NavContainer,
  NavUtilsContainer,
  SortLabel,
} from "./components";
import { AssetSortOptions } from "../../../../../application";

interface SortItem {
  label?: string;
  value?: number | string | null;
}

interface HomeNavbarProps {
  sortOptions?: SortItem[];
  onChangeSortBy: (sortBy: string) => void;
  redirectToQueryResultsPage: (replace?: boolean) => void;
  redirectToTableResultsPage: (replace?: boolean) => void;
  activeSubpage: "query" | "table";
  numberOfQueryResults: number;
  numberOfTableResults: number;
  sortByValue?: any;
}

export const HomeNestedNavbar: React.FC<HomeNavbarProps> = ({
  sortOptions,
  onChangeSortBy,
  activeSubpage,
  redirectToQueryResultsPage,
  redirectToTableResultsPage,
  sortByValue,
}) => {
  return (
    <NavContainer>
      <NavBadgesContainer>
        <LabelTypography>View:</LabelTypography>
        <NavBadge
          onClick={() => redirectToQueryResultsPage(false)}
          isActive={activeSubpage === "query"}
        >
          Query
        </NavBadge>
        <NavBadge
          onClick={() => redirectToTableResultsPage(false)}
          isActive={activeSubpage === "table"}
        >
          Table
        </NavBadge>
      </NavBadgesContainer>
      <NavUtilsContainer>
        <SortLabel>Sort by:</SortLabel>
        <Dropdown
          value={sortByValue}
          options={sortOptions}
          onChange={(e: any) => onChangeSortBy(e.value)}
          menuPortalTarget={document.getElementById("homepage-header")}
          menuPosition={"fixed"}
          isSearchable={false}
          styles={{
            container: (styles) => ({
              ...styles,
              minWidth: "150px",
            }),
          }}
        />
      </NavUtilsContainer>
    </NavContainer>
  );
};
