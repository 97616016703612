/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { ClientStorage, AnalyticsClientType } from "../../infrastructure";
import { IUser, User } from "../../core";
import { GoogleLoginData } from "./interface";

export const makeUserLoginFactory = (
  analyticsClient: AnalyticsClientType,
  clientStorage: ClientStorage,
  tokenDecoder
) => {
  return Object.freeze({
    onSuccess: (googleLoginResponse: GoogleLoginData): GoogleLoginData => {
      const { user: userparams, extensionAuthUser } = googleLoginResponse;
      if (!userparams.Token) throw Error(userparams.Username);
      const user = new User(userparams, tokenDecoder);
      const userDetails = user.details;
      const { FirstName, LastName, Email } = userDetails;
      clientStorage.set("user-token", userparams.Token);
      clientStorage.set("sherloq-auth-token", extensionAuthUser.access_token);
      analyticsClient.initUserToTrack(userparams.Username);
      analyticsClient.trackAction("main_server_login_success");
      analyticsClient.monitorSpecificUserActions.setUser({
        first_name: FirstName,
        last_name: LastName,
        email: Email,
      });
      const res = { ...userDetails, ...userparams };
      return { user: res, extensionAuthUser };
    },
    onFailure: (error: any, username: string) => {
      analyticsClient.initUserToTrack(username);
      analyticsClient.trackAction("main_server_login_failed", {
        message: error.message,
      });
      return error;
    },
  });
};
