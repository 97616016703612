import axios, { AxiosInstance } from "axios";
import { Client, ClientConstructorType } from "./interface";

const addRequestInterceptor = (axiosConstructor: any) => {
  axiosConstructor.Axios.prototype.addRequestInterceptor = function (
    this: AxiosInstance,
    fulfilled: (config: any) => any,
    reject: (error: any) => any
  ) {
    this.interceptors.request.use(fulfilled, reject);
  };
};

const addResponseInterceptor = (axiosConstructor: any) => {
  axiosConstructor.Axios.prototype.addResponseInterceptor = function (
    this: AxiosInstance,
    fulfilled: (config: any) => any,
    reject: (error: any) => any
  ) {
    this.interceptors.response.use(fulfilled, reject);
  };
};

export const CreateClientConstructor: ClientConstructorType<
  AxiosInstance & Client
> = Object.freeze({
  create: (baseURL) => {
    const localisedInstance = axios;
    addRequestInterceptor(localisedInstance);
    addResponseInterceptor(localisedInstance);
    return axios.create({ baseURL }) as AxiosInstance & Client;
  },
});
