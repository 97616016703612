import React from "react";
import { HomeOutlet } from "./components";

interface HomeOutletProps<Type> {
  data?: Type[];
  renderData?: (dataItem: Type, idx: number, arr: Type[]) => React.ReactElement;
}

export function HomeNestedNavOutlet<
  T extends string | number | Record<string, any>
>({ data, renderData }: HomeOutletProps<T>) {
  const OutletData = renderData ? data?.map(renderData) : null;

  return <HomeOutlet>{OutletData}</HomeOutlet>;
}
