import React, { useState } from 'react'
import Button from './Button/button-component'
import { Modal, Box } from "@material-ui/core";
import styled from "styled-components";
import theme from '../styles/theme';
import { toast } from 'react-toastify'
import { postUserFeedback } from '../services/serverApi';
import { Mixpanel } from '../mixpanelWrapper';

interface FeedbackProps {
  open: boolean,
  handleClose: () => void,
  screenName: 'search' | 'query_profile' | 'table_profile' | 'table_of_tables' | 'table_of_fields' | 'table_of_queries' | 'insights',
  assetId: number,
  sherloqUserId: number
}
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 5,
};

function FeedbackModal({ open, handleClose, screenName, assetId, sherloqUserId }: FeedbackProps) {
  const placeholder = 'Really! Just type in any question, bug, feature request, concern, or even how’s your day going...'
  const [feedbackText, setFeedbackText] = useState('')

  const handleFeedback = async () => {
    if (feedbackText === '') return toast.info('Please input a comment. It seems empty')

    Mixpanel.track(`main_comment_button_feedback_${screenName}_click`, { "asset_id": assetId, "feedback": feedbackText });
    try {
      const data = await postUserFeedback(
        screenName,
        sherloqUserId,
        feedbackText
      )
      if (data) toast.success('We received your feedback!, thanks!')
      setFeedbackText('')
      handleClose()
      Mixpanel.track(`main_send_feedback_${screenName}_success`, { "asset_id": assetId, "feedback": feedbackText });

    } catch (err) {
      Mixpanel.track(`main_send_feedback_${screenName}_fail`, { "asset_id": assetId, "feedback": feedbackText });
      toast.error('Something went wrong sending your feedback!')
    }
  }

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <FeedbackTextArea
          value={feedbackText}
          rows={10}
          placeholder={placeholder}
          onChange={(e) => setFeedbackText(e.target.value)}>
        </FeedbackTextArea>
        <BottomContainer>
          <CommentButtonWrapper>
            <Button
              text={'Comment'}
              background={theme.appColors.tertiary}
              color={theme.appColors.white}
              type={'submit'}
              onClick={handleFeedback} />
          </CommentButtonWrapper>
        </BottomContainer>
      </Box>
    </Modal>
  )
}

export const FeedbackTextArea = styled.textarea`
  width: 100%;
  padding: 18px 5px;
  &::placeholder {
    font-style: italic;
  }
`;
export const BottomContainer = styled.div`
  display: flex;
  justify-content: end;
`;
export const CommentButtonWrapper = styled.div`
  min-width: 100px;
  margin: 20px 0 0;
`;
export default FeedbackModal