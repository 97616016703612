import stikerIcon from "./sticker.svg";
import PencilIcon from "./pencil.svg";
import StarIcon from "./star.svg";
import ShareIcon from "./share.svg";
import DBTableIcon from "./db-table.svg";
import QueryIcon from "./help-circle.svg";
import DBFieldIcon from "./field.svg";
import DBFieldBlueIcon from "./field-blue.svg";
import UserPurpleIcon from "./user_purple.svg";
import PlusSquareIcon from "./plus-square.svg";
import UserIcon from "./user.svg";
import ClockIcon from "./clock.svg";
import IngredientIcon from "./ingredients.svg";
import TableBlackIcon from "./tableIcon.svg";
import CirclePlusIcon from "./circle-plus-purple.svg";
import CircleMinusIcon from "./circle-minus-purple.svg";
import FolderIcon from "./folder-icon.svg";

export default {
  stikerIcon,
  PencilIcon,
  StarIcon,
  ShareIcon,
  DBTableIcon,
  QueryIcon,
  DBFieldBlueIcon,
  DBFieldIcon,
  UserPurpleIcon,
  PlusSquareIcon,
  UserIcon,
  ClockIcon,
  IngredientIcon,
  TableBlackIcon,
  CirclePlusIcon,
  CircleMinusIcon,
  FolderIcon,
};
