import { AppRoutes, AssetSortOptions } from "../../application";
import { useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AssetFilterType, defaultAssetFilter } from "../../core";
import { useGlobalStateDispatch } from "../redux";
import { AnalyticsClient, homeActions } from "../../infrastructure";
import { useHomeGlobalState } from "./homeReducerHooks";
import { useInitUserCompanyUsers } from "../user";

export const useHomePage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useGlobalStateDispatch();
  const homeState = useHomeGlobalState();
  const { exec: initUserCompanyUsers } = useInitUserCompanyUsers();

  const selectedSortbyValue = useMemo(() => {
    const selectedValueFromOptions = AssetSortOptions?.find(
      (option) => option.value === homeState.sortBy
    );
    if (!selectedValueFromOptions) {
      return null;
    }
    return selectedValueFromOptions;
  }, [homeState.sortBy]);

  const activeSubpage = useMemo(() => {
    if (
      location.pathname === `/${AppRoutes.HOME}/${AppRoutes.HOME_QUERY_RESULTS}`
    )
      return "query" as const;
    else return "table" as const;
  }, [location.pathname]);

  const onChangeSortBy = (sortBy?: string) => {
    dispatch(homeActions.syncSortBy(sortBy));
  };

  const syncFilters = (filters: any) => {
    dispatch(homeActions.syncFilters(filters));
  };

  const toggleHomeSideView = (state: boolean) => {
    dispatch(homeActions.toggleFilterSideView(state));
  };

  const syncSearchKeyword = (keyword: string) => {
    dispatch(homeActions.syncKeyword(keyword));
  };

  const toggleFilterView = () => {
    toggleHomeSideView(!homeState.openFilterSideView);
    if (homeState.openFilterSideView) {
      AnalyticsClient.trackAction("main_home_close_side_view");
    } else {
      AnalyticsClient.trackAction("main_home_open_side_view");
    }
  };

  //
  const redirectToQueryResults = (replace?: boolean) => {
    dispatch(homeActions.syncFilters(defaultAssetFilter));
    navigate(AppRoutes.HOME_QUERY_RESULTS, { replace });
    AnalyticsClient.trackAction("main_home_query_results_tab_click");
  };
  const redirectToTableResults = () => {
    dispatch(homeActions.syncFilters(defaultAssetFilter));
    navigate(AppRoutes.HOME_TABLE_RESULTS);
    AnalyticsClient.trackAction("main_home_table_results_tab_click");
  };

  const onClickSearch = (payload: AssetFilterType) => {
    syncFilters(payload);
    toggleHomeSideView(false);
  };

  const onSearching = (keyword: string) => {
    syncSearchKeyword(keyword);
  };

  const onRemoveAppliedFilter = (e?: any) => {
    const ruleToRemove = e?.target?.value;
    const rules = homeState.filters?.rules.filter(
      (rule) =>
        rule?.value !== ruleToRemove?.value ||
        rule?.column !== ruleToRemove?.column
    );
    const updatedFilter = { ...homeState?.filters, rules };
    dispatch(homeActions.syncFilters(updatedFilter));
  };

  const onUpdateLastQueriedBy = (e?: any) => {
    const lastQueriedBy = {
      column: "lastUsernameThatReferenced",
      condition: "inList",
      value: "",
    };
    const updatedFilters = { ...homeState?.filters, lastQueriedBy };
    dispatch(homeActions.syncFilters(updatedFilters));
  };

  const onUpdateQueriedIn = (e?: any) => {
    const updatedQueriedIn = {
      column: "lastReferenceDatetime",
      condition: "pastDays",
      value: "",
    };
    const updatedFilters = {
      ...homeState?.filters,
      queriedIn: updatedQueriedIn,
    };
    dispatch(homeActions.syncFilters(updatedFilters));
  };

  useEffect(() => {
    initUserCompanyUsers();
  }, []);

  return {
    homeState,
    toggleFilterView,
    onClickSearch,
    onSearching,
    onChangeSortBy,
    redirectToQueryResults,
    redirectToTableResults,
    activeSubpage,
    onRemoveAppliedFilter,
    onUpdateLastQueriedBy,
    onUpdateQueriedIn,
    selectedSortbyValue,
  };
};
