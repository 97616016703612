import styled from "styled-components";
import { Select as SharedSelect } from "../../shared-components";
import { Input as Sherloqinput } from "@sherloq/design-system";

export const Select = styled(SharedSelect)`
  margin-right: 8px;
  width: 130px;
  flex-shrink: 0;
  padding: 0 !important;
  display: flex;
  align-items: center;
  height: 32px;
  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Container = styled.div`
  display: flex;
`;

export const Input = styled(Sherloqinput)`
  width: 150px;
  box-sizing: border-box;
  height: 32px !important;
`;
