import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  AppRoutes,
  AssetsService,
  makeTransformCompanyAllListsAssets,
} from "../../application";
import { AssetType } from "../../core";
import { useGlobalAuthState } from "../user";

export const useAllLists = () => {
  const [allList, setAllList] = useState<any>([]);
  const [loadingAllList, setLoadingAllList] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { details } = useGlobalAuthState();

  const getCompanyList = async () => {
    setLoadingAllList(true);
    const result = await AssetsService.getCompanyAssets(details.CompanyId);
    const allCompanyLists = makeTransformCompanyAllListsAssets(result);
    setAllList(allCompanyLists);
    setLoadingAllList(false);
  };
  const handleAssetClick = (type: AssetType, AssetData: any) => {
    if (type === "table") {
      //   const extras = {
      //     state: {
      //       assetPropData: {
      //         tableId: AssetData.id.toString().slice(5),
      //         description: AssetData.description,
      //         lastReferenceDatetime: AssetData.userName,
      //         lastUsernameThatReferenced: AssetData.userName,
      //         tableName: AssetData.title,
      //         dbName: AssetData.parentName,
      //       },
      //       fromPage: location.pathname,
      //     },
      //   };
      //   Mixpanel.track("main_table_profile_from_lists_click", {
      //     table_name: AssetData.title,
      //     table_id: AssetData.id,
      //     last_queried_by: AssetData.userName,
      //     last_query_date: AssetData.lastQueriedDate,
      //   });

      return navigate(
        `/${AppRoutes.TABLE_PROFILE}/${AssetData.id.toString().slice(5)}`
      );
    }
    if (type === "query") {
      //   const extras = {
      //     state: {
      //       assetPropData: {
      //         queryId: AssetData.id.toString().slice(5),
      //         queryGivenName: AssetData.title,
      //         description: AssetData.description,
      //         listOfTablesAccessed: AssetData.parentName,
      //         lastQueryThatReferencedDatetime: AssetData.lastQueriedDate,
      //         lastUsernameThatReferenced: AssetData.userName,
      //       },
      //       fromPage: location.pathname,
      //     },
      //   };
      //   Mixpanel.track("main_query_profile_from_lists_click", {
      //     query_name: AssetData.title,
      //     query_id: AssetData.id,
      //     last_queried_by: AssetData.userName,
      //     last_query_date: AssetData.lastQueriedDate,
      //   });

      return navigate(
        `/${AppRoutes.QUERY_PROFILE}/${AssetData.id.toString().slice(5)}`
      );
    }
  };

  useEffect(() => {
    if (location.pathname !== "/lists/all") return;
    getCompanyList();
  }, [location.pathname]);

  return { allList, handleAssetClick, loadingAllList };
};
