import styled from "styled-components";
export * from "./breadcrumbs";
export * from "./avatar";
export * from "./avatarOptions";

export const Row = styled.div`
  display: flex;
`;
export const HeaderContainer = styled(Row)`
  width: 100%;
`;

export const HeaderUtilsContainer = styled(Row)`
  display: flex;
  margin-left: auto;
  width: fit-content;
  align-items: center;
`;

export const BackIconContainer = styled(Row)`
  align-items: center;
  :hover {
    cursor: pointer;
  }
`;

export const Icon = styled.img`
  height: 16px;
  width: 16px;
`;

export const NavContainer = styled(Row)`
  column-gap: 18px;
  align-items: center;
`;

export const InjectedUtilsContainer = styled.div`
  margin-right: 24px;
`;
