import { FormControlProps, RadioGroup } from "@mui/material";
import { RadioButton } from "@sherloq/design-system";
import { LabelValue } from "../../lib";
import {
  Container,
  Label,
  RadioButtonContainer,
  RadioLabel,
} from "./components";

interface Props extends FormControlProps {
  value: any;
  onChange?: any;
  label?: string;
  options?: LabelValue<string>[];
}

export const QueriedInFilter: React.FC<Omit<Props, "children">> = ({
  value,
  onChange,
  label,
  options,
  ...props
}) => {
  return (
    <Container {...props}>
      <Label variant="small">{label}</Label>
      <RadioGroup
        row
        value={value}
        onChange={onChange}
        aria-labelledby="demo-radio-buttons-group-label"
        name="radio-buttons-group"
      >
        {options?.map(({ label, value }, idx) => (
          <RadioButtonContainer
            key={idx}
            value={value}
            control={<RadioButton />}
            label={<RadioLabel>{label}</RadioLabel>}
          />
        ))}
      </RadioGroup>
    </Container>
  );
};
