import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  height: 100%;
  margin-bottom: 40px;
`;

export const Header = styled.header`
  padding-left: 8px;
`;

export const Body = styled.div`
  margin-top: 23px;
`;

export * from "./UsedTablesAndFieldsRow";
