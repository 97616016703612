import { useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  authActions,
  clientStorage,
  env,
  homeActions,
} from "../infrastructure";
import { useGlobalAuthState } from "./user";
import { useGlobalStateDispatch } from "./redux";

export const useRootLayout = () => {
  const globalAuthState = useGlobalAuthState();
  const dispatch = useGlobalStateDispatch();
  const navigate = useNavigate();

  const { isLoggedIn, user } = useGlobalAuthState();
  const location = useLocation();

  const rootListRoute = useMemo(() => {
    return `${user.SherloqUserId}`;
  }, [user]);

  const showSideBar = useMemo(() => {
    let show = false;
    if (location.pathname !== "/login") {
      if (globalAuthState.isLoggedIn) show = true;
    }
    return show;
  }, [globalAuthState, location]);

  useEffect(() => {
    (async () => {
      if (isLoggedIn) return;
      if (location.pathname === "/login") return;
      await dispatch(authActions.setRouteBeforeAuth(location.pathname));
    })();
  }, []);

  return {
    globalAuthState,
    showSideBar,
    rootListRoute,
    user,
  };
};
