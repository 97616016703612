import React, { ReactNode, useState } from "react";
import {
  QueryProfileGlobalStateContext,
  initialQueryProfileGlobalState,
} from "./contexts";
import { QueryProfileGlobalState } from "./interface";

export const QueryProfileGlobalStateContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [state, setState] = useState<QueryProfileGlobalState>(
    initialQueryProfileGlobalState
  );

  return (
    <QueryProfileGlobalStateContext.Provider value={[state, setState]}>
      {children}
    </QueryProfileGlobalStateContext.Provider>
  );
};

export * from "./contexts";

export * from "./interface";
