import { numberConditions, textConditions } from "../../core";

type TopFieldsColumnValues =
  | "fieldName"
  | "fieldType"
  | "referencePercentageOutOfTableReferences"
  | "numOfReferences"
  | "numWhereReferences"
  | "numSelectReferences"
  | "numJoinReferences"
  | "lastReferenceDatetime"
  | "lastUsernameThatReferenced"
  | "lastQueryThatReferenced";

export const getTopFieldsRuleConditions = (column: TopFieldsColumnValues) => {
  if (["fieldName", "fieldType"].includes(column)) {
    return textConditions;
  }
  if (
    [
      "referencePercentageOutOfTableReferences",
      "numOfReferences",
      "numWhereReferences",
      "numSelectReferences",
      "numJoinReferences",
    ].includes(column)
  )
    return numberConditions;
};

export const TopTableFieldsRules = [
  { label: "Field Name", value: "fieldName" },
  { label: "Field Type", value: "fieldType" },
  {
    label: "% Query references",
    value: "referencePercentageOutOfTableReferences",
  },
  { label: "# Query references", value: "numOfReferences" },
  { label: "# WHERE references", value: "numWhereReferences" },
  { label: "# SELECT references", value: "numSelectReferences" },
  { label: "# JOIN references", value: "numJoinReferences" },
];

export const TableDropdownRules = [
  "deptName",
  "businessUnit",
  "jobTitle",
  "source",
];
export const QueryDropdownRules = ["querySource"];
export const QuerySourceDropdownValues = ["athena", "bigquery"];
