import { ClientStorage } from "../interfaces";

export const addUserTokenToRequests = (
  config: any,
  clientStorage: ClientStorage
) => {
  const token = clientStorage.get("user-token");
  config.headers["X-Requested-With"] = "XMLHttpRequest";
  config.headers["accept"] = "application/json, text/plain, */*";
  if (!token) return config;
  config.headers.Authorization = `Bearer ${token}`;
  return config;
};

export const requestHandlerOn401Error = async (
  error: Record<"response", any>,
  callback: () => void
) => {
  const response = error.response;
  if (!response) return Promise.reject(error);
  if (response && response.status === 401) {
    callback();
    return Promise.reject(error);
  }
  return Promise.reject(response);
};

export const addHeaderToRequest = (config: any) => {
  config.headers["X-Requested-With"] = "XMLHttpRequest";
  return config;
};

export const defaultRequestErrorHandler = (error: any) => Promise.reject(error);

export const returnErrorResponse = (error: any) =>
  Promise.reject(error.response);
