export const transformExtensionQueries = (data: any[]) => {
  return data
    ?.filter((query) => query.type === "query")
    .map(({ query, type }) => ({
      description: query.description,
      userName: `${query.user?.first_name ?? ""} ${
        query.user?.last_name ?? ""
      }`,
      title: query.name,
      type: type,
      savedDate: query.updated_at,
      source: query.origin,
      id: query.uid,
      queryString: query.query,
    }));
};

export const transformExtensionQueryFolders = (data: any[]) => {
  return data.filter((query) => query.type === "folder").map((q) => q.child);
};
