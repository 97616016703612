import { useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AppRoutes } from "../application";
import { useGlobalAuthState } from "./user";
import { SupportedCostsReportCompanies, makeUser } from "../core";

export const useSidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { details, isLoggedInOnExtension } = useGlobalAuthState();
  const User = useState(makeUser(details))[0];
  const showCostReport = useMemo(() => {
    if (SupportedCostsReportCompanies.includes(User.company) && User.isAdmin)
      return true;
    return false;
  }, [User]);

  const activePage = useMemo(() => {
    return location.pathname.split("/")[1];
  }, [location]);

  const onClickHome = () => {
    navigate(`/${AppRoutes.HOME}`);
  };

  const onClickCostsReport = () => {
    navigate(`/${AppRoutes.ANALYTICS}`);
  };

  const onClickMyQueries = () => {
    navigate(`/${AppRoutes.LISTS}`);
  };

  const activeHomeIcon = useMemo(() => {
    if (activePage === "home") return "ic24mainHomeSelected" as const;
    return "ic24mainHomePreview" as const;
  }, [activePage]);

  const activeListsIcon = useMemo(() => {
    if (activePage === "lists") return "ic24mainTrendUpSelected" as const;
    return "ic24mainTrendUpPreview" as const;
  }, [activePage]);

  const activeAnalyticsIcon = useMemo(() => {
    if (activePage === "analytics")
      return "ic24mainCostReportSelected" as const;
    return "ic24mainCostReportPreview" as const;
  }, [activePage]);

  return {
    onClickHome,
    onClickMyQueries,
    onClickCostsReport,
    activeAnalyticsIcon,
    activeHomeIcon,
    activeListsIcon,
    showCostReport,
    showWorkspace: isLoggedInOnExtension,
  };
};
