import { useRootLayout } from "../../../hooks";
import { Outlet } from "react-router-dom";
import { MainSidebar } from "../../shared-components";
import { ContentContainer, RootDiv } from "./components";
import { RenderIf } from "../../lib";

export const RootLayout = () => {
  const { showSideBar } = useRootLayout();
  return (
    <RootDiv>
      {RenderIf(showSideBar, <MainSidebar />)}
      <ContentContainer auth={showSideBar}>
        <Outlet />
      </ContentContainer>
    </RootDiv>
  );
};
