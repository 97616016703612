import { createSlice, PayloadAction } from "@reduxjs/toolkit";
export type sideNavModeType = "hover" | "open";
type AppState = {
  showSideNavbar: boolean;
  sideNavMode: sideNavModeType;
  extensionQueries: any[];
};

const initialState: AppState = {
  showSideNavbar: true,
  sideNavMode: "open",
  extensionQueries: [],
};

export const appSlice = createSlice({
  name: "App",
  initialState,
  reducers: {
    setShowSideNavbar(state, action: PayloadAction<boolean>) {
      state.showSideNavbar = action.payload;
    },
    setSideNavMode(state, action: PayloadAction<sideNavModeType>) {
      state.sideNavMode = action.payload;
    },
    setExtensionQueries(state, action: PayloadAction<any>) {
      state.extensionQueries = action.payload;
    },
  },
});

export const appReducer = appSlice.reducer;
export const appActions = appSlice.actions;
