import {
  AssetProfileSectionHeaderWithInfo,
  Typography,
} from "../../../../shared-components";
import { TableProfileSectionHeader, Table, TopUserRowDescContainer } from "..";
import { ITableProfileTopUser } from "../../../../../core";
import { Dropdown, RowContainer } from "./components";
import { useTableProfileTopUsers } from "../../../../../hooks";
import { useState } from "react";
import SqlViewer from "../../../../../../components/SqlViewer/SqlViewer";

interface Props {
  onClickViewAll: () => void;
  data?: ITableProfileTopUser[];
}

export const TopUsers = ({ data }: Props) => {
  const { filterOptions } = useTableProfileTopUsers();
  const [showModal, setShowModal] = useState(false);
  const [selectedQuery, setSelectedQuery] = useState("");
  const [selectedQueryId, setSelectedQueryId] = useState("");

  const popUpSQLModal = (queryString: string, queryId: string) => {
    setShowModal(true);
    setSelectedQuery(queryString);
    setSelectedQueryId(queryId);
  };
  return (
    <div style={{ height: "400px" }}>
      <Table style={{ height: "fit-content" }}>
        <TableProfileSectionHeader>
          <AssetProfileSectionHeaderWithInfo tooltipText="Top users - The users who used this table the most. It includes the last date use and the amount the user used this table.">
            Top Users
          </AssetProfileSectionHeaderWithInfo>
          {/* <AssetProfileContentSectionTextLink onClick={onClickViewAll}>
            View all users
          </AssetProfileContentSectionTextLink> */}
        </TableProfileSectionHeader>
        <Dropdown
          options={filterOptions}
          value={filterOptions[0].value}
          isSearchable={false}
          placeholder="All users"
        />
        <tbody>
          {data?.map(
            (
              {
                lastquerydate,
                numofreferences,
                username,
                querystring,
                queryId,
              },
              idx
            ) => (
              <RowContainer
                value={numofreferences}
                key={idx}
                onClick={() => popUpSQLModal(querystring, queryId)}
              >
                <TopUserRowDescContainer>
                  <Typography variant="p-light" color="#252525">
                    {username}
                  </Typography>
                  <Typography variant="small" color="#707070">
                    {lastquerydate}
                  </Typography>
                </TopUserRowDescContainer>
              </RowContainer>
            )
          )}
        </tbody>
      </Table>
      <SqlViewer
        show={showModal}
        query={selectedQuery ?? ""}
        query_id={selectedQueryId ?? ""}
        onClose={() => setShowModal(false)}
      />
    </div>
  );
};
