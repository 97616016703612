import { useState, useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  AppRoutes,
  makeGetUserFromCompanyUsers,
  transformUserList,
  userService,
} from "../../application";
import { AssetType } from "../../core";
import { useGlobalAuthState } from "../user";

interface UserListProfile {
  firstName: string;
  lastName: string;
  sherloqUserId: number;
  compnayId: number;
}

export interface IGenericAssets {
  id: number;
  type: string;
  title: string;
  description: string;
  userName: string;
  lastQueriedDate: string;
  parentName: string;
  savedDatetime: string;
}
export const useUserList = () => {
  const { user, details } = useGlobalAuthState();
  const [userList, setUserList] = useState<any>([]);
  const [loadingUserList, setLoadingUserList] = useState(false);
  const [userProfile, setUserProfile] = useState<UserListProfile | null>(null);
  const navigate = useNavigate();
  const { userid } = useParams();

  const viewLoggedInUserExtension = useMemo(() => {
    if (userProfile && user) {
      return user.SherloqUserId === userProfile.sherloqUserId;
    }
    return false;
  }, [userProfile, user]);

  const getUserList = async (userid: string | number) => {
    setLoadingUserList(true);
    const result = await userService.getUserLists(userid);
    const transforomedUserLists = transformUserList(result);
    setUserList(transforomedUserLists);
    setLoadingUserList(false);
  };

  const listTitle = useMemo(() => {
    if (!userProfile) return "List";
    else {
      const { firstName, lastName } = userProfile;
      return `${firstName} ${lastName}'s List`;
    }
  }, [userProfile]);

  const handleAssetClick = (type: AssetType, AssetData: IGenericAssets) => {
    if (type === "table") {
      //   Mixpanel.track("main_table_profile_from_user_list_click", {
      //     from_useer: id,
      //     table_name: AssetData.title,
      //     table_id: AssetData.id,
      //     last_queried_by: AssetData.userName,
      //     last_query_date: AssetData.lastQueriedDate,
      //   });

      return navigate(`/${AppRoutes.TABLE_PROFILE}/${AssetData.id}`);
    }
    if (type === "query") {
      //   Mixpanel.track("main_query_profile_from_user_list_click", {
      //     from_useer: id,
      //     query_name: AssetData.title,
      //     query_id: AssetData.id,
      //     last_queried_by: AssetData.userName,
      //     last_query_date: AssetData.lastQueriedDate,
      //   });

      return navigate(`/${AppRoutes.QUERY_PROFILE}/${AssetData.id}`);
    }
  };

  const getUserProfile = async (userid) => {
    const companyUsers = await userService.getUserCompanyUsers(
      details.CompanyId
    );
    const companyUser = makeGetUserFromCompanyUsers(
      Number(userid),
      companyUsers
    );
    setUserProfile(companyUser);
  };

  useEffect(() => {
    (async () => {
      if (userid) {
        await getUserProfile(userid);
        await getUserList(userid);
      }
    })();
  }, [userid]);

  return {
    listTitle,
    userList,
    userProfile,
    handleAssetClick,
    loadingUserList,
    viewLoggedInUserExtension,
  };
};
