import styled from "styled-components";
import { AppTypography } from "../../styles/global";

export const BackButtonContainer = styled.div`
  cursor: pointer;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-self: flex-start;
  margin-top: 40px;
  gap: ${({ theme }) => theme.spacing.md};
  margin: ${({ theme }) => theme.spacing.sm};
  &:active {
    opacity: 0.2;
  }
`;

export const BackIcon = styled.img``;

export const BackText = styled(AppTypography)`
  border-bottom: 1px solid black;
`;
