import theme from "../../../../styles/theme";
import styled from "styled-components";

export const SearchIcon = styled.img`
`;

export const SearchContainer = styled.div`
  display: flex;
  margin: 20px 35px;
  gap: 10px;

`
export const SearchInput = styled.input`
  border: none;
  outline: none;
  border-bottom: 1px solid ${theme.appColors.primary};
  
  &::placeholder {
    color: ${theme.appColors.primary};
  }
`