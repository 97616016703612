import { Container, Button } from "./components";

interface AvatarPopupProps {
  onClickLogout?: () => void;
  onClickDocumentation?: () => void;
}

export const AvatarPopup = (props: AvatarPopupProps) => {
  return (
    <Container>
      <Button onClick={props?.onClickDocumentation}>Documentation</Button>
      <Button onClick={props?.onClickLogout}>Log out</Button>
    </Container>
  );
};
