import { Tooltip, TooltipProps } from "@material-ui/core";
import { AppTypography } from "../../styles/global";
import React from "react";
import { AppTypographyProps } from "../../types/styles";

interface Props
  extends AppTypographyProps,
    React.HTMLAttributes<HTMLSpanElement> {
  toolTipValue?: string | number;
  typographyProps?: AppTypographyProps;
  tooltipProps?: Omit<TooltipProps, "title">;
}

export const TextBoxWithToolTip: React.FC<Props> = ({
  children,
  toolTipValue,
  tooltipProps = { placement: "top-start" },
  typographyProps,
  ...rest
}) => {
  // remove title from tooltip props

  return (
    <Tooltip title={toolTipValue ?? ""} {...tooltipProps}>
      <AppTypography {...typographyProps} {...rest}>{children}
      </AppTypography>
    </Tooltip>
  );
};
