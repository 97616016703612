import {
  textConditions,
  AssetFilterType,
  AssetFilterRule,
  sourceConditions,
} from "../asset";

export const DefaultQueryFilters = ["queryString", "listOfTablesAccessed"];

export const AppsFlyterQueryFilters = [
  ...DefaultQueryFilters,
  "businessUnit",
  "deptName",
  "jobTitle",
  "querySource",
];

export const PublicQueryFilterRulesColumns = [
  {
    label: "Query String",
    value: "queryString",
  },
  {
    label: "Field Name",
    value: "fieldName",
  },
  {
    label: "Table Name",
    value: "listOfTablesAccessed",
  },
] as const;

export const AppsFlyerQueryFilterRulesColumns = [
  ...PublicQueryFilterRulesColumns,
  {
    label: "Query Source",
    value: "querySource",
  },
];

export const getFilterQueryConditionsFromColumn = (
  column:
    | (typeof PublicQueryFilterRulesColumns)[number]["value"]
    | (typeof AppsFlyerQueryFilterRulesColumns)[number]["value"]
) => {
  //
  switch (column) {
    case "queryString":
      return textConditions;
    case "listOfTablesAccessed":
      return textConditions;
    case "fieldName":
      return textConditions;
    case "querySource":
      return sourceConditions;
    default:
      return [];
  }
};

export interface QueryFilterRule {
  column: (typeof PublicQueryFilterRulesColumns)[number]["value"];
  condition: ReturnType<typeof getFilterQueryConditionsFromColumn>[number];
  value: string;
}

export type QueryFilterType = AssetFilterType<
  AssetFilterRule<string>,
  AssetFilterRule<string[]>,
  QueryFilterRule
>;
