import {
  clientStorage,
  persistedAppStore,
  appStore,
  authActions,
  notificationSnackbar,
} from "../../infrastructure";

export const handleUserTokenExpiredUseCase = async () => {
  clientStorage.clear("user-token");
  await persistedAppStore.purge();
  await appStore.dispatch(authActions.reset({}));
  // trigger notificationSnackbar
  notificationSnackbar.error("Sorry, your token has expired");
  window.location.href = "/login";
};
