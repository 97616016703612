import React from "react";
import icons from "../../../../assets/images/global";
import { TypographyColor, TypographySize } from "../../../../types/styles.d";
import { AppTypography } from "../../../../styles/global";
import { Row, SmallTableIcon } from "./Table.styled";

interface Props {
  value?: number;
}

export const UsageAnalytics: React.FC<Props> = ({ value }) => {
  return (
    <Row>
      {Number(value) > 0 && (
        <Row>
          <SmallTableIcon src={icons.trendingUpIcon} />
          <AppTypography
            textColor={TypographyColor.ok}
            size={TypographySize.sm}
          >
            +{Number(value).toFixed(1)}% usage
          </AppTypography>
        </Row>
      )}
      {Number(value) < 0 && (
        <Row>
          <SmallTableIcon src={icons.trendingDownIcon} />
          <AppTypography
            textColor={TypographyColor.danger}
            size={TypographySize.sm}
          >
            {Number(value).toFixed(1)}% usage
          </AppTypography>
        </Row>
      )}
      {Number(value) == 0 && (
        <AppTypography size={TypographySize.sm}>no change</AppTypography>
      )}
    </Row>
  );
};
