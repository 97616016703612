import { device } from "../../../../utils/helpers/devices";
import styled from "styled-components";
import Select from "react-select";
import React from "react";

export const TableContainer = styled.div`
  display: flex;
  justify-content: center;
`;
export const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
`;
export const TBody = styled.tbody``;
export const THead = styled.thead<React.CSSProperties>`
  display: ${({ display }) => display};
  align-items: ${({ alignItems }) => alignItems};
`;
export const TableRow = styled.tr`
  text-align: center;
`;
export const TableHead = styled.th<React.CSSProperties>`
  text-align: center;
`;
export const TableColumn = styled.td<React.CSSProperties>`
  padding: 5.5px;
`;
export const TextOverflow = styled.div<{ maxWidth?: number }>`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  @media ${device.tablet} {
    max-width: 90px;
  }
  @media ${device.laptop} {
    max-width: 130px;
  }
  @media ${device.laptopL} {
    max-width: 195px;
  }
  @media ${device.desktop} {
    max-width: 205px;
  }
`;

export const QueryTextOverflow = styled.div<{ maxWidth?: number }>`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  @media ${device.tablet} {
    max-width: 80px;
  }
  @media ${device.laptop} {
    max-width: 200px;
  }
  @media ${device.laptopL} {
    max-width: 400px;
  }
  @media ${device.desktop} {
    max-width: 500px;
  }
`;
export const SmallTableIcon = styled.img`
  height: 25px;
`;
export const Row = styled.div<{ justifyContent?: string }>`
  display: flex;
  align-items: center;
  justify-content: ${({ justifyContent }) =>
    justifyContent ? justifyContent : "center"};
  gap: 2px;
`;

export const CardComponentHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  z-index: 999;
  margin-bottom: 15px;
`;

export const CostGraphContainer = styled.div`
  height: 400px;
`;
export const CostContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.lg};
`;
export const Dropdown = styled(Select)`
  align-self: center;
  width: inherit;
  height: 30px;
  border-radius: 4px;
  font-size: 12px;
  height: 40px;
  z-index: 9999;
`;

export const HeaderRow = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const HeaderUtilsContainer = styled.div`
  display: flex;
  width: fit-content;
  column-gap: 50px;
`;

export const SearchAreaContainer = styled.div`
  column-gap: 10px;
  display: flex;
  align-items: center;
`;

export const SearchInput = styled.input`
  border: 0;
  border-bottom: 1px;
  border-color: #6c7074;
  border-style: solid;
  width: 200px;
  outline: none;
`;

export const Icon = styled.img`
  height: 20px;
  width: 20px;
`;

export const TableAnalyticsColumnContainer = styled.thead`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const TableAnalyticsColumn = styled.col`
  border: 0;
  border-right-width: 0.5px;
  border-right-style: solid;
  border-right-color: #bdbdbd;
`;

export const TableColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TablePaginationContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: end;
  align-items: center;
  margin-top: 30px;
`;

export const TableDivWrapper = styled.div`
  width: 100%,
  overflowY: hidden,
  minHeight: 301px,
`;
export const TablesAnalyticsTable = styled(Table)`
  margin-top: 20px;
  overflow-x: auto;
`;

export const MostJoinedTableData = styled.td`
  text-align: start;
`;

export const UsedQueriesLoaderContainer = styled.div`
  width: 100%;
  height: 170px;
  justify-content: center;
  align-items: center;
`;
