import { useCallback, useMemo, useState } from "react";
import {
  AssetFilterType,
  defaultAssetFilter,
  defaultAssetFilterRule,
  getFilterQueryConditionsFromColumn,
  getFilterTableConditionsFromColumn,
} from "../core";
import { AnalyticsClient } from "../infrastructure";
import {
  QuerySourceDropdownValues,
  TableDropdownRules,
  createBusinessUnitFilterFromCompanyData,
  createDepartmentFilterFromCompanyData,
  createJobTitleFilterFromCompanyData,
} from "../application";
import { useGlobalAuthState } from "./user";

export const useAssetFilters = (eventPrefix?: string) => {
  const { companyStructure } = useGlobalAuthState();
  const eventPrefixName = useMemo(() => {
    return eventPrefix ?? "main_home";
  }, [eventPrefix]);
  const [filter, setFilter] = useState<AssetFilterType>(defaultAssetFilter);

  const addRule = () => {
    setFilter((prevFilters) => ({
      ...prevFilters,
      rules: [...prevFilters.rules, defaultAssetFilterRule],
    }));
    AnalyticsClient.trackAction(
      `${eventPrefixName}_add_rule_button_click` as any
    );
  };

  const removeRule = (id) => {
    setFilter((prevFilter) => {
      const rules = [...prevFilter.rules];
      rules.splice(id, 1);
      const updatedFilter = { ...prevFilter, rules };
      return updatedFilter;
    });
  };

  const onUpdateRules = (id: number, updatedRule: any) => {
    setFilter((prevFilter) => {
      const updatedFilter = { ...prevFilter };
      const updatedRules = [...prevFilter.rules];
      updatedRules[id] = { ...updatedRule };
      updatedFilter.rules = updatedRules;
      return updatedFilter;
    });
  };

  const onChangeQueriedIn = (e: any) => {
    const value = e.target.value;
    setFilter((prev) => ({
      ...prev,
      queriedIn: { ...prev.queriedIn, value },
    }));
    AnalyticsClient.trackAction(
      `${eventPrefixName}_change_queried_in_button_click` as any,
      {
        value,
      }
    );
  };

  const onChangeLastQueriedBy = (lastQueriedBy: any) => {
    setFilter((prev) => ({
      ...prev,
      lastQueriedBy: {
        ...prev.lastQueriedBy,
        value: lastQueriedBy,
      },
    }));
  };

  const resetFilters = () => {
    setFilter(defaultAssetFilter);
    AnalyticsClient.trackAction(
      `${eventPrefixName}_reset_filters_button_click` as any
    );
  };

  const getFilterRuleConditions = useCallback(
    (asset: "query" | "table") => {
      return (id: string) => {
        const rule = filter.rules[id];
        if (asset === "query" && rule.column)
          return getFilterQueryConditionsFromColumn(rule.column) ?? [];
        if (asset === "table" && rule.column)
          return getFilterTableConditionsFromColumn(rule.column);
        return [];
      };
    },
    [filter.rules]
  );

  const getRuleDropdownState = useCallback(
    (asset: "query" | "table") => {
      return (id: string) => {
        const rule = filter.rules[id];
        if (rule?.column && asset === "table") {
          return TableDropdownRules.includes(rule.column);
        }
        return false;
      };
    },
    [filter]
  );

  const getDropdownRuleValues = useCallback(
    (id: string) => {
      const rule = filter.rules[id];
      if (rule?.column) {
        const column = rule?.column;
        if (column === "querySource" || column === "source") {
          return createJobTitleFilterFromCompanyData(QuerySourceDropdownValues);
        }
        if (column === "deptName") {
          // selecting department from a selected business unit.
          const businessUnitFilter = filter?.rules?.find(
            (rule) => rule.column === "businessUnit"
          );
          let departments: any;
          if (businessUnitFilter) {
            departments = createDepartmentFilterFromCompanyData(
              companyStructure.companyStructure,
              businessUnitFilter?.value
            );
          } else {
            departments = createDepartmentFilterFromCompanyData(
              companyStructure.companyStructure
            );
          }
          return departments;
        } else if (column === "businessUnit") {
          const units = createBusinessUnitFilterFromCompanyData(
            companyStructure?.companyStructure
          );
          return units;
        } else if (column === "jobTitle") {
          const titles = createJobTitleFilterFromCompanyData(
            companyStructure?.JobTitle
          );
          return titles;
        }
      }
      return [];
    },
    [filter]
  );

  return {
    addRule,
    removeRule,
    filter,
    onUpdateRules,
    resetFilters,
    onChangeLastQueriedBy,
    onChangeQueriedIn,
    setFilter,
    getFilterRuleConditions,
    getRuleDropdownState,
    getDropdownRuleValues,
  };
};
