export const debounce = (cb: any, delay?: number) => {
  let timeout: NodeJS.Timeout;
  return function (this: any, ...args) {
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      cb.apply(this, args);
    }, delay ?? 1000);
  };
};

export const setMaxString = (str: string, length: number) => {
  if (!str) return "";
  if (str.length < length) return str;
  const substring = str.substring(0, length);
  return substring + "...";
};
